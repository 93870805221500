import React, { FC, FunctionComponent, PropsWithChildren, useRef, useState } from 'react'

import styles from "./SaivaMultiModal.module.scss"
import { useOnClickOutside } from 'usehooks-ts'

interface SaivaMultiModal {

}

export default function SaivaMultiModal(props: PropsWithChildren<SaivaMultiModal>) {

  const anyOpen = () => {
    let isOpen = false
    React.Children.forEach(props.children, element => {
      if (!React.isValidElement(element)) return
      const { open } = element.props
      if(open) isOpen = true
    })
    return isOpen
  }

  if(anyOpen()) {
    return (
      <div className={`${styles.saivaMultiModalWrapper}`}>
        <div className={`${styles.saivaMultiContent}`}>
          {props.children}
        </div>
      </div>
    )
  }
  else return null
}

