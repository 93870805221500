import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mixpanelInstance } from 'utils/mixpanel'

import { IntegrationType, SaivaIntegration, StatusType } from 'types/integration-types'
import IntegrationService, { IntegrationRequest } from 'services/integration-service'
import { showErrorToast } from 'utils'
import { handleError } from 'utils/errorHandler'
import { useUserContext } from 'context/UserContext'
import LoadingSpinner from 'components/LoadingSpinner'
import { useConfirmModal } from 'components/ConfirmModal/ConfirmModal'

import styles from './IntegrationModal.module.scss'

import { ReactComponent as SFTPIcon } from '../../../assets/icons/sftp-icon.svg'
import { ReactComponent as SdkApiIcon } from '../../../assets/icons/sdk-api-icon.svg'
import { ReactComponent as WebhookIcon } from '../../../assets/icons/webhook-icon.svg'
import { ReactComponent as InfoIcon } from '../../../assets/icons/exclamation.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as DisconnectIcon } from 'assets/icons/disconnect.svg'
import { ReactComponent as TestIcon } from 'assets/icons/test.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg'
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation.svg'
import Switch from '../../../components/Buttons/Switch'
import { useFormContext } from '../../../context/FormProvider'
import { CanceledError } from 'axios'
import { UserPermission } from '../../../types/user-types'
import { SaivaMultiModalItem } from '../../../components/SaivaMultiModal/SaivaModalItem/SaivaMultiModalItem'
import SaivaMultiModal from '../../../components/SaivaMultiModal/SaivaMultiModal'
import StatusOptions = SaivaIntegration.StatusOptions
import ActionOptions = SaivaIntegration.ActionOptions
import DirectCatalogItem = SaivaIntegration.DirectCatalogItem
import AccessControl from 'components/AccessControl/AccessControl'

export const getIntegrationIcon = (id: string | undefined) : ReactNode => {
  switch (id) {
    case "sftp-icon":
      return <SFTPIcon />
    case "sdk-api-icon":
      return <SdkApiIcon />
    case "webhook-icon":
      return <WebhookIcon />
    default:
      return null
  }
}

const asyncInterval = async (callback: () => Promise<boolean>, ms: number, signal: AbortSignal, triesLeft: number = 100) : Promise<void> => {
  if (signal.aborted) {
    return Promise.reject("SIGNAL INITIALY ABORTED");
  }
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      if (await callback()) {
        resolve();
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        reject(new Error("All tries used"));
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
    signal.addEventListener('abort', () => {
      clearInterval(interval);
      reject(new CanceledError());
    });
  });
}


export const useIntegrationStatus = (props: {statusBase: StatusType | undefined, enabled: boolean | undefined} | undefined) => {

  const {statusBase, enabled} = props ? props : {statusBase: undefined, enabled: undefined}

  const isUpdating = useMemo(() => {
    if(statusBase === StatusType.TESTING) return true
    if(statusBase === StatusType.CONNECTING) return true
    if(statusBase === StatusType.DISCONNECTING) return true
    if(statusBase === StatusType.UPDATING) return true
    return false
  }, [enabled, statusBase])

  const isEnabled = useMemo(() => {
    if(statusBase === StatusType.CONNECTING) return false
    if(statusBase === StatusType.TESTING) return false
    if(statusBase === StatusType.DISCONNECTING) return false
    if(statusBase === StatusType.UPDATING) return false
    if(statusBase === StatusType.UNAVAILABLE) return false
    if(statusBase === StatusType.DISCONNECTED) return true
    return enabled
  }, [enabled, statusBase])

  const isConnected = useMemo(() => {
    if(statusBase === StatusType.CONNECTED) return true
    if(statusBase === StatusType.UPDATING) return true
    if(statusBase === StatusType.TESTING) return true
    if(statusBase === StatusType.DISCONNECTING) return true
    return false
  }, [statusBase])

  const canMakeChanges = useMemo(() => {
    if(statusBase === StatusType.CONNECTED) return true
    if(statusBase === StatusType.DISCONNECTED) return true
    return false
  }, [statusBase])

  const canGetData = useMemo(() => {
    if (statusBase === StatusType.DISCONNECTED) return false
    if (statusBase === StatusType.UNAVAILABLE) return false
    return true
  }, [statusBase])

  return {
    isUpdating,
    isConnected,
    isEnabled,
    canMakeChanges,
    canGetData,
  }
}

export interface IntegrationModalProps<T> {
  item: SaivaIntegration.DirectCatalogItem<T>
  updateItem: (item: Partial<SaivaIntegration.DirectCatalogItem<T>>) => void
  children: ReactNode | ((handleConnect: () => Promise<boolean>, handleDisconnect: (confirmCheck?: boolean) => Promise<boolean>) => ReactNode),
  additionalModals?: ((handleConnect: () => Promise<boolean>, handleDisconnect: () => Promise<boolean>) => ReactNode)
  open: boolean,
  handleClose: () => void,
  getUpdatePayload?: () => IntegrationRequest.Update<T>,
  getCreatePayload?: () => IntegrationRequest.Create<T>,
  disconnectMessage?: string,
  doNotCheckDirty?: boolean,
  doNotAwaitStatus?: boolean,
  hasTest?: boolean,
  hasUpdate?: boolean,
  hasEnabled?: boolean,
  hasDisconnectBtn?: boolean,
  handleDisconnect?: () => void,
  mixpanelEventsData?:  {
    integration_type: string | undefined,
    organisation: string | undefined,
    user: string,
    facilities?: string[]
    reports?: any
  } 
}


export default function IntegrationModalWrapper<T extends IntegrationType>(props: IntegrationModalProps<T>) {

  const { t } = useTranslation()
  const userContext = useUserContext()
  const confirmModal = useConfirmModal()

  const { open, doNotCheckDirty = false, doNotAwaitStatus = false, hasDisconnectBtn = true, hasTest = false, hasEnabled = false, hasUpdate = false,
    getUpdatePayload, getCreatePayload, updateItem, mixpanelEventsData
  } = props
  const {getValues, setValue, isValid, isDirty, clearErrors, clear} = useFormContext()


  const {id, statusBase, statusReceived, userGuideUrl} = props.item


  const values = getValues<SaivaIntegration.DataType>()

  const [controller, setController] = useState<AbortController>(new AbortController())
  const [loading, setLoading] = useState<boolean>(false)

  const setData = (value: Partial<SaivaIntegration.DataType>) => {
    //@ts-ignore
    updateItem({ isEnabled: value.isEnabled, id: props.item.id })
    setValue<SaivaIntegration.DataType>(value)
  }

  const setStatus = (value: SaivaIntegration.Status) => {
    const partialItem : Partial<SaivaIntegration.DirectCatalogItem<T>> = {statusReceived: value}
    const base = getStatusBase(value)
    if(base) partialItem.statusBase = base
    updateItem(partialItem)
  }

  const getStatusBase = (statusReceived: SaivaIntegration.Status) : StatusType | undefined => {
    if(statusReceived?.action === SaivaIntegration.ActionOptions.UPDATE) {
      if (statusReceived.status === SaivaIntegration.StatusOptions.FAILED) return StatusType.CONNECTED
      if (statusReceived.status === SaivaIntegration.StatusOptions.IN_PROGRESS) return StatusType.UPDATING
      if (statusReceived.status === SaivaIntegration.StatusOptions.COMPLETE) return StatusType.CONNECTED
    }
    if(statusReceived?.action === SaivaIntegration.ActionOptions.TEST) {
      if (statusReceived.status === SaivaIntegration.StatusOptions.FAILED) return StatusType.CONNECTED
      if (statusReceived.status === SaivaIntegration.StatusOptions.IN_PROGRESS) return StatusType.TESTING
      if (statusReceived.status === SaivaIntegration.StatusOptions.COMPLETE) return StatusType.CONNECTED
    }
    if(statusReceived?.action === SaivaIntegration.ActionOptions.CONNECT) {
      if (statusReceived.status === SaivaIntegration.StatusOptions.FAILED) return StatusType.DISCONNECTED
      if (statusReceived.status === SaivaIntegration.StatusOptions.IN_PROGRESS) return StatusType.CONNECTING
      if (statusReceived.status === SaivaIntegration.StatusOptions.COMPLETE) return StatusType.CONNECTED
    }
    if(statusReceived?.action === SaivaIntegration.ActionOptions.DISCONNECT) {
      if (statusReceived.status === SaivaIntegration.StatusOptions.FAILED) return StatusType.CONNECTED
      if (statusReceived.status === SaivaIntegration.StatusOptions.IN_PROGRESS) return StatusType.DISCONNECTING
      if (statusReceived.status === SaivaIntegration.StatusOptions.COMPLETE) return StatusType.DISCONNECTED
    }
  }

  const fetchIntegration = async () => {
    try {
      if(id) {
        setLoading(true)
        const integration = await IntegrationService.getIntegration(userContext.currentOrg.id, id)
        if(integration) setData({...integration, status: props.item.statusBase === StatusType.TESTING ? StatusType.TESTING : integration.status})
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
      clear()
    }
  }

  const awaitConnection = async (action: SaivaIntegration.ActionOptions = SaivaIntegration.ActionOptions.CONNECT, cont: AbortController = controller): Promise<SaivaIntegration.Status> => {
    try {
      let status: SaivaIntegration.StatusOptions = SaivaIntegration.StatusOptions.IN_PROGRESS
      let last_status: SaivaIntegration.StatusOptions = status
      await asyncInterval(async () : Promise<boolean> => {
        try {
          let res
          if (action === SaivaIntegration.ActionOptions.TEST && id) res = await IntegrationService.getIntegrationTestStatus(userContext.currentOrg.id, id)
          else {
            if(!id) throw new Error("Do not have ID in integration modal")
            res = await IntegrationService.getIntegrationStatus(userContext.currentOrg.id, id)
          }
          if(res && res.status !== last_status) {
            last_status = res.status
            setStatus(res)
            if (res.status !== StatusOptions.IN_PROGRESS) return true
          }
        } catch (e) {
          handleError({id: "StatusGet", error: e, customHandle: true})
        }
        return false
      }, 5000, cont.signal)
      return {status, action}
    } catch (e) {

      if(e instanceof CanceledError) return
      handleError({id: "CheckConnection", error: e})
      updateItem({statusReceived: {status: StatusOptions.FAILED, action}})
    }
  }

  const handleClose = async () => {
    if(isDirty && !isUpdating && !doNotCheckDirty) {
      const confirm = await confirmModal.open({title: "Do you really want to discard changes?"})
      if(confirm) {
        props.handleClose()
        clear()
      }
    } else {
      props.handleClose()
      clear()
    }
  }

  const handleSave = async () => {
    if(isValid() || doNotCheckDirty) {
      if (statusBase !== StatusType.CONNECTED) await handleConnect()
      else await handleUpdate()
    }
  }

  const handleConnect = async () : Promise<boolean> => {
    const action = SaivaIntegration.ActionOptions.CONNECT
    try {
      const payload: IntegrationRequest.Create<T> | undefined = getCreatePayload ? getCreatePayload() : undefined
      setStatus({status: SaivaIntegration.StatusOptions.IN_PROGRESS, action})
      if(!id) throw new Error("Do not have ID in integration modal")
      //@ts-ignore
      const res = await IntegrationService.createIntegration(userContext.currentOrg.id, id, payload)
      if(doNotAwaitStatus) {
        setStatus({status: SaivaIntegration.StatusOptions.COMPLETE, action})
      } else {
        await awaitConnection(action)
      }
      res && setData(res)
      return true
    } catch (e) {
      handleError({id: "IntegrationConnectError", error: e, customHandle: true})
      setStatus({status: StatusOptions.FAILED, action})
      showErrorToast("An error occurred while connection to integration!")
      return false
    }
  }

  const handleUpdate = async () : Promise<boolean> => {
    const action = SaivaIntegration.ActionOptions.UPDATE
    try {
      const payload: IntegrationRequest.Update<T> | undefined = getUpdatePayload ? getUpdatePayload() : undefined
      setStatus({status: SaivaIntegration.StatusOptions.IN_PROGRESS, action})
      if(!id) throw new Error("Do not have ID in integration modal")
      if(!payload) throw new Error("Do not have payload for integration update")
      const res = await IntegrationService.updateIntegration(userContext.currentOrg.id, id, payload)
      if(doNotAwaitStatus) {
        setStatus({status: SaivaIntegration.StatusOptions.COMPLETE, action})
      } else {
        await awaitConnection(action)
      }
      res && setData(res)
      return true
    } catch (e) {
      handleError({id: "IntegrationUpdateError", error: e, customHandle: true})
      setStatus({status: StatusOptions.FAILED, action})
      showErrorToast("An error occurred while updating integration connection!")
      return false
    }
  }

  const handleTest = async () => {
    mixpanelInstance.integrationEventHandler(mixpanelEventsData, 'integrationTest');

    const action = SaivaIntegration.ActionOptions.TEST
    try {
      setStatus({ status: SaivaIntegration.StatusOptions.IN_PROGRESS, action })
      if(!id) throw new Error("Do not have ID in integration modal")
      await IntegrationService.testIntegration(userContext.currentOrg.id, id)
      if(doNotAwaitStatus) {
        setStatus({status: SaivaIntegration.StatusOptions.COMPLETE, action})
      } else {
        await awaitConnection(action)
      }
      return true
    } catch (e) {
      handleError({id: "IntegrationTestError", error: e, customHandle: true})
      setStatus({status: StatusOptions.FAILED, action})
      showErrorToast("An error occurred while testing connection to integration!")
      return false
    }
  }

  const handleDisconnect = async (confirmCheck: boolean = true) : Promise<boolean> => {
    const action = SaivaIntegration.ActionOptions.DISCONNECT
    mixpanelEventsData && mixpanelInstance.integrationEventHandler(mixpanelEventsData, 'integrationDisconnect')

    try {
      if(confirmCheck) {
        const confirm = await confirmModal.open({
          title: "Are you sure you want to disconnect?",
          description: props.disconnectMessage ? props.disconnectMessage : "This action is irreversible. You will lost all data."
        })
        if (!confirm) return false
      }
      setStatus({ status: StatusOptions.IN_PROGRESS, action })
      if(!id) throw new Error("Do not have ID in integration modal")
      await IntegrationService.deleteIntegration(userContext.currentOrg.id, id)
      if(doNotAwaitStatus) {
        setStatus({status: SaivaIntegration.StatusOptions.COMPLETE, action})
      } else {
        await awaitConnection(action)
      }
      if(props.handleDisconnect) props.handleDisconnect()
      clear()
      return true
    } catch (e) {
      setStatus({status: StatusOptions.FAILED, action})
      handleError({id: "IntegrationDeleteError", error: e})
      showErrorToast("An error occurred while disconnecting integration connection!")
      return false
    }
  }

  const checkTesting = async (newController: AbortController) => {
    const test = await IntegrationService.getIntegrationTestStatus(userContext.currentOrg.id, "sftp_export")
    if (test && test.status === StatusOptions.IN_PROGRESS) {
      setStatus(test)
      await awaitConnection(test.action, newController)
    }
  }

  const {isConnected, isUpdating, canMakeChanges, canGetData} = useIntegrationStatus({statusBase, enabled: values?.isEnabled})

  useEffect(() => {
    if(open) {
      if(canGetData) fetchIntegration()
      if(!isUpdating) setStatus(undefined)
    }
  }, [open])



  useEffect(() => {
    controller.abort()
    const newController = new AbortController()
    setController(newController)
    if(!doNotAwaitStatus) {
      if (statusBase === StatusType.CONNECTED) checkTesting(newController)
      if (statusBase === StatusType.CONNECTING) {
        awaitConnection(ActionOptions.CONNECT, newController)
        updateItem({statusReceived: {status: SaivaIntegration.StatusOptions.IN_PROGRESS, action: SaivaIntegration.ActionOptions.CONNECT}})
      }
      if (statusBase === StatusType.UPDATING) {
        awaitConnection(ActionOptions.UPDATE, newController)
        updateItem({statusReceived: {status: SaivaIntegration.StatusOptions.IN_PROGRESS, action: SaivaIntegration.ActionOptions.UPDATE}})
      }
      if (statusBase === StatusType.DISCONNECTING) {
        awaitConnection(ActionOptions.DISCONNECT, newController)
        updateItem({statusReceived: {status: SaivaIntegration.StatusOptions.IN_PROGRESS, action: SaivaIntegration.ActionOptions.DISCONNECT}})
      }
    }
    return(() => {
      newController.abort()
    })
  }, [])

  const IntegrationGuide = () => {
    const handleRedirect = () => {
      if(userGuideUrl) {
        window.open(userGuideUrl)
      } else {
        handleError({id: "IntegrationGuideRedirect", error: new Error()})
      }
    }

    if(!userGuideUrl) return null
    return (
      <div className={styles.integrationGuide}>
        <div onClick={handleRedirect}><HelpIcon /> Integration User Guide</div>
      </div>
    )
  }

  const getFileStatus = () : string => {
    let str = ""
    if(!statusReceived) return ""

    if(statusReceived.filesUploaded !== null && statusReceived.filesUploaded !== undefined) {
      if (statusReceived.filesRequested !== null && statusReceived.filesRequested !== undefined) {
        str += ": " + statusReceived.filesUploaded + " from " + statusReceived.filesRequested + " files exported."
      } else {
        str += ": " + statusReceived.filesUploaded + " files exported"
      }
    }

    if(statusReceived.errorCode) {
      switch(statusReceived.errorCode) {
        case "sftp_export_integration_test_no_report_to_export":
          str += " (No data is available)"
          break
      }
    }
    return str
  }

  const Status = () => {
    return (
      <>
        {statusReceived?.status === SaivaIntegration.StatusOptions.IN_PROGRESS &&
          <div className={`${styles.banner}`}>
            <WarningIcon />
            <p>
              {statusReceived.action === SaivaIntegration.ActionOptions.CONNECT && "Integration is connecting… this will take a moment"}
              {statusReceived.action === SaivaIntegration.ActionOptions.TEST && "Test is running… this will take a moment"}
              {statusReceived.action === SaivaIntegration.ActionOptions.UPDATE && "Integration connection is updating"}
              {statusReceived.action === SaivaIntegration.ActionOptions.DISCONNECT && "Integration is disconnecting"}
            </p>
            <LoadingSpinner />
          </div>
        }
        {statusReceived?.status === SaivaIntegration.StatusOptions.COMPLETE &&
          <div className={`${styles.banner} ${styles.bannerSuccess}`}>
            <CheckCircleIcon />
            <p>
              {statusReceived.action === SaivaIntegration.ActionOptions.CONNECT && "The integration was successfully connected."}
              {statusReceived.action === SaivaIntegration.ActionOptions.TEST && `Test completed successfully${getFileStatus()}`}
              {statusReceived.action === SaivaIntegration.ActionOptions.UPDATE && "The integration connection was successfully updated."}
              {statusReceived.action === SaivaIntegration.ActionOptions.DISCONNECT && "The integration was successfully disconnected."}
            </p>
          </div>
        }
        {statusReceived?.status === SaivaIntegration.StatusOptions.FAILED &&
          <div className={`${styles.banner} ${styles.bannerFailed}`}>
            <InfoIcon />
            <p>
              {statusReceived.action === SaivaIntegration.ActionOptions.CONNECT && "Connection to the integration failed."}
              {statusReceived.action === SaivaIntegration.ActionOptions.TEST && `An error occurred while testing${getFileStatus()}`}
              {statusReceived.action === SaivaIntegration.ActionOptions.UPDATE && "Update of the the integration connection failed."}
              {statusReceived.action === SaivaIntegration.ActionOptions.DISCONNECT && "You can’t disconnect this integration, connect with other admin users."}
            </p>
          </div>
        }
      </>
    )
  }

  return (
    <SaivaMultiModal>
      <SaivaMultiModalItem open={open} handleClose={handleClose}>
          <div className={styles.integrationsModal}>
            <div className={styles.header}>
              <div className={styles.row}>
                <div className={styles.row}>
                  <div className={styles.imageContainer}>
                    {getIntegrationIcon(props.item?.icon)}
                  </div>
                  <div>
                    <h4>{props.item?.label}</h4>
                    <p>{props.item?.shortDescription}</p>
                  </div>
                </div>
                <div className={"d-flex"}>
                  {hasDisconnectBtn && isConnected && 
                    <AccessControl userPermissions={[UserPermission.INTEGRATIONS_DELETE]} >
                      <button className={styles.disconnected} onClick={() => handleDisconnect()} disabled={!canMakeChanges}>
                        DISCONNECT <DisconnectIcon />
                      </button>
                    </AccessControl>
                  }
                  {isConnected &&
                    <button className={styles.connected}>
                      CONNECTED <CheckCircleIcon />
                    </button>
                  }
                </div>
              </div>
              <div className={styles.row}>
                {isConnected && hasEnabled &&
                  <Switch
                    <SaivaIntegration.CatalogItem>
                    id={"isEnabled"}
                    disabled={isUpdating || loading}
                    onChange={(e) => {
                      mixpanelInstance.integrationEventHandler({...mixpanelEventsData, is_enabled: e}, "integrationSwitched")
                    }}
                  />
                }
              </div>
            </div>
            <div className={styles.content}>
              {loading && <div className={styles.loadingWrapper}>
                <LoadingSpinner />
              </div>}
              {typeof props.children === "function" ? props.children(handleConnect, handleDisconnect) : props.children}
            </div>
            {!doNotAwaitStatus && <Status />}
            <div className={styles.footer}>
              {hasTest && isConnected && <div className={styles.testButtonContainer}><button className={"primary-text-button"} disabled={!canMakeChanges} onClick={handleTest}><TestIcon />TEST</button></div>}
              <IntegrationGuide />
              {hasUpdate && <div className={styles.buttons}>
                <AccessControl userPermissions={[UserPermission.INTEGRATIONS_CONNECT]} >
                  {isConnected ?
                    <button className={"primary-button"} onClick={handleSave} disabled={!canMakeChanges || (!isDirty && !doNotCheckDirty)}>SAVE CHANGES</button>
                  :
                    <button className={"primary-button"} onClick={handleSave} disabled={!canMakeChanges}>CONNECT</button>
                  }
                </AccessControl>
              </div>}
            </div>
          </div>
      </SaivaMultiModalItem>
      {props?.additionalModals && props.additionalModals(handleConnect, handleDisconnect)}
    </SaivaMultiModal>
  )
}

