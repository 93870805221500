import { Schedules } from "types/schedule-reports";

export const generateSendingTimeOptions = () => {
  const options = [];
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set start time to midnight

  const endTime = new Date();
  endTime.setHours(23, 59, 59, 999); // Set end time to 11:59:59 PM

  const intervalMinutes = 15;

  let currentTime = new Date(startTime);

  while (currentTime <= endTime) {
    const formattedTime = currentTime.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });

    //@ts-ignore
    options.push({
      value: formattedTime,
      label: formattedTime,
    });

    currentTime.setMinutes(currentTime.getMinutes() + intervalMinutes);
  }

  return options;
}

export const getReportName = (reportId) => {
  if (reportId === Schedules.ReportTypes.CROSS_FACILITY_REPORT) return "Cross-facility Risk Report"
  return '';
}

export const timeZoneOptions = [
  { value: 'US/Alaska', label: 'US/Alaska' },
  { value: 'US/Aleutian', label: 'US/Aleutian' },
  { value: 'US/Arizona', label: 'US/Arizona' },
  { value: 'US/Central', label: 'US/Central' },
  { value: 'US/Eastern', label: 'US/Eastern' },
  { value: 'US/East-Indiana', label: 'US/East-Indiana' },
  { value: 'US/Indiana-Starke', label: 'US/Indiana-Starke' },
  { value: 'US/Hawaii', label: 'US/Hawaii' },
  { value: 'US/Michigan', label: 'US/Michigan' },
  { value: 'US/Mountain', label: 'US/Mountain' },
  { value: 'US/Pacific', label: 'US/Pacific' },
  { value: 'US/Samoa', label: 'US/Samoa' },
  { value: 'UTC', label: 'UTC' },
  { value: 'GMT', label: 'GMT' }
];

export const weekOfMonth = [
  { value: 'first', label: 'First' },
  { value: 'last', label: 'Last' }
];

export const fileTypeOpts = [
  { label: 'CSV', value: 'csv' },
  { label: 'PDF', value: 'pdf' }
];

export const weekOptions = [
  { label: 'Mon', value: 'monday', index: 1},
  { label: 'Tue', value: 'tuesday', index: 2},
  { label: 'Wed', value: 'wednesday', index: 3},
  { label: 'Thu', value: 'thursday', index: 4},
  { label: 'Fri', value: 'friday', index: 5},
  { label: 'Sat', value: 'saturday', index: 6},
  { label: 'Sun', value: 'sunday', index: 7},
];

export const repeatOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' }
];

export const repeatOptionsMonthly = [
  { value: 'monthly', label: 'Monthly' }
];

export const repeatOptionsDaily = [
  { value: 'daily', label: 'Daily' },
];


// grup days function
export const getDaysGroup = (days: string[]) => {
  const sorter = {
    "monday": 1,
    "tuesday": 2,
    "wednesday": 3,
    "thursday": 4,
    "friday": 5,
    "saturday": 6,
    "sunday": 7
  }
  const week = [
    'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'
  ]
  const list: string[] = []
  days.sort(function sortByDay(a, b) {
    let day1 = a.toLowerCase();
    let day2 = b.toLowerCase();
    return sorter[day1] - sorter[day2];
  });
  for(var i = 0; i < week.length; i++){
    for(var j = 0; j < days.length; j++){
      if (days[j] == week[i] && days[j - 1] != week[i - 1] || days[j] == week[i] && days[j] == 'monday') {
        list.push(week[i])
      }
      if (days[j] == week[i] && days[j + 1] != week[i + 1] || days[j] == week[i] && days[j] == 'sunday') {
        list.push(week[i])
      }
    } 
  }
  const newList: string[] = []
  for(var z = 0; z < list.length; z++){
    if (list[z] == list[z + 1]) {
      newList.push(list[z].substring(0,3))
      z++
    } else {
      newList.push(list[z].substring(0,3) + '-' + list[z + 1].substring(0,3))
      z++
    }
  }
  return newList
}
