import LayoutCards from './LayoutCards'
import RiskCard from '../../components/UI/RiskCard'
import ChartCardRisk from '../../components/UI/ChartCard/ChartCardRisk'
import Keywords from '../../components/UI/Keywords'
import Highlights from '../../components/UI/Highlights'
import { ReactComponent as HighlightIcon } from '../../assets/icons/pinpaper-filled.svg'

const LogicCards = ({ data, type }) => {
  const layouts = {
    xxl: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 4,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 4,
        y: 0,
        w: 4,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 8,
      //   y: 0,
      //   w: 4,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 1,
        w: 8,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 8,
        y: 1,
        w: 4,
        h: 3.5,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 4,
      //   w: 8,
      //   h: 2,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={200}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ],
    xl: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 4,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 4,
        y: 0,
        w: 4,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 8,
      //   y: 0,
      //   w: 4,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 1,
        w: 8,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 8,
        y: 1,
        w: 4,
        h: 3.5,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 3.5,
      //   w: 8,
      //   h: 2,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={200}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ],
    lg: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 6,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 6,
        y: 0,
        w: 6,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 0,
      //   y: 1,
      //   w: 6,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 2,
        w: 12,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 4.5,
        w: 12,
        h: 4,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 8.5,
      //   w: 12,
      //   h: 2.5,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={290}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ],
    md: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 6,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 6,
        y: 0,
        w: 6,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 0,
      //   y: 1,
      //   w: 6,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 2,
        w: 12,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 4.5,
        w: 12,
        h: 4,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 8.5,
      //   w: 12,
      //   h: 2.5,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={290}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ],
    sm: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 6,
        y: 1,
        w: 12,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 0,
      //   y: 2,
      //   w: 12,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 3,
        w: 12,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 5.5,
        w: 12,
        h: 4,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 9.5,
      //   w: 12,
      //   h: 2.5,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={290}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ],
    xs: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 1,
        component: (
          <RiskCard
            type={'risk'}
            title={'Current Rank'}
            value={data.risk_analysis.overview.current_rank}
            risk_values={{
              current_rank_indicator:
                data.risk_analysis.overview.current_rank_indicator,
              current_rank: data.risk_analysis.overview.current_rank,
              rank_yesterday: data.risk_analysis.overview.rank_yesterday
            }}
          />
        )
      },
      {
        i: '2',
        x: 6,
        y: 1,
        w: 12,
        h: 1,
        component: (
          <RiskCard
            type={'calendar'}
            title={'Rank Yesterday'}
            value={data.risk_analysis.overview.rank_yesterday}
          />
        )
      },
      // {
      //   i: '3',
      //   x: 0,
      //   y: 2,
      //   w: 12,
      //   h: 1,
      //   component: (
      //     <RiskCard
      //       type={'score'}
      //       title={'Risk Score'}
      //       value={data.risk_analysis.overview.risk_score}
      //     />
      //   )
      // },
      {
        i: '4',
        x: 0,
        y: 3,
        w: 12,
        h: 2.5,
        component: (
          <Highlights
            icon={<HighlightIcon />}
            title={'Clinical Highlights'}
            data={data.risk_analysis.highlights}
            color='#7F6CE8'
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 5.5,
        w: 12,
        h: 4,
        component: <Keywords data={data.risk_analysis.keywords} />
      },
      // {
      //   i: '6',
      //   x: 0,
      //   y: 9.5,
      //   w: 12,
      //   h: 2.5,
      //   component: (
      //     <ChartCardRisk
      //       title={'Risk Score'}
      //       height={290}
      //       quality_measure={data.quality_measure}
      //     />
      //   )
      // }
    ]
  }
  return <LayoutCards layouts={layouts} />
}

export default LogicCards
