import { UseFormReturn } from 'react-hook-form'
import { Row, Col, Button } from 'antd'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import { SaivaFacility } from 'types/facility-types'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

interface Props {
  form: UseFormReturn<SaivaFacility.Item>
  apiError: any
  regions: any
  onSubmit: (data: SaivaFacility.Item) => any
  handleCancel: () => void
}

const FormLayout = ({ form, apiError, regions, onSubmit, handleCancel }: Props) => {
  const { t } = useTranslation()
  const {
    formState,
    handleSubmit,
    control,
    resetField,
    setValue
  } = form
  const { errors, isSubmitting, isValid } = formState

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Row gutter={[16, 16]} align="top">
        <Col span={12}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('facilityForm.name.placeholder')}
                label={t('facilityForm.name.label')}
                name="name"
                errors={errors.name}
                onChange={(e) => {
                  form.trigger('name')
                  setValue('name', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('facilityForm.address.placeholder')}
                label={t('facilityForm.address.label')}
                name="address"
                errors={errors.address}
                onChange={(e) => {
                  form.trigger('address')
                  setValue('address', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name="zipcode"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('facilityForm.zipcode.placeholder')}
                label={t('facilityForm.zipcode.label')}
                name="zipcode"
                errors={errors.zipcode}
                onChange={(e) => {
                  form.trigger('zipcode')
                  setValue('zipcode', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name="city"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('facilityForm.city.placeholder')}
                label={t('facilityForm.city.label')}
                name="city"
                errors={errors.city}
                onChange={(e) => {
                  form.trigger('city')
                  setValue('city', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name="state"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder={t('facilityForm.state.placeholder')}
                label={t('facilityForm.state.label')}
                name="state"
                errors={errors.state}
                onChange={(e) => {
                  form.trigger('state')
                  setValue('state', e.target.value)
                }}
              />
            )}
          />
        </Col>
        <Col span={12}>
          <Controller
            control={control}
            name="region_id"
            render={({ field }) => (
              <SaivaSelect
                value={field.value}
                placeholder="Select Region"
                label="Region"
                options={regions}
                multiselect={false}
                icon={null}
                reset={() => resetField('region_id')}
                onChange={(e) => {
                  setValue('region_id', e)
                }}
              />
            )}
          />
        </Col>
      </Row>
      {apiError && (
        <>
          <div className={`banner bannerFailed`}>
            <InfoIcon />
            <p>
              {t(apiError)}
            </p>
          </div>
        </>
      )}
      <Row justify="end" style={{ marginTop: '32px' }}>
        <button
          className={'primary-text-button button-base-small'}
          onClick={handleCancel}
        >
          {t('facilityForm.cancelButton.text')}
        </button>
        <Button
            disabled={!isValid}
            loading={isSubmitting}
            className={'primary-button button-base-small'}
            onClick={handleSubmit(onSubmit)}
          >
            {t('facilityForm.saveButton.text')}
          </Button>
      </Row>
    </form>
  )
}

export default FormLayout
