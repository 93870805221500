import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useOnClickOutside, useUpdateEffect } from 'usehooks-ts'

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg'
import { ReactComponent as CalenderIcon } from 'assets/icons/calendar.svg'
import { LocalStorageDateItem } from 'types/reports-types'
import { showErrorToast } from '../../utils'
import styles from './SaivaNewDatePicker.module.scss'

const isEqualMonth = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return false
  return (
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

const isEqualDate = (date1: Date | undefined, date2: Date | undefined) => {
  if (!date1 || !date2) return false
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}

const getQuarter = (date: Date = new Date(), startWithOne = false) => {
  if (startWithOne) return Math.floor(date.getMonth() / 3) + 1
  return Math.floor(date.getMonth() / 3)
}

export enum Range {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Daily = 'daily',
  Day = 'day'
}

export interface IDateRange {
  start: Date | undefined
  end: Date | undefined
  isQuarterly: boolean | undefined
  type: Range
}

export enum DateFiledType {
  Single,
  Start,
  End
}

type SelectedOptions =
  | { start: Date | undefined; end: Date | undefined }
  | Date
  | undefined

interface MakeDateFieldProps {
  label: string
  range: Range
  type: DateFiledType
  onChange: (start: Date | undefined, end: Date | undefined) => void
  defaultValue?: SelectedOptions
  start?: Date
  end?: Date
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
const days = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
const quarters = [0, 1, 2, 3]

const MakeDateField = (props: MakeDateFieldProps) => {
  const { label, range, onChange, defaultValue, type } = props

  const [popup, setPopup] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] =
    useState<SelectedOptions>(defaultValue)
  const [hoveredDate, setHoveredDate] = useState<Date | undefined>(undefined)

  const getDefaultDate = (): { year: number; month: number } => {
    const value = defaultValue
    if (value) {
      if (value instanceof Date) {
        return { year: value.getFullYear(), month: value.getMonth() }
      } else {
        if (value.start) {
          return {
            year: value.start.getFullYear(),
            month: value.start.getMonth()
          }
        }
      }
    }
    return { year: new Date().getFullYear(), month: new Date().getMonth() }
  }

  const [year, setYear] = useState<number>(getDefaultDate().year)
  const [month, setMonth] = useState<number>(getDefaultDate().month)

  const ref = useRef(null)

  const handleClosePopup = () => {
    setPopup(false)
  }

  const handleClickBack = (month) => {
    if (range === Range.Daily || range === Range.Day) {
      if (month === 0) {
        setMonth(11)
        setYear((prev) => {
          prev -= 1
          return prev
        })
      } else {
        setMonth((prev) => --prev)
      }
    } else {
      setYear((prev) => {
        prev -= 1
        return prev
      })
    }
  }

  const handleClickNext = (month) => {
    if (range === Range.Daily || range === Range.Day) {
      if (month === 11) {
        setMonth(0)
        setYear((prev) => {
          prev += 1
          return prev
        })
      } else {
        setMonth((prev) => ++prev)
      }
    } else {
      setYear((prev) => {
        prev += 1
        return prev
      })
    }
  }

  const isInFuture = (date: Date | undefined): boolean => {
    if (!date) return false
    const currentDate = new Date()
    if (range === Range.Monthly)
      return (
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1) <=
        new Date(date)
      )
    if (range === Range.Quarterly)
      return (
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0) <
        date
      )
    return new Date() < date
  }

  const getDateRangeFromQuarter = (
    quarter: number
  ): { start: Date; end: Date } | undefined => {
    if (quarter === 0)
      return { start: new Date(year, 0, 1), end: new Date(year, 3, 0) }
    if (quarter === 1)
      return { start: new Date(year, 3, 1), end: new Date(year, 6, 0) }
    if (quarter === 2)
      return { start: new Date(year, 6, 1), end: new Date(year, 9, 0) }
    if (quarter === 3)
      return { start: new Date(year, 9, 1), end: new Date(year, 12, 0) }
  }

  const getDateFromQuarterByType = (
    quarter: number,
    type: DateFiledType
  ): Date | undefined => {
    if (type === DateFiledType.Start)
      return getDateRangeFromQuarter(quarter)?.start
    if (type === DateFiledType.End) return getDateRangeFromQuarter(quarter)?.end
    return undefined
  }

  const getDateRangeFromMonth = (
    month
  ): { start: Date; end: Date } | undefined => {
    if (month < 0 || month > 11) return undefined
    return {
      start: new Date(year, month, 1),
      end: new Date(year, month + 1, 0)
    }
  }

  const getDateFromMonthByType = (
    month: number,
    type: DateFiledType
  ): Date | undefined => {
    if (type === DateFiledType.Start) return getDateRangeFromMonth(month)?.start
    if (type === DateFiledType.End) return getDateRangeFromMonth(month)?.end
    return undefined
  }

  const isSelected = (date: SelectedOptions) => {
    if (
      !selectedOption ||
      selectedOption instanceof Date ||
      !(date instanceof Date)
    ) {
      if (selectedOption instanceof Date && date instanceof Date)
        return isEqualDate(selectedOption, date)
      return false
    }
    return (
      isEqualDate(selectedOption.end, date) ||
      isEqualDate(selectedOption.start, date)
    )
  }

  const isBetween = (date: Date) => {
    if (selectedOption instanceof Date) return false
    return !!(
      selectedOption?.start &&
      date > selectedOption?.start &&
      ((selectedOption?.end && selectedOption?.end > date) ||
        (!selectedOption?.end && hoveredDate && hoveredDate > date))
    )
  }

  const getDateRange = useMemo(() => {
    const now = new Date(year, month)
    let firstDay = new Date(now.getFullYear(), now.getMonth(), 1).getDay() - 1
    if (firstDay === -1) firstDay = 6
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
    const monthBeforeLastDay = new Date(now.getFullYear(), now.getMonth(), 0)

    const before: number[] = [],
      current: number[] = [],
      after: number[] = []
    const length = Math.ceil((firstDay + lastDay.getDate()) / 7) * 7

    let countMonth = 1,
      countEnd = 1

    for (let i = 0; i < length; i++) {
      if (i < firstDay) {
        before.push(monthBeforeLastDay.getDate() - (firstDay - i - 1))
      } else if (i < firstDay + lastDay.getDate()) {
        current.push(countMonth)
        countMonth++
      } else {
        after.push(countEnd)
        countEnd++
      }
    }
    return { before, current, after }
  }, [month, year])

  const getDateFormat = (date: Date) => {
    var month =
      (date.getMonth() + 1).toString().length < 2
        ? '0' + (date.getMonth() + 1).toString()
        : date.getMonth() + 1

    var day =
      date.getDate().toString().length < 2
        ? '0' + date.getDate().toString()
        : date.getDate()

    let year = date.getFullYear()

    return `${month}/${day}/${year}`
  }

  const getLabel = (type: Range) => {
    if (!selectedOption) return 'Select date'
    if (type === Range.Quarterly && selectedOption instanceof Date)
      return (
        'Q' +
        getQuarter(selectedOption, true) +
        ' ' +
        selectedOption.getFullYear()
      )
    if (type === Range.Monthly && selectedOption instanceof Date)
      return (
        months[selectedOption.getMonth()] + ' ' + selectedOption.getFullYear()
      )
    if (type === Range.Day && selectedOption instanceof Date)
      return getDateFormat(selectedOption)
    if (
      !(selectedOption instanceof Date) &&
      type === Range.Daily &&
      selectedOption.end &&
      selectedOption.start
    )
      return (
        getDateFormat(selectedOption.start) +
        ' - ' +
        getDateFormat(selectedOption.end)
      )
    return 'Select date'
  }

  const handleSelectOptionChange = (value: SelectedOptions) => {
    if (value instanceof Date) {
      setSelectedOption(value)
      if (props.start || props.end) {
        onChange(value, undefined)
      } else {
        onChange(value, value)
      }
    } else {
      setSelectedOption(value)
      if (value && value.start && value.end) {
        onChange(value.start, value.end)
      }
    }
  }

  const handleSelectOption = (date: Date | undefined) => {
    if (!date || isInFuture(date)) return
    if (range === Range.Daily && !(selectedOption instanceof Date)) {
      if (
        selectedOption?.start &&
        !selectedOption?.end &&
        date >= selectedOption?.start
      ) {
        handleSelectOptionChange({ start: selectedOption.start, end: date })
        setPopup(false)
      } else {
        handleSelectOptionChange({ start: date, end: undefined })
      }
    } else {
      if (props.start) {
        if (props.start < date) {
          handleSelectOptionChange(date)
        } else {
          showErrorToast('End date should be grater or equal to start date')
        }
      } else if (props.end) {
        if (props.end > date) {
          handleSelectOptionChange(date)
        } else {
          showErrorToast('End date should be grater or equal to start date')
        }
      } else {
        handleSelectOptionChange(date)
      }
      setPopup(false)
    }
  }

  const handleHover = (year, month, day) => {
    const date = new Date(year, month, day)
    if (date > new Date()) setHoveredDate(new Date())
    else setHoveredDate(date)
  }

  useOnClickOutside(ref, handleClosePopup)

  useEffect(() => {
    setSelectedOption(defaultValue)
    const defaultDate = getDefaultDate()
    setYear(defaultDate.year)
    setMonth(defaultDate.month)
  }, [defaultValue])

  const getDay = (day, offset) => {
    const currentDate = new Date(),
      offsetDay = new Date(year, month + offset, day)

    return (
      <div
        key={day}
        onMouseEnter={() => handleHover(year, month + offset, day)}
        onMouseLeave={() => setHoveredDate(undefined)}
        className={`${styles.option}
          ${offset !== 0 && styles.outside}
          ${isEqualDate(currentDate, offsetDay) && styles.currentDay} 
          ${isBetween(offsetDay) && range === Range.Daily && styles.middle} 
          ${isSelected(offsetDay) && styles.optionActive} 
          ${isInFuture(offsetDay) && styles.disabled}
        `}
        onClick={() => handleSelectOption(offsetDay)}
      >
        {day}
      </div>
    )
  }

  return (
    <div className={styles.pickerWrapper} ref={ref}>
      <div className={styles.buttonAndDateWrapper}>
        <div
          className={`${styles.button} ${popup && styles.activeButton}`}
          onClick={() => setPopup((prev) => !prev)}
        >
          <CalenderIcon /> Date
        </div>
        <label className={`${selectedOption && styles.active}`}>
          {getLabel(range)}
        </label>
      </div>
      {popup && (
        <div
          className={`${styles.popup} ${
            range === Range.Daily && styles.popupDaily
          }`}
        >
          <div className={styles.header}>
            <div className={styles.box} onClick={() => handleClickBack(month)}>
              <MoreIcon className={`${styles.leftIcon} ${styles.icon}`} />
            </div>
            <label>
              <>
                {range === Range.Daily || range === Range.Day
                  ? months[month]
                  : ''}{' '}
                {year}
              </>
            </label>
            <div className={styles.box} onClick={() => handleClickNext(month)}>
              <MoreIcon className={`${styles.rightIcon} ${styles.icon}`} />
            </div>
          </div>
          <div className={styles.content}>
            {range === Range.Monthly && (
              <div className={`${range}`}>
                {months.map((month, index) => {
                  if (selectedOption instanceof Date)
                    return (
                      <div
                        key={month}
                        className={`${styles.option} ${
                          isEqualMonth(
                            selectedOption,
                            getDateFromMonthByType(index, type)
                          ) && styles.optionActive
                        } ${
                          isInFuture(getDateRangeFromMonth(index)?.start) &&
                          styles.disabled
                        }`}
                        onClick={() =>
                          handleSelectOption(
                            getDateFromMonthByType(index, type)
                          )
                        }
                      >
                        {month}
                      </div>
                    )
                  return null
                })}
              </div>
            )}
            {range === Range.Quarterly && (
              <div className={`${range}`}>
                {quarters.map((quarter) => {
                  if (!(selectedOption instanceof Date)) return null
                  return (
                    <div
                      key={quarter}
                      className={`option quarter ${
                        isEqualMonth(
                          selectedOption,
                          getDateFromQuarterByType(quarter, type)
                        )
                          ? 'option-active'
                          : ''
                      } ${
                        isInFuture(getDateRangeFromQuarter(quarter)?.start)
                          ? 'disabled'
                          : ''
                      }`}
                      onClick={() =>
                        handleSelectOption(
                          getDateFromQuarterByType(quarter, type)
                        )
                      }
                    >
                      Q{quarter + 1}
                    </div>
                  )
                })}
              </div>
            )}
            {(range === Range.Daily || range === Range.Day) && (
              <div className={styles.daily}>
                {days.map((day) => {
                  return (
                    <div
                      key={day}
                      className={`${styles.optionWithoutHover} ${styles.outside}`}
                    >
                      {day}
                    </div>
                  )
                })}
                {getDateRange.before.map((day) => {
                  return getDay(day, -1)
                })}
                {getDateRange.current.map((day) => {
                  return getDay(day, 0)
                })}
                {getDateRange.after.map((day) => {
                  return getDay(day, +1)
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

interface ComponentProps {
  onDateChange: (
    value: {
      start: Date | undefined
      end: Date | undefined
      isQuarterly: boolean
      type: Range
    },
    range: Range
  ) => void
  rangeOptions: Range[]
  onRangeChange?: (newRange: Range) => void
  defaultValue?: LocalStorageDateItem
}

interface DateFieldProps {
  start: Date | undefined
  end: Date | undefined
}

const DatePickerCard = (props: ComponentProps) => {
  const { onDateChange, rangeOptions, defaultValue } = props

  const [range, setRange] = useState<Range>(
    defaultValue?.defaultRange &&
      rangeOptions.includes(defaultValue.defaultRange)
      ? defaultValue.defaultRange
      : rangeOptions[0]
  )

  const [date, setDate] = useState<DateFieldProps | undefined>(defaultValue?.[range])

  // FUNCTIONS

  const setStartDate = (start) => {
    setDate((prev) => {
      return { start, end: prev?.end }
    })
  }

  const setEndDate = (end) => {
    setDate((prev) => {
      return { start: prev?.start, end }
    })
  }

  const handleSetDate = (start, end) => {
    setDate({ start, end })
  }

  // EFFECTS

  useEffect(() => {
    const isQuarterly = range === Range.Quarterly ? true : false
    onDateChange(
      {
        start: date?.start,
        end: date?.end,
        isQuarterly: isQuarterly,
        type: range
      },
      range
    )
  }, [date])

  useEffect(() => {
    setDate(defaultValue ? defaultValue[range] : undefined)
  }, [range])

  // COMPONENTS

  return (
    <div className={styles.dateCardWrapper}>
      {range === Range.Monthly && (
        <div className={styles.monthlyWrapper}>
          <MakeDateField
            label={'Start Date'}
            range={Range.Monthly}
            type={DateFiledType.Start}
            end={date?.end}
            onChange={(date) => setStartDate(date)}
            defaultValue={defaultValue?.monthly?.start}
          />
          <MakeDateField
            label={'End Date'}
            range={Range.Monthly}
            type={DateFiledType.End}
            start={date?.start}
            onChange={(date) => setEndDate(date)}
            defaultValue={defaultValue?.monthly?.end}
          />
        </div>
      )}
      {range === Range.Quarterly && (
        <div className={styles.monthlyWrapper}>
          <MakeDateField
            label={'Start Date'}
            range={Range.Quarterly}
            type={DateFiledType.Start}
            end={date?.end}
            onChange={(date) => setStartDate(date)}
            defaultValue={defaultValue?.quarterly?.start}
          />
          <MakeDateField
            label={'End Date'}
            range={Range.Quarterly}
            type={DateFiledType.End}
            start={date?.start}
            onChange={(date) => setEndDate(date)}
            defaultValue={defaultValue?.quarterly?.end}
          />
        </div>
      )}
      {(range === Range.Daily || range === Range.Day) && (
        <div>
          <MakeDateField
            label={'Date'}
            range={range}
            type={DateFiledType.Single}
            onChange={handleSetDate}
            defaultValue={
              range === Range.Daily
                ? defaultValue?.daily
                : defaultValue?.day?.start
            }
          />
        </div>
      )}
    </div>
  )
}

export default DatePickerCard
