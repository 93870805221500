import { parseErrorResponse } from './api'
import { IBasicTableData } from '../components/Tables/BasicTable/BasicTable'
import { IDateRange, Range } from '../components/DatePicker/datePicker'
import { FilterType, FormatTypes, IGeneralReportData, LocalStorageDateItem, SaivaReport } from '../types/reports-types'
import { IPeriodTableData } from '../components/Tables/PeriodTable/dataTable'
import { IFileTableData } from '../components/Tables/FileTable/fileTable'
import { handleError } from '../utils/errorHandler'
import { generateReportUrl } from './utils/api-utils'
import RegionService from './region-service'
import { get } from './utils/api-client'
import { generateFilterUrl } from './utils/api-utils'
import { OrgProductFeature } from 'types/user-types'

// FILTERS

namespace ReportResponse {

  export namespace Filter {
    export interface OptionItem {
      id: string,
      label: string,
    }

    export interface FacilitySelect {
      type: "facility_select",
    }

    export interface DatePicker {
      type: "date_picker",
      custom_default_range?: LocalStorageDateItem
      ranges: string[]
    }

    export interface Dropdown {
      type: "dropdown",
      id: string,
      label: string
      query_param: string,
      items: {label: string, value: any}[]
      popup_label?: string,
      default_option?: number,
    }

    export interface OptionSelectBase {
      id: string,
      label: string,
      query_param: string,
      manage_button_text?: string,
      no_options_message?: string,
      color?: string,
      required?: boolean
    }

    export interface OptionSelectWithItems extends OptionSelectBase {
      type: "option_select_items",
      items: OptionItem[]
    }

    export interface RadioSelect extends OptionSelectBase {
      type: "radio_select",
      default_option: string,
      items: OptionItem[]
    }

    export interface OptionSelectWithRequest extends OptionSelectBase {
      type: "option_select_request",
      url: string
    }

    export interface MultiOptionSelect {
      type: "multi_option_select",
      option_selects: OptionSelectWithItems[]
    }
  }

  export type Filters =
    Filter.FacilitySelect |
    Filter.DatePicker |
    Filter.Dropdown |
    Filter.OptionSelectWithRequest |
    Filter.OptionSelectWithItems |
    Filter.MultiOptionSelect |
    Filter.RadioSelect

  export namespace Data {
    export interface TableStructure {
      format: "period_table" | "basic_table" | 'saiva_basic_table',
      columns:
        {
          label: string,
          id: string,
          type: "number" | "string" | "percentage" | "date" | "boolean" | "array" | "url",
          sortable?: boolean
        }[],
      cumulative_columns?: TableStructure["columns"]
    }

    export interface TableStructureWithoutColumns {
      format: "file_table"
    }

    export namespace Stats {
      export interface Item {
        type?: "number" | "percentage"
        label: string,
        data: {value: string, increase?: boolean}
        date_range: string
      }

      export interface Group {
        type: "group",
        label: string,
        items: Item[]
      }
    }

    export type Stats = Stats.Group | Stats.Item

    export type Charts = {
      title: string,
      datasets: {
        label: string,
        data: number[]
      }[]
    }[]

    // TODO remove string[]
    export interface Filter {
      [id: string]: ReportResponse.Filter.OptionItem[] | string[]
    }
  }

  export type Data = Data.TableStructure | Data.TableStructureWithoutColumns

  export interface Options {
    pdf_download: {url: string},
    csv_download: {url: string},
    pdf_download_cumulative?: {url: string},
    csv_download_cumulative?: {url: string},
    schedule: boolean,
    exportOptions: boolean
  }

  export interface Catalog {
    categories: {
      label: string,
      id: string,
      report_types: {label: string, id: string, description: string, quality_measure: OrgProductFeature, reports: any}[]
    }[]
  }

  export interface StructureItem {
    id: string,
    url: string,
    label: string,
    flag?: string,
    description: string,
    quality_measure: OrgProductFeature,
    all_reports_in_category: {label: string, id: string}[],
    filters: Filters[],
    data_type: Data,
    report_options?: Options
    view_type: string,
    view_label: string
  }

  export type Structure = StructureItem[]
}

const parseReport = {
  filters: {
    FacilitySelect: (_response: ReportResponse.Filter.FacilitySelect) : SaivaReport.Filter.FacilitySelect => {      
      return {
        type: FilterType.FACILITY_SELECT,
        id: "facilities",
        required: true,
        label: 'Facilities',
        queryParam: "facility_ids",
        manageButtonText: 'Manage Facilities'
      }
    },
    DatePicker: (response: ReportResponse.Filter.DatePicker) : SaivaReport.Filter.DatePicker => {
      return {
        customDefaultRange: response.custom_default_range,
        ranges: response.ranges.map(range => range as Range),
        type: FilterType.DATE_PICKER
      }
    },
    Dropdown: (response: ReportResponse.Filter.Dropdown) : SaivaReport.Filter.Dropdown => {
      return {
        type: FilterType.DROPDOWN,
        id: response.id,
        items: response.items,
        label: response.label,
        popupLabel: response.popup_label,
        queryParam: response.query_param,
        defaultOption: response.default_option
      }
    },
    OptionSelectBase: (response: ReportResponse.Filter.OptionSelectBase) : SaivaReport.Filter.OptionSelectBase => {
      return  {
        id: response.id,
        color: response.color,
        label: response.label,
        manageButtonText: response.manage_button_text,
        noOptionsMessage: response.no_options_message,
        queryParam: response.query_param,
        required: response.required
      }
    },
    OptionSelectWithItems: (response: ReportResponse.Filter.OptionSelectWithItems, defaultValue?: boolean) : SaivaReport.Filter.OptionSelectWithItems => {
      return {
        ...parseReport.filters.OptionSelectBase(response),
        type: FilterType.OPTION_SELECT_WITH_ITEMS,
        items: response.items.map(item => {return {...item, isSelected: defaultValue !== undefined ? defaultValue : true}}),
        label: response.label,
        id: response.id,
        no_options_message: response.no_options_message,
        color: response.color,
        query_param: response.query_param

      }
    },
    RadioSelect: (response: ReportResponse.Filter.RadioSelect, defaultValue?: boolean) : SaivaReport.Filter.RadioSelect => {
      return {
        ...parseReport.filters.OptionSelectBase(response),
        type: FilterType.RADIO_SELECT,
        default_option: response.default_option,
        items: response.items.map(item => {return {...item, isSelected: defaultValue !== undefined ? defaultValue : true}}),
      }
    },
    OptionSelectWithRequest: (response: ReportResponse.Filter.OptionSelectWithRequest) : SaivaReport.Filter.OptionSelectWithRequest => {
      return {
        ...parseReport.filters.OptionSelectBase(response),
        type: FilterType.OPTION_SELECT_WITH_REQUEST,
        fetchData(_organizationId, _controller: AbortController): Promise<SaivaReport.Filter.OptionItem[]> {
          // TODO create universal request and make sure data will came in OptionItem format
          return Promise.resolve([]);
        }
      }
    },
    MultiOptionSelect: (response: ReportResponse.Filter.MultiOptionSelect) : SaivaReport.Filter.MultiOptionSelect => {
      return {
        type: FilterType.MULTI_OPTION_SELECT,
        optionSelects: response.option_selects.map(option => parseReport.filters.OptionSelectWithItems(option)),
        fetchData(_organizationId, _controller: AbortController): Promise<{ [p: string]: SaivaReport.Filter.OptionItem[]} | undefined > {
          return Promise.resolve(undefined);
        }
      }
    }
  },
  data: {
    TableStructure: (response: ReportResponse.Data.TableStructure) : SaivaReport.Data.TableStructure => {
      return {
        ...response,
        format: response.format === "basic_table" ? FormatTypes.BASIC_TABLE : FormatTypes.PERIODIC_TABLE
      }
    },
    TableStructureWithoutColumns: (_response: ReportResponse.Data.TableStructureWithoutColumns) : SaivaReport.Data.TableStructureWithoutColumns => {
      return {
        format: FormatTypes.FILE_TABLE
      }
    },
    Data: (response: ReportResponse.Data) : SaivaReport.Data => {
      if(response.format === "file_table") return parseReport.data.TableStructureWithoutColumns(response)
      return parseReport.data.TableStructure(response)
    },
    StatsItem: (response: ReportResponse.Data.Stats.Item) : SaivaReport.Data.Stats.Item => {
      return {
        label: response.label,
        type: response.type,
        data: response.data,
        dateRange: response.date_range
      }
    },
    StatsGroup: (response: ReportResponse.Data.Stats.Group) : SaivaReport.Data.Stats.Group => {
      return {
        label: response.label,
        type: response.type,
        items: response.items.map(item => parseReport.data.StatsItem(item))
      }
    },
    Stats: (response: ReportResponse.Data.Stats) : SaivaReport.Data.Stats => {
      if(response.type === "group") return parseReport.data.StatsGroup(response)
      return parseReport.data.StatsItem(response)
    },
    Filter: (response: ReportResponse.Data.Filter | undefined) : SaivaReport.Data.Filter | undefined => {
      // TODO - edit when new API will be implemented
      if (!response) return undefined
      const data: SaivaReport.Data.Filter = []
      const keys = Object.keys(response);
      keys.forEach((key) => {
        data.push({
          id: key,
          items: response[key].map((item) => {
            return { id: item.id ? item.id : encodeURIComponent(item), label: item.label ? item.label : item, isSelected: true, parent: item.region_id }
          })
        })
      });
      return data
    }
  },
  Filter: (response: ReportResponse.Filters) : SaivaReport.Filters => {
    switch (response.type) {
      case "date_picker": return parseReport.filters.DatePicker(response)
      case "dropdown": return parseReport.filters.Dropdown(response)
      case "option_select_items": return parseReport.filters.OptionSelectWithItems(response)
      case "option_select_request": return parseReport.filters.OptionSelectWithRequest(response)
      case "multi_option_select": return  parseReport.filters.MultiOptionSelect(response)
      case "radio_select": return parseReport.filters.RadioSelect(response)
      default: return parseReport.filters.FacilitySelect(response)
    }
  },
  Options: (response: ReportResponse.Options | undefined) : SaivaReport.Options | undefined => {
    if(!response) return undefined;

    const {
      csv_download, 
      pdf_download, 
      csv_download_cumulative, 
      pdf_download_cumulative,
      schedule,
      exportOptions
    } = response;

    const downloads = {
      csvDownload: csv_download,
      pdfDownload: pdf_download,
      schedule: schedule,
      exportOptions: exportOptions
    };

    if (csv_download_cumulative) Object.assign(downloads, { csvDownloadCumulative: csv_download_cumulative });
    if (pdf_download_cumulative) Object.assign(downloads, { pdfDownloadCumulative: pdf_download_cumulative });

    return downloads;
  },
  Catalog: (response: ReportResponse.Catalog) : SaivaReport.Categories | undefined=> {
    const colors = [
      {color: "#4070DC", bg: "#D9E2F8"},
      {color: "#A03AFD", bg: "#ECD8FF"},
      {color: "#C9C13E", bg: "rgba(201, 193, 62, 0.2)"},
      {color: "#60C2CA", bg: "rgba(96, 194, 202, 0.2)"},
      {color: "#E19368", bg: "rgba(225, 147, 104, 0.2)"},
      {color: "#5EC7FF", bg: "rgba(94, 199, 255, 0.2)"}
    ]

    return {categories: response?.categories.map((category, i) => {
      return {
        ...category,
        color: colors[i % colors.length]
      }
    })}
  },
  StructureItem: (response: ReportResponse.StructureItem) : SaivaReport.StructureItem => {
    return {
      id: response.id,
      url: response.url,
      label: response.label,
      flag: response?.flag,
      description: response.description,
      quality_measure: response.quality_measure,
      allReportsInCategory: response.all_reports_in_category,
      dataType: parseReport.data.Data(response.data_type),
      filters: response.filters.map(filter => parseReport.Filter(filter)),
      reportOptions: parseReport.Options(response.report_options),
      view_type: response.view_type,
      view_type_label: response.view_label,
    }
  }
}

interface IFileTableDataResponseItem {
  id: string,
  name: string,
  region_name: string,
  reports: {
    date: string,
    id: string
  }[]
}

export type IFileTableResponse = IFileTableDataResponseItem[]

export interface IPeriodTableResponse {
  time_periods: string[],
  organization: {
    name: string,
    data: {
      [id: string]: Array<string| {value: string, detail_url: string}>
    },
    regions: {
      id: string,
      name: string,
      data: {
        [id: string]: string[]
      },
      facilities: {
        id: string,
        name: string,
        data: {
          [id: string]: string[]
        },
      }[]
    }[]
  }
}

export type IBasicTableResponse = any[]

export interface IFileResponse {url: string}

const parseBasicTableResponse = (data: IGeneralReportResponse<IBasicTableResponse>) : IGeneralReportData<IBasicTableData> => {
  try {
    return {
      format: FormatTypes.BASIC_TABLE,
      data: data.data.length === 0 ? undefined : data.data,
      cumulative_data: data?.cumulative_data,
      stats: data.stats?.map(item => parseReport.data.Stats(item)),
      filters: parseReport.data.Filter(data.filters),
    }
  } catch (err) {
    handleError({error: err, id: "ParseBasicTable", track: true})
    return {
      format: FormatTypes.BASIC_TABLE,
      data: undefined,
      stats: undefined,
      filters: undefined
    }
  }
}

const parsePeriodTableResponse = (data: IGeneralReportResponse<IPeriodTableResponse>) : IGeneralReportData<IPeriodTableData> => {
  try {
    return {
      format: FormatTypes.PERIODIC_TABLE,
      data: {
        timePeriods: data.data.time_periods,
        organization: {
          name: data.data.organization.name,
          data: data.data.organization.data,
          regions:  data.data.organization.regions,
          isSelected: true,
          regionsToggle: data.data.organization.regions.map((i) => {return Number(i.id)})
        }
      },
      stats: data.stats?.map(item => parseReport.data.Stats(item)),
      filters: parseReport.data.Filter(data.filters),
      charts: data.charts
    }
  } catch (err) {
    handleError({error: err, id: "ParsePeriodTable", track: true})
    return {
      format: FormatTypes.PERIODIC_TABLE,
      data: undefined,
      stats: undefined,
      filters: undefined
    }
  }
}

const parseFileTableResponse = (data: IGeneralReportResponse<IFileTableData>) : IGeneralReportData<IFileTableData>=> {
  try {
   return {
      format: FormatTypes.FILE_TABLE,
      data: data.data.sort((a, b) => a.region_name.localeCompare(b.region_name)).filter(e => e.region_name !== 'Other')
        .concat(
          data.data
            .filter(e => e.region_name === 'Other')
            .sort((a, b) => a.region_name.localeCompare(b.region_name))
        )
      ,
      stats: data.stats?.map(item => parseReport.data.Stats(item)),
      filters: parseReport.data.Filter(data.filters),
    }
  } catch (err) {
    handleError({error: err, id: "ParseFileTable", track: true})
    return {
      format: FormatTypes.PERIODIC_TABLE,
      data: undefined,
      stats: undefined,
      filters: undefined
    }
  }
}

const parseDownloadFileResponse = (response: IFileResponse) : IFileResponse => {
  return response
}

// REPORT QUERY

export enum QueryTypes {
  Array, String, Date, Object
}

export enum FileType {
  PDF = "pdf",
  CSV = "csv"
}

type IReportQueryItem = {
  type: QueryTypes
  value: any,
  query?: string,
}

interface IReportQuery {
  [name: string]: IReportQueryItem | undefined,
  date?: {
    type: QueryTypes.Date
    value: IDateRange | undefined,
  }
}

// REPORT REQUEST PROPS

//TODO separate for file and request
export interface IReportRequestProps {
  requestUrl: string,
  organizationId: string,
  reportId?: string,
  queryParams?: IReportQuery
}

// OTHER INTERFACES

interface IGeneralReportResponse<T> {
  cumulative_data?: T
  data: T
  filters?: ReportResponse.Data.Filter
  stats?: ReportResponse.Data.Stats[]
  charts?: ReportResponse.Data.Charts
}

// GET FUNCTIONS

// TODO - temp - remove after new API is created
const formatOldData = (data) => {
  let newData: any = []
  data?.forEach(facility => {
    facility?.data?.forEach(row => {
      newData.push({...row, facility_name: facility.name, region_name: facility.region_name})
    })
  })
  return newData
}

const getDateForCard = (date: IDateRange | undefined) : string => {
  if(!date) return ""
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  return `${date.start ? months[date.start.getMonth()] + ' ' + date.start.getFullYear() : ''}${date.start && date.end ? ' - ' : ''}${date.end ? months[date.end.getMonth()] + ' ' + date.end.getFullYear() : ''}`
}

const formatOldStats = (stats, options: IReportRequestProps) : ReportResponse.Data.Stats.Item[] | undefined=> {
  if(!stats) return
  return [
    {
      data: { value: stats.patients_visited },
      date_range: getDateForCard(options.queryParams?.date?.value),
      label: "Visited Patients",
      type: "number"
    },
    {
      data: { value: stats.coverage_unique },
      date_range: getDateForCard(options.queryParams?.date?.value),
      label: "Coverage",
      type: "number"
    }
  ]
}

async function getReportData<R, D> (options: IReportRequestProps, parseFunction: (response: R) => D, controller?: AbortController) : Promise<D | undefined> {
  const url = generateReportUrl(options)
  const res = await get<R>(url, {timeout: 300000, signal: controller?.signal})
  // TODO - temp parsing, to make data consistent, remove after new universal API implementation
  if(!res) return undefined
  if(url.includes("pdf") || url.includes("csv")) {
    return parseFunction(res)
  }
  if(url.includes("predictions_performance")) {
    // @ts-ignore
    const editedRes = {data: res, filters: res.filters, charts: res.charts}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("daily_risk_report")) {
    if(url.includes("file")) {
      return parseFunction(res)
    }
    // @ts-ignore
    const editedRes = {data: res}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("rehospitalization")) {
    // @ts-ignore
    const editedRes = {data: {organization: res.organization, time_periods: res.time_periods}, stats: res.stats, filters: res.filters, charts: res.charts}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("patient_days")) {
    // @ts-ignore
    const editedRes = {data: {organization: res.organization, time_periods: res.time_periods}, filters: res.filters}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("open_rate")) {
    // @ts-ignore
    const editedRes = {data: {organization: res.organization, time_periods: res.time_periods}}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("vitals")) {
    // @ts-ignore
    const editedRes = {data: formatOldData(res.facilities)}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  if(url.includes("visits")) {
    // @ts-ignore
    const editedRes = {data: formatOldData(res.facilities), stats: formatOldStats(res.stats, options)}
    // @ts-ignore
    return parseFunction(editedRes)
  }
  return parseFunction(res)
}

const getReportCatalog = async (orgId) : Promise<SaivaReport.Categories | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/analytics/report_structure', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<SaivaReport.Categories | undefined>(url).then((res: SaivaReport.Categories | undefined) => {
   return res
  })
}

const getReportCategories = async (orgId) : Promise<SaivaReport.Categories | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/analytics/catalog_data', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<SaivaReport.Categories>(url).then((res: any) => {
    return parseReport.Catalog({categories: res})
  })
}

const getAllReports = async (orgId) : Promise<void | SaivaReport.CatalogItem[] | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/analytics/catalog_data', [
    { id: '{org_id}', value: orgId }
  ])
  const items: SaivaReport.CatalogItem[] = []
  return await get<SaivaReport.Categories>(url).then((res: any) => {
    if (res) {
      return parseReport.Catalog({categories: res})?.categories?.forEach(cat => {
        items.push(...cat.report_types)
      })
    }
  })
}

const getReportStructure = async (reportId: string | undefined, orgId: string) : Promise<SaivaReport.StructureItem[] | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/analytics/report_structure', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<any>(url).then((res) => {
    const item = res.find(report => report.id === reportId)
    const items: any = []
    if (!item) return undefined
    for (const element of item.reports) {
      items.push(parseReport.StructureItem(
        {...element,
          flag: item.flag,
          description: item.description,
          id: item.id,
          label: item.label,
        }))
    }
    if(item) return items
    return undefined
  })
}

const getReportBasicTableData = async (options: IReportRequestProps, controller?: AbortController) : Promise<IGeneralReportData<IBasicTableData> | undefined> => {
  return await getReportData<IGeneralReportResponse<IBasicTableResponse>, IGeneralReportData<IBasicTableData>>(options, parseBasicTableResponse, controller)
}

const getReportPeriodTableData = async (options: IReportRequestProps, controller?: AbortController) : Promise<IGeneralReportData<IPeriodTableData> | undefined> => {
  return await getReportData<IGeneralReportResponse<IPeriodTableResponse>, IGeneralReportData<IPeriodTableData>>(options, parsePeriodTableResponse, controller)
}

const getReportFileTableData = async (options: IReportRequestProps, controller?: AbortController) : Promise<IGeneralReportData<IFileTableData> | undefined> => {
  return await getReportData<IGeneralReportResponse<IFileTableResponse>, IGeneralReportData<IFileTableData>>(options, parseFileTableResponse, controller)
}

const downloadReportFile = async (options: IReportRequestProps, controller?: AbortController) : Promise<IFileResponse | undefined> => {
  // TODO replace IFileTableData with IFileTableResponse
  return await getReportData<IFileResponse, IFileResponse>(options, parseDownloadFileResponse, controller)
}

const getCrossFacilityReport = async (
  orgId: string,
  filter: any,
  controller?: AbortController
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/analytics/cross_facility_daily_risk_report',
    [
      { id: '{org_id}', value: orgId },
    ],
    filter
  )
  return await get<any>(url, {timeout: 300000, signal: controller?.signal})
}

const exportCrossFacilityReportPDF = async (
  orgId: string,
  filter: any,
  view?: string
): Promise<any | undefined> => {
  const viewId = view === 'facility_view' ? 'facility_view/' : ''
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/analytics/cross_facility_daily_risk_report/${viewId}pdf',
    [
      { id: '{org_id}', value: orgId },
      { id: '${viewId}', value: viewId }
    ],
    filter
  )
  return await get<any>(url)
}

const exportCrossFacilityReportCSV = async (
  orgId: string,
  filter: any,
  view?: string
): Promise<any | undefined> => {
  const viewId = view === 'facility_view' ? 'facility_view/' : ''
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/analytics/cross_facility_daily_risk_report/${viewId}csv',
    [
      { id: '{org_id}', value: orgId },
      { id: '${viewId}', value: viewId }
    ],
    filter
  )
  return await get<any>(url)
}

const getReportDataByURL = async (
  url: string,
  orgId: string,
  filter: any,
  controller?: AbortController
) => {
  const generatedUrl = generateFilterUrl(
    url,
    [{ id: '{org_id}', value: orgId }],
    filter
  )
  return await get<any>(generatedUrl, {
    timeout: 300000,
    signal: controller?.signal
  })
}

const exportPDF = async (
  orgId: string,
  url: string | undefined,
  filter: any,
  view?: string
): Promise<any | undefined> => {
  const viewId = view === 'facility_view' ? 'facility_view/' : ''
  const urldefined = url ? url : ''
  const generatedUrl = generateFilterUrl(
    urldefined,
    [
      { id: '{org_id}', value: orgId },
      { id: '${viewId}', value: viewId }
    ],
    filter
  )
  try {
    return await get<any>(generatedUrl)
  } catch (error) {
    return undefined;
  }
}

const exportCSV = async (
  orgId: string,
  url: string | undefined,
  filter: any,
  view?: string
): Promise<any | undefined> => {
  const viewId = view === 'facility_view' ? 'facility_view/' : ''
  const urldefined = url ? url : ''
  const generatedUrl = generateFilterUrl(
    urldefined,
    [
      { id: '{org_id}', value: orgId },
      { id: '${viewId}', value: viewId }
    ],
    filter
  )
  try {
    return await get<any>(generatedUrl)
  } catch (error) {
    return undefined;
  }
}


const ReportService = {
  getAllReports,
  getReportCatalog,
  getReportCategories,
  getReportStructure,
  getReportBasicTableData,
  getReportPeriodTableData,
  getReportFileTableData,
  downloadReportFile,
  getCrossFacilityReport,
  exportCrossFacilityReportPDF,
  exportCrossFacilityReportCSV,
  getReportDataByURL,
  exportPDF,
  exportCSV
}

export default ReportService

