import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useOnClickOutside } from 'usehooks-ts'
import * as Sentry from '@sentry/react'
import { useTranslation } from 'react-i18next'

import ReportService from 'services/report-service'
import { SaivaReport } from 'types/reports-types'
import { showSuccessToast } from 'utils'
import { Row, Col } from 'antd'
import { ReactComponent as MenuIcon } from 'assets/icons/three-dots.svg'
import { ReactComponent as ShareIcon } from 'assets/icons/share.svg'
import { ReactComponent as CopyLinkIcon } from 'assets/icons/copy-link.svg'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { OrgProductFeature, UserPermission } from '../../types/user-types'
import { useUserContext } from '../../context/UserContext'
import AccessControl from 'components/AccessControl/AccessControl'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import SaivaRadioButton from 'components/SaivaForm/SaivaRadioButton/saivaRadioButton'
import { getFeatureLabel } from "utils/helper"
import SkeletonCard from 'components/Skeleton/Card/SkeletonCard'
import styles from './Reports.module.scss'
import { showErrorToast } from 'utils'

interface IReportNavigationProps {
  category?: boolean,
}

const ReportNavigation = ({category = true} : IReportNavigationProps) => {

  // STATE

  const navigate = useNavigate()
  const userContext = useUserContext()
  const modelProductFeatures = userContext.currentOrg.productFeatures.filter((item) => {return item.includes('model')})
  const { t } = useTranslation()
  const [data, setData] = useState<SaivaReport.Categories | undefined>(undefined)
  const [filteredData, setFilteredData] = useState<SaivaReport.Categories | undefined>(undefined)
  const [showAsCategories, setShowAsCategories] = useState<boolean>(category)
  const [reportFilter, setReportFilter] = useState<SaivaReport.Filter.ReportFilter>({search:''})
  const [loading, setLoading] = useState<boolean>(true)

  const refInput = useRef<HTMLInputElement>(null)

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  useEffect(() => {
    const error = query.get('error')
    if (error == 'noaccess') {
      showErrorToast(t('dashboard.error.noaccess'))
    } else if (error == 'usernotpartorganization') {
      showErrorToast(t('dashboard.error.usernotpartorganization'))
    }
  }, [])

  // FETCH FUNCTIONS

  const fetchCatalogData = async () => {
    setLoading(true)
    try {
      const catalog: SaivaReport.Categories | undefined = await ReportService.getReportCategories(userContext.currentOrg.id)
      setData(catalog)
      setFilteredData(catalog)
      setTimeout(() => {
        setLoading(false)
      }, 300)
    } catch (err) {
      setFilteredData(undefined)
      setData(undefined)
      setLoading(false)
      // TODO add error handler
    }
  }

  // HANDLE FUNCTIONS

  const handleClickShowAsCategory = (value: boolean) => {
    if(showAsCategories) navigate("/reports")
    else navigate("/reports/category")
    setShowAsCategories(value)
  }

  // EFFECT

  useEffect(() => {
    const navigateToDailyRisk = () => {
      if(window.innerWidth < 1000) navigate("/reports/daily_risk_report")
    }
    navigateToDailyRisk()
    window.addEventListener("resize", navigateToDailyRisk)
    return (() => {
      window.removeEventListener("resize", navigateToDailyRisk)
    })
  }, [])

  useEffect(() => {
    fetchCatalogData()
  }, [userContext.currentOrg.id])

  // COMPONENTS

  const CategoryCard = ({categoryName, reports, color} : {categoryName: string, reports: SaivaReport.CatalogItem[], color?: {color: string, bg: string}}) => {

    const navigate = useNavigate()
    const [hover, setHover] = useState<boolean>(false)

    const ownedReports = 
      reportFilter.qualityMeasure && 
      reportFilter.qualityMeasure.length > 0 ? 
        reports.filter((report) => {
          return reportFilter.qualityMeasure?.includes(report.quality_measure)
        }) : reports

    const handleItemClick = (report) => {
      navigate("/reports/" + report.id + '?qm=' + report.reports[0].quality_measure, {state: {from: showAsCategories ? "categories" : "all"}})
    }

    try {
      if(!ownedReports || ownedReports.length === 0) return null
      return (
        <div className={`saiva-component-card ${ hover ? "saiva-component-card-hover" : "" }`} style={{padding: "17px 12px"}}>
          <div className={"card-header"}>
            <label className={"category-label"} style={{marginLeft: 8, color: color?.color, background: color?.bg}}>{categoryName}</label>
          </div>
          <div className={"card-content"}>
            <label>Contains Reports</label>
            {ownedReports.map((report) => {
              return (
                <div key={report.id} className={"card-item-category"} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={() => handleItemClick(report)}>
                  <div className={"text"}>
                    <div className={"dot"} style={{background: color?.color}} />
                    {report.label}
                  </div>
                  <div className={"bg"} style={{background: color?.color}} />
                </div>
              )
            })}
          </div>
        </div>
      )
    } catch (e) {
      return null
    }
  }

  const ReportCard = ({categoryName, report, color} : {categoryName: string, report: SaivaReport.CatalogItem, color: {color: string, bg: string} | undefined}) => {

    const navigate = useNavigate()

    const [menu, setMenu] = useState<boolean>(false)
    const [menuHover, setMenuHover] = useState<boolean>(false)

    const popupRef = useRef<HTMLDivElement>(null)


    const closeMenu = () => {
      setMenu(false)
    }

    const toggleMenu = () => {
      setMenu(prev => !prev)
    }

    const handleCardClick = () => {
      if(!menuHover && !menu) navigate("/reports/" + report.id + '?qm=' + report.reports[0].quality_measure, {state: {from: showAsCategories ? "categories" : "all"}})
    }

    const url = window.location.origin + "/reports/" + report.id

    const handleShare = () => {
      if(typeof navigator?.share === "function") navigator?.share({url})
      closeMenu()
    }

    const handleCopyLink = async () => {
      navigator.clipboard.writeText(url)
        .then(() => {
          showSuccessToast("Link was copied to clipboard!")
          closeMenu()
        })
        .catch((e) => {
          showSuccessToast("An error occurred!")
          Sentry.captureException("Error: ReportCardCopyLink " + e)
        });
    }

    const menuItems = [
      {icon: <ShareIcon />, label: "Share", onClick: handleShare},
      {icon: <CopyLinkIcon />, label: "Copy link", onClick: handleCopyLink}
    ]

    useOnClickOutside(popupRef, closeMenu)

    return (
      <div className={"saiva-component-card-hover"} onClick={handleCardClick}>
        <div className={"card-header"}>
          <div className={"card-info"}>
            <div className={"card-item-report"}>
              <div className={"dot"} style={{background: color?.color}} />
              <h6>{report.label}</h6>
            </div>
            <label className={"category-label"} style={{color: color?.color, background: color?.bg}}>{categoryName}</label>
          </div>
          <div className={`card-menu ${menu ? "card-menu-active" : ""}`} onClick={toggleMenu} onMouseEnter={() => setMenuHover(true)} onMouseLeave={() => setMenuHover(false)}>
            <MenuIcon />
          </div>
          {menu && <div className={"popup"} ref={popupRef}>
            {menuItems.map(item => {
              if(item.label === "Share")
                if(typeof navigator.canShare === "function" && navigator.canShare({url})) return (
                  <button className={"menu-item"} onClick={item.onClick}>{item.icon} {item.label}</button>
                )
                else return null
              else return <button className={"menu-item"} onClick={item.onClick}>{item.icon} {item.label}</button>
            })}
          </div>}
        </div>
        <div className={"card-content"}>
          <div>{report.description}</div>
        </div>
      </div>
    )
  }

  const getData = (
    data: SaivaReport.Categories | undefined,
    singleReports: boolean
  ) => {
    const categories = data?.categories
    const reports = data?.categories
      ?.map((category) => {
        return category?.report_types.map((item) => {
          return { ...item, category: category }
        })
      })
      .flat(1)
      .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)

    const checkReport = (value: { label: string }): boolean => {
      return value.label
        .toLowerCase()
        .includes(reportFilter.search.toLowerCase())
    }

    const checkCategory = (value: {
      label: string
      report_types: { label: string }[]
    }): boolean => {
      return (
        value.label.toLowerCase().includes(reportFilter.search.toLowerCase()) ||
        Boolean(value?.report_types.find((report) => checkReport(report)))
      )
    }

    const filteredReports = reports?.filter((item) => {
      if (
        reportFilter &&
        reportFilter.reportCategory &&
        reportFilter.reportCategory.length > 0
      ) {
        if (
          reportFilter &&
          reportFilter.qualityMeasure &&
          reportFilter.qualityMeasure.length > 0
        ) {
          return (
            checkReport(item) &&
            checkCategory(item.category) &&
            reportFilter.reportCategory.includes(item.category.id) &&
            reportFilter.qualityMeasure.includes(item.quality_measure)
          )
        } else {
          return (
            checkReport(item) &&
            checkCategory(item.category) &&
            reportFilter.reportCategory.includes(item.category.id)
          )
        }
      } else {
        return (
          checkReport(item) &&
          checkCategory(item.category)
        )
      }
    })
    
    const filteredCategories =
      reportFilter.reportCategory && reportFilter.reportCategory.length > 0
        ? categories?.filter((category) => {
            return (
              reportFilter.reportCategory?.includes(category.id) &&
              checkCategory(category) &&
              category.report_types.filter((report) => checkReport(report)).length >
                0
            )
          })
        : categories?.filter((category) => {
            return (
              checkCategory(category) &&
              category.report_types.filter((report) => checkReport(report)).length >
                0
            )
          })

    if (singleReports && filteredReports && filteredReports.length > 0) {
      return (
        <>
          <div className={'content'}>
            {filteredReports.map((report, j) => {
              return (
                <ReportCard
                  categoryName={report.category.label}
                  report={report}
                  key={report.id}
                  color={report.category.color}
                />
              )
            })}
          </div>
        </>
      )
    } else if (filteredCategories && filteredCategories.length > 0) {
      return (
        <div>
            <div className={'content'}>
              {filteredCategories?.map((category, i) => {
                return (
                  <CategoryCard
                    categoryName={category.label}
                    reports={
                      category.report_types.filter((report) =>checkReport(report))
                      .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
                    }
                    key={category.id}
                    color={category.color}
                  />
                )
              })}
            </div>
        </div>
      )
    } else return null
  }

  const accessSchedules = userContext.currentOrg.productFeatures.includes(OrgProductFeature.ANALYTICS_READ)
  const isUserFilterActive = !!(reportFilter &&
    (reportFilter.qualityMeasure?.length || reportFilter.reportCategory?.length));

  const filters = (
    <div style={{ minWidth: '300px', padding: '16px 16px 0px 16px', maxWidth: '300px' }}>
    <b style={{ fontSize: '14px' }}>{t('users.table.filters.title')}</b>
    <div style={{ margin: '12px 0px' }}>
      <SaivaSelect
        value={reportFilter?.reportCategory}
        icon={null}
        label={t('reports.filters.label.reportCategory')}
        options={data?.categories?.map((category, i) => {
          return {
            label: category.label,
            value: category.id
          }
        })}
        placeholder={t('reports.filters.placeholder.reportCategory')}
        multiselect={true}
        name="roles"
        reset={() => {
          setReportFilter({...reportFilter, reportCategory: []})
        }}
        onChange={(e) => {
          setReportFilter({...reportFilter, reportCategory: e})
        }}
      />
    </div>
    {/* <div style={{ margin: '12px 0px' }}>
      <SaivaRadioButton
        value={
          reportFilter && reportFilter?.qualityMeasure
            ? reportFilter?.qualityMeasure
            : []
        }
        name="quality_measure"
        width={'60px'}
        height={24}
        inputType="checkbox"
        returnType="string"
        label={t('reports.filters.label.qualityMeasure')}
        values={modelProductFeatures.map((feature) => {
          return {
            label: getFeatureLabel(feature).label,
            value: feature
          }
        })}
        onChange={(e) => {
          setReportFilter({...reportFilter, qualityMeasure: e})
        }}
      />
    </div> */}
  </div>
  )

  return (
    <div className={"reportNavigationWrapper"}>
      <Row gutter={12} align={'middle'} style={{padding: "40px 0"}} justify={'space-between'}>
        <Col xs={24} md={4}>
          <h5>Reports</h5>
        </Col>
        <Col xs={24} md={8}>
          <SaivaSearch
            placeholder={"Search..."}
            filters={true}
            activeFilters={isUserFilterActive}
            onChange={(e) => {
              setReportFilter({...reportFilter, search: e.target.value})
            }}
            value={reportFilter?.search}
            content={filters}
          />
        </Col>
        <Col xs={24} md={12} lg={12} xl={10} xxl={8}>
          <Row gutter={12} justify={'space-between'} className={styles.navigationWrapper} style={{background: '#fff', padding: '8px', borderRadius: '12px'}}>
            <Col md={accessSchedules ? 8 : 12}>
              <button className={`selection-button ${showAsCategories && 'selection-button-active'}`} onClick={() => handleClickShowAsCategory(true)}>Category</button>
            </Col>
            <Col md={accessSchedules ? 8 : 12}>
              <button className={`selection-button ${!showAsCategories && 'selection-button-active'}`} onClick={() => handleClickShowAsCategory(false)}>All Reports</button>
            </Col>
            <AccessControl userPermissions={[UserPermission.ANALYTICS_READ]} productFeatures={[OrgProductFeature.ANALYTICS_READ]}>
              <Col md={8}>
                <button className={'selection-button'} onClick={() => navigate("/reports/schedules")}>Schedules</button>
              </Col>
            </AccessControl>
          </Row>
        </Col>
      </Row>
      <AccessControl userPermissions={[UserPermission.ANALYTICS_READ]}>
        {!loading ? (
          <>
            {!showAsCategories ? (
              getData(filteredData, false) ? (
                getData(filteredData, true)
              ) : (
                <div>
                  <h5 className={styles.noCards}>
                    <EmptyDataIcon style={{ marginBottom: '6px' }} />
                    <br />
                    {t('dashboard.cards.no-card-value')}
                  </h5>
                </div>
              )
            ) : getData(filteredData, false) ? (
              <>{getData(filteredData, false)}</>
            ) : (
              <div>
                <h5 className={styles.noCards}>
                  <EmptyDataIcon style={{ marginBottom: '6px' }} />
                  <br />
                  {t('dashboard.cards.no-card-value')}
                </h5>
              </div>
            )}
          </>
        ) : (
          <div className="content pt-5">{SkeletonCard({ repeat: 3 })}</div>
        )}
      </AccessControl>
    </div>
  )
}

export default ReportNavigation
