import React, { Fragment, ReactElement, createRef, useEffect, useMemo, useRef, useState } from 'react'
import { handleError } from 'utils/errorHandler'
import Select from 'react-select'

import { FileType } from 'services/api'
import { loadPaginationFromLocalStorage, savePaginationToLocalStorage } from 'utils/localStorage'
import { useUserContext } from 'context/UserContext'

import { ReactComponent as MoreIcon } from 'assets/icons/more.svg'
import { ReactComponent as SortUp } from 'assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from 'assets/icons/sort-down.svg'

import styles from './BasicTable.module.scss'
import { useIsOverflow } from 'hooks/useIsOverflow'
import { Tooltip } from 'antd'
import SaivaPagination from '../SaivaPagination/SaivaPagination'

const rowHeight = 60

const formatDataForTable = (data: any[]) => {
  return data?.map((item, index) => {return {...item, id: index}})
}

const formatHeadersForTable = (data: any[]) => {
  return data?.map((item) => {return {...item, asc: undefined}})
}

export interface ISaivaTableHeaderItem {
  label: string
  id: string
  type?: string
  attribute?: string
}

export type IBasicTableData = any[]


interface IBasicTableProps {
  data: any,
  headers: ISaivaTableHeaderItem[],
  rowsPerPageOptions: {label: string, value: number}[],
  fileLoading: boolean,
  downloadReport: ((fileType: FileType) => void) | undefined,
  headerElement?: ReactElement,
  sortBy: string | undefined,
  sortChange: (value: string | undefined) => void
}

interface IPaginationProps {
  page: number,
  setPage: (value: number) => void,
  allRows: number,
  maxPages: number,
  rowsPerPage: {label: string, value: number},
  rowsPerPageOptions: {label: string, value: number}[],
  handleSelectRowsPerPage: (value: {label: string, value: number} | null) => void
  decreasePage: () => void,
  increasePage: () => void,
}

const Pagination = (props : IPaginationProps) => {

  const {page, setPage, allRows, maxPages, rowsPerPage, rowsPerPageOptions, handleSelectRowsPerPage, decreasePage, increasePage} = props

  const getPaginationItem = (index, p) => <div key={index} className={`${styles.pageItem} ${p === page ? styles.active : ""}`} onClick={() => setPage(p)}>{p}</div>

  const lastRowOnPrevPage = (page - 1) * rowsPerPage.value
  const lastRowOnCurrentPage = page * rowsPerPage.value

  return <div className={styles.footer}>
    <div className={styles.pageSelect}>
      <div className={`${styles.icon} ${page === 1 ? styles.disabled : ""}`} onClick={decreasePage}>
        <MoreIcon style={{ transform: "rotate(90deg)" }} className={`${page === 1 ? styles.disabled : ""}`} />
      </div>
      {Array.from({ length: maxPages }, (_, i) => i + 1).map((p, index) => {
        const firstPage = index === 0
        const lastPage = index === maxPages - 1
        const selectedPage = p === page
        const leftFromSelectedPage = p === page - 1
        const rightFromSelectedPage = p === page + 1
        if(maxPages > 10) {
          if((page > 3 && index === 1) || (page < maxPages - 2 && index === maxPages - 2)) return <div key={index} className={`${styles.pageItem} ${styles.pageItemNoHover}`}>...</div>
          if((firstPage || lastPage) || (leftFromSelectedPage || selectedPage || rightFromSelectedPage)) {
            return <Fragment key={index}>{getPaginationItem(index, p)}</Fragment>
          }
        }
        else return <Fragment key={index}>{getPaginationItem(index, p)}</Fragment>
      })}
      <div className={`${styles.icon} ${page === maxPages ? styles.disabled : ""}`} onClick={increasePage}>
        <MoreIcon style={{ transform: "rotate(-90deg)" }} className={`${page === maxPages ? styles.disabled : ""}`} />
      </div>
    </div>
    <div className={styles.pageSelect}>
      <div>
        <label>{lastRowOnPrevPage + 1} - {lastRowOnCurrentPage > allRows ? allRows : lastRowOnCurrentPage} from {allRows}</label>
      </div>
      <div className={styles.pagePerRow}>
        <label>Rows per page</label>
        <Select
          name="capabilities"
          onChange={(newValue) => handleSelectRowsPerPage(newValue)}
          defaultValue={rowsPerPageOptions[0]}
          value={rowsPerPage}
          options={rowsPerPageOptions}
          isSearchable={false}
          classNamePrefix={"pagesSelect"}
          menuPlacement={"top"}
        />
      </div>
    </div>
  </div>
}

const BasicTable = (props : IBasicTableProps) => {

  const {rowsPerPageOptions} = props

  const userContext = useUserContext()

  const [data, setData] = useState<any | undefined>(formatDataForTable(props.data))
  const [headers, setHeaders] = useState<any>(formatHeadersForTable(props.headers))
  const [allRows, setAllRows] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(0)
  const [maxHeight, setMaxHeight] = useState<number>(0)

  const header = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)

  const lastRowInPrevPage = rowsPerPage * (page - 1) - 1
  const lastRowInCurrentPage = rowsPerPage * page - 1
  const lastRowInLastPage = allRows - 1

  const increasePage = () => {
    if(page < maxPages) setPage(prev => ++prev)
  }

  const decreasePage = () => {
    if(page > 1) setPage(prev => --prev)
  }

  const handleSelectRowsPerPage = (newValue: any) => {
    savePaginationToLocalStorage(userContext.currentOrg.id, newValue)
    setRowsPerPage(newValue)
  }

  const isIconActive = (id: string, asc: boolean) => {
    const {sortBy} = props

    const isCurrentColumn = sortBy?.includes(id)
    const dsc = sortBy?.includes("-")
    if(isCurrentColumn)
      if(dsc && !asc || !dsc && asc) return styles.iconActive
      else return ''
  }

  const sortData = (id: string) => {
    const {sortBy, sortChange} = props
    const isCurrentColumn = sortBy?.includes(id)
    const dsc = sortBy?.includes("-")
    if(dsc || !isCurrentColumn) sortChange(id)
    else sortChange("-" + id)
  }

  const getRowDistancesFromStart = () => {
    if(!data) return {rowDistancesFromStart: []}
    const rowDistancesFromStart: number[] = []
    data.forEach((_row, index) => {
      const row = document.getElementById("row-" + index)
      if(row) {
        const rowHeight = row.clientHeight + 1 // plus on because of border
        const lastRowDistance = rowDistancesFromStart[rowDistancesFromStart.length - 1]
        rowDistancesFromStart.push(rowHeight + (rowDistancesFromStart.length > 0 ? lastRowDistance : 0))
      }
    })
    return {rowDistancesFromStart}
  }

  const findBiggestWidth = (array: ISaivaTableHeaderItem[], widths: number[]) : number[] => {
    array.forEach((item, index) => {
      const element = document.getElementById("header-" + item.id + index)
      if(element && (element.clientWidth > widths[index] || widths[index] === undefined)) widths[index] = element.clientWidth
    })
    return widths
  }

  const getHeightForCurrentPage = () => {
    const {rowDistancesFromStart} = getRowDistancesFromStart()
    // - 1 on all because array starts with 0
    if(allRows === 0) return 0
    if(rowsPerPage * page > allRows) {
      return rowDistancesFromStart[lastRowInLastPage] - (lastRowInPrevPage < 0 ? 0 : rowDistancesFromStart[lastRowInPrevPage])
    } else {
      if(page === 1) return rowDistancesFromStart[lastRowInCurrentPage]
      return rowDistancesFromStart[lastRowInCurrentPage] - rowDistancesFromStart[lastRowInPrevPage]
    }
  }

  const handleResize = () => {
    setPage(1)
  }

  const handleContentScroll = () => {
    if(header.current && content.current) header.current.scrollLeft = content.current.scrollLeft
  }

  const checkIndex = (row, header, index) => {
    return row[header.id].length - 1 === index ? '' : ','
  }

  const init = (setNewData: boolean) => {
    if(!props.data) return
    if(setNewData) setData(formatDataForTable(props.data))
    let allRows = props.data.length
    setAllRows(allRows)
    const max = Math.ceil(allRows / rowsPerPage)
    setMaxPages(max)
    if(page > max) setPage(max)
  }

  const getTransform = useMemo(() => {
    if(page === 1) return 0
    const {rowDistancesFromStart} = getRowDistancesFromStart()
    return - rowDistancesFromStart[lastRowInPrevPage]
  }, [data, page, rowsPerPage])

  const getSize = useMemo(() => {
    if(!data) return {columnWidths: 0}
    let columnWidths: number[] = Array(10)
    columnWidths = findBiggestWidth(headers, columnWidths)
    data.forEach((_row) => {
      columnWidths = findBiggestWidth(headers, columnWidths)
    })
    return {columnWidths}
  }, [data, rowsPerPage, window.innerWidth])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return (() => {
      window.removeEventListener("resize", handleResize)
    })
  }, [])

  useEffect(() => {
    setMaxHeight(getHeightForCurrentPage())
  }, [data, page, rowsPerPage])

  useEffect(() => {
    handleResize()
  }, [data])

  useEffect(() => {
    const rows = loadPaginationFromLocalStorage(userContext.currentOrg.id)
    if(rows) setRowsPerPage(rows)
  }, [userContext.currentOrg])

  useEffect(() => {
    content.current?.addEventListener("scroll", handleContentScroll)
    return (() => {
      content.current?.removeEventListener("scroll", handleContentScroll)
    })
  }, [header.current, content.current])

  useEffect(() => {
    setHeaders(formatHeadersForTable(props.headers))
  }, [props.headers])

  useEffect(() => {
    init(true)
  }, [props.data])

  useEffect(() => {
    init(false)
  }, [rowsPerPage])

  const refs = useRef<any>([]);

  if (refs.current.length !== props.headers.length) {
    refs.current = Array(props.headers.length)
      .fill(null)
      .map((_, i) => refs.current[i] || createRef());
  }
  
  const isOverflow = (element: HTMLElement | null) => {
    return element ? useIsOverflow(element) : false
  }

  try {
    if(data?.length > 0) return (
      <>
      {props.headerElement}
      <div className={styles.basicTableWrapper}>
        <div className={styles.tableContentWrapper}>
          <div className={styles.headerWrapper} ref={header} style={{gridTemplateColumns: `repeat(${headers.length}, 1fr)`, height: rowHeight, overflow: 'hidden'}}>
            {headers.map((header, index) => {
              return (
                <div key={header.id} className={`${styles.header} ${header.sortable ? styles.headerClick : ''}`} id={"header-" + header.id + index} onClick={() => {if(header.sortable) sortData(header.id)}} style={{minWidth: getSize.columnWidths[index]}}>
                  {header.sortable && <div className={styles.sortIconsWrapper}>
                    <SortUp className={`${styles.icon} ${isIconActive(header.id, true)}`} />
                    <SortDown className={`${styles.icon} ${isIconActive(header.id, false)}`} />
                  </div>}
                  {header.label}
                </div>
              )
            })}
          </div>
          <div  className={`${styles.tableItem} ${styles.animation}`} ref={content} style={{maxHeight: content.current && content.current?.scrollWidth > content.current?.clientWidth ? maxHeight + 8 : maxHeight, overflowY: 'hidden'}}>
            <div className={styles.animation} style={{transform: allRows > rowsPerPage ? `translateY(${getTransform}px)` : 'translateY(0)'}}>
              {data?.map((row, index) => {
                return (
                  <div id={"row-" + index} key={row.id} className={styles.tableRow} style={{gridTemplateColumns: `repeat(${headers.length}, 1fr)`, minHeight: rowHeight, padding: '0 20px', minWidth: content.current ? content.current.scrollWidth - 40 : "auto"}}>
                    {headers.map((header, j) => {
                      switch(header.type) {
                        case "array":
                          return <div id={"row-" + header.value +  index} key={'row-item' + j} className={styles.tableRowItem} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', minWidth: getSize.columnWidths[j]}}>
                            {row[header.id].map((item, i) => {
                              return <div id={"row-" + header.value + index} key={i} style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginRight: 10,
                                whiteSpace: 'pre-wrap'
                              }}>{item.name}:  <b>{item.value}{item.unit}</b>{checkIndex(row, header, i)}</div>
                            })}
                          </div>
                        case "object":
                          return <div id={"row-" + header.id +  index} key={'row-item' + j} className={styles.tableRowItem} style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', minWidth: getSize.columnWidths[j]}}>
                            {header.attribute && row[header.id][header.attribute]}
                          </div>
                        case "boolean":
                          return <div id={"row-" + header.id +  index} key={'row-item' + j} className={styles.tableRowItem} style={{minWidth: getSize.columnWidths[j]}}>{row[header.id] ? row[header.id] : ''}</div>
                        default:
                          if (header.id === 'patient_name' || header.id === 'resident') {
                            return (
                              <Tooltip 
                                arrow={false}
                                overlayInnerStyle={{
                                  background: '#112443',
                                  textAlign: 'left'
                                }} 
                                title={row[header.id].value}
                              >
                                <div
                                  id={"row-" + header.id +  index} key={'row-item' + j}
                                  className={styles.tableRowItemFull}
                                >
                                  {row[header.id].detail_url !== null ? 
                                    <a 
                                      href={row[header.id].detail_url + '?report=' + window.location.pathname + window.location.search}
                                      style={{textDecoration: 'none'}}
                                    >
                                      {row[header.id].value}
                                    </a> : row[header.id].value}
                                </div>
                              </Tooltip>
                            )
                          } else return (
                            <Tooltip 
                              arrow={false}
                              overlayInnerStyle={{
                                background: '#112443',
                                textAlign: 'left'
                              }} 
                              title={row[header.id]}
                            >
                              <div
                                id={"row-" + header.id +  index} key={'row-item' + j}
                                className={styles.tableRowItem}
                                ref={refs.current[j]}
                              >
                                {row[header.id]}
                              </div>
                            </Tooltip>)
                      }
                    })}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <SaivaPagination
          total={allRows}
          page={page}
          pageSize={rowsPerPage}
          onChange={setPage}
          onShowSizeChange={handleSelectRowsPerPage}
        />
      </div>
      </>
    )
    else return (<div className={"dataTableWrapper"}>
    {props.headerElement}
    <div className={'center'}>
      No Data to Display
    </div>
  </div>)
  } catch (e) {
    handleError({ id: "NoDataInTable", error: e, track: true })
    return null
  }
}

export default BasicTable
