import { useEffect } from 'react'
import FormLayout from './FormLayout'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SaivaUser } from 'types/user-types'
import { handleError } from 'utils/errorHandler'
import { SaivaInvitation } from 'types/invitation-types'

interface Props {
  defaultValues: SaivaUser.User | undefined
  options: any
  isEdit: boolean
  apiError: any
  isClosed: boolean
  onSubmit: (data: SaivaInvitation.Request) => any
  formState: (value: boolean) => void
  handleDelete: () => void
}

const FormLogic = ({
  defaultValues,
  options,
  isEdit,
  apiError,
  isClosed,
  onSubmit,
  formState,
  handleDelete
}: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
    form.reset(defaultValues)
  }, [isClosed])

  useEffect(() => {
    if (!apiError) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const email = yup.lazy((value) => yup
    .string()
    .required(t('invitedUsers.form.validation.required'))
    .email()
    .matches(/@[^.]*\./, t('invitedUsers.form.validation.email'))
    .matches(/@(\[(\d{1,3}\.){3}|(?!hotmail|gmail|googlemail|yahoo|gmx|ymail|outlook|bluewin|protonmail|t\-online|web\.|online\.|aol\.|live\.)(([a-z\d-]+\.)+))([a-zA-Z]{2,63}|\d{1,3})(\]?)$/, t('invitedUsers.form.validation.personal_email', { email: value }))
  )

  const title = yup
    .string()
    .required(t('invitedUsers.form.validation.required'))

  const name = yup
    .string()
    .matches(
      /^[A-Za-z0-9 _-]*[A-Za-z0-9][A-Za-z0-9 _-]*$/,
      t('invitedUsers.form.validation.name')
    )
    .required(t('invitedUsers.form.validation.required'))

  const roles = yup.array().min(1, t('invitedUsers.form.validation.required'))

  const regions = yup.array().min(1, 'Select at least 1 facility')

  const CreateUserFormSchema = yup.object().shape({
    email,
    title,
    name,
    roles,
    regions
  })

  const form = useForm<SaivaUser.User | any>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CreateUserFormSchema)
  })

  formState(form.formState.isDirty)

  const handleSubmit = async (data: SaivaInvitation.Request) => {
    await onSubmit(data)
      .then(() => {
        form.reset()
      })
      // TODO
      .catch((err) =>
        handleError({ id: 'scheduleReport', error: err, customHandle: true })
      )
  }

  return (
    <FormLayout
      defaultValues={defaultValues}
      options={options}
      form={form}
      isEdit={isEdit}
      apiError={apiError}
      onSubmit={handleSubmit}
      handleDelete={handleDelete}
    />
  )
}

export default FormLogic
