import { useEffect, useState } from 'react'
import styles from './SaivaToggle.module.scss'

interface Props {
  name: string
  value: boolean
  hideLabel?: boolean
  onChange: (value: boolean) => void
}

export default function SaivaToggle(props: Props) {
  const [checked, setChecked] = useState(true)
  useEffect(() => {
    setChecked(props.value)
  }, [props.value])

  const toggleChecked = () => {
    setChecked(!checked)
    props.onChange(!checked)
  }

  return (
    <div style={{display: 'flex', alignItems: 'flex-end'}}>
      <label className={styles.switch}>
        <input
          type="checkbox"
          name={props.name}
          onChange={toggleChecked}
          checked={checked}
        />
        <span className={styles.slider}></span>
      </label>
      {!props.hideLabel && <label className={styles.label}>{props.value ? 'Enabled' : 'Disabled'}</label>}
    </div>
  )
}
