import { get, patch, post, del } from './utils/api-client'
import {
  generateFilterUrl,
  PaginatedResponse,
  SortDirection
} from './utils/api-utils'
import {ScheduleFilter, Schedules } from 'types/schedule-reports'

const getSchedules = async (
  orgId: string,
  filter: ScheduleFilter
  ) : Promise<PaginatedResponse<Schedules.Item> | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{org_id}/scheduled_reports/',
    [{ id: '{org_id}', value: orgId }], filter
  )
  return await get<any>(url)
}

const createSchedule = async (
  orgId: string,
  payload: Schedules.Create
) => {
  const url = generateFilterUrl('v2/orgs/{org_id}/scheduled_reports/', [
    { id: '{org_id}', value: orgId }
  ])
  return await post<Schedules.Create, Schedules.Item>(url, {
    payload
  })
}

const getSchedule = async (
  orgId: string,
  scheduledReportId: string
): Promise<Schedules.Item | undefined> => {
  const url = generateFilterUrl('/v2/orgs/{org_id}/scheduled_reports/{scheduled_report_id}', [
    { id: '{org_id}', value: orgId },
    { id: '{scheduled_report_id}', value: scheduledReportId }
  ])
  return await get<Schedules.Item, Schedules.Item>(url)
}

const updateSchedule = async (
  orgId: string,
  scheduledReportId: string,
  payload: {
    schedule_config: {
      day_of_week?: string[]
      time_of_day?: string
      time_zone?: string
      schedule_type?: string
      is_enabled?: boolean
      file_format?: string
      week_of_month?: string
      name?: string
    }
  }

): Promise<Schedules.Item | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/scheduled_reports/{scheduled_report_id}', [
    { id: '{org_id}', value: orgId },
    { id: '{scheduled_report_id}', value: scheduledReportId }
  ])
  return await patch<Schedules.Item, Schedules.Item>(url, {
    payload
  })
}

const sendNow = async (
  orgId: string,
  scheduledReportId: string,
): Promise<Schedules.Item | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/scheduled_reports/{scheduled_report_id}/send', [
    { id: '{org_id}', value: orgId },
    { id: '{scheduled_report_id}', value: scheduledReportId }
  ])
  return await post<Schedules.Item, Schedules.Item>(url)
}

const deleteSchedule = async (
  orgId: string,
  scheduledReportId: string
): Promise<Schedules.Item | undefined> => {
  const url = generateFilterUrl('/v2/orgs/{org_id}/scheduled_reports/{scheduled_report_id}', [
    { id: '{org_id}', value: orgId },
    { id: '{scheduled_report_id}', value: scheduledReportId }
  ])
  return await del(url)
}

const getScheduleByType = async (
  orgId: string,
  report_type: any
): Promise<Schedules.Item | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/scheduled_reports/type/{report_type}', [
    { id: '{org_id}', value: orgId },
    { id: '{report_type}', value: report_type }
  ])
  return await get<Schedules.Item[], Schedules.Item>(url)
}

const getScheduledByUsers = async (
  orgId: string,
): Promise<Schedules.scheduledByUsers | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/scheduled_reports/scheduled_by_users', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<Schedules.scheduledByUsers[], Schedules.scheduledByUsers>(url)
}

const SchedulesService = {
  getSchedules,
  createSchedule,
  getSchedule,
  updateSchedule,
  sendNow,
  deleteSchedule,
  getScheduleByType,
  getScheduledByUsers
}

export default SchedulesService
