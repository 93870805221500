import styles from './CardLayout.module.scss'
import { Responsive, WidthProvider } from 'react-grid-layout'

const ResponsiveGridLayout = WidthProvider(Responsive)

type Props = {
  children: JSX.Element[]
  layouts?: any
}

export default function CardLayout({ children, layouts }: Props) {
  return (
    <ResponsiveGridLayout
      className="layout"
      margin={[0, 0]}
      isDraggable={false}
      isRearrangeable={true}
      isResizable={true} 
      layouts={layouts}
      rowHeight={175}
      preventCollision={true}
      cols={{ xxl: 12, xl: 12, lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
    >
      {children}
    </ResponsiveGridLayout>
  )
}
