import styles from './Card.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as KeyWordsIcon } from '../../../assets/icons/keywords.svg'
import { ReactComponent as NoDataIcon } from '../../../assets/icons/calendar-cross.svg'

interface Item {
  date: string
  time: string
  progress_note_type: string
  days_lapsed: string
  clean_note: string
}

interface Props {
  data: {
    keywords: string[]
    items: Item[]
  }
}

export default function Keywords({ data }: Props) {
  const { t } = useTranslation()

  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <div className={styles.headerTitleWrapper}>
          <KeyWordsIcon />
          <div className={styles.headerTitle}>
            <h4>{t('residentPage.keywords.yourKeywords')}</h4>
            <div className={styles.headerNote}>
              {t('residentPage.keywords.yourKeywordsNote')}
            </div>
          </div>
        </div>
        <div className={styles.keywordTabs}>
          {data.keywords.map((keyword, i) => {
            return (
              <div key={i} className={styles.keywordTab}>
                {keyword}
              </div>
            )
          })}
        </div>
      </div>
      <div className={styles.content}>
        {data && data?.items.length > 0 ? (
          <ul className={styles.keywords}>
            {data.items.map((item, i) => {
              return (
                <li key={i}>
                  <table className={styles.table}>
                    <tbody>
                      <tr>
                        <td style={{ width: '20px' }}>
                          <span className={styles.item}></span>
                        </td>
                        <td>
                          <div>
                            {item.date} {item.time}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          {item.progress_note_type} | {item.days_lapsed}
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item.clean_note
                            }}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </li>
              )
            })}
          </ul>
        ) : (
          <>
            <div className={styles.noData}>
              <NoDataIcon />
              {t('residentPage.keywords.noKeywords')}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
