import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { mixpanelInstance } from 'utils/mixpanel'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as LessIcon } from 'assets/icons/less.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox.svg'
import { showErrorToast } from 'utils'
import { isMobile } from 'utils/helper'
import ManageModal from './Modal'
import { Progress, Divider, Button, Space } from 'antd'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import styles from './OptionSelectCard.module.scss'

export interface IOptionSelectItem {
  id: any
  label: string
  isSelected: boolean
}

interface ComponentProps {
  label: string
  manageButtonText?: string
  items: IOptionSelectItem[] | (() => Promise<IOptionSelectItem[]>) | undefined
  onChange: (value: IOptionSelectItem[]) => void
  color?: string
  required?: boolean
  noOptionsMessage?: string
  loading?: boolean
}

const OptionSelectCard = (props: ComponentProps) => {
  const { label, manageButtonText, required } = props
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showMore, setShowMore] = useState<boolean>(true)
  const [height, setHeight] = useState<number>(0)
  const [items, setItems] = useState<IOptionSelectItem[] | undefined>(
    typeof props.items === 'function' ? undefined : props.items
  )
  const itemsRef = useRef<(HTMLDivElement | null)[]>([])

  const getItems = async () => {
    if (props.items) {
      if (typeof props.items === 'function') {
        setItems(undefined)
        const res = await props.items()
        setItems(res)
      } else {
        setItems(props.items)
      }
    } else {
      setItems(undefined)
    }
  }

  const selectedItems = useMemo((): IOptionSelectItem[] => {
    const selected = items?.filter((i) => i.isSelected)
    return selected ? selected : []
  }, [items])

  const handleDisplayedChange = (value: IOptionSelectItem[]) => {
    props.onChange(value)
  }

  const handleRemoveItem = (item) => {
    setItems((prev) => {
      if (!prev) return undefined
      const fac = prev.map((i) => {
        if (i.id === item.id) return { ...item, isSelected: !item.isSelected }
        return i
      })
      const selected = fac.filter((i) => i.isSelected)
      if (selected.length > 0 || !required) {
        handleDisplayedChange(fac)
      } else {
        showErrorToast(t('optionSelect.error.oneOrMoreItem'))
        return prev
      }
    })
  }

  const handleOpen = () => {
    setShowModal(true)
  }

  const handleClose = (e) => {
    e.preventDefault()
    setShowModal(false)
  }

  const handleSubmit = (items: IOptionSelectItem[]) => {
    if (!items) return
    const selected = items.filter((i) => i.isSelected)
    setItems(items)
    handleDisplayedChange(items)
    setShowModal(false)
  }

  const showValue = 3
  const maxShowValue = 15
  const rowHeight = 33

  useEffect(() => {
    getItems()
    setHeight(showValue * rowHeight)
  }, [props.items])

  const numOfSelected = useMemo(() => {
    return selectedItems.length
  }, [selectedItems])

  return (
    <>
      <div className={'facilityCardWrapper'}>
        <table className={styles.header}>
          <tbody>
            <tr>
              <td>
                <h5>{label}</h5>
              </td>
              <td style={{ textAlign: 'right' }}>
                {items && items.length > showValue && 
                <Button className={styles.actionButton} onClick={handleOpen} style={{width: '100%'}}>
                  <EditIcon />
                  <Space style={{ paddingLeft: '10px' }}>{manageButtonText}</Space>
                </Button>}
              </td>
            </tr>
            <tr>
              <td>
                {items && items.length > showValue && <small>
                  {t('optionSelect.card.header.show')} {numOfSelected} /{' '}
                  {items.length}
                </small>}
              </td>
              <td>
                {items && items.length > showValue && <div style={{ width: 80, marginLeft: '10px' }}>
                  <Progress
                    style={{ marginLeft: '12px' }}
                    strokeColor={props.color}
                    trailColor="rgba(160, 58, 253, 0.2)"
                    percent={
                      (numOfSelected / items.length) * 100
                    }
                    showInfo={false}
                    size="small"
                  />
                </div>}
              </td>
            </tr>
          </tbody>
        </table>
        <Divider style={{ margin: '8px 0px' }} />
        <div
          className={'cardContent animation'}
          style={{
            height: height,
            minHeight: rowHeight,
            overflowY: 'scroll',
            overflowX: 'hidden'
          }}
        >
          {props.loading ? <>
            <p className={styles.loading}>
              <LoadingIcon style={{ marginRight: '8px' }}/>
              Please Wait
            </p>
          </> :
          !items || items.length === 0 ? (
            <div style={{ maxWidth: 200 }}>
              {props.noOptionsMessage
                ? props.noOptionsMessage
                : t('optionSelect.card.body.noOption')}
            </div>
          ) : (
            <>
              {items && items.length > showValue &&
                selectedItems.map((item, index) => {
                  return (
                    <div
                      ref={(el) => (itemsRef.current[index] = el)}
                      title={item.label}
                      className={'cardItem'}
                      key={index}
                    >
                      <div className={'cardText'}>
                        <span
                          className={'dot'}
                          style={{ backgroundColor: props.color }}
                        />
                        <div className={'elipsis'}>{item.label}</div>
                      </div>
                      <div
                        className={'icon'}
                        onClick={() => handleRemoveItem(item)}
                      >
                        <CrossIcon />
                      </div>
                    </div>
                  )
                })}
              {items && items.length <= showValue &&
                items.map((item, index) => {
                  return (
                    <div
                      ref={(el) => (itemsRef.current[index] = el)}
                      className={'cardItem'}
                      key={index}
                    >
                      <div className={'cardText'}>
                        <span
                          className={'dot'}
                          style={{ backgroundColor: props.color }}
                        />
                        <div className={'elipsis'}>{item.label}</div>
                      </div>
                      {items.length > 1 && (
                        <div
                          className={'icon'}
                          onClick={() => handleRemoveItem(item)}
                        >
                          {item.isSelected ? <CrossIcon /> : <CheckboxIcon />}
                        </div>
                      )}
                    </div>
                  )
                })}
              {items && items.length > showValue && selectedItems.length === 0 && (
                <label>
                  {t('optionSelect.card.body.noItemSelected', {
                    label: label
                  })}
                </label>
              )}
            </>
          )}
        </div>
        {(selectedItems.length > showValue || isMobile()) && (
          <div className={'cardFooter d-none d-lg-block'}>
            <Button className={styles.actionButton} 
              onClick={() => {
                setShowMore(!showMore)
                setHeight(
                  !showMore
                    ? rowHeight * showValue
                    : (selectedItems.length > maxShowValue
                        ? maxShowValue
                        : selectedItems.length) * rowHeight
                )
              }}
            >
              {showMore
                ? <Space style={{ paddingRight: '10px' }}>{t('optionSelect.card.footer.showMore')}</Space>
                : <Space style={{ paddingRight: '10px' }}>{t('optionSelect.card.footer.showLess')}</Space>
                }
              {showMore ? (
                <MoreIcon style={{ marginLeft: 8 }} />
              ) : (
                <LessIcon style={{ marginLeft: 8 }} />
              )}
            </Button>
          </div>
        )}
      </div>
      <ManageModal
        open={showModal}
        handleCancel={handleClose}
        handleSubmit={handleSubmit}
        items={items}
        required={required}
      />
    </>
  )
}

export default OptionSelectCard