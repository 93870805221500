import styles from './Hightlights.module.scss'

type Props = {
  color?: string
  icon: JSX.Element
  title: string
  data?: string[]
}

export default function TopNavigation({ color, icon, title, data }: Props) {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <h4>
          <span style={{ paddingRight: '10px' }}>{icon}</span>
          {title}
        </h4>
      </div>
      <div className={styles.content}>
        <table className={styles.table}>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td width={8}>
                    <span
                      className={styles.dot}
                      style={{ background: `${color}` }}
                    ></span>
                  </td>
                  <td className={styles.message}>{item}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
        {/* <table className={styles.table}>
          <tbody>
            {data?.slice(2, data.length)?.map((item, index) => {
              return (
                <tr key={index}>
                  <td width={20}>
                    <span></span>
                  </td>
                  <td width={110} className={styles.date}>
                    {item}
                  </td>
                  <td className={styles.message}>{item}</td>
                </tr>
              )
            })}
          </tbody>
        </table> */}
      </div>
    </div>
  )
}
