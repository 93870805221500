import React from 'react'
import blueSpinner from '../assets/spinners/spinner-blue.gif'
import whiteSpinner from '../assets/spinners/spinner-white.gif'

interface Props {
  white?: boolean,
  width?: string,
  height?: string,
  className?: string,
}

const LoadingSpinner = (props: Props) => {

  const {white, width = "40px", height = "40px", className} = props

  return <img
    className={className}
    src={white ? whiteSpinner : blueSpinner}
    width={width}
    height={height}
    alt={"loading gif"}
  />
}

export default LoadingSpinner
