import styles from './SaivaInput.module.scss'
import { Input } from 'antd'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as ClearIcon } from 'assets/icons/cross.svg'
import RequiredAsterisk from 'components/requiredAsterisk'

interface Props {
  placeholder: string
  name: string
  label: string
  required?: boolean
  errors?:
    | { message?: string | undefined; type?: string | undefined }
    | undefined
  prefixIcon?: JSX.Element
  value?: string
  onChange?: (e: any) => void
  allowClear?: boolean
}

export default function SaivaInput(props: Props) {
  return (
    <div>
      <label
        className={`${styles.label} ${!!props.errors && styles.labelErrors}`}
      >
        {props.label}
        {props.required && <RequiredAsterisk />}
      </label>
      <Input
        value={props.value}
        className={`${styles.inputDiv} ${
          !!props.errors && styles.inputDivError
        }`}
        allowClear={props.allowClear ? {clearIcon: <ClearIcon />}: false}
        size="large"
        name={props.name}
        placeholder={props.placeholder}
        prefix={
          !!props.errors ? (
            <InfoIcon className={styles.errorIcon} />
          ) : (
            props.prefixIcon
          )
        }
        onChange={props.onChange}
      />
      {!!props.errors && (
        <small className={styles.errorMessage}>{props.errors?.message}</small>
      )}
    </div>
  )
}
