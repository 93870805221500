import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonWithSpinner from '../../../components/buttonWithSpinner'
import { useUserContext } from '../../../context/UserContext'
import { ApiError } from '../../../services/api'
import * as Sentry from '@sentry/react'
import { showErrorToast, showSuccessToast } from '../../../utils'
import OrganizationService from '../../../services/organization-service'
import { SaivaOrg } from '../../../types/organization-types'
import { handleError } from '../../../utils/errorHandler'
import { UserPermission } from '../../../types/user-types'
import AccessControl from 'components/AccessControl/AccessControl'
import SettingsService from 'services/settings-service'
import { SaivaSettings } from 'types/settings-types'
import { ReactComponent as PremiumIcon } from 'assets/icons/trophy.svg'
import { ReactComponent as ProfessionalIcon } from 'assets/icons/medkit.svg'
import { ReactComponent as EnterpriseIcon } from 'assets/icons/crown.svg'
import { Button } from 'antd'
import styles from './Settings.module.scss'
import SaivaRadioButton from 'components/SaivaForm/SaivaRadioButton/saivaRadioButton'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import { mixpanelInstance } from 'utils/mixpanel'
  

const packageHelper = (packageValue: any) => {
  if (packageValue === 'professional') {
    return {
      label: 'Professional',
      background: '#5F8CFA',
      icon: (<ProfessionalIcon />)
    }
  } else if (packageValue === 'premium') {
    return {
      label: 'Premium',
      background: '#7E5EFF',
      icon: (<PremiumIcon />)
    }
  } else if (packageValue === 'enterprise') {
    return {
      label: 'Professional',
      background: '#B66DFF',
      icon: (<EnterpriseIcon />)
    }
  } else {
    return {
      label: 'Premium',
      background: '#7E5EFF',
      icon: (<PremiumIcon />)
    }
  }
}

export default function AdminOrgSettings() {
  const userContext = useUserContext()
  const { t } = useTranslation()

  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [org, setOrg] = useState<SaivaOrg.Item | undefined>(undefined)
  const [packages, setPackages] = useState<any>(undefined)
  const [avaliablePackage, setAvaliablePackage] = useState<any>(undefined)
  const [orgName, setOrgName] = useState<string>(userContext.currentOrg.name)
  const [limitInfo, setLimitInfo] = useState<any>(undefined)

  const getOrgData = async () => {
    await OrganizationService.getOrganization(userContext.currentOrg.id).then((res) => {
      if(!res) return
      setOrg(res)
      setOrgName(res.name)
    }).catch((err) => {
      if(err instanceof ApiError) {
        showErrorToast(t(err.errorCode))
      } else {
        showErrorToast(t('genericError'))
      }
    })    
  }

  const getPackageData = async () => {
    setLoading(true)
    try {
      const res = await SettingsService.getSettings(userContext.currentOrg.id)
      if(!res) throw new Error()
      setPackages(res.packages)
      setLimitInfo(res)
      setAvaliablePackage(res.packages.find((p: any) => {return !p.is_addon}))
      setLoading(false)
    } catch (err: any) {
      setLoading(false)
      if (err.name === 'ApiError') {
        showErrorToast(t(err.errorCode))
      } else {
        showErrorToast(t('genericError'))
      }
    }
  }

  useEffect(() => {
     getOrgData()
     getPackageData()
  }, [userContext.currentOrg])

  const saveOrgDetails = async () => {
    setRequestInFlight(true)
    setTimeout(async () => {
      try {
        const res = await OrganizationService.updateOrganization(userContext.currentOrg.id, {name: orgName})
        if(!res) throw new Error()
        setOrgName(res.name)
        setRequestInFlight(false)
        userContext.refreshOrgs()
        showSuccessToast(t("settings.generalInfo.saveSuccessText"))
      } catch (err) {
        handleError({id: "SaveOrgDetails", error: err})
        setRequestInFlight(false)
        if(err instanceof ApiError) {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t("genericError"))
        }
      }
    }, 1000)
  }

  const mailLink = `mailto:support@saivahc.com?subject=Request more users for ${userContext.currentOrg.id}`

  return (
    <div id="adminOrgSettings" className='container-fluid'>
      <div className='saivaFlexTable shadow-big'>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.organization-name')}</div>
          <div className='tableCol content'>
          <AccessControl userPermissions={[UserPermission.ORGANIZATIONS_EDIT]} returnValue={<>{orgName}</>}>
            <input className='form-control' value={orgName} onChange={(e) => setOrgName(e.target.value)} />
          </AccessControl>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.customer-id')}</div>
          <div className='tableCol content'>
            <span className='text-muted'>{org?.id}</span>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.status')}</div>
          <div className='tableCol content'>
            <span className={`badge status-${org?.status}`}>{org?.status}</span>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
      </div>
      <AccessControl userPermissions={[UserPermission.ORGANIZATIONS_EDIT]}>
        <div className='row'>
          <div className='mt-4 col d-flex justify-content-end align-items-center'>
            <ButtonWithSpinner size='24px' className='btn btn-primary text-uppercase' showSpinner={requestInFlight} onClick={() => saveOrgDetails()}>
              {t('settings.generalInfo.saveButton.text')}
            </ButtonWithSpinner>
          </div>
        </div>
      </AccessControl>
      <div className='saivaFlexTable shadow-big mt-4'>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.product-tier')}</div>
          <div className='tableCol content'>
          <label
            className={`${styles.categoryLabel} category-label`}
            style={{
              color: '#fff',
              background: packageHelper(avaliablePackage?.name).background,
              fontSize: '14px',
            }}
          >
            {packageHelper(avaliablePackage?.name).icon}
            {avaliablePackage?.description}
          </label>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.add-ons')}</div>
          <div className='tableCol content'>
            <div className={styles.addonsWrapper}>
              {packages?.filter((item: SaivaSettings.Package) => item.is_addon).length > 0 ? 
                packages?.filter((item: SaivaSettings.Package) => item.is_addon)
                  .map((p: SaivaSettings.Package) => {
                    return (
                      <label
                        className={`${styles.addons} category-label`}
                        style={{
                          color: '#4070DC',
                          background: '#D9E2F8',
                          fontSize: '14px',
                        }}
                      >
                        {p.description}
                      </label>
                    )
                  }) : 'N/A'
              }
            </div>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
        <div className='tableRow'>
          <div className='tableCol header'>{t('settings.user-limit')}</div>
          <div className={`tableCol content ${styles.limits}`}>
            <div className={styles.limit}>{limitInfo?.feature_values.user_licenses}</div>
            <div className={styles.active}>{limitInfo?.enabled_users}/{limitInfo?.feature_values.user_licenses} Active Users</div>
            <a href={mailLink}><Button type='text' className={styles.actionButton}>Request Users</Button></a>
          </div>
          <div className='tableCol trailingSpace'></div>
        </div>
      </div>
    </div>
  )
}
