import React, { ReactElement, useContext, useRef, useState } from 'react'
import styles from "./ConfirmModal.module.scss"
import {ReactComponent as CloseIcon} from 'assets/icons/cross.svg'

interface ConfirmModalLabels {
  title: string,
  description?: string,
  confirmButtonText: string
  discardButtonText: string
}

export interface IConfirmModalContext {
  isOpen: boolean
  open: (labels?: Partial<ConfirmModalLabels>) => Promise<boolean | undefined>
  labels: ConfirmModalLabels
}

const ConfirmModalContext = React.createContext<IConfirmModalContext>(null!)

export const useConfirmModal = (): IConfirmModalContext => {
  const context = useContext(ConfirmModalContext)
  if (context == null) {
    throw new Error("ERROR: Using Confirm Modal Context while it's null")
  }
  return context
}

const CONFIRM_BUTTON_ID = "modal_confirm_button_id"
const DISCARD_BUTTON_ID = "modal_discard_button_id"


const DEFAULT_VALUE: ConfirmModalLabels = {
  confirmButtonText: 'CONFIRM',
  discardButtonText: 'CANCEL',
  title: 'Confirm the action'
}

interface ConfirmModalProps {
  children: any
}

const ConfirmModalProvider = (props: ConfirmModalProps) => {

  const [open, setOpen] = useState<boolean>(false)
  const [labels, setLabels] = useState<ConfirmModalLabels>(DEFAULT_VALUE)

  const openModal = (labels?: Partial<ConfirmModalLabels>) : Promise<boolean | undefined> => {
    setLabels({...DEFAULT_VALUE, ...labels})
    setOpen(true)
    return new Promise(function (resolve, reject) {
      const confirm = document.getElementById(CONFIRM_BUTTON_ID)
      const discard = document.getElementById(DISCARD_BUTTON_ID)

      const confirmEvent = () => {
        if(confirm) confirm.removeEventListener("click", confirmEvent);
        resolve(true);
        setOpen(false)
      };
      const discardEvent = () => {
        if(discard) discard.removeEventListener("click", discardEvent);
        resolve(false);
        setOpen(false)
      };
      if(confirm) confirm.addEventListener("click", confirmEvent);
      if(discard) discard.addEventListener("click", discardEvent);
    });
  }

  const value: IConfirmModalContext = {
    isOpen: open,
    open: openModal,
    labels
  }

  return (
    <ConfirmModalContext.Provider value={value}>
      <ConfirmModal />
      {props.children}
    </ConfirmModalContext.Provider>
  )
}


export const ConfirmModal = () => {

  const {labels, isOpen} = useConfirmModal()

  const handleClose = () => {
    document.getElementById(DISCARD_BUTTON_ID)?.click()
  }

  return (
    <div className={styles.confirmModalWrapper} style={{display: isOpen ? "flex" : "none"}}>
      <div className={styles.confirmModalContainer}>
        <CloseIcon className={styles.closeIcon} onClick={handleClose}/>
        <h4>{labels.title}</h4>
        {labels.description && <label>{labels.description}</label>}
        <div className={styles.buttons}>
          <button id={DISCARD_BUTTON_ID} className={"primary-text-button"}>{labels.discardButtonText}</button>
          <button id={CONFIRM_BUTTON_ID} className={"primary-button"}>{labels.confirmButtonText}</button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModalProvider
