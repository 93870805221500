import axios, { AxiosRequestConfig, Method } from 'axios'
import { AuthService, getUserFromStorage } from '../auth'
import { PaginatedResponse, parseApi } from './api-utils'
import { handleError } from '../../utils/errorHandler'
import { ApiError } from '../api'
import { AxiosError } from 'axios'

const REQUSET_TIMEOUT = 60000

let count = 0
let isRefreshing: boolean = false

export const getBearer = (refresh: boolean = false) : string => {
  const storage = window.localStorage.getItem("user")
  count++
  if (storage) {
    const user = JSON.parse(storage)
    if(refresh) return user.refreshToken
    return "Bearer " + user.accessToken
  }
  handleError({id: "GetBearer", error: {name: "errir" + count}, customHandle: true})
  return ""
}

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_SAIVA_BACKEND_URL,
  withCredentials: false,
  timeout: REQUSET_TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: getBearer()
  },
})

apiClient.interceptors.request.use(
  (config) => {
    const userAuth = getBearer();

    if (userAuth) {
      config!.headers!.Authorization = userAuth;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const isExpired = () : boolean | undefined => {
  const user = getUserFromStorage()
  if(!user) return undefined
  return user.lastRefresh >= user.expiresAt
}

interface RequestProps<Response, Data, Payload> {
  params?: any,
  parser?: (value: Response) => Data,
  payload?: Payload,
  timeout?: number,
  signal?: AbortSignal,
  paginated?: boolean,
  customErrorHandle?: boolean,
}

interface PaginatedRequestProps<Response, Data, Payload> extends RequestProps<Response, Data, Payload> {
  paginated?: true
}

interface NotPaginatedRequestProps<Response, Data, Payload> extends RequestProps<Response, Data, Payload> {
  paginated?: false
}

async function parseAndGetData<Response, Data>({parser, paginated = false, config} : {parser?: (value: Response) => Data, paginated?: boolean, config: AxiosRequestConfig}) {
  try {
    if (paginated) {
      const { data } = await apiClient.request<PaginatedResponse<Response>>(config)
      if (parser) return parseApi.Pagination<Response, Data>(data, parser)
      return parseApi.Pagination<Response, Data>(data)
    }
    const { data } = await apiClient.request<Response>(config)
    if (parser) return parser(data)
    return data
  } catch (e: AxiosError | unknown) {
    if(e instanceof AxiosError && e.response && e.response.status === 401) {
      if (!isRefreshing) {
        isRefreshing = true;
        return await AuthService.refreshToken()
          .then(async newToken => {
            isRefreshing = false;
            config.headers = { Authorization : 'Bearer ' + newToken}
            return await parseAndGetData<Response, Data>({ parser, paginated, config })
          })
          .catch(async (e) => {
            isRefreshing = false;
            if(e.statusCode === 401) {
              await AuthService.logout()
            }
            throw handleError({ id: "FailedRequestAfterRefresh", error: e, track: true, customHandle: true })
          })
      } else {
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if(!isRefreshing) {
              clearInterval(interval);
              resolve(parseAndGetData<Response, Data>({ parser, paginated, config }))
            }
          }, 100)
        })
      }
    } else throw handleError({id: "ParseAndGetData", error: e, customHandle: true})
  }
}


const getConfig = (method, url, options) : AxiosRequestConfig => {
  try {
    const config: AxiosRequestConfig = { method, url }
    const bearer = getBearer()
    if (bearer) config.headers = { Authorization: bearer }
    if (options?.params) config.params = options.params
    if (options?.payload) config.data = options.payload
    if (options?.signal) config.signal = options.signal
    if (options?.timeout) config.timeout = options.timeout
    return config
  } catch (err) {
    throw handleError({ id: "CouldNotCreateRequestConfig", error: err, customHandle: true })
  }
}

function request(method: Method | undefined) {
  // TODO replace undefined with error type
  // TODO parsing function not required when needed
  async function a<Response = any, Data = Response, Payload = any>(url: string, options?: NotPaginatedRequestProps<Response, Data, Payload>) : Promise<Data | undefined>
  async function a<Response = any, Data = Response, Payload = any>(url: string, options?: PaginatedRequestProps<Response, Data, Payload>) : Promise<PaginatedResponse<Data> | undefined>
  async function a<Response = any, Data = Response, Payload = any>(url: string, options?: RequestProps<Response, Data, Payload>) {
    try {
      const config: AxiosRequestConfig = getConfig(method, url, options)
      if(isExpired()) throw new ApiError("auth_token_expired", undefined, 401, config)
      return await parseAndGetData<Response,Data>({parser: options?.parser, paginated: options?.paginated, config})
    } catch (error: any) {
      throw error
    }
  }
  return a
}

export const get = request("get")
export const post = request("post")
export const patch = request("patch")
export const del = request("delete")

export default apiClient
