import { useRef } from 'react'
import SaivaTable from 'components/Tables/AntdTable/AntdTable'
import { Button, Tooltip, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as ResizeIcon } from 'assets/icons/resize-small-vertical.svg'
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-small-down.svg'
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-small-up.svg'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import styles from '../BasicTable.module.scss'
import { TableColumns } from './TableColumns'
import { useTranslation } from 'react-i18next'

export interface ISaivaTableHeaderItem {
  label: string
  id: string
  type?: string
  attribute?: string
}

type PositionType = 'left' | 'right'

interface IBasicTableProps {
  data: any
  rowKey: string
  loading: boolean
  onSearch: (value: any) => void
  searchValue: string | undefined
  handleSortClick: (value: string) => void
  tableColumns: {
    label: string
    id: string
    sortable?: boolean
    type: string
  }[]
  pagination: any
  onChangeTab: (key: string) => void
  activeView: string
}

export default function UserEngagementTable({
  data,
  rowKey,
  loading,
  onSearch,
  handleSortClick,
  searchValue,
  pagination,
  tableColumns,
  onChangeTab,
  activeView
}: IBasicTableProps) {
  const { t } = useTranslation()
  const tableRef = useRef<HTMLDivElement>(null)

  const sortingHeader = (title: string, sortingID: string) => (
    <>
      <Button
        type="link"
        onClick={() => handleSortClick(sortingID)}
        className={styles.sortButton}
      >
        {title}
        <ResizeIcon />
      </Button>
    </>
  )

  const Operations: Record<PositionType, React.ReactNode> = {
    left: null,
    right: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip
            arrow={false}
            placement="left"
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={
              activeView == 'patient_view'
                ? t('reports.userEngagementReport.table.searchMessage')
                : t('reports.userEngagementReport.table.facilitySearchMessage')
            }
          >
            <div>
              <SaivaInput
                value={searchValue}
                key="search"
                placeholder={t(
                  'reports.crossFacilityRiskReport.table.search.placeholder'
                )}
                name="search"
                label=""
                prefixIcon={<SearchIcon />}
                allowClear={true}
                onChange={(value) => onSearch(value)}
              />
            </div>
          </Tooltip>
        </div>
      </div>
    )
  }

  function saivaTable() {
    const renderExpandIcon = ({ expanded, onExpand, record }) => {
      if (record.facility_data && record.facility_data.length > 0) {
        const Icon = expanded ? ChevronUp : ChevronDown
        return (
          <Button type="link" onClick={(e) => onExpand(e, record)}>
            <Icon style={{ stroke: '#000' }} />
          </Button>
        )
      }
      return null
    }

    return (
      <SaivaTable
        columns={TableColumns(sortingHeader, tableColumns)}
        rowKey={rowKey}
        rowClassName={(record) =>
          record.facility_data && record.facility_data.length > 0
            ? `${styles.row} ${styles.heightRow}`
            : styles.heightRow
        }
        expandable={{
          expandIcon: renderExpandIcon,
          expandRowByClick: true
        }}
        data={data}
        loading={loading}
        pagination={pagination}
        scroll={{ x: 'max-content' }}
      />
    )
  }

  const items: TabsProps['items'] = [
    {
      key: 'patient_view',
      label: t('reports.userEngagementReport.table.patientView.label'),
      children: saivaTable()
    },
    {
      key: 'facility_view',
      label: t('reports.userEngagementReport.table.facilityView.label'),
      children: saivaTable()
    }
  ]

  return (
    <>
      {data && (
        <div ref={tableRef}>
          <Tabs
            activeKey={activeView}
            destroyInactiveTabPane={true}
            items={items}
            onChange={onChangeTab}
            tabBarExtraContent={Operations}
            className={styles.tabs}
          />
        </div>
      )}
    </>
  )
}
