import React, { Fragment, useEffect, useRef, useState } from 'react'
import { CanceledError } from 'axios'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useOnClickOutside, useUpdateEffect } from 'usehooks-ts'
import { useTranslation } from 'react-i18next'

import {
  FilterType,
  FormatTypes,
  IGeneralReportData,
  LocalStorageDateItem,
  SaivaReport
} from 'types/reports-types'
import { LocalStorageItem } from 'types/localStorage'

import { FileType } from 'services/api'
import ReportService, { QueryTypes } from 'services/report-service'

import { useUserContext } from 'context/UserContext'

import BasicTable, {
  IBasicTableData,
  ISaivaTableHeaderItem
} from 'components/Tables/BasicTable/BasicTable'
import SaivaWoundsTable from 'components/Tables/BasicTable/SaivaWoundsTable'
import OptionSelectCard, {
  IOptionSelectItem
} from 'components/OptionSelect/OptionSelect'
import DatePickerCard, {
  IDateRange,
  Range
} from 'components/DatePicker/datePicker'
import DataTable, {
  IPeriodTableData
} from 'components/Tables/PeriodTable/dataTable'
import FileTable, {
  IFileTableData
} from 'components/Tables/FileTable/fileTable'
import Charts from 'components/Charts'

import SaivaDropdown from 'components/Dropdown/Dropdown'
import RadioSelect from 'components/OptionSelect/RadioSelect/RadioSelect'
import ReportCard from './ReportCard/reportCard'

import { showErrorToast } from 'utils'
import { mixpanelInstance } from 'utils/mixpanel'
import { compareArrays, getBrowserInfo, getReportId } from 'utils/helper'
import downloadFile from 'utils/downloadFile'

import blueSpinner from 'assets/spinners/spinner-blue.gif'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as SubscriptionIcon } from 'assets/icons/subscription.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/filters.svg'
import { get } from '../../services/utils/api-client'
import { UserPermission } from '../../types/user-types'
import ScheduleReportingModal from 'views/Reports/ScheduleReporting/SRModal/SRModal'
import ManageReportsModal from 'views/Reports/ScheduleReporting/MRModal/MRModal'

import { Dropdown, Space, Button, Badge, Col, Row } from 'antd'
import type { MenuProps } from 'antd'
import styles from './Reports.module.scss'
import { OrgProductFeature } from 'types/user-types'
import FacilitiesSelectCard from 'components/OptionSelect/OptionSelectWounds/FacilitiesSelectWounds'
import QualityMeasureSelector from 'components/QualityMeasureSelector/QualityMeasureSelector'
import { getFeatureLabel } from 'utils/helper'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import SchedulesButton from './ScheduleReporting/ScheduleButton'
import RegionService from 'services/region-service'

const max_number_of_facilities = Number(
  process.env.REACT_APP_MAX_NUMBER_OF_FACILITIES
)

const openNewWindow = (type?: FileType): Window | null => {
  if (getBrowserInfo?.name === 'ios' && getBrowserInfo.os === 'iOS')
    return window.open('/loading', '_blank')
  if (getBrowserInfo?.name === 'ios' || type === FileType.CSV) return null

  return window.open('/loading', '_blank')
}

export type IFilterStateType = {
  id: string
  data?: any
  value: any
  query?: string
  type: QueryTypes
  fromReport?: boolean
  default_option?: string
}
export interface IFilterState {
  [name: string]: IFilterStateType | undefined
  date?: { id: 'date'; value: IDateRange | undefined; type: QueryTypes.Date }
}

export interface IStats {
  type?: string
  label: string
  id?: string
  date_range: string
}

export interface IStatsGroup {
  type: 'group'
  id: string
  label: string
  items: IStats[]
}

export type DateRange =
  | { start: Date | undefined; end: Date | undefined }
  | undefined

const checkDate = (
  range: Date | undefined,
  defaultDate: Date = new Date()
): Date => {
  return range ? range : defaultDate
}

type Facilities = {
  name: string
  id: number
}

type Regions = {
  name: string
  id: number
  facilities: Facilities[]
}

type DateFilter = {
  id: string
  value: IDateRange | undefined
}

type Data = {
  label: string
  id: string
  isSelected: boolean
}

type DynamicFilterList = {
  id: string
  data: Data[]
  value: Data[]
  default_option: string
  query: string
  type: number
}

type DynamicFilter = {
  id: string
  data: Data
  value: Data
  default_option: string
  query: string
  type: number
}

type FiltersProps = {
  facilities?: {
    id: string
    query: string
    type: number
    value: Regions[]
    data: Regions[]
  }
  date?: DateFilter
  include_hospice?: DynamicFilter
  los_range?: DynamicFilter
  filter_30_days?: boolean
  covid_diagnosed?: DynamicFilterList
  payer_type?: DynamicFilterList
  quality_measure?: string
  outcome?: DynamicFilterList
  planned?: DynamicFilterList
  predicted?: DynamicFilterList
  report_date?: string 
  report_name?: string
}

function parseDate(date) {
  if (!date) return undefined
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()
  return `${year}-${month}-${day}`
}

const parseFilters = (filters: FiltersProps) => {
  const {
    facilities,
    date,
    include_hospice,
    los_range,
    filter_30_days,
    payer_type,
    quality_measure,
    outcome,
    covid_diagnosed,
    report_date,
    report_name,
    planned,
    predicted
  } = filters

  function filteredValues(value: DynamicFilterList | undefined) {
    if (value == undefined) return undefined
    return value?.value
      ?.filter((item) => item.isSelected)
      .map((item) => item.label)
  }

  const facilityNames = [] as string[]
  const facilityIds = [] as number[]

  facilities?.value.forEach((region) => {
    region.facilities.forEach((facility) => {
      facilityNames.push(facility.name)
      facilityIds.push(facility.id)
    })
  })

  const include_hospice_value = include_hospice
  ? include_hospice.value.id === 'True'
  : undefined;

const filter_30_days_value = los_range
  ? los_range.value.id !== 'False'
  : filter_30_days ?? undefined;

  const parsedFilters = {
    facilities: facilityNames,
    facility_ids: facilityIds,
    payer_type: filteredValues(payer_type),
    outcome: filteredValues(outcome),
    planned: filteredValues(planned),
    predicted: filteredValues(predicted),
    covid_diagnosed: filteredValues(covid_diagnosed),
    report_date,
    report_name,
    quality_measure,
    date_end: parseDate(date?.value?.end),
    date_start: parseDate(date?.value?.start),
    type: date?.value?.type,
    include_hospice: include_hospice_value,
    filter_30_days: filter_30_days_value
  }

  Object.keys(parsedFilters).forEach(
    (key) => parsedFilters[key] === undefined && delete parsedFilters[key]
  )

  return parsedFilters
}

const getDefault = (
  defaultValue: { start: Date | undefined; end: Date | undefined } = {
    start: undefined,
    end: undefined
  },
  range: Range,
  customDefault?: LocalStorageDateItem
) => {
  const currentDate = new Date()
  switch (range) {
    case Range.Day:
      const yesterday = new Date(new Date().setDate(currentDate.getDate() - 1))
      return {
        start: checkDate(
          defaultValue?.start,
          checkDate(customDefault?.day?.start, yesterday)
        ),
        end: checkDate(
          defaultValue?.end,
          checkDate(customDefault?.day?.end, yesterday)
        )
      }
    case Range.Daily:
      const weekBeforeToday = customDefault?.daily
        ? customDefault.daily.start
        : new Date(new Date().setDate(currentDate.getDate() - 8))
      return {
        start: checkDate(
          defaultValue?.start,
          checkDate(customDefault?.daily?.start, weekBeforeToday)
        ),
        end: checkDate(defaultValue?.end, checkDate(customDefault?.daily?.end))
      }
    case Range.Monthly:
      const startOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 2,
        1
      )
      const endOfCurrentMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      )
      return {
        start: checkDate(
          defaultValue?.start,
          checkDate(customDefault?.monthly?.start, startOfCurrentMonth)
        ),
        end: checkDate(
          defaultValue?.end,
          checkDate(customDefault?.monthly?.start, endOfCurrentMonth)
        )
      }
    case Range.Quarterly:
      const startOfQuarter = new Date(
        currentDate.getFullYear(),
        Math.floor(currentDate.getMonth() / 3) * 3,
        1
      )
      const endOfQuarter = new Date(
        currentDate.getFullYear(),
        Math.floor(currentDate.getMonth() / 3) * 3 + 3,
        0
      )
      return {
        start: checkDate(
          defaultValue?.start,
          checkDate(customDefault?.quarterly?.start, startOfQuarter)
        ),
        end: checkDate(
          defaultValue?.end,
          checkDate(customDefault?.quarterly?.start, endOfQuarter)
        )
      }
  }
}

const getDate = (value: { start: string; end: string }) => {
  return {
    start: value?.start ? new Date(value.start) : undefined,
    end: value?.end ? new Date(value.end) : undefined
  }
}

const getDefaultRanges = (
  value: any | undefined = undefined,
  customDefault?: LocalStorageDateItem
): LocalStorageDateItem => {
  return {
    day: getDefault(getDate(value?.day), Range.Day, customDefault),
    daily: getDefault(getDate(value?.daily), Range.Daily, customDefault),
    monthly: getDefault(getDate(value?.monthly), Range.Monthly, customDefault),
    quarterly: getDefault(
      getDate(value?.quarterly),
      Range.Quarterly,
      customDefault
    ),
    defaultRange: value?.defaultRange
  }
}

const loadDateFromLocalStorage = (
  groupId: string,
  customDefault?: LocalStorageDateItem
): LocalStorageDateItem => {
  try {
    const a = window.localStorage.getItem('filters')
    if (!a) return getDefaultRanges(undefined, customDefault)
    const value: LocalStorageItem = JSON.parse(a)
    if (!value || !value[groupId])
      return getDefaultRanges(undefined, customDefault)
    return getDefaultRanges(value[groupId].date, customDefault)
  } catch (e) {
    Sentry.captureException('Error: ReportLoadDateFilterFromLocalStorage;' + e)
    return undefined
  }
}

const loadFiltersFromLocalStorage = (
  orgId: string
): IFilterState | undefined => {
  try {
    const res = window.localStorage.getItem('filters')
    if (!res) return undefined
    return JSON.parse(res)[orgId]
  } catch (e) {
    Sentry.captureException('Error: ReportLoadFiltersFromLocalStorage;' + e)
    return undefined
  }
}

const parseDateToLocalStorage = (
  value: LocalStorageDateItem,
  date: IDateRange | undefined,
  range: Range | undefined
): LocalStorageDateItem => {
  const types = ['day', 'daily', 'monthly', 'quarterly']
  let result: LocalStorageDateItem = value
  if (!date || !range) return result
  const { start, end } = date
  if (result) {
    types.forEach((type) => {
      if (type === range && result)
        result = {
          day: result.day,
          daily: result.daily,
          monthly: result.monthly,
          quarterly: result.quarterly,
          [type]: { start, end }
        }
    })
  } else {
    types.forEach((type) => {
      if (type === range) result = { [type]: { start, end } }
    })
  }
  return { ...result, defaultRange: range }
}

const saveFiltersToLocalStorage = (
  orgId: string,
  filter: IFilterState | undefined,
  range?: Range
) => {
  try {
    const res = window.localStorage.getItem('filters')
    let value = res ? JSON.parse(res) : undefined
    let updatedFilter: any = filter
    if (filter?.date) {
      updatedFilter = {
        ...updatedFilter,
        date: parseDateToLocalStorage(
          value && value[orgId] ? value[orgId]?.date : undefined,
          filter.date.value,
          range
        )
      }
    }
    if (value)
      window.localStorage.setItem(
        'filters',
        JSON.stringify({
          ...value,
          [orgId]: { ...value[orgId], ...updatedFilter }
        })
      )
    else
      window.localStorage.setItem(
        'filters',
        JSON.stringify({ [orgId]: updatedFilter })
      )
  } catch (e) {
    Sentry.captureException('Error: ReportSaveFiltersToLocalStorage;' + e)
  }
}

interface DownloadButtonProps {
  fileLoading: boolean
  downloadReport: ((fileType: FileType) => void) | undefined
}

const DownloadButton = ({
  downloadReport,
  fileLoading
}: DownloadButtonProps) => {
  const downloadPopup = useRef(null)
  useOnClickOutside(downloadPopup, () => setOpenFileType(false))

  const [openFileType, setOpenFileType] = useState<boolean>(false)

  const handleFileChoose = (fileType: FileType) => {
    if (downloadReport) {
      setOpenFileType(false)
      downloadReport(fileType)
    }
  }

  const menu = [
    {
      key: '1',
      label: (
        <button
          onClick={() => handleFileChoose(FileType.PDF)}
          className={styles.dropdownButton}
        >
          Export PDF
        </button>
      )
    },
    {
      key: '2',
      label: (
        <button
          onClick={() => handleFileChoose(FileType.CSV)}
          className={styles.dropdownButton}
        >
          Export CSV
        </button>
      )
    }
  ]

  const items: MenuProps['items'] = menu

  return (
    <div className={'downloadButtonWrapper'}>
      <Dropdown menu={{ items }} overlayClassName={styles.dropdown}>
        <Button className={styles.actionButton} size="large">
          {fileLoading ? (
            <img
              className="me-2"
              src={blueSpinner}
              width="20px"
              height="20px"
              alt={'loading gif'}
            />
          ) : (
            <DownloadIcon />
          )}
          <Space style={{ paddingLeft: '10px' }}>Export</Space>
        </Button>
      </Dropdown>
    </div>
  )
}

const rowOptions = [
  { label: '5', value: 5 },
  { label: '10', value: 10 },
  { label: '20', value: 20 },
  { label: '50', value: 50 },
  { label: '100', value: 100 }
]

let abortData = new AbortController()
let abortFilters = new AbortController()

interface ReportWrapperProps {
  reportStructure: SaivaReport.StructureItem
}

const ReportWrapper = (props: ReportWrapperProps) => {
  // const {type, tableType = FormatTypes.PeriodTable, stats, hasCards, headers, filters, fileName, fetchFunction, fetchFileFunction} = props
  const userContext = useUserContext()

  const { t } = useTranslation()
  const {
    id,
    url,
    dataType,
    filters,
    reportOptions,
    label,
    flag,
    quality_measure
  } = props.reportStructure

  const [data, setData] = useState<
    | IGeneralReportData<IFileTableData | IBasicTableData | IPeriodTableData>
    | undefined
  >(undefined)
  const [filtersData, setFiltersData] = useState<IFilterState | undefined>(
    undefined
  )

  const [loading, setLoading] = useState<boolean>(false)
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [oldOrg, setOldOrg] = useState<string>('')
  const [localStorageValue, setLocalStorageValue] =
    useState<LocalStorageDateItem>(
      loadDateFromLocalStorage(userContext.currentOrg.id)
    )

  const [showCharts, setShowCharts] = useState<boolean>(false)
  const [showCumulativeTable, setShowCumulativeTable] = useState<boolean>(false)
  const [hideFilters, setHideFilters] = useState<boolean>(true)
  const [regions, setRegions] = useState<any>([])

  useEffect(() => {
    RegionService.getRegions(userContext.currentOrg.id).then((res) => {
      setRegions(res)
      setLocalStorageValue(loadDateFromLocalStorage(userContext.currentOrg.id))
    })
  }, [userContext.currentOrg.id, window.localStorage.getItem('filters')])

  const fetchFilters = async (orgId: string): Promise<IFilterState> => {
    let state: IFilterState = {}
    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i]
      if (filter.type === FilterType.FACILITY_SELECT) {
        const data = checkWithLocalStorage(orgId, regions, filter.id)
        state = {
          ...state,
          [filter.id]: {
            id: filter.id,
            data: data,
            value: data,
            query: filter.queryParam,
            type: QueryTypes.Array
          }
        }
      }
      if (filter.type === FilterType.OPTION_SELECT_WITH_ITEMS) {
        const data = checkWithLocalStorage(orgId, filter.items, filter.id)
        state = {
          ...state,
          [filter.id]: {
            id: filter.id,
            data: data,
            value: data,
            query: filter.queryParam,
            type: QueryTypes.Array
          }
        }
      }
      if (filter.type === FilterType.OPTION_SELECT_WITH_REQUEST) {
        const resp = await filter.fetchData(orgId, abortFilters)
        const data = checkWithLocalStorage(orgId, resp, filter.id)
        state = {
          ...state,
          [filter.id]: {
            id: filter.id,
            data: data,
            value: data,
            query: filter.queryParam,
            type: QueryTypes.Array
          }
        }
      }
      if (filter.type === FilterType.MULTI_OPTION_SELECT) {
        filter.optionSelects.forEach((optionSelect) => {
          if (optionSelect.type === FilterType.OPTION_SELECT_WITH_ITEMS) {
            const data = checkWithLocalStorage(
              orgId,
              optionSelect.items,
              optionSelect.id
            )
            state = {
              ...state,
              [optionSelect.id]: {
                id: optionSelect.id,
                data: data,
                value: data,
                query: optionSelect.queryParam,
                type: QueryTypes.Array
              }
            }
          }
        })
      }

      if (filter.type === FilterType.DATE_PICKER) {
        const localStorage = loadDateFromLocalStorage(
          orgId,
          filter.customDefaultRange
        )
        if (localStorage) {
          const defaultValue = localStorage.defaultRange
            ? localStorage[localStorage.defaultRange]
            : localStorage[filter.ranges[0]]
          state = {
            ...state,
            date: {
              id: 'date',
              value: {
                start: defaultValue?.start
                  ? defaultValue.start
                  : state.date?.value?.start,
                end: defaultValue?.end
                  ? defaultValue.end
                  : state.date?.value?.end,
                isQuarterly:
                  localStorage.defaultRange === Range.Quarterly ||
                  filter.ranges[0] === Range.Quarterly
                    ? true
                    : filter.ranges[0] === Range.Monthly
                    ? false
                    : undefined,
                type: filter.ranges[0]
              },
              type: QueryTypes.Date
            }
          }
        }
      }
      if (filter.type === FilterType.DROPDOWN) {
        const default_option = filter.items[0]
        const value = checkDropdownWithLocalStorage(
          orgId,
          filter.id,
          default_option
        )
        if (value) {
          state = {
            ...state,
            [filter.id]: {
              id: filter.id,
              data: filter.items,
              value: value,
              type: QueryTypes.Object
            }
          }
        }
      }
      if (filter.type === FilterType.RADIO_SELECT) {
        const data = checkDropdownWithLocalStorage(
          orgId,
          filter.id,
          filter.items.find((item) => item.id === filter.default_option)
        )
        state = {
          ...state,
          [filter.id]: {
            id: filter.id,
            data: data,
            value: data,
            default_option: filter.default_option,
            query: filter.queryParam,
            type: QueryTypes.Object
          }
        }
      }
    }
    return state
  }

  const parseDataToFilterState = (
    filter: SaivaReport.Data.FilterItem
  ): IFilterStateType => {
    const selected: SaivaReport.Filter.OptionItem[] = checkWithLocalStorage(
      userContext.currentOrg.id,
      filter.items,
      filter.id
    )
    return {
      id: filter.id,
      data: selected,
      value: selected,
      query: filter.id,
      type: QueryTypes.Array,
      fromReport: true
    }
  }

  const checkFilters = (filter: IFilterState): Boolean => {
    return Boolean(
      userContext.currentOrg &&
        filter?.facilities &&
        filter?.facilities?.value?.length > 0 &&
        filter?.date?.value?.start &&
        filter?.date?.value.end
    )
  }

  const fetchFile = async (
    fileType: FileType,
    filter: IFilterState | undefined,
    enableCustomRepOpts?
  ) => {
    if (filter && checkFilters(filter)) {
      try {
        let window = openNewWindow(fileType)
        setFileLoading(true)
        let downloadUrl: { url: string } | undefined = undefined
        if (fileType === FileType.PDF) {
          downloadUrl = enableCustomRepOpts
            ? reportOptions?.pdfDownloadCumulative
            : reportOptions?.pdfDownload
        }
        if (fileType === FileType.CSV) {
          downloadUrl = enableCustomRepOpts
            ? reportOptions?.csvDownloadCumulative
            : reportOptions?.csvDownload
        }
        const resp = await ReportService.downloadReportFile(
          {
            organizationId: userContext.currentOrg.id,
            requestUrl: downloadUrl ? downloadUrl.url : url,
            queryParams: {
              ...filter,
              quality_measure: quality_measure
                ? {
                    query: 'quality_measure',
                    type: QueryTypes.String,
                    value: quality_measure
                  }
                : undefined
            }
          },
          abortData
        )
        mixpanelInstance.reportsFileDownload(
          id,
          downloadUrl ? downloadUrl.url : url,
          fileType,
          parseFilters({
            ...filtersData,
            quality_measure: quality_measure ? quality_measure : undefined
          })
        )
        downloadFile(resp, {
          title: filter.date?.value,
          fileName: id + '-' + getFeatureLabel(quality_measure).label,
          type: fileType,
          window
        })
        setFileLoading(false)
      } catch (err) {
        if (err instanceof CanceledError) return
        window.close()
        showErrorToast('An error occurred while loading data!')
        Sentry.captureException('Error: GetReport - ' + id + ', ' + err)
      } finally {
        setFileLoading(false)
      }
    }
  }

  const clearFiltersFromDataResponse = (
    f: any | undefined
  ): any | undefined => {
    if (f) {
      const keys = Object.keys(f)
      keys.forEach((key) => {
        if (f[key] && f[key].fromReport) {
          f[key] = { value: [], data: [] }
        }
      })
      return f
    }
    return undefined
  }

  const getData = async (f, quality_measure_filter) => {
    if (dataType.format === FormatTypes.BASIC_TABLE) {
      return await ReportService.getReportBasicTableData(
        {
          organizationId: userContext.currentOrg.id,
          requestUrl: url,
          queryParams: {
            ...f,
            quality_measure: quality_measure_filter
          }
        },
        abortData
      )
    }
    if (dataType.format === FormatTypes.FILE_TABLE) {
      return await ReportService.getReportFileTableData(
        {
          organizationId: userContext.currentOrg.id,
          requestUrl: url,
          queryParams: {
            ...f,
            quality_measure: quality_measure_filter
          }
        },
        abortData
      )
    }
    if (dataType.format === FormatTypes.PERIODIC_TABLE) {
      return await ReportService.getReportPeriodTableData(
        {
          organizationId: userContext.currentOrg.id,
          requestUrl: url,
          queryParams: {
            ...f,
            quality_measure: quality_measure_filter
          }
        },
        abortData
      )
    }
  }

  const fetchData = async ({
    filter,
    updateFilters = false
  }: {
    filter?: IFilterState
    updateFilters?: boolean
  }) => {
    abortData.abort()
    abortData = new AbortController()
    let f = filter ? filter : filtersData
    //TODO create universal check for filters
    if (f && checkFilters(f)) {
      setLoading(true)
      // TODO temp solution think of better
      const quality_measure_filter = quality_measure
        ? {
            query: 'quality_measure',
            type: QueryTypes.String,
            value: quality_measure
          }
        : undefined

      getData(f, quality_measure_filter)
        .then((resp) => {
          if (resp && resp.filters && updateFilters) {
            const filters: IFilterState = {}
            resp.filters.forEach((item) => {
              filters[item.id] = parseDataToFilterState(item)
            })
            setFiltersData({ ...f, ...filters })
            setLoading(false)
            fetchData({ filter: { ...f, ...filters }, updateFilters: false })
          } else {
            setData(resp)
            setLoading(false)
          }
        })
        .catch((err) => {
          setLoading(false)
          if (err instanceof CanceledError) return
          if (err.message === 'ml_model_organization_config_not_found') {
            showErrorToast(
              t('backend.ml_model_organization_config_not_found', {
                model_name: quality_measure
              })
            )
          } else showErrorToast(t('backend.generic_error'))
        })
    } else {
      setData(undefined)
      clearFiltersFromDataResponse(f)
      setLoading(false)
    }
  }

  const fetchAll = async () => {
    await fetchFilters(userContext.currentOrg.id).then((res) => {
      const localStorage = loadFiltersFromLocalStorage(
        userContext.currentOrg.id
      )
      if (localStorage) {
        const keys = Object.keys(localStorage)
        keys.forEach((key) => {
          if (res[key] && key !== 'date') {
            res[key] = localStorage[key]
          }
        })
      }
      const filter = { ...res }
      setFiltersData(filter)
      mixpanelInstance.reportsNavigationChange(
        label,
        id,
        parseFilters({
          ...filter,
          quality_measure: quality_measure ? quality_measure : undefined
        })
      )
      fetchData({ filter: filter, updateFilters: true })
    })
  }

  const handleSetFilterAndRefetch = (
    params: IFilterStateType,
    options?: { updateFilters?: boolean; dateRange?: Range }
  ) => {
    if (oldOrg !== userContext.currentOrg.id) {
      setOldOrg(userContext.currentOrg.id)
      setFiltersData({ [params.id]: params })
      fetchData({
        filter: { [params.id]: params },
        updateFilters: options?.updateFilters
      })
    } else {
      setFiltersData((prev) => {
        const filter = { ...prev, [params.id]: params }
        fetchData({ filter: filter, updateFilters: options?.updateFilters })
        return filter
      })
    }
    saveFiltersToLocalStorage(
      userContext.currentOrg.id,
      { [params.id]: params },
      options?.dateRange
    )
  }

  useEffect(() => {
    if (regions && regions.length > 0) {
      fetchAll()
    }
  }, [showCharts, regions.length])

  useEffect(() => {
    abortData = new AbortController()
    abortFilters = new AbortController()

    return () => {
      abortData.abort()
      abortFilters.abort()
    }
  }, [])

  useEffect(() => {
    if (filtersData)
      mixpanelInstance.reportsTabSelect(
        id,
        showCharts ? 'Charts' : 'TableData',
        parseFilters({
          ...filtersData,
          quality_measure: quality_measure ? quality_measure : undefined
        })
      )
  }, [showCharts])

  const getFileTable = (data: any) => {
    const handleReportClick = async (report, setFileLoading, facility) => {
      if (userContext.currentOrg && filtersData) {
        try {
          const window = openNewWindow()
          setFileLoading(true)
          const resp = await ReportService.downloadReportFile({
            requestUrl: url,
            organizationId: userContext.currentOrg.id,
            reportId: report.id
          })
          if (!resp) return
          mixpanelInstance.reportsFileDownload(
            id,
            resp.url,
            undefined,
            {
              facilities: [facility.name],
              facility_ids: [facility.id],
              quality_measure: quality_measure ? quality_measure : undefined,
              report_date: report.date,
              report_name: report.id,
              report_type: 'daily_risk_report'
            }
          )
          downloadFile(resp.url, {
            title: report.date,
            fileName: report.id,
            fromUrl: true,
            window
          })
          setFileLoading(false)
        } catch (e) {
          setFileLoading(false)
        }
      }
    }

    const header = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          marginBottom: 4
        }}
      ></div>
    )

    return (
      <>
        {header}
        <FileTable
          data={data}
          onReportClick={handleReportClick}
          dateRange={
            filtersData &&
            filtersData.date?.value?.start &&
            filtersData.date?.value.end
              ? {
                  start: filtersData.date.value.start,
                  end: filtersData.date.value.end
                }
              : undefined
          }
        />
      </>
    )
  }

  // TODO do headers interface
  const getPeriodTable = (
    data: any,
    headers: { label: string; id: string, info?: string }[],
    charts?: SaivaReport.Data.Charts
  ) => {
    const header = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div className={'tabs'}>
          <button
            className={`${!showCharts ? 'active' : ''}`}
            onClick={() => setShowCharts(false)}
          >
            Tabular Data
          </button>
          <button
            className={`${showCharts ? 'active' : ''}`}
            onClick={() => setShowCharts(true)}
          >
            Charts
          </button>
        </div>
      </div>
    )

    const download = async (
      detailUrl,
      facility: string | undefined,
      date: string
    ) => {
      const data = await get(detailUrl)
      const facName = facility?.toLowerCase().replaceAll(' ', '-')
      downloadFile(data, {
        title: date.toLowerCase().replace(' ', '-'),
        fileName: `emails${facName ? '-' + facName : ''}`,
        type: FileType.CSV
      })
    }

    return (
      <>
        {showCharts ? (
          <Charts
            data={charts}
            timePeriods={data.timePeriods}
            headerElement={header}
          />
        ) : (
          <DataTable
            data={data}
            mainTitle={'Organization, Region, Facilities'}
            headerItems={headers}
            downloadReport={(fileType) => fetchFile(fileType, filtersData)}
            downloadDetail={download}
            fileLoading={fileLoading}
            headerElement={header}
            quality_measure={quality_measure}
          />
        )}
      </>
    )
  }

  const getHitMissTable = (
    data: any,
    cumulative_data,
    headers: ISaivaTableHeaderItem[],
    cumulative_headers
  ) => {
    const isPredictedYesSelected = () =>
      filtersData?.predicted?.value.find((e) => e.id === 'Yes')?.isSelected
    const header = (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 8
        }}
      >
        <div className={'tabs'}>
          <button
            className={`${!showCumulativeTable ? 'active' : ''}`}
            onClick={() => setShowCumulativeTable(false)}
          >
            Detailed
          </button>
          <button
            className={`${showCumulativeTable ? 'active' : ''}`}
            onClick={() => setShowCumulativeTable(true)}
          >
            Cumulative
          </button>
        </div>
      </div>
    )

    return (
      <div>
        <>{header}</>
        {showCumulativeTable ? (
          isPredictedYesSelected() ? (
            <BasicTable
              data={cumulative_data}
              headers={cumulative_headers}
              rowsPerPageOptions={rowOptions}
              downloadReport={(fileType) => fetchFile(fileType, filtersData)}
              fileLoading={fileLoading}
              sortBy={filtersData?.sort?.value}
              sortChange={(value) =>
                handleSetFilterAndRefetch({
                  id: 'sort',
                  value,
                  query: 'sort',
                  type: QueryTypes.String,
                  fromReport: true
                })
              }
            />
          ) : (
            <div className={'center'}>No Data to Display</div>
          )
        ) : (
          <BasicTable
            data={data}
            headers={headers}
            rowsPerPageOptions={rowOptions}
            downloadReport={(fileType) =>
              fetchFile(fileType, filtersData, true)
            }
            fileLoading={fileLoading}
            sortBy={filtersData?.sort?.value}
            sortChange={(value) =>
              handleSetFilterAndRefetch({
                id: 'sort',
                value,
                query: 'sort',
                type: QueryTypes.String,
                fromReport: true
              })
            }
          />
        )}
      </div>
    )
  }

  const getBasicTable = (data: any, headers: ISaivaTableHeaderItem[]) => {
    return (
      <BasicTable
        data={data}
        headers={headers}
        rowsPerPageOptions={rowOptions}
        downloadReport={(fileType) => fetchFile(fileType, filtersData)}
        fileLoading={fileLoading}
        sortBy={filtersData?.sort?.value}
        sortChange={(value) =>
          handleSetFilterAndRefetch({
            id: 'sort',
            value,
            query: 'sort',
            type: QueryTypes.String,
            fromReport: true
          })
        }
      />
    )
  }

  const getStats = (data: SaivaReport.Data.Stats) => {
    if (data.type === 'group') {
      return (
        <div className={'reportCardGroupWrapper'}>
          <div className={'reportCardGroupHeader'}>{data.label}</div>
          <div className={'reportCardGroupCards'}>
            {data.items.map((card, i) => {
              return (
                <Fragment key={i}>
                  <ReportCard
                    label={card.label}
                    loading={loading}
                    data={card.data}
                    dateRange={card.dateRange}
                    type={card.type}
                  />
                </Fragment>
              )
            })}
          </div>
        </div>
      )
    } else {
      return (
        <ReportCard
          label={data.label}
          loading={loading}
          data={data.data}
          dateRange={data.dateRange}
          type={data.type}
        />
      )
    }
  }

  const getFilter = (filter: SaivaReport.Filters) => {
    const getOptionSelectCard = (
      filter:
        | SaivaReport.Filter.OptionSelectWithItems
        | SaivaReport.Filter.OptionSelectWithRequest
    ) => {
      const fromReport = 'items' in filter && filter.items.length === 0
      return (
        <OptionSelectCard
          label={filter.label}
          color={filter.color}
          required={true}
          manageButtonText={filter.manageButtonText}
          noOptionsMessage={filter.noOptionsMessage}
          loading={loading}
          onChange={(value) =>
            handleSetFilterAndRefetch(
              {
                id: filter.id,
                value,
                query: filter.queryParam,
                type: QueryTypes.Array,
                fromReport: fromReport
              },
              {
                updateFilters: !fromReport
              }
            )
          }
          items={filtersData ? filtersData[filter.id]?.value : undefined}
        />
      )
    }

    const getFacilitySelectCard = (
      filter: SaivaReport.Filter.FacilitySelect
    ) => {
      return (
        <div style={{ maxWidth: '290px' }}>
          <FacilitiesSelectCard
            items={regions}
            selectedItems={
              filtersData ? filtersData[filter.id]?.value : undefined
            }
            onChange={(value) =>
              handleSetFilterAndRefetch(
                {
                  id: filter.id,
                  value,
                  query: filter.queryParam,
                  type: QueryTypes.Array,
                  fromReport: false
                },
                { updateFilters: true }
              )
            }
          />
        </div>
      )
    }

    if (
      filter.type === FilterType.OPTION_SELECT_WITH_ITEMS ||
      filter.type === FilterType.OPTION_SELECT_WITH_REQUEST
    ) {
      return getOptionSelectCard(filter)
    }
    if (filter.type === FilterType.FACILITY_SELECT) {
      return getFacilitySelectCard(filter)
    }
    if (filter.type === FilterType.MULTI_OPTION_SELECT) {
      return filter.optionSelects.map((option, i) => {
        return <Fragment key={i}>{getOptionSelectCard(option)}</Fragment>
      })
    }
    if (filter.type === FilterType.DATE_PICKER) {
      return (
        <DatePickerCard
          rangeOptions={filter.ranges}
          defaultValue={localStorageValue}
          onDateChange={(date, range) => {
            handleSetFilterAndRefetch(
              {
                id: 'date',
                value: date,
                query: undefined,
                type: QueryTypes.Date
              },
              {
                dateRange: range,
                updateFilters: true
              }
            )
          }}
        />
      )
    }
    if (filter.type === FilterType.DROPDOWN) {
      return (
        <div className={'facilityCardWrapper'}>
          <SaivaDropdown
            label={filter.label}
            popupLabel={filter.popupLabel}
            options={filter.items}
            defaultOption={
              filtersData && filtersData[filter.id]
                ? filter.items.find(
                    (item) => item.value === filtersData?.[filter.id]?.value
                  )
                : filter.items[filter.defaultOption]
            }
            onChange={(option) =>
              handleSetFilterAndRefetch({
                id: filter.id,
                value: option?.value,
                query: filter.queryParam,
                type: QueryTypes.String
              })
            }
          />
        </div>
      )
    }
    if (filter.type === FilterType.RADIO_SELECT) {
      return (
        <div className={'facilityCardWrapper'}>
          <RadioSelect
            label={filter.label}
            items={filter.items}
            name={filter.id}
            selectedValue={
              filtersData && filtersData[filter.id]
                ? filter.items.find(
                    (item) => item.id === filtersData[filter.id]?.value.id
                  )
                : filter.items.find((item) => item.id === filter.default_option)
            }
            onChange={(option) =>
              handleSetFilterAndRefetch({
                id: filter.id,
                value: option,
                query: filter.queryParam,
                type: QueryTypes.Object
              })
            }
          />
        </div>
      )
    }
    return null
  }

  const GetLongLoadingInfo = () => {
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
      const timeout = setTimeout(() => {
        setShow(true)
      }, 30000)
      return () => {
        clearTimeout(timeout)
      }
    }, [])

    if (show)
      return (
        <div style={{ fontSize: 18, marginTop: 20 }}>
          Your report is loading… this will take a moment.
        </div>
      )
    return null
  }

  return (
    <div>
      <div className={styles.topBar}>
        <Button
          className={`${styles.actionButton} ${styles.filtersButton}`}
          size="large"
          onClick={() => setHideFilters(!hideFilters)}
        >
          <FilterIcon style={{ marginRight: '8px', stroke: '#4070DC' }} />
          {hideFilters ? 'Show Filters' : 'Hide Filters'}
        </Button>

        <>
          {reportOptions?.schedule && (
            <SchedulesButton
              loading={loading}
              reportId={id}
              filtersData={parseFilters({
                ...filtersData,
                quality_measure: quality_measure ? quality_measure : undefined
              })}
            />
          )}
        </>
        <>
          {reportOptions?.exportOptions && (
            <DownloadButton
              fileLoading={fileLoading}
              downloadReport={
                !showCumulativeTable
                  ? (fileType) => fetchFile(fileType, filtersData)
                  : (fileType) => fetchFile(fileType, filtersData, true)
              }
            />
          )}
        </>
      </div>
      <div
        className={`topCards ${hideFilters && styles.hidden}`}
        key={userContext.currentOrg.id}
      >
        {filters?.map((filter, i) => {
          return <Fragment key={i}>{getFilter(filter)}</Fragment>
        })}
      </div>
      {data && data.stats && (
        <div
          className={'reportCardsWrapper'}
          style={{
            justifyContent:
              data && data.stats && data.stats.length < 3
                ? 'start'
                : 'space-between'
          }}
        >
          {data && data.stats?.map((item) => getStats(item))}
        </div>
      )}
      {loading || !filtersData?.facilities ? (
        // TODO create loading component
        <div className={'center'}>
          <img
            className="me-2"
            src={blueSpinner}
            width="40px"
            height="40px"
            alt={'loading gif'}
          />
          <GetLongLoadingInfo />
        </div>
      ) : (
        <>
          {dataType.format === FormatTypes.BASIC_TABLE &&
            flag === 'cumulative_report_incl' &&
            getHitMissTable(
              data?.data,
              data?.cumulative_data,
              dataType.columns,
              dataType.cumulative_columns
            )}
          {dataType.format === FormatTypes.FILE_TABLE &&
            getFileTable(data?.data)}
          {dataType.format === FormatTypes.PERIODIC_TABLE &&
            getPeriodTable(data?.data, dataType.columns, data?.charts)}
          {dataType.format === FormatTypes.BASIC_TABLE &&
            !flag &&
            getBasicTable(data?.data, dataType.columns)}
        </>
      )}
    </div>
  )
}

const checkWithLocalStorage = (
  orgId: string,
  data: SaivaReport.Filter.OptionItem[] | SaivaReport.Filter.ParentOptionItem[],
  id: string
): IOptionSelectItem[] => {
  const localStorage = loadFiltersFromLocalStorage(orgId)
  if (
    localStorage &&
    localStorage[id]?.value &&
    localStorage[id]?.value?.length > 0
  ) {
    if (id == 'facilities') {
      const selectedFacilities = data
        .map((item) => {
          return item.facilities
        })
        .flat(1)
        .slice(0, max_number_of_facilities)
      return data.map((dataItem) => {
        const p = localStorage[id]?.value.find(
          (item) => dataItem.id === item.id
        )
        if (p?.isSelected)
          return {
            ...dataItem,
            facilities: dataItem.facilities.filter((item) =>
              selectedFacilities.find((i) => i.id === item.id)
            )
          }
        return { ...dataItem }
      })
    } else
      return data.map((dataItem) => {
        const p = localStorage[id]?.value.find(
          (item) => dataItem.id === item.id
        )
        if (p?.isSelected) return { ...dataItem, isSelected: true }
        return { ...dataItem, isSelected: false }
      })
  } else if (id == 'facilities') {
    return data.map((item) => {
      const selectedFacilities = data
        ?.map((item) => {
          return item?.facilities
        })
        .flat(1)
        .slice(0, max_number_of_facilities)
      return {
        ...item,
        facilities: item.facilities.filter((item) =>
          selectedFacilities.find((i) => i.id === item.id)
        )
      }
    })
  } else if (id === 'planned') {
    return data.map((item) => {
      if (item.id === 'Yes') return { ...item, isSelected: false }
      else return { ...item, isSelected: true }
    })
  } else
    return data.map((item) => {
      return { ...item, isSelected: true }
    })
}

const checkDropdownWithLocalStorage = (
  orgId: string,
  id: string,
  default_option: any
): any => {
  const localStorage = loadFiltersFromLocalStorage(orgId)
  if (localStorage && localStorage[id]?.value) return localStorage[id]?.value
  else return default_option
}

export default function Report() {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  const quality_measure = query.get('qm')
    ? (query.get('qm') as OrgProductFeature)
    : undefined

  const navigate = useNavigate()
  const location = useLocation()
  const userContext = useUserContext()
  const { reportId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeQM, setActiveQM] = useState<OrgProductFeature | undefined>(
    quality_measure ? quality_measure : OrgProductFeature.MODEL_UPT
  )
  const [modelReportOptions, setModelReportOptions] = useState<
    OrgProductFeature[] | undefined
  >([OrgProductFeature.MODEL_UPT])
  const [loading, setLoading] = useState<boolean>(false)

  const [reportStructure, setReportStructure] = useState<
    SaivaReport.StructureItem | undefined
  >(undefined)

  const navigateToReport = (reportId: string) => {
    navigate('/reports/' + reportId)
  }

  const navigateToReportNavigation = () => {
    // @ts-ignore
    if (location.state?.from === 'categories') navigate(-1)
    else navigate('/reports')
  }

  const fetchReport = async () => {
    setLoading(true)
    const report = await ReportService.getReportStructure(
      reportId,
      userContext.currentOrg.id
    )
    if (report) {
      setModelReportOptions(
        report?.map((item) => {
          return item.quality_measure
        })
      )
      if (report.length > 0) {
        const selectedReport =
          report.find((item) => item.quality_measure === activeQM) || report[0]
        setReportStructure(selectedReport)
      } else navigate('/reports?error=noacces', { replace: true })
      setLoading(false)
    } else {
      navigate('/reports?error=noacces', { replace: true })
      setLoading(false)
    }
  }

  useEffect(() => {
    if (reportId && userContext.hasPermission(UserPermission.ANALYTICS_READ)) {
      fetchReport()
    } else {
      navigate('/reports?error=noacces', { replace: true })
    }
  }, [reportId, activeQM, userContext.currentOrg.id])

  const getButton = (title: string, onClick: () => void, active: boolean) => {
    return (
      <button className={`${active ? 'active' : ''}`} onClick={onClick}>
        {title}
      </button>
    )
  }

  if (!reportStructure) return <div></div>

  const accessibleReportsInCategory = () => {
    return reportStructure.allReportsInCategory
  }

  return (
    <div className={'reportWrapper'}>
      <div
        className={'d-lg-inline-flex align-items-center'}
        style={{ width: '100%' }}
      >
        <div className={'d-none d-lg-inline-flex '}>
          <button
            className={'text-button'}
            onClick={navigateToReportNavigation}
          >
            <ArrowIcon /> BACK
          </button>
          {reportStructure.allReportsInCategory.length === 1 ||
          accessibleReportsInCategory().length === 1 ? (
            <h6 className={'mb-0'} style={{ fontSize: 20, lineHeight: '2.2' }}>
              {reportStructure.allReportsInCategory[0].label}
            </h6>
          ) : (
            <div className={'reportSelect d-none d-lg-inline-flex'}>
              {reportStructure.allReportsInCategory.map((report) => {
                const isActive = report.id === reportId
                return (
                  <Fragment key={report.id}>
                    {getButton(
                      report.label,
                      () => navigateToReport(report.id),
                      isActive
                    )}
                  </Fragment>
                )
              })}
            </div>
          )}
          <div className={'reportItem d-block d-lg-none'}>
            Daily risk report
          </div>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {!loading && modelReportOptions && modelReportOptions.length > 1 && (
            <QualityMeasureSelector
              options={modelReportOptions}
              defaultoption={activeQM}
              setQM={(qm: OrgProductFeature) => {
                setActiveQM(qm)
                if ('URLSearchParams' in window) {
                  var newurl = ''
                  var searchParams = new URLSearchParams(window.location.search)
                  searchParams.set('qm', qm)
                  var newurl =
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    window.location.pathname +
                    '?' +
                    searchParams
                  window.history.pushState({ path: newurl }, '', newurl)
                }
              }}
            />
          )}
        </div>
      </div>

      {loading ? (
        <div style={{ marginTop: '100px', textAlign: 'center' }}>
          <LoadingIcon />
        </div>
      ) : (
        <ReportWrapper
          key={reportStructure.id}
          reportStructure={reportStructure}
        />
      )}
    </div>
  )
}
