import { useEffect, useState } from 'react'
import { useUserContext } from '../context/UserContext'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ButtonWithSpinner from '../components/buttonWithSpinner'
import OtpInput from 'react-otp-input-rc-17'
import { useForm } from 'react-hook-form'
import SaivaIcon from '../components/saivaIcon'
import { makeEmailValidator } from '../formValidators'
import SaivaModal from '../components/saivaModal'
import TermsOfService from './termsOfService'
import PrivacyPolicy from './privacyPolicy'
import { LOGIN_HELP_URL } from '../utils/constants'
import AuthService from '../services/auth'
import { Tooltip, Button } from 'antd'
import MobileOTPLogin from './MobileLogin/MobileOTPLogin'
import { ReactComponent as AppleButton } from 'assets/app_store.svg'
import { ReactComponent as AndroidButton } from 'assets/play_store.svg'


type LoginInputsStep1 = {
  email: string,
}

type LoginStep = 'EMAIL' | 'MOBILEREQUEST' | 'OTP'

const appleLink = 'https://apps.apple.com/us/app/mysaiva/id1621426257'
const androidLink = 'https://play.google.com/store/apps/details?id=com.saivahc.saivaai'

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { email: autofilledEmail } = useParams()
  const userContext = useUserContext()
  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [isOtpValid, setIsOtpValid] = useState(true)
  const [submitError, setSubmitError] = useState<string | undefined>(undefined)
  const [loginStep, setLoginStep] = useState<LoginStep>('EMAIL')
  const [requestInFlight, setRequestInFlight] = useState(false)
  const [phrase, setPhrase] = useState<string>('')
  const [userId, setUserId] = useState<number>()
  const [otpSent, setOtpSent] = useState<boolean>(false)

  const { register: registerStep1, setValue, handleSubmit: handleSubmitStep1, watch: watchStep1, formState: { errors: errorsStep1 } } = useForm<LoginInputsStep1>();

  const [showTos, setShowTos] = useState<boolean>(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false)

  const tosClicked = () => {
    setShowTos(true)
  }

  const privacyPolicyClicked = () => {
    setShowPrivacyPolicy(true)
  }

  const handleCloseTos = () => {
    setShowTos(false)
  }

  const handleClosePrivacyPolicy = () => {
    setShowPrivacyPolicy(false)
  }

  async function submitEmail(data) {
    setEmail(data.email)
    requestToken(data.email)
  }

  const handleOtpSent = (value: boolean) => {
    setOtpSent(value)
    setSubmitError(undefined)
  }

  const handleSubmitError = (error: string | undefined) => {
    setSubmitError(error)
    setOtpSent(false)
  }

  function handlenextStep(
    phrase: string | undefined,
    active_device_exists: boolean | undefined,
    userId: number,
    resend?: boolean
  ) {
    if (!phrase) {
      setSubmitError("genericError")
      return
    }
    if (!resend && phrase && active_device_exists && userId) {
      setPhrase(phrase)
      setUserId(userId)
      setLoginStep('MOBILEREQUEST')
    } else {
      setPhrase(phrase)
      setLoginStep('OTP')
    }
  }

  const requestToken = async (email: string) => {
    handleSubmitError(undefined)
    setRequestInFlight(true)
    await AuthService.requestOtp({ email: email })
      .then((resp) => {
        if (!resp) return
        const phrase: string | undefined = resp.phrase
        const active_devices: boolean | undefined =
          resp.active_devices
        const userId = resp.user_id
        
        handlenextStep(phrase, active_devices, userId)
        setRequestInFlight(false)
      })
      .finally(() => {
        setRequestInFlight(false)
      })
  }

  async function requestNewOtp(resend: boolean = false) {
    handleSubmitError(undefined)
    handleOtpSent(true)
    setTimeout(() => {
      handleOtpSent(false)
    }, 5000)
    setRequestInFlight(true)
    await AuthService.requestOtp({ email, phrase, email_login: true})
      .then((resp) => {
        if (!resp) return
        const phrase: string | undefined = resp.phrase
        const active_devices: boolean | undefined =
          resp.active_devices
        const userId = resp.user_id

        handlenextStep(phrase, active_devices, userId, resend)
      }).catch(() => {
        setSubmitError("genericError")
        setRequestInFlight(false)
      })
      .finally(() => {
        setRequestInFlight(false)
      })
  }

  
  async function submitOtp() {
    handleSubmitError(undefined)
    if (otp.length === 6) {
      setIsOtpValid(true)
    } else {
      return setIsOtpValid(false)
    }
    setRequestInFlight(true)
    try {
      await userContext.loginOtp(email, otp, phrase)
      setRequestInFlight(false)
      const existingRedirect = window.location.search.split("?redirect=")[1]
      if (!existingRedirect) {
        navigate('/')
      } else if (existingRedirect && existingRedirect.includes("login")) {
        navigate('/')
      } else if (existingRedirect) {
        navigate(existingRedirect)
      } else {
        navigate('/')
      }
    } catch (err: any) {
      setRequestInFlight(false)
      setOtp("")
      if (err?.name === "ApiError") {
        handleSubmitError(t(err.errorCode))
      } else {
        handleSubmitError("genericError")
      }
    }
  }

  useEffect(() => {
    if (autofilledEmail) {
      setEmail(autofilledEmail)
      setValue("email", autofilledEmail)
      requestToken(autofilledEmail)
    }
  }, [])

  useEffect(() => {
    if (otp.length === 6) {
      submitOtp()
    }
  }, [otp])

  const makeErrorMessage = (validationError) => {
    return (validationError && <span className="invalid-feedback">{validationError.message}</span>)
  }

  const emailValidation = makeEmailValidator(true)

  function MobileAppPromotion({message}) {
    return (
      <div>
        <p style={{textAlign: 'center', marginTop: '20px', whiteSpace: 'pre-line'}}>{message}</p>
        <div style={{display: 'flex', width: "100%", alignContent: 'space-between'}}>
          <a href={appleLink} style={{width: '100%'}}>
            <AppleButton style={{height: '30px', width: '100%'}}/>
          </a>
          <a href={androidLink} style={{width: '100%'}}>
            <AndroidButton style={{height: '30px', width: '100%'}} />
          </a>
        </div>
      </div>
    )
  }

  const makeEmailView = () => {
    return (
      <>
        <h4>
          {t('login.welcomeMessage')}
        </h4>
        <form className="d-grid needs-validation" onSubmit={handleSubmitStep1(submitEmail)}>
          <div className="form-group mt-3">
            <label className="form-label">{t('login.email.label')}</label>
            <input type="email" placeholder={t('login.email.placeholder')} className={`form-control input-shadow ${errorsStep1.email ? "is-invalid" : ""}`} {...registerStep1("email", emailValidation)} />
            {makeErrorMessage(errorsStep1.email)}
          </div>
          <ButtonWithSpinner size="24px" showSpinner={requestInFlight} className="btn btn-primary mt-4">
            {t('login.logInButtonText')}
          </ButtonWithSpinner>
          <div className={"d-flex justify-content-center mt-4"}>
            <a href={LOGIN_HELP_URL}>Having trouble logging in ?</a>
          </div>
          <MobileAppPromotion message={t('login.email.appMessage')}/>
        </form>
      </>
    )
  }

  function handleSwitchToEmailOTP() {
    requestNewOtp(true)
    setLoginStep('OTP')
  }

  function handleSwitchToEmail(error: Error | null) { 
    handleSubmitError(error?.message)
    setLoginStep('EMAIL')
  }

  const makeOtpView = () => {
    return (
      <>
        <h4>
          {t('login.enterOtpCode')}
        </h4>
        <small className="text-center">{t('login.checkEmailForOtp')}</small>
        {email.length > 25 ? 
        <Tooltip 
          arrow={false}
          overlayInnerStyle={{
            background: '#112443',
            textAlign: 'left'
          }}
          title={email}
        >
          <small className="text-center elipsis">{email}</small>
        </Tooltip> : <small className="text-center elipsis">{email}</small>}
        <small className="text-center mt-4">Your login request phrase is</small>
        <h3 className={"text-center mb-0 mt-1 d-flex justify-content-center align-items-center"}>{phrase}</h3>
        <div className="form-group mt-4">
          <OtpInput
            inputStyle="otp-input form-control input-shadow"
            containerStyle="justify-content-between"
            numInputs={6}
            onChange={(value) => setOtp(value)}
            isInputNum={true}
            shouldAutoFocus
            hasErrored={!isOtpValid}
            errorStyle="otp-invalid"
            value={otp}
          />
          {!isOtpValid && <span className="d-block invalid-feedback">This field is required</span>}
        </div>
        <ButtonWithSpinner size="24px" showSpinner={requestInFlight} onClick={() => submitOtp()} className="btn btn-primary mt-4">
          {t('login.logInButtonText')}
        </ButtonWithSpinner>
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
          <Button type="link" onClick={() => setLoginStep('EMAIL')}>
            {t('login.mobileOTP.switchEmailOtpbutton')}
          </Button>
          <Button type="link" onClick={() => requestNewOtp(false)}>
            {t('login.mobileOTP.resendOtpbutton')}
          </Button>
        </div>
        <MobileAppPromotion message={t('login.mobileOTP.appMessage')}/>
      </>
    )
  }

  return (
    <div className="floatingDialog container-fluid align-items-center">
      <SaivaModal show={showTos} onHide={() => handleCloseTos()} onCancel={() => handleCloseTos()} backdrop={true}>
        <TermsOfService />
      </SaivaModal>
      <SaivaModal show={showPrivacyPolicy} onHide={() => handleClosePrivacyPolicy()} onCancel={() => handleClosePrivacyPolicy()} backdrop={true}>
        <PrivacyPolicy />
      </SaivaModal>
      <img src="/img/saiva-logo-combined.png" className="logo" />
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-sm-6 col-md-5 col-lg-5 col-xl-4 col-xxl-3">
          <div className="contentWrapper">
            {submitError && <div className={`${submitError ? "d-flex" : "d-none"} align-items-center justify-content-center errorWrapper`}>
              <div className="errorContent d-flex">
                <SaivaIcon name="WarningCircle" className="active me-2" />
                <label>{t(submitError).replace("${email}", email)}</label>
              </div>
            </div>}
            {otpSent && <div className={`${otpSent ? "d-flex" : "d-none"} align-items-center justify-content-center infoWrapper`}>
              <div className="errorContent">
                <SaivaIcon name="WarningCircle" className="active me-2" />We sent you new verification code to your email
              </div>
            </div>}
            <div className={`d-flex flex-row content justify-content-center ${submitError ? 'is-invalid' : ''} ${otpSent ? 'is-info' : ''}`}>
              <div className="login col-10 col-lg-8 d-grid mt-5 mb-4 pb-2" style={{minWidth: '300px'}}>
                {loginStep === 'EMAIL' && makeEmailView()}
                {loginStep === 'MOBILEREQUEST' && 
                  <MobileOTPLogin 
                    email={email} 
                    user_id={userId}
                    handleSwitchToEmailOTP={handleSwitchToEmailOTP} 
                    handleSwitchToEmail={handleSwitchToEmail}
                  />
                }
                {loginStep === 'OTP' && makeOtpView()}
              </div>
              <span className="footer my-4">
                ©Saiva AI 2024 | <a href="#" onClick={() => privacyPolicyClicked()} style={{ textDecoration: 'none' }}> Privacy Policy</a> |
                <a href="#" onClick={() => tosClicked()} style={{ textDecoration: 'none' }}> Terms & Conditions</a> |
                <a href="http://status.saiva.ai" target="_blank" style={{ textDecoration: 'none' }}> Service Status</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
