import { LocalStorageItem } from '../types/localStorage'

export const savePaginationToLocalStorage = (groupId, rowsPerPage: number) => {
  const a = window.localStorage.getItem("savedReportsSelection")
  if(a) {
    let value: LocalStorageItem = JSON.parse(a)
    if(!value || !rowsPerPage) return
    value = {...value, [groupId]: {...value[groupId], rowsPerPage: rowsPerPage}}
    window.localStorage.setItem("savedReportsSelection", JSON.stringify(value))
  } else {
    const value = {[groupId]: {rowsPerPage: rowsPerPage}}
    window.localStorage.setItem("savedReportsSelection", JSON.stringify(value))
  }
}

export const loadPaginationFromLocalStorage = (groupId) : number | undefined => {
  const a = window.localStorage.getItem("savedReportsSelection")
  if(!a) return undefined
  const value: LocalStorageItem = JSON.parse(a)
  if(!value) return undefined
  return value[groupId]?.rowsPerPage
}
