import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react'
import useAutosizeTextArea from '../../hooks/useAutosizeTextArea'
import styles from './Form.module.scss'
import { useFormContext } from '../../context/FormProvider'
import { ValidatorTypes } from '../../utils/validation'

interface TextareaProps {
  id: string,
  value?: string,
  onChange?: (value: string) => void
  placeholder?: string,
  validators?: ValidatorTypes[],
  label?: string
  required?: boolean
  disabled?: boolean
}

const TextArea = (props: TextareaProps) => {

  const {errors, updateValue, getValue, addValidationType} = useFormContext()
  const {id, onChange, placeholder = "", validators, label, required = false, disabled = false} = props

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const value = props.value ? props.value : getValue(id)
  useAutosizeTextArea(textAreaRef, value, placeholder);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if(onChange) onChange(e.target.value)
    else updateValue({[id]: e.target.value})
  }

  const error = useMemo(() : string | undefined => {
    return errors.get(id)
  }, [errors])

  useEffect(() => {
    addValidationType(id, validators)
  }, [validators])

  return (
    <div className={styles.inputWrapper}>
      <div className={`${styles.inputContainer} ${disabled ? styles.inputContainerDisabled : ""} ${error ? styles.inputContainerError : ""}`}>
        {label && <label className='saivaLabel'>{label}{required && <span className={styles.requiredLabel}>*</span>}</label>}
        <textarea
          value={value ? value : ""}
          onChange={handleChange}
          placeholder={placeholder}
          ref={textAreaRef}
          spellCheck={false}
        />
        {error && <label className={styles.errorLabel}>{error}</label>}
      </div>
    </div>
  )
}

export default TextArea
