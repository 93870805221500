import React, { useEffect, useState } from 'react'
import { Modal, Popover, Tag, Space, Divider, Row, Col } from 'antd'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { SaivaUser } from 'types/user-types'

import FormApi from './Form/FormApi'

const FormModal = ({
  isOpen,
  editingUser,
  title,
  editInvitation,
  close
}: {
  isOpen?: boolean
  editingUser: SaivaUser.User | undefined
  title: string,
  editInvitation: boolean,
  close: () => void
}) => {
  const [changed, setChanged] = useState(false)
  const [isClosed, setIsClosed] = useState(false)

  const isDirty = (value) => {
    setChanged(value)
  }

  const closeModal = () => {
    setIsClosed(!isClosed)
    close()
  }

  return (
    <>
      <Modal
        closeIcon={<CloseIcon />}
        width={'584px'}
        maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
        }}
        footer={null}
        wrapClassName={'modalAntdGrey'}
        open={isOpen}
        onCancel={closeModal}
      >
        <Row gutter={[16, 16]} align="top" style={{ marginBottom: '20px' }}>
          <Col span={24}>
            <Space direction="horizontal" size={80} wrap align="center">
              <div
                style={{
                  flex: 'none',
                  order: 0,
                  flexGrow: 0
                }}
              >
                <h4>{title}</h4>
              </div>
            </Space>
          </Col>
        </Row>
        <FormApi
          closeModal={closeModal}
          formState={isDirty}
          isClosed={isClosed}
          editingUser={editingUser}
          editInvitation={editInvitation}
        />
      </Modal>
    </>
  )
}

export default FormModal
