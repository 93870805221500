import './i18n/config'
import { Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'
import { userActions, useUserContext } from './context/UserContext'
import Login from './views/login'
import Navigation from './views/navigation'
import WoundsNavigation from './views/wounds/WoundsNavigation'
import AdminUsers from './views/admin/Users/users'
import UsersTabs from 'views/admin/usersTabs'
import AdminFacilities from './views/admin/facilities'
import Integrations from './views/Integrations/Integrations'
import Wounds from './views/wounds/Wounds'
import Icons from './views/icons'
import EmptyNavigation from './views/emptyNavigation'
import AdminUsersInvited from './views/admin/usersInvited'
import Invitation from './views/invitation'
import AdminOrgSettings from './views/admin/Settings/orgSettings'
import AdminOrgSettingsTabs from './views/admin/Settings/orgSettingsTabs'
import Dashboard from './views/Dashboard/dashboard'
import Resident from './views/resident'
import PDF from './views/pdf'
import React, { useEffect } from 'react'
import { mixpanelInstance } from 'utils/mixpanel'
import ReportNavigation from './views/Reports/reportNavigation'
import DesignTheme from './views/DesignTheme'
import { ENVIRONMENT, Environments } from './utils/constants'
import ErrorPage from './views/ErrorPage/ErrorPage'
import Report from './views/Reports/reports'
import {CrossFacilityReport} from './views/Reports/Reports/CrossFacilityReport/CrossFacilityReport'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { OrgProductFeature, UserLoginState, UserPermission } from './types/user-types'
import LoadingPreview from './views/LoadingPreview/LoadingPreview'
import { getBrowserInfo } from './utils/helper'
import AdminOrgBranding from 'views/admin/Settings/orgSettingsBranding'
import AdminOrgClinical from './views/admin/Settings/orgSettingsClinical'
import AllSchedules from 'views/Reports/ScheduleReporting/Schedules'
import { UserEngagementReport } from 'views/Reports/Reports/UserEngagementReport'
import { MostViewedReports } from 'views/Reports/Reports/MostViewedReports'

console.log("Welcome to", ENVIRONMENT, "mode.")
console.log("You are using", getBrowserInfo?.name, "browser on", getBrowserInfo?.os)

type ProtectedRouteProps = {
  loginState: UserLoginState,
  children?: any
}

const ProtectedRoute = (props: ProtectedRouteProps) => {

  const {loginState, children} = props

  const location = useLocation()

  if (loginState === UserLoginState.Loading) {
    return <EmptyNavigation />
  }

  return children ? children : <Outlet />
};

const FeatureRoute = (props: {component?: any, permission?: UserPermission[], feature?: OrgProductFeature[]}) => {
  const userContext = useUserContext()

    if(props.permission && props.permission.filter(p => userContext.hasPermission(p)).length > 0) {
      if (props.feature && props.feature.filter(f => userContext.hasFeature(f)).length > 0) {
        return props.component
      } else if (!props.feature) {
        return props.component
      } else return <Navigate to={"?error=noaccess"} replace />
  } else return <Navigate to={"?error=noaccess"} replace />
}
function App() {
  const userContext = useUserContext()
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      if(userContext.email) mixpanelInstance.isActive(userContext.email)
    }
  }

  const setRoleCategory = (category: string) => {
    userContext.dispatch(userActions.setCurrentMedicalSupplyOrgRole(category))
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    })
  }, [userContext.email])

  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/login/:email" element={<Login />} />
        <Route path="/join/:token" element={<Invitation />} />
        <Route element={<ProtectedRoute loginState={userContext.loginState} />}>
          <Route path="/" element={userContext.currentRole === 'medical_supply' ? <WoundsNavigation setRoleCategory={setRoleCategory}/> : <Navigation setRoleCategory={setRoleCategory}/>}>
            <Route path="" element={
              userContext.currentRole === 'medical_supply' ?
                userContext.currentOrg?.permissions.includes(UserPermission.USERS_EDIT) ?
                  <Navigate to="/users" />
                  : <Navigate to="/order-capture" />
                : userContext.currentOrg?.permissions.includes(UserPermission.DASHBOARD_READ) ?
                <Navigate to="/dashboard" /> : <Navigate to="/error" />
            } />
            <Route path="dashboard" element={
              <FeatureRoute
                component={<Dashboard />}
                permission={[
                  UserPermission.DASHBOARD_READ
                ]}
              />}
            />
            <Route
              path="facilities"
              element={
                <FeatureRoute
                  component={<AdminFacilities />}
                  permission={[
                    UserPermission.FACILITIES_EDIT,
                    UserPermission.FACILITIES_READ
                  ]}
                />
              }
            />
            <Route
              path="users"
              element={
                <FeatureRoute
                  component={<UsersTabs />}
                  permission={[UserPermission.USERS_EDIT]}
                />
              }
            >
              <Route
                path=""
                element={
                  <FeatureRoute
                    component={<AdminUsers filter={{active:true}}/>}
                    permission={[UserPermission.USERS_EDIT]}
                  />
                }
              />
              <Route
                path="invited"
                element={
                  <FeatureRoute
                    component={<AdminUsersInvited />}
                    permission={[UserPermission.INVITATIONS_READ]}
                  />
                }
              />
              <Route
                path="inactive"
                element={
                  <FeatureRoute
                    component={<AdminUsers filter={{active:false}}/>}
                    permission={[UserPermission.INVITATIONS_READ]}
                  />
                }
              />
            </Route>
            <Route path="settings" element={<AdminOrgSettingsTabs />}>
              <Route path="" element={
                <FeatureRoute
                  component={<AdminOrgSettings />}
                  permission={[
                    UserPermission.ORGANIZATIONS_EDIT
                  ]}
                />
              }/>
              <Route
                path="branding"
                element={
                  <FeatureRoute
                    component={<AdminOrgBranding />}
                    permission={[
                      UserPermission.ORGANIZATION_BRANDING_READ_WRITE
                    ]}
                    feature={[OrgProductFeature.ORGANIZATIN_BRANDING]}
                  />
                }
              />
              <Route
                path="clinical"
                element={
                  <FeatureRoute
                    component={<AdminOrgClinical />}
                    permission={[
                      UserPermission.ORGANIZATION_BRANDING_READ_WRITE
                    ]}
                  />
                }
              />
            </Route>
            <Route path="reports">
              <Route
                path=""
                element={
                  <FeatureRoute
                    component={<ReportNavigation category={false} />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route path="pdf" element={<PDF />} />
              <Route
                path="category"
                element={
                  <FeatureRoute
                    component={<ReportNavigation category={true} />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route
                path="schedules"
                element={
                  <FeatureRoute
                    component={<AllSchedules />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route
                path={'cross_facility_risk_report'}
                element={
                  <FeatureRoute
                    component={<CrossFacilityReport />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route
                path={'user_engagement_report'}
                element={
                  <FeatureRoute
                    component={<UserEngagementReport />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route
                path={'viewed_reports'}
                element={
                  <FeatureRoute
                    component={<MostViewedReports />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
              <Route
                path={':reportId'}
                element={
                  <FeatureRoute
                    component={<Report />}
                    permission={[UserPermission.ANALYTICS_READ]}
                    feature={[
                      OrgProductFeature.ANALYTICS_DAILY_RISK_REPORT,
                      OrgProductFeature.ANALYTICS_READ
                    ]}
                  />
                }
              />
            </Route>
            <Route
              path="integrations"
              element={
                <FeatureRoute
                  component={<Integrations />}
                  permission={[UserPermission.INTEGRATIONS_READ]}
                  feature={[
                    OrgProductFeature.CUSTOMER_API_INTEGRATION,
                    OrgProductFeature.SFTP_EXPORT_INTEGRATION,
                    OrgProductFeature.WEBHOOK_EXPORT_INTEGRATION
                  ]}
                />
              }
            />
            <Route path="icons" element={<Icons />} />
            <Route path="/resident">
              <Route
                path={':residentId'}
                element={<Resident />}
              />
            </Route>
            <Route
              path="order-capture"
              element={userContext.isSuperuser ? <Wounds /> : <FeatureRoute
                component={<Wounds />}
                permission={[
                  UserPermission.WOUNDS_READ,
                  UserPermission.WOUNDS_EDIT
                ]}
              />}
            />
          </Route>
        </Route>
        <Route path="loading" element={<LoadingPreview />} />
        <Route path="error" element={<ErrorPage />} />
        {ENVIRONMENT === Environments.LOCAL && (
          <Route path="design-theme" element={<DesignTheme />} />
        )}
        <Route path="*" element={<ErrorPage emptyState />} />
      </Routes>
      <ToastContainer
        position="top-right"
        theme={"colored"}
      />
    </>
  )
}

export default App
