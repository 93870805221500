import { useTranslation } from 'react-i18next'

import styles from './IntegrationCard.module.scss'

import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg'

import { IntegrationType, SaivaIntegration, StatusType } from 'types/integration-types'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useMemo, useState } from 'react'
import { getIntegrationIcon, useIntegrationStatus } from '../IntegrationModal/IntegrationModal'
import { useUserContext } from '../../../context/UserContext'
import { UserPermission } from '../../../types/user-types'
import FormProvider from '../../../context/FormProvider'
import ApiTokensModal from '../IntegrationModal/SecretModal/SecretModal'
import SFTPModal from '../IntegrationModal/SFTPModal/SFTPModal'
import WebhookModal from '../IntegrationModal/WebhookModal/WebhookModal'
import { mixpanelInstance } from '../../../utils/mixpanel'


interface IntegrationCardProps {
  integration: SaivaIntegration.CatalogItem
  updateIntegration: (value: Partial<SaivaIntegration.CatalogItem>) => void
  disabled?: boolean
}


export default function IntegrationCard(props: IntegrationCardProps) {

  const userContext = useUserContext()

  const { integration } = props
  const { label, description, icon, isEnabled, statusBase } = integration

  const [openModal, setOpenModal] = useState<boolean>(false)

  const mixpanelEventsData = {
    integration_type: integration.id,
    organisation: userContext.currentOrg?.name,
    user: userContext.email,

  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const handleOpenModal = () => {
    setOpenModal(true)
    const name = integration.id.split("_").map(i => capitalize(i)).join("")
    mixpanelInstance.integrationEventHandler(mixpanelEventsData, "Integration" + name)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const { isConnected, isUpdating } = useIntegrationStatus({ statusBase: integration.statusBase, enabled: integration.isEnabled })

  return (
    <>
      <div className={`${styles.integrationCard} ${isConnected || isUpdating ? styles.integrationCardHover : ""}`} onClick={isConnected || isUpdating ? handleOpenModal : undefined}>
        <div className={styles.header}>
          <div className={styles.imageContainer}>
            {getIntegrationIcon(icon)}
          </div>
          <h4>{label}</h4>
        </div>
        <div className={styles.content}>
          {description}
        </div>
        <div className={styles.footer}>
          {isConnected && integration?.id !== 'webhook' && <label className={`${isEnabled ? styles.enabled : styles.disabled}`}>{isEnabled ? "Enabled" : "Disabled"}</label>}
          {statusBase === StatusType.CONNECTED && <button className={styles.connected}>Connected <CheckCircleIcon /></button>}
          {statusBase === StatusType.CONNECTING && <button className={styles.inProgress}><LoadingSpinner width={"20px"} height={"20px"} />CONNECTING</button>}
          {statusBase === StatusType.UPDATING && <button className={styles.inProgress}><LoadingSpinner width={"20px"} height={"20px"} />UPDATING</button>}
          {statusBase === StatusType.TESTING && <button className={styles.inProgress}><LoadingSpinner width={"20px"} height={"20px"} />TESTING</button>}
          {statusBase === StatusType.DISCONNECTING && <button className={styles.inProgress}><LoadingSpinner width={"20px"} height={"20px"} />DISCONNECTING</button>}
          {statusBase === StatusType.DISCONNECTED && userContext.hasPermission(UserPermission.INTEGRATIONS_CONNECT) && <button className={"primary-button"} onClick={handleOpenModal}>CONNECT</button>}
          {statusBase === StatusType.UNAVAILABLE && <button className={"primary-button"} disabled>UNAVAILABLE</button>}
        </div>
      </div>

      {integration.id === IntegrationType.SFTP_EXPORT &&
        <div key={userContext.currentOrg.id}>
          <FormProvider defaultValue={integration.values}>
            <SFTPModal
              open={openModal}
              handleClose={handleCloseModal}
              item={integration as SaivaIntegration.SFTPItem}
              updateItem={props.updateIntegration}
            />
          </FormProvider>
        </div>
      }

      {integration.id === IntegrationType.CUSTOMER_API &&
        <div key={userContext.currentOrg.id}>
          <FormProvider defaultValue={integration.values}>
            <ApiTokensModal
              open={openModal}
              handleClose={handleCloseModal}
              item={integration as SaivaIntegration.CustomerApiItem}
              updateItem={props.updateIntegration}
            />
          </FormProvider>
        </div>
      }

      {integration?.id == IntegrationType.WEBHOOK &&
        <div key={userContext.currentOrg.id}>
          <FormProvider defaultValue={integration.values}>
            <WebhookModal
              open={openModal}
              handleClose={handleCloseModal}
              /*@ts-ignore*/
              item={integration}
              updateItem={props.updateIntegration}
            />
          </FormProvider>
        </div>
      }
    </>
  )
}
