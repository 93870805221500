import React, { useEffect, useState } from 'react'

import {ReactComponent as DecreaseIcon} from '../../../assets/icons/decrease.svg'
import {ReactComponent as IncreaseIcon} from '../../../assets/icons/increase.svg'
import Gradient from '../../../assets/icons/Gradient.gif'

export enum ReportCardTypes {
  PERCENTAGE = 'percentage'
}

interface ComponentProps {
  label: string,
  data: {value: number | string, increase?: boolean}
  dateRange: string,
  color?: string,
  type?: string,
  loading?: boolean,
}

const ReportCard = (props: ComponentProps) => {

  const hasChange = props.data.increase !== undefined && props.data.increase !== null

    return (
      <div className={"reportCard"}>
        <label>{hasChange ? props.data.increase ? "Increase in " : "Decrease in " : ""}{props.label}</label>
        <div className={"content"}>
          <div className={"valueWrapper"}>
            {props.loading ?
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: 70}}><img className='me-2' src={Gradient} width='40px' height='40px' alt={"loading gif"} /></div>
              :
              <>
                <h2>
                  {typeof (props.data.value) === 'number' ? Math.round(props.data.value) : props.data.value === undefined ? 0 : props.data.value}
                  {!hasChange && props.type === ReportCardTypes.PERCENTAGE && "%"}
                </h2>
                {hasChange && <div className={"valueDetails"}>
                  {props.type === ReportCardTypes.PERCENTAGE && <p>%</p>}
                  {props.data.increase ? <IncreaseIcon /> : <DecreaseIcon />}
                </div>}
              </>
            }
          </div>
          <p className={"dateRange"}>{props.dateRange}</p>
        </div>
      </div>
    )

}

export default ReportCard
