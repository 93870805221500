import { useEffect, useState } from 'react'
import IntegrationModalWrapper from '../IntegrationModal'
import { IntegrationType, SaivaIntegration } from 'types/integration-types'
import styles from '../WebhookModal/WebhookModal.module.scss'
import { useFormContext } from 'context/FormProvider'
import { SWMIcon } from 'react-swm-icon-pack'
import colors from 'styles/variables.module.scss'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { useUserContext } from '../../../../context/UserContext'
import IntegrationService from 'services/integration-service'
import { SaivaMultiModalItem } from '../../../../components/SaivaMultiModal/SaivaModalItem/SaivaMultiModalItem'
import Switch from 'components/Buttons/Switch'
import Input from '../../../../components/Form/Input'
import { ReactComponent as TestIcon } from 'assets/icons/test.svg'
import { validateValue } from '../../../../utils/validation'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import MultiOptionSelect, {
  SelectOption
} from 'components/Form/MultiOptionSelect'
import FacilityService from '../../../../services/facility-service'
import { SaivaFacility } from 'types/facility-types'
import { ReactComponent as WarningIcon } from 'assets/icons/exclamation.svg'
import LoadingSpinner from 'components/LoadingSpinner'
import { useConfirmModal } from '../../../../components/ConfirmModal/ConfirmModal'
import { ReactComponent as RegenerateIcon } from 'assets/icons/refresh-circle.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'
import { showSuccessToast, showErrorToast } from '../../../../utils'
import { copyToClipboard } from '../../../../utils/helper'
import { t } from 'i18next'
import _ from 'lodash'
import { mixpanelInstance } from '../../../../utils/mixpanel'

import SaivaCustomSelect from 'components/SaivaForm/SaivaMultiSelect/SaivaCustomSelect'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import RegionService from 'services/region-service'
import { getFeatureLabel } from 'utils/helper'

/* Move to utils */

const REPORTS: SelectOption[] = [
  { value: 'daily_risk_report', label: 'Daily Risk Report' },
  { value: 'cross_facility_risk_report', label: 'Cross Facility Report' }
]

const parseOptionsToReports = (reports): string[] | undefined => {
  const a = reports.map((f) => f.value.toString())
  return a.length > 0 ? a : undefined
}

const parseFacilitiesToOptions = (
  facilities: SaivaFacility.Item[]
): SelectOption[] => {
  return facilities.map((f) => {
    return {
      label: f.name,
      value: f.id,
      parent: f.region_id,
      color: '#799BE6'
    }
  })
}

const parseFacilityIdsToOptions = (
  facilities: number[] | undefined,
  allFacilities: SelectOption[]
): SelectOption[] => {
  const options: SelectOption[] = []
  facilities?.forEach((facility) => {
    const f = allFacilities.find((i) => i.value === facility)
    if (f) options.push(f)
  })
  return options
}

const parseReportTypesToOptions = (
  reports: string[] | undefined,
  allReports: SelectOption[]
): SelectOption[] => {
  const options: SelectOption[] = []
  reports?.forEach((report) => {
    const r = allReports.find((i) => i.value === report)
    if (r) options.push(r)
  })
  return options
}

const parseOptionsToFacilities = (
  facilities: SelectOption[],
  allFacilities: SelectOption[]
): number[] | undefined => {
  const a = () => {
    if (facilities.find((f) => f.value === 'all'))
      return allFacilities.map((f) => Number(f.value))
    return facilities.map((f) => Number(f.value))
  }
  return a().length > 0 ? a() : undefined
}

const validateWebhookModalInput = (i, type?) => {
  const regex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\~#?&//=]*)$/
  if (type === 'url') {
    const { isValid } = validateValue(i, [{ regex: regex }])
    return !isValid && t('integrations.webhookModal.isValidUrl')
  }
  const { error } = validateValue(i, [{ required: true }])
  return error
}

const webhookEndpointStatusIsUpdated = async (orgId, webhookId) => {
  let response = null

  //@ts-ignore
  while (response === null || response?.status === 'updating') {
    try {
      //@ts-ignore
      response = await IntegrationService.WebhookAPI.getWebhookEndpointInfo(
        orgId,
        webhookId
      )
      //@ts-ignore
      response = await response
    } catch (error) {
      return { status: 'disconnected' }
    }
    //@ts-ignore
    if (response?.status === 'updating') {
      await new Promise((resolve) => setTimeout(resolve, 2000))
    }
  }

  return response
}

const webhookEndpointTest = async (orgId, webhookId) => {
  let response = null
  let attempts = 50
  //@ts-ignore
  while (response === null || response?.status === 'in_progress') {
    try {
      //@ts-ignore
      response = await IntegrationService.WebhookAPI.testWebhookEndpointStatus(
        orgId,
        webhookId
      )
      //@ts-ignore
      response = await response
      attempts--
      if (attempts === 0) return { status: 'failed' }
    } catch (error) {
      return { status: 'failed' }
    }
    //@ts-ignore
    if (response?.status === 'in_progress') {
      await new Promise((resolve) => setTimeout(resolve, 2000))
    }
  }

  //@ts-ignore
  return { status: response?.status }
}

/* Move to utils end */

export default function WebhookModal(props) {
  const { item = {}, open, handleClose } = props
  const formContext = useFormContext()
  const data = formContext.getValues()
  const userContext = useUserContext()
  const confirmModal = useConfirmModal()

  /* General WebhookInfo */
  const [webhookIntegrationInfo, setWebhookIntegrationInfo] = useState({})
  const [webhookEndpointInfo, setWebhookEndpointInfo] = useState()

  /* General WebhookInfo end*/

  /* Show/Close the modals */

  const [createModalIsShown, setCreateModalIsShown] = useState(false)
  const [updateModalIsShown, setUpdateModalIsShown] = useState(false)
  const [secretKeyModalIsShown, setSecretKeyModalIsShown] = useState(false)

  const [allFacilities, setFacilities] = useState<SelectOption[]>([])
  const [regions, setRegions] = useState<any>([])

  const closeAdditionModals = () => {
    setCreateModalIsShown(false)
    setUpdateModalIsShown(false)
    setSecretKeyModalIsShown(false)
  }

  /* Show/Close the modals end */

  /* Specific modal statuses */
  const [isModalFormDisabled, setModalFormDisabled] = useState(false)
  const [isCloseModalShown, setIsCloseModalShown] = useState(true)
  /* Specific modal statuses end */

  /* Set user flow statuses */
  const reqStatuses = {
    inProgress: 'in progress',
    error: 'error',
    success: 'success'
  }

  const [createReqStatus, setCreateReqStatus] = useState('')
  const [updateReqStatus, setUpdateReqStatus] = useState('')
  const [testReqStatus, setTestReqStatus] = useState('')

  /* Set user flow statuses end*/

  /* Set Errors */
  const [globalModalError, setGlobalModalError] = useState('')

  const [nameErr, setNameErr] = useState<string>('')
  const [urlErr, setUrlErr] = useState<string>('')
  const [reportTypeErr, setReportTypeErr] = useState<string>('')
  const [qualityMeasureErr, setQualityMeasureErr] = useState<string>('')
  const [facilityIdsErr, setFacilityIdsErr] = useState<string>('')
  const [regionErr, setRegionErr] = useState<string>('')
  const [validate, setValidate] = useState<boolean>(false)
  /* Set Errors end*/

  /* Set Input values */
  const [name, setName] = useState<string>('')
  const [url, setUrl] = useState<string>('')
  const [secretKey, setSecretKey] = useState<string>('')
  const [reportType, setReportType] = useState<string | string[] | null>([])
  const [qualityMeasure, setQualityMeasure] = useState<string | string[] | null>([])
  const [facilityIds, setFacilityIds] = useState<any[]>([])
  const [regionsValue, setRegionsValue] = useState<any>([])
  const [isConnected, setIsConnected] = useState<boolean>(false)

  const [initUpdateModalState, setInitUpdateModalState] = useState({
    client_endpoint_url: '',
    facility_ids: [],
    region_ids: [],
    id: '',
    is_disabled_due_to_error: '',
    name: '',
    quality_measure: [],
    report_type: [],
    secret_key: '',
    status: ''
  })

  const modelProductFeatures = userContext.currentOrg.productFeatures.filter(
    (item) => {
      return item.includes('model' || 'rth')
    }
  )
  const REPORT_TYPES: SelectOption[] = modelProductFeatures.map((i) => {
    return { label: getFeatureLabel(i)?.label, value: getFeatureLabel(i)?.id }
  })

  const filteredRegions = regions.filter((i) => {
    return i.children.map((f: any) => {
      return facilityIds?.includes(f.value)
    }).includes(true)
  })

  const mixpanelEventsData = {
    integration_type: item?.id,
    organisation: userContext.currentOrg?.name,
    user: userContext.email,
    facilities: parseFacilityIdsToOptions(
        facilityIds,
        allFacilities
      )?.map((e) => e.label),
    facility_ids: parseFacilityIdsToOptions(
        facilityIds,
        allFacilities
      )?.map((e) => e.value),
    regions: filteredRegions?.map((e) => e.label),
    regions_ids: filteredRegions?.map((e) => e.value),
    reports: reportType,
  }

  const clearInputs = () => {
    setName('')
    setUrl('')
    setSecretKey('')
    setReportType(null)
    setQualityMeasure(null)
    setFacilityIds([])
    setRegionsValue([])
    setIsConnected(false)
  }

  const [formIsChanged, setFormIsChanged] = useState(false)

  useEffect(() => {
    const inputsChanged = () => {
      const {
        name: nameNew,
        client_endpoint_url,
        report_type,
        quality_measure,
        facility_ids,
        region_ids
      } = initUpdateModalState
      return (
        nameNew !== name ||
        client_endpoint_url !== url ||
        !_.isEqual([report_type], reportType) ||
        !_.isEqual([quality_measure], qualityMeasure) ||
        !_.isEqual(facility_ids?.sort(), facilityIds?.sort()) ||
        !_.isEqual(region_ids?.sort(), region_ids?.sort())
      )
    }

    setFormIsChanged(inputsChanged())
  }, [name, url, reportType, qualityMeasure, facilityIds, regionsValue])

  /* Set Input values end*/

  const isErrorFound = () => {
    const nameIsValid = validateWebhookModalInput(name)
    const clientUrlIsValid =
      validateWebhookModalInput(url) || validateWebhookModalInput(url, 'url')
    const reportTypeIsValid = validateWebhookModalInput(reportType)
    const qualityMeasureIsValid = validateWebhookModalInput(qualityMeasure)
    const facilityIdsIsValid = validateWebhookModalInput(facilityIds)
    const regionIdsIsValid = validateWebhookModalInput(regions)

    nameIsValid && setNameErr(nameIsValid)
    clientUrlIsValid && setUrlErr(clientUrlIsValid)
    reportTypeIsValid && setReportTypeErr(reportTypeIsValid)
    qualityMeasureIsValid && setQualityMeasureErr(qualityMeasureIsValid)
    facilityIdsIsValid && setFacilityIdsErr(facilityIdsIsValid)
    regionIdsIsValid && setRegionErr(regionIdsIsValid)

    return Boolean(
      nameIsValid ||
        clientUrlIsValid ||
        reportTypeIsValid ||
        qualityMeasureIsValid ||
        facilityIdsIsValid || 
        facilityIdsIsValid
    )
  }

  const addEndpointTable = (webhook) => {
    if (webhook) {
      {
        /*@ts-ignore*/
      } if (data?.endpoints) {
        {
          /*@ts-ignore*/
        } formContext.setValue({ endpoints: [...data.endpoints, webhook] })
      } else {
        {
          /*@ts-ignore*/
        }
        formContext.setValue({ endpoints: [webhook] })
      }
    }
  }

  const removeEndpointFromTable = (webhookId: string | undefined) => {
    {
      /*@ts-ignore*/
    } if (data?.endpoints && webhookId) formContext.setValue({endpoints: data.endpoints.filter((i) => i.id !== webhookId)})
  }
  const getWebhookIntegrationInfo = async () => {
    try {
      const res = await IntegrationService.WebhookAPI.getWebhookIntegrationInfo(
        userContext.currentOrg.id
      )
      setWebhookIntegrationInfo(res)
      return res
    } catch (e: any) {
      setWebhookIntegrationInfo({})
      if (e?.errorCode !== 'backend.webhook_integration_not_found')
        setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
    }
  }

  const getWebhookEndpointInfo = async (webhookId) => {
    try {
      const res = await IntegrationService.WebhookAPI.getWebhookEndpointInfo(
        userContext.currentOrg.id,
        webhookId
      )
      //@ts-ignore
      setWebhookEndpointInfo(res)
      return res
    } catch (e: any) {
      setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
    }
  }

  const handleCreateModalWindowShow = () => {
    mixpanelInstance.integrationEventHandler(
      mixpanelEventsData,
      'IntegrationWebhookCreateModal'
    )
    clearInputs()
    setUpdateModalIsShown(false)
    setCreateModalIsShown(true)
  }

  const handleModalClose = async () => {
    setUpdateModalIsShown(false)
    setCreateModalIsShown(false)
    setValidate(false)

    //@ts-ignore
    setWebhookEndpointInfo(undefined)

    setGlobalModalError('')

    setNameErr('')
    setUrlErr('')

    setReportTypeErr('')
    setReportTypeErr('')
    setQualityMeasureErr('')
    setFacilityIdsErr('')

    setUpdateReqStatus('')
    setTestReqStatus('')
    setCreateReqStatus('')

    setCreateModalIsShown(false)

    await getWebhookIntegrationInfo()
  }

  const fetchFacilities = async () => {
    try {
      const facilities = await FacilityService.getAllFacilities(
        userContext.currentOrg.id
      )
      if (facilities) setFacilities(parseFacilitiesToOptions(facilities))
    } catch (e) {
      setFacilities([])
    }
  }

  const fetchRegions = async () => {
    try {
      const regions = await RegionService.getRegions(
        userContext.currentOrg.id
      )
      if (regions) setRegions(
        regions.map((i) => {
          return {
            value: i.id,
            label: i.name,
            children: i.facilities
              .map((f) => {
                return {value: f.id, label: f.name, parent: i.id}
              }
            )
          }}
        ))
    } catch (e) {
      setRegions([])
    }
  }

  useEffect(() => {
    fetchFacilities()
    fetchRegions()
    getWebhookIntegrationInfo()
  }, [userContext.currentOrg.id, open])

  const testWebhookEndpoint = async (webhookId) => {
    setModalFormDisabled(true)
    setGlobalModalError('')
    setUpdateReqStatus('')
    setTestReqStatus('')
    setTestReqStatus(reqStatuses.inProgress)
    setIsCloseModalShown(false)

    setTimeout(async () => {
      try {
        mixpanelInstance.integrationEventHandler(
          mixpanelEventsData,
          'IntegrationWebhookTest'
        )
        await IntegrationService.WebhookAPI.testWebhookEndpoint(
          userContext.currentOrg.id,
          webhookId
        )
        //@ts-ignore
        const { status } = await webhookEndpointTest(
          userContext.currentOrg.id,
          webhookId
        )

        if (status === 'failed') {
          setTestReqStatus(reqStatuses.error)
          setGlobalModalError('Test failed')
          setModalFormDisabled(false)
        }

        if (status === 'complete') {
          setTestReqStatus(reqStatuses.success)
          setModalFormDisabled(false)
        }
        await getWebhookIntegrationInfo()
        setModalFormDisabled(false)
        setIsCloseModalShown(true)
      } catch (e: any) {
        setTestReqStatus(reqStatuses.error)
        setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
        setModalFormDisabled(false)
        setIsCloseModalShown(true)
      }
    }, 3000)
  }

  const createWebhookEndpoint = async (
    handleConnect: () => Promise<boolean>
  ) => {
    setGlobalModalError('')
    setUpdateReqStatus('')
    setTestReqStatus('')
    setValidate(true)

    const errorIsFound = await isErrorFound()

    if (!errorIsFound) {
      if (item.statusBase === 'disconnected') await handleConnect()

      setModalFormDisabled(true)

      //move to ext layer for all methods to be used
      const payload = {
        name,
        client_endpoint_url: url,
        report_type: reportType,
        quality_measure: qualityMeasure,
        facility_ids: facilityIds,
        region_ids: regionsValue,
        status: isConnected ? 'connected' : 'disconnected'
      }

      setCreateReqStatus(reqStatuses.inProgress)
      setTimeout(async () => {
        try {
          mixpanelInstance.integrationEventHandler(
            mixpanelEventsData,
            'IntegrationWebhookConnect'
          )
          const res = await IntegrationService.WebhookAPI.createWebhookEndpoint(
            userContext.currentOrg.id,
            payload
          )
          if (res) {
            addEndpointTable(res) // needs to change to async (fixed for async for upd, test)
            await getWebhookIntegrationInfo()
            setCreateReqStatus(reqStatuses.success)
            setModalFormDisabled(false)

            setWebhookEndpointInfo(res)

            setName(res?.name)
            setUrl(res?.client_endpoint_url)
            setSecretKey(res?.secret_key)
            setReportType(res?.report_type)
            setQualityMeasure(res?.quality_measure)
            setFacilityIds(res?.facility_ids)
            setRegionsValue(res?.region_ids ? res?.region_ids : [])
            setIsConnected(res?.connected === 'connected' ? true : false)

            setSecretKeyModalIsShown(true)
            setCreateModalIsShown(false)
            setFormIsChanged(false)
          }
        } catch (e: any) {
          setCreateReqStatus(reqStatuses.error)
          setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
          setModalFormDisabled(false)
        }
      }, 1500)
    }
  }

  const updateWebhookEndpoint = async (webhookId, payloadNew?) => {
    setGlobalModalError('')
    setUpdateReqStatus('')
    setTestReqStatus('')
    setValidate(true)

    const errorIsFound = await isErrorFound()

    if (!errorIsFound) {
      setModalFormDisabled(true)

      //move to ext layer for all methods to be used
      const payload = {
        name,
        client_endpoint_url: url,
        report_type: reportType,
        quality_measure: qualityMeasure,
        facility_ids: facilityIds,
        regions: regions,
        status: isConnected ? 'connected' : 'disconnected'
      }

      setUpdateReqStatus(reqStatuses.inProgress)
      try {
        mixpanelInstance.integrationEventHandler(
          mixpanelEventsData,
          'IntegrationWebhookUpdate'
        )
        const res = await IntegrationService.WebhookAPI.updateWebhookEndpoint(
          userContext.currentOrg.id,
          webhookId,
          payloadNew ? payloadNew : payload
        )
        if (res?.status === 'updating') {
          const { status } = await webhookEndpointStatusIsUpdated(
            userContext.currentOrg.id,
            res.id
          )
          const setActualWebhookState = async () => {
            await getWebhookEndpointInfo(webhookId)
            await getWebhookIntegrationInfo()
            setUpdateReqStatus('')
            setUpdateReqStatus(reqStatuses.success)
            setModalFormDisabled(false)
          }

          if (status === 'connected') {
            setIsConnected(true)
            await setActualWebhookState()
          }

          if (status === 'disconnected') {
            setIsConnected(false)
            await setActualWebhookState()
          }
        } else {
          if (res?.is_disabled_due_to_error) {
            setUpdateReqStatus('')
            await getWebhookEndpointInfo(webhookId)
            await getWebhookIntegrationInfo()
          } else {
            setUpdateReqStatus(reqStatuses.success)
            await getWebhookEndpointInfo(webhookId)
            await getWebhookIntegrationInfo()
          }
          setIsConnected(res?.status === 'connected')
          setModalFormDisabled(false)
        }
      } catch (e: any) {
        setUpdateReqStatus(reqStatuses.error)
        setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
        setModalFormDisabled(false)
      }
    }
  }

  const updateWebhookEndpointStatus = async (webhookId, isConnected) => {
    if (isConnected)
      mixpanelInstance.integrationEventHandler(
        mixpanelEventsData,
        'IntegrationWebhookRequestEnabled'
      )
    else
      mixpanelInstance.integrationEventHandler(
        mixpanelEventsData,
        'IntegrationWebhookRequestDisabled'
      )
    const res = await IntegrationService.WebhookAPI.updateWebhookEndpoint(
      userContext.currentOrg.id,
      webhookId,
      { status: isConnected ? 'connected' : 'disconnected' }
    )
    if (res?.status === 'updating') {
      const { status } = await webhookEndpointStatusIsUpdated(
        userContext.currentOrg.id,
        res.id
      )
      if (status === 'connected' || status === 'disconnected') {
        await getWebhookIntegrationInfo()
      }
    }

    if (res.status === 'connected') {
      await getWebhookEndpointInfo(webhookId)
      await getWebhookIntegrationInfo()
    }

    if (res.status === 'disconnected') {
      await getWebhookEndpointInfo(webhookId)
      await getWebhookIntegrationInfo()
    }
  }

  const removeWebhookEndpoint = async (
    webhookId,
    handleDisconnect?: (confirmCheck?: boolean) => Promise<boolean>
  ) => {
    const response = await confirmModal.open({
      title: t('integrations.webhookModal.removeWebhookTitle')
    })
    if (response) {
      try {
        mixpanelInstance.integrationEventHandler(
          mixpanelEventsData,
          'IntegrationWebhookDisconnect'
        )
        await IntegrationService.WebhookAPI.removeWebhookEndpoint(
          userContext.currentOrg.id,
          webhookId
        )
        await getWebhookIntegrationInfo()
        {
          /*@ts-ignore*/
        } if (data?.endpoints?.length === 1 && handleDisconnect) { await handleDisconnect(false)
          closeAdditionModals()
        }
        removeEndpointFromTable(webhookId)
        showSuccessToast(t('integrations.webhookModal.successfullyRemoved'))
      } catch (e: any) {
        await getWebhookIntegrationInfo()
        if (e?.errorCode === 'webhook_integration_endpoint_action_in_progress')
          return showErrorToast(
            'backend.webhook_integration_endpoint_action_in_progress'
          )
        return setGlobalModalError(
          t(`${e?.errorCode || 'backend.unknown_error'}`)
        )
      }
    }
  }

  const regenerateSecretKey = async (webhookId) => {
    try {
      setModalFormDisabled(true)
      mixpanelInstance.integrationEventHandler(
        mixpanelEventsData,
        'IntegrationWebhookRegenerateToken'
      )
      const res = await IntegrationService.WebhookAPI.regenerateWebhookKey(
        userContext.currentOrg.id,
        webhookId
      )
      setSecretKey(res?.secret_key)
      setSecretKeyModalIsShown(true)
      setUpdateModalIsShown(false)
      setTimeout(() => setModalFormDisabled(false), 2000)
    } catch (e: any) {
      setUpdateModalIsShown(true)
      setGlobalModalError(t(`${e?.errorCode || 'backend.unknown_error'}`))
      setModalFormDisabled(false)
    }
  }

  /* Secret modal */

  const handleCloseSecretKeyModal = async (webhookId) => {
    const res = await getWebhookEndpointInfo(webhookId)
    setSecretKey(res?.secret_key)
    setSecretKeyModalIsShown(false)
    setUpdateModalIsShown(true)
  }

  /* Secret modal end */

  const handleCloseMainModal = () => {
    closeAdditionModals()
    handleClose()
  }

  const { t } = useTranslation()
  const [loadingRow, setLoadingRow] = useState(null)

  const renderWebhookMainModalRows = (webhook, id, handler?) => {
    const isRowLoading = loadingRow === id
    const isWebhookConnected =
      webhook.status === 'connected' && !webhook.is_disabled_due_to_error
    const temporarySwitchStatus = isConnected && isRowLoading
    return (
      <>
        <div className={`${styles.row}`} key={`endpoint-${id}`}>
          <label className={styles.name}>
            {t('integrations.webhookModal.renderWebhook.webhook')}{' '}
            {webhook.name.length > 40
              ? webhook.name.slice(0, 40) + '...'
              : webhook.name}
          </label>
          {/*<label className={styles.name}>Webhook {webhook.name}</label>*/}
          {/*@ts-ignore*/}
          {!isRowLoading && (webhookIntegrationInfo.is_disabled_due_to_error ||
              webhook.is_disabled_due_to_error) && (
              <div className={styles.failureMsg}>
                <span>{t('integrations.webhookModal.renderWebhook.failure')}</span>
              </div>
            )}
          {isRowLoading ? (
            <>
              <div className={styles.processingMsg}>{t('integrations.webhookModal.renderWebhook.processing')}</div>
              <div style={{ marginRight: '10px' }}>
                <LoadingSpinner height={'28px'} width={'28px'} />
              </div>
            </>
          ) : (
            ''
          )}
          <div title={t('integrations.webhookModal.renderWebhook.title')}>
            <Switch
              //@ts-ignore
              id={webhook.id}
              onChange={async (e) => {
                try {
                  await setIsConnected(e)
                  await setModalFormDisabled(true)
                  await setLoadingRow(id)
                  await updateWebhookEndpointStatus(webhook.id, e)
                  setLoadingRow(null)
                  setModalFormDisabled(false)
                } catch (e) {
                  await getWebhookIntegrationInfo()
                  setLoadingRow(null)
                  setModalFormDisabled(false)
                }
              }}
              value={isWebhookConnected || temporarySwitchStatus}
              disabled={isModalFormDisabled}
            />
          </div>
          <div className={styles.icons}>
            <div
              title={t('integrations.webhookModal.renderWebhook.titleEdit')}
              className={`${styles.iconWrapper} ${
                isModalFormDisabled ? styles.disabled : ''
              }`}
            >
              <EditIcon
                onClick={async () => {
                  mixpanelInstance.integrationEventHandler(
                    mixpanelEventsData,
                    'IntegrationWebhookUpdateModal'
                  )
                  const res = await getWebhookEndpointInfo(webhook.id)
                  if (res) {
                    //@ts-ignore
                    setWebhookEndpointInfo(res)
                    //@ts-ignore
                    setInitUpdateModalState(res)
                    setFormIsChanged(false)
                    setName(res?.name)
                    setUrl(res?.client_endpoint_url)
                    setSecretKey(res?.secret_key)
                    setReportType(res?.report_type)
                    setQualityMeasure(res?.quality_measure)
                    setFacilityIds(res?.facility_ids)
                    setRegionsValue(res?.region_ids)
                    setIsConnected(res?.status === 'connected')
                    setCreateModalIsShown(false)
                    setUpdateModalIsShown(true)
                    setFormIsChanged(false)
                  } else {
                    await getWebhookIntegrationInfo()
                  }
                }}
              />
            </div>
            <div
              title="Delete"
              className={`${styles.iconWrapper} ${
                isModalFormDisabled ? styles.disabled : ''
              }`}
            >
              <DeleteIcon
                onClick={() => removeWebhookEndpoint(webhook.id, handler)}
                className={styles.delete}
              />
            </div>
          </div>
        </div>
      </>
    )
  }

  const values = allFacilities.map((r) => {
    return {
      value: r.value,
      label: r.label,
      parent: r.parent
    }
  })

  const getAdditionalModals = (handleConnect, handleDisconnect) => {
    return (
      <>
        {/* Create Modal; Reminder: need to investigate the pros/cons of the Create/Update mode */}
        <SaivaMultiModalItem
          open={Boolean(createModalIsShown)}
          handleClose={handleModalClose}
          overlay
        >
          <div style={{ display: 'block' }}>
            <div style={{ display: 'inline-block', width: '55%' }}>
              <h4>{t('integrations.webhookModal.getAdditional.create')}</h4>
              <p>{t('integrations.webhookModal.getAdditional.createMessage')}</p>
            </div>
          </div>
          <div style={{ margin: '0 0 15px 0' }}>
            <label style={{ margin: '0 0 10px 0' }}>
              {t('integrations.webhookModal.getAdditional.name')}
              <span style={{ color: 'red', margin: '0 0 10px 5px' }}>*</span>
            </label>
            <div style={{ width: '100%' }}>
              <Input
                id={'name'}
                value={name}
                onChange={(value) => {
                  setNameErr('')
                  setName(value)
                  if (value.length > 100) {
                    setNameErr(t('integrations.errors.nameMaxLength'))
                  }
                }}
                placeholder={'Name'}
                error={nameErr}
                disabled={isModalFormDisabled}
                validators={[{ maxLength: 100 }]}
              />
            </div>
          </div>

          <div style={{ margin: '0 0 15px 0' }}>
            <label style={{ margin: '0 0 10px 0' }}>
              {t('integrations.webhookModal.getAdditional.endpoint')}
              <span style={{ color: 'red', margin: '0 0 10px 5px' }}>*</span>
            </label>
            <div>
              <Input
                id={'client_endpoint_url'}
                value={url}
                onChange={(value) => {
                  setUrlErr('')
                  setUrl(value.trim())
                }}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.endpointUrl')}
                error={urlErr}
                disabled={isModalFormDisabled}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 0 5px 0'
            }}
          >
            <label style={{ display: 'inline-block', width: '15%' }}>
              {t('integrations.webhookModal.getAdditional.label.secretKey')}
            </label>
            <div style={{ display: 'inline-block', width: '85%' }}>
              <Input
                id={'secret_key'}
                value={secretKey}
                onChange={(value) => setSecretKey(value)}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.secretKey')}
                readOnly
                skipBoxShadow={true}
              />
            </div>
          </div>
          <div className={styles.line}></div>

          <Row gutter={[16, 16]} align="top" style={{ marginTop: '20px' }}>
            <Col span={12}>
              <SaivaSelect
                value={reportType}
                multiselect={false}
                icon={null}
                label={t('integrations.webhookModal.getAdditional.label.reportType')}
                options={REPORTS}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.reportType')}
                required={true}
                errors={(validate && !reportType) ? {message: 'This field is required'} : undefined}
                onChange={(value) => {
                  setReportType(value)
                }}
              />
            </Col>
            <Col span={12}>
              <SaivaSelect
                value={qualityMeasure}
                multiselect={false}
                icon={null}
                label={t('integrations.webhookModal.getAdditional.label.qualityMeasure')}
                options={REPORT_TYPES}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.qualityMeasure')}
                required={true}
                errors={(validate && !qualityMeasure) ? {message: 'This field is required'} : undefined}
                onChange={(value) => {
                  setQualityMeasure(value)
                }}
              />
            </Col>
            <Col span={12}>
              <SaivaCustomSelect
                value={facilityIds}
                name="facilityIds"
                label={t('integrations.webhookModal.getAdditional.label.facility')}
                reset={() => {
                  setFacilityIds([])
                }}
                values={values}
                parents={regions}
                disabled={regions.length > 0 ? false : true}
                required={true}
                errors={(validate && facilityIds.length < 1) ? {message: 'At least one Facility is required'} : undefined}
                onChange={(value) => {
                  setFacilityIds(value)
                }}
              />
            </Col>
          </Row>

          {globalModalError && (
            <div className={`${styles.banner} ${styles.bannerFailed}`}>
              <p>
                <WarningIcon />
                {globalModalError}
              </p>
            </div>
          )}

          {(updateReqStatus == reqStatuses.inProgress ||
            testReqStatus == reqStatuses.inProgress ||
            createReqStatus == reqStatuses.inProgress) && (
            <div className={`${styles.banner}`}>
              <p>
                <WarningIcon />
                {/* Move to a smaller condition */}
                {updateReqStatus && t('integrations.webhookModal.info.isUpdating')}
                {testReqStatus && t('integrations.webhookModal.info.testIsRunning')}
                {createReqStatus && t('integrations.webhookModal.info.isCreating')}
                <LoadingSpinner />
              </p>
            </div>
          )}

          {(updateReqStatus == reqStatuses.success ||
            testReqStatus == reqStatuses.success ||
            createReqStatus == reqStatuses.success) && (
            <div className={`${styles.banner} ${styles.bannerSuccess}`}>
              <p>
                <CheckCircleIcon />
                {/* Move to a smaller condition */}
                {updateReqStatus &&
                  t('integrations.webhookModal.success.updated')}
                {testReqStatus &&
                  t('integrations.webhookModal.success.tested')}
                {createReqStatus && t('integrations.webhookModal.success.created')}
              </p>
            </div>
          )}

          {/*@ts-ignore*/}
          <div className={styles.buttons} style={{ justifyContent: 'right' }}>
            {/*@ts-ignore*/}
            <button
              title={t('integrations.webhookModal.success.createButtonTitle')}
              className={'primary-button'}
              disabled={isModalFormDisabled ? true : false}
              onClick={() => {
                if (name.length <= 100) {
                  createWebhookEndpoint(handleConnect)
                }
              }}
            >
              {t('integrations.webhookModal.success.createButton')}
            </button>
          </div>
        </SaivaMultiModalItem>
        {/* Create Modal end*/}

        {/* Update Modal */}
        <SaivaMultiModalItem
          open={Boolean(updateModalIsShown)}
          handleClose={handleModalClose}
          isClosing={isCloseModalShown}
          overlay
        >
          <div style={{ display: 'block' }}>
            <div style={{ display: 'inline-block', width: '60%' }}>
              <h4>{t('integrations.webhookModal.getAdditional.edit')}</h4>
              <p>{t('integrations.webhookModal.getAdditional.editMessage')}</p>
            </div>
          </div>
          <div style={{ margin: '0 0 15px 0' }}>
            <label style={{ margin: '0 0 10px 0' }}>
              {t('integrations.webhookModal.getAdditional.name')}
              <span style={{ color: 'red', margin: '0 0 10px 5px' }}>*</span>
            </label>
            <div style={{ width: '100%' }}>
              <Input
                id={'name'}
                value={name}
                onChange={(value) => {
                  setNameErr('')
                  setName(value)
                  if (value.length > 100) {
                    setNameErr(t('integrations.errors.nameMaxLength'))
                  }
                }}
                placeholder={'Name'}
                error={nameErr}
                disabled={isModalFormDisabled}
                validators={[{ maxLength: 100 }]}
              />
            </div>
          </div>

          <div style={{ margin: '0 0 15px 0' }}>
            <label style={{ margin: '0 0 10px 0' }}>
              {t('integrations.webhookModal.getAdditional.endpoint')}
              <span style={{ color: 'red', margin: '0 0 10px 5px' }}>*</span>
            </label>
            <div style={{ width: '100%' }}>
              <Input
                id={'client_endpoint_url'}
                value={url}
                onChange={(value) => {
                  setUrlErr('')
                  setUrl(value.trim())
                }}
                placeholder={'Endpoint URL'}
                error={urlErr}
                disabled={isModalFormDisabled}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '0 0 5px 0'
            }}
          >
            <label style={{ display: 'inline-block', width: '15%' }}>
              {t('integrations.webhookModal.getAdditional.label.secretKey')}
            </label>
            <div style={{ display: 'inline-block', width: '85%' }}>
              <Input
                id={'secret_key'}
                value={secretKey}
                onChange={(value) => setSecretKey(value)}
                placeholder={'Secret key'}
                readOnly
                skipBoxShadow={true}
                disabledText={true}
              />
            </div>
            <div
              style={{
                display: 'inline-block',
                width: '5%',
                margin: '0 0 0 25px'
              }}
            >
              <div
                className={`${styles.iconWrapper} ${
                  isModalFormDisabled ? styles.disabled : ''
                }`}
              >
                {/*@ts-ignore*/}
                <RegenerateIcon
                  title={t('integrations.webhookModal.getAdditional.regenerateSecretKey')}
                  stroke={isModalFormDisabled ? 'grey' : '#4070DC'}
                  style={{ cursor: 'pointer' }}
                  /*@ts-ignore*/
                  onClick={() => regenerateSecretKey(webhookEndpointInfo?.id)}
                />
              </div>
            </div>
          </div>
          <div className={styles.line}></div>
          <Row gutter={[16, 16]} align="top" style={{ marginTop: '20px' }}>
            <Col span={12}>
              <SaivaSelect
                value={reportType}
                multiselect={false}
                icon={null}
                label={t('integrations.webhookModal.getAdditional.label.reportType')}
                options={REPORTS}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.reportType')}
                required={true}
                errors={(validate && !reportType) ? {message: 'This field is required'} : undefined}
                onChange={(value) => {
                  setReportType(value)
                }}
              />
            </Col>
            <Col span={12}>
              <SaivaSelect
                value={qualityMeasure}
                multiselect={false}
                icon={null}
                label={t('integrations.webhookModal.getAdditional.label.qualityMeasure')}
                options={REPORT_TYPES}
                placeholder={t('integrations.webhookModal.getAdditional.placeholder.qualityMeasure')}
                required={true}
                errors={(validate && !qualityMeasure) ? {message: 'This field is required'} : undefined}
                onChange={(value) => {
                  setQualityMeasure(value)
                }}
              />
            </Col>
            <Col span={12}>
              <SaivaCustomSelect
                value={facilityIds}
                name="facilityIds"
                label={t('integrations.webhookModal.getAdditional.label.facility')}
                reset={() => {
                  setFacilityIds([])
                }}
                values={values}
                parents={regions}
                disabled={regions.length > 0 ? false : true}
                required={true}
                errors={(validate && facilityIds.length < 1) ? {message: 'At least one Facility is required'} : undefined}
                onChange={(value) => {
                  setFacilityIds(value)
                }}
              />
            </Col>
          </Row>

          {globalModalError && (
            <div className={`${styles.banner} ${styles.bannerFailed}`}>
              <p>
                <WarningIcon />
                {globalModalError}
              </p>
            </div>
          )}

          {(updateReqStatus == reqStatuses.inProgress ||
            testReqStatus == reqStatuses.inProgress) && (
            <div className={`${styles.banner}`}>
              <p>
                <WarningIcon />
                {updateReqStatus && t('integrations.webhookModal.info.isUpdating')}{' '}
                {testReqStatus && t('integrations.webhookModal.info.testIsRunning')}
                <LoadingSpinner />
              </p>
            </div>
          )}

          {(updateReqStatus == reqStatuses.success ||
            testReqStatus == reqStatuses.success) && (
            <div className={`${styles.banner} ${styles.bannerSuccess}`}>
              <p>
                <CheckCircleIcon />
                {updateReqStatus &&
                  t('integrations.webhookModal.success.updated')}{' '}
                {testReqStatus &&
                  t('integrations.webhookModal.success.tested')}
              </p>
            </div>
          )}

          <div
            className={styles.buttons}
            style={{ justifyContent: 'space-between' }}
          >
            <button
              title={t('integrations.webhookModal.testButtonTitle')}
              /*@ts-ignore*/
              style={{ align: 'left' }}
              className={'primary-text-button'}
              disabled={formIsChanged || isModalFormDisabled}
              /*@ts-ignore*/
              onClick={() => testWebhookEndpoint(webhookEndpointInfo?.id)}
            >
              <TestIcon />
              {t('integrations.webhookModal.testButton')}
            </button>
            <button
              title={t('integrations.webhookModal.saveButtonTitle')}
              className={'primary-button'}
              disabled={isModalFormDisabled || !formIsChanged}
              onClick={() => {
                if (name.length <= 100) {
                  {
                    /*@ts-ignore*/
                  } updateWebhookEndpoint(webhookEndpointInfo?.id)
                    setFormIsChanged(false)
                }
              }}
            >
              {t('integrations.webhookModal.saveButton')}
            </button>
          </div>
        </SaivaMultiModalItem>
        {/* Update Modal end*/}

        {/* Copy modal token */}

        <SaivaMultiModalItem
          open={secretKeyModalIsShown}
          //@ts-ignore
          handleClose={() => handleCloseSecretKeyModal(webhookEndpointInfo?.id)}
          isClosing={false}
          overlay
        >
          <div>
            <div className={styles.webhookWrapper}>
              <div className={styles.info}>
                <CheckCircleIcon /> {t('integrations.webhookModal.copyToken.title')}
              </div>
              <div className={styles.key}>
                <h6> {t('integrations.webhookModal.copyToken.secretKey')}</h6>
                {/*@ts-ignore*/}
                <label>{secretKey}</label>
                <div className={styles.icon}>
                  {/*@ts-ignore*/}
                  <CopyIcon
                    title={t('integrations.webhookModal.copyToken.copyIcon')}
                    onClick={() => {
                      mixpanelInstance.integrationEventHandler(
                        mixpanelEventsData,
                        'IntegrationWebhookCopiedGeneratedToken'
                      )
                      copyToClipboard(secretKey, 'Secret')
                      //@ts-ignore
                      handleCloseSecretKeyModal(webhookEndpointInfo?.id)
                    }}
                  />
                </div>
              </div>
              <div className={styles.infoBottom}>
                {t('integrations.webhookModal.keyPopup.infoLabel') + ''}
              </div>
            </div>
          </div>
        </SaivaMultiModalItem>
        {/* Copy modal token end */}
      </>
    )
  }

  //@ts-ignore
  const ednpointsLength = webhookIntegrationInfo?.endpoints?.length || 0

  return (
    <IntegrationModalWrapper<IntegrationType.WEBHOOK>
      open={open}
      handleClose={handleCloseMainModal}
      item={item}
      updateItem={props.updateItem}
      additionalModals={getAdditionalModals}
      disconnectMessage={
        t('integrations.webhookModal.integrationModal.disconnectMessage')
      }
      doNotAwaitStatus
      doNotCheckDirty
      hasDisconnectBtn={false}
      mixpanelEventsData={mixpanelEventsData}
    >
      {(handleConnect, handleDisconnect) => {
        return (
          <div>
            <div className={styles.webhookHeader}>
              <div>
                <h5>{t('integrations.webhookModal.integrationModal.webhook')}</h5>

                {ednpointsLength > 0 ? (
                  <p> {t('integrations.webhookModal.integrationModal.ednpointsLength', {ednpointsLength: ednpointsLength})}</p>
                ) : (
                  <p>
                    {t('integrations.webhookModal.integrationModal.clickToCreate')}
                  </p>
                )}
              </div>
              <button
                className={`primary-text-button-small ${
                  isModalFormDisabled ? styles.disabled : ''
                }`}
                onClick={() => handleCreateModalWindowShow()}
              >
                <SWMIcon name={'PlusSmall'} color={colors['primary-blue']} />
                {t('integrations.webhookModal.integrationModal.createButton')}
              </button>
            </div>
            {ednpointsLength > 0 ? (
              <>
                <div className={styles.webhookTable}>
                  {/*@ts-ignore*/}
                  {webhookIntegrationInfo?.endpoints?.map((webhook, id) =>
                    renderWebhookMainModalRows(webhook, id, handleDisconnect)
                  )}
                </div>
              </>
            ) : (
              <div className={styles.tablePlaceholder}>
                {t('integrations.webhookModal.integrationModal.noActiveMessage')}
              </div>
            )}
          </div>
        )
      }}
    </IntegrationModalWrapper>
  )
}
