import { useState, useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getFeatureLabel } from 'utils/helper'
import { showSuccessToast } from 'utils'
import { useMediaQuery } from 'react-responsive'
import { Row, Col, Button, Modal, Dropdown, Menu, Space } from 'antd'
import styles from './TopNavigation.module.scss'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send-2.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location-med.svg'
import { ReactComponent as HomeIcon } from '../../../assets/icons/home-hospital.svg'
import { ReactComponent as MedsIcon } from '../../../assets/icons/meds.svg'
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg'
import { ReactComponent as RankIcon } from '../../../assets/icons/rank-indicator.svg'
import { ReactComponent as MenuVertical } from '../../../assets/icons/menu-vertical.svg'
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross-big.svg'
import QualityMeasureSelector from '../../../../../components/QualityMeasureSelector/QualityMeasureSelector'
import { OrgProductFeature } from '../../../../../types/user-types'
import SaivaSelect from '../../../../../components/SaivaForm/SaivaSelect/saivaSelect'
import SaivaTextArea from '../../../../../components/SaivaForm/SaivaTextArea/SaivaTextArea'
import ResidentService from '../../../../../services/resident-service'
import { useUserContext } from '../../../../../context/UserContext'
import LoadingAnimation from '../../../../../components/LoadingAnimation/LoadingAnimation'
import { ReactComponent as InfoIcon } from '../../../../../assets/icons/info.svg'
import { mixpanelInstance } from 'utils/mixpanel'

interface TopNavigation {
  date: string
  patient_name: string
  facility_name: string
  room: string
  mrn: string
  rank: number
  current_rank_indicator: string
  rank_yesterday: number
}

type Props = {
  data: TopNavigation
  onChangeQM: (value: OrgProductFeature) => void
  activeQM: OrgProductFeature
}

export default function TopNavigation({ data, onChangeQM, activeQM }: Props) {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [sharingModalOpen, setSharingModalOpen] = useState<boolean>(false)
  const [email, setEmail] = useState<string[]>([])
  const [comment, setComment] = useState<string>('')
  const [residentSharingUsers, setResidentSharingUsers] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<any>(undefined)
  const userContext = useUserContext()
  const { t } = useTranslation()

  const modelProductFeatures = userContext.currentOrg.productFeatures.filter(
    (item) => {
      return item.includes('model')
    }
  )

  const { residentId } = useParams()

  useEffect(() => {
    setApiError(undefined)
    if (residentId) {
      const decodedId = JSON.parse(atob(residentId))
      requestResidentSharingUsers(decodedId)
    }
  }, [])

  const requestResidentSharingUsers = async (data) => {
    await ResidentService.getResidentSharingUsers(
      data.org_id,
      data.customers_facility_identifier
    ).then((res) => {
      setResidentSharingUsers(res)
    })
  }

  const shareResident = async () => {
    if (residentId) {
      const decodedId = JSON.parse(atob(residentId))
      shareResidentRequest(decodedId)
    }
  }

  const shareResidentRequest = async (data) => {
    setLoading(true)
    const filter = {
      quality_measure: [activeQM],
      patient_id: data.patient_id
    }
    const body = {
      email,
      comment
    }

    await ResidentService.postShareResident(
      data.org_id,
      data.customers_facility_identifier,
      filter,
      body
    )
      .then(() => {
        setSharingModalOpen(false)
        setLoading(false)
        setEmail([])
        setComment('')
        showSuccessToast(
          t('residentPage.topNavigation.sharingSucces')
        )
        mixpanelInstance.shareResident({
          patient_id: data.patient_id,
          org_id: data.org_id,
          customers_facility_identifier: data.customers_facility_identifier,
          ...body
        }, activeQM)
      })
      .catch((err) => {
        setLoading(false)
        if (err.name === 'ApiError') {
          setApiError(err.errorCode)
        } else {
          setApiError('genericError')
        }
        throw err
      })
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1476 })
    return isDesktop ? children : null
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1475 })
    return isTablet ? children : null
  }
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
  }

  const mobileMenu = (
    <div className={styles.topNavigation}>
      <div style={{ width: '100%', whiteSpace: 'nowrap' }}>
        <div className={styles.calendarIcon}>
          <CalendarIcon /> {data.date}
        </div>
        <div>
          <button
            className={styles.moreInfoButton}
            onClick={() => setOpenConfirm(true)}
          >
            {data.patient_name}
          </button>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <Dropdown
            trigger={['click']}
            overlayClassName={styles.dropdown}
            dropdownRender={(menu) => (
              <Menu>
                {/* <Menu.Item className={`${styles.menuItem}`}>Download</Menu.Item> */}

                <Menu.Item
                  className={`${styles.menuItem}`}
                  onClick={() => setSharingModalOpen(true)}
                >
                  {t('residentPage.topNavigation.send')}
                </Menu.Item>
                <label className={styles.menuLabel}>
                  {t('residentPage.topNavigation.qualityMeasures')}
                </label>
                {modelProductFeatures.map((item, i) => {
                  return (
                    <Menu.Item
                      key={i}
                      className={`${i == 0 && styles.deleteMenuItem} ${
                        styles.menuItem
                      }`}
                      onClick={() => {
                        onChangeQM(item)
                        mixpanelInstance.switchQM(item, 'Resident')
                      }}
                    >
                      {getFeatureLabel(item).label}
                    </Menu.Item>
                  )
                })}
              </Menu>
            )}
          >
            <Space>
              <Button
                icon={<MenuVertical />}
                className={styles.mobileMenuButton}
              />
            </Space>
          </Dropdown>
        </div>
      </div>
      <div
        className={`${styles.rankMobile} ${
          data.current_rank_indicator == 'down'
            ? styles.headerRiskGreen
            : data.rank === data.rank_yesterday
            ? styles.headerRiskOrange
            : styles.headerRiskRed
        }`}
      >
        {t('residentPage.topNavigation.rank')} {data.rank}
        <RankIcon />
      </div>
    </div>
  )

  const tabletMenu = (
    <div className={styles.topNavigation}>
      <div className={styles.calendarIcon}>
        <CalendarIcon /> {data.date}
      </div>
      <div>
        <button
          className={styles.moreInfoButton}
          onClick={() => setOpenConfirm(true)}
        >
          {data.patient_name}
        </button>
      </div>
      <div
        className={`${styles.rank} ${
          data.current_rank_indicator == 'down'
            ? styles.headerRiskGreen
            : data.rank === data.rank_yesterday
            ? styles.headerRiskOrange
            : styles.headerRiskRed
        }`}
      >
        {t('residentPage.topNavigation.rank')} {data.rank}
        <RankIcon />
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <Dropdown
          trigger={['click']}
          overlayClassName={styles.dropdown}
          dropdownRender={(menu) => (
            <Menu>
              {/* <Menu.Item className={`${styles.menuItem}`}>Download</Menu.Item> */}

              <Menu.Item
                className={`${styles.menuItem}`}
                onClick={() => setSharingModalOpen(true)}
              >
                {t('residentPage.topNavigation.send')}
              </Menu.Item>
              <label className={styles.menuLabel}>
                {t('residentPage.topNavigation.qualityMeasures')}
              </label>
              {modelProductFeatures.map((item, i) => {
                return (
                  <Menu.Item
                    key={i}
                    className={`${i == 0 && styles.deleteMenuItem} ${
                      styles.menuItem
                    }`}
                    onClick={() => {
                      onChangeQM(item)
                      mixpanelInstance.switchQM(item, 'residents')
                    }}
                  >
                    {getFeatureLabel(item).label}
                  </Menu.Item>
                )
              })}
            </Menu>
          )}
        >
          <Space>
            <Button className={styles.mobileMenuButton}>
              {t('residentPage.topNavigation.manage')}{' '}
              <MenuVertical style={{ marginLeft: '8px' }} />
            </Button>
          </Space>
        </Dropdown>
      </div>
    </div>
  )

  const desktopMenu = (
    <Row justify={'space-between'}>
      <Col span={19}>
        <div className={styles.topNavigation}>
          <div className={styles.calendarIcon}>
            {/* <PDFDownloadLink document={resume} fileName={'resident.pdf'}>
              <Button icon={<DownloadIcon />} className={styles.iconButton} />
            </PDFDownloadLink> */}
            <Button
              icon={<SendIcon />}
              className={styles.iconButton}
              onClick={() => setSharingModalOpen(true)}
            />
          </div>
          <div className={styles.calendarIcon}>
            <CalendarIcon /> {data.date}
          </div>
          <div>
            <UserIcon /> {data.patient_name}
          </div>
          <div>
            <LocationIcon /> {data.facility_name}
          </div>
          <div>
            <HomeIcon /> {data.room}
          </div>
          <div>
            <MedsIcon /> MRN {data.mrn}
          </div>
          <div
            className={`${styles.rank} ${
              data.current_rank_indicator == 'down'
                ? styles.headerRiskGreen
                : data.rank === data.rank_yesterday
                ? styles.headerRiskOrange
                : styles.headerRiskRed
            }`}
          >
            {t('residentPage.topNavigation.rank')} {data.rank}
            <RankIcon />
          </div>
        </div>
      </Col>
      {modelProductFeatures.length > 1 && (
        <Col>
          <QualityMeasureSelector
            options={modelProductFeatures}
            defaultoption={activeQM}
            setQM={(qm: OrgProductFeature) => {
              onChangeQM(qm)
            }}
          />
        </Col>
      )}
    </Row>
  )
  return (
    <>
      <Modal
        closeIcon={
          <CrossIcon
            style={{ padding: '2px' }}
            onClick={() => setOpenConfirm(false)}
          />
        }
        open={openConfirm}
        footer={null}
        maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
        }}
        closable={true}
        width={'400px'}
      >
        <h3>{t('residentPage.topNavigation.generalInfo')}</h3>
        <div className={styles.mobileModalData}>
          <div className={styles.calendarIcon}>
            <CalendarIcon /> {data.date}
          </div>
          <div>
            <UserIcon /> {data.patient_name}
          </div>
          <div>
            <LocationIcon /> {data.facility_name}
          </div>
          <div>
            <HomeIcon /> {data.room}
          </div>
          <div>
            <MedsIcon /> MRN {data.mrn}
          </div>
          <button
            className={`${styles.rank} ${
              data.current_rank_indicator == 'down'
                ? styles.headerRiskGreen
                : data.rank === data.rank_yesterday
                ? styles.headerRiskOrange
                : styles.headerRiskRed
            }`}
          >
            {t('residentPage.topNavigation.rank')} {data.rank}
            <RankIcon />
          </button>
        </div>
      </Modal>
      <Modal
        closeIcon={
          <CrossIcon
            style={{ padding: '2px' }}
            onClick={() => {
              setSharingModalOpen(false)
              setApiError(undefined)
              setEmail([])
              setComment('')
            }}
          />
        }
        open={sharingModalOpen}
        footer={null}
        maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
        }}
        closable={true}
        width={'600px'}
      >
        <h3 style={{ fontWeight: '500', fontSize: '20px' }}>
          {t('residentPage.topNavigation.sharePatient')}
        </h3>
        <small style={{ color: '#667A84' }}>
          {t('residentPage.topNavigation.shareText')}
        </small>
        {!loading ? (
          <>
            <Row style={{ marginTop: '24px' }} gutter={24}>
              <Col md={12} xs={24}>
                <SaivaSelect
                  value={email}
                  options={residentSharingUsers.map((user) => {
                    return {
                      value: user.email,
                      label: user.first_name
                        ? user.first_name + ' ' + user.last_name
                        : user.email
                    }
                  })}
                  label={t('residentPage.topNavigation.share.email.label')}
                  placeholder={t(
                    'residentPage.topNavigation.share.email.placeholder'
                  )}
                  multiselect={true}
                  icon={null}
                  reset={() => setEmail([])}
                  onChange={(e) => {
                    setEmail(e)
                  }}
                />
              </Col>
              <Col md={12} xs={24}>
                <SaivaTextArea
                  placeholder={t(
                    'residentPage.topNavigation.share.comment.placeholder'
                  )}
                  name="name"
                  label={t('residentPage.topNavigation.share.comment.label')}
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: '32px' }}>
              <button
                className={styles.cancelButton}
                onClick={() => {
                  setSharingModalOpen(false)
                  setApiError(undefined)
                  setEmail([])
                  setComment('')
                }}
              >
                {t('residentPage.topNavigation.cancel')}
              </button>
              <button
                className={styles.button}
                disabled={!comment || !email}
                onClick={() => shareResident()}
              >
                {t('residentPage.topNavigation.send')}
              </button>
            </Row>
          </>
        ) : (
          <div style={{ marginTop: '20px' }}>
            <LoadingAnimation />
          </div>
        )}
        {apiError && (
          <>
            <div className={`banner bannerFailed`}>
              <InfoIcon />
              <p>{t(apiError)}</p>
            </div>
          </>
        )}
      </Modal>
      <Desktop>{desktopMenu}</Desktop>
      <Tablet>{tabletMenu}</Tablet>
      <Mobile>{mobileMenu}</Mobile>
    </>
  )
}
