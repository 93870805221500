import { useEffect } from 'react'
import FormLayout from './FormLayout'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SaivaFacility } from 'types/facility-types'

const name = yup.string().required()
const address = yup.string().max(256)
const zipcode = yup.string().max(10)
const city = yup.string().max(50)
const state = yup.string().max(50)
const regions = yup.array().of(yup.string())

const CreateUserFormSchema = yup.object().shape({
  name,
  address,
  zipcode,
  city,
  state,
  regions
})

interface Props {
  defaultValues: SaivaFacility.Item | undefined
  apiError: any
  regions: any
  onSubmit: (data: SaivaFacility.Item) => any
  formState: (value: boolean) => void
  handleCancel: () => void
}

const FormLogic = ({
  defaultValues,
  apiError,
  regions,
  onSubmit,
  formState,
  handleCancel
}: Props) => {

  useEffect(() => {
    if (!apiError) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const form = useForm<SaivaFacility.Item | any>({
    defaultValues: defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(CreateUserFormSchema)
  })

  formState(form.formState.isDirty)

  const handleSubmit = async (data: SaivaFacility.Item) => {
    await onSubmit(data)
      .then(() => {
        form.reset()
      })
      // TODO
      .catch((err) => console.error(err))
  }

  return (
    <FormLayout
      apiError={apiError}
      form={form}
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      regions={regions}
    />
  )
}

export default FormLogic
