import React, { FunctionComponent, PropsWithChildren, useRef, useState } from 'react'
import styles from './SaivaMultiModalItem.module.scss'
import { ReactComponent as CloseIcon } from '../../../assets/icons/cross.svg'
import { useOnClickOutside } from 'usehooks-ts'

interface SaivaModalProps {
  open: boolean,
  handleClose: () => void,
  transition?: "left" | "right" | "top" | "bottom" | "none"
  closeButtonId?: string,
  isClosing?: boolean,
  overlay?: boolean
}

export const SaivaMultiModalItem = (props: PropsWithChildren<SaivaModalProps>) => {

  const {open, handleClose, transition = "none", isClosing = true, overlay = false} = props

  if(open) return (
    <div className={`${styles.saivaModalItemWrapper} ${ overlay ? styles.saivaModalItemWrapperOverlay : ""}`}>
      <div className={`${styles.saivaModalItem}`}>
      {isClosing && <CloseIcon id={props.closeButtonId} className={styles.closeIcon} onClick={handleClose} />}
        {props.children}
      </div>
    </div>
  )
  else return null
}
