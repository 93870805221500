export function FacilityViewColumns(sortingHeader) {
  return [
    {
      title: sortingHeader('Facility Name', 'facility_name'),
      dataIndex: 'facility_name',
      key: 'facility_name',
      width: 80,
      fixed: 'left',
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: sortingHeader('Region Name', 'region_name'),
      dataIndex: 'region_name',
      key: 'region_name',
      width: 80,
      fixed: 'left',
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Number of Patients',
      dataIndex: 'number_of_patients',
      key: 'number_of_patients',
      width: 50,
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: '% of Patient Visited',
      dataIndex: 'visited_patients_percentage',
      key: 'visited_patients_percentage',
      width: 50,
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Today’s Average Risk Rank',
      dataIndex: 'avg_rank_today',
      key: 'avg_rank_today',
      width: 50,
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Facility Risk Rank',
      dataIndex: 'facility_risk_rank',
      key: 'facility_risk_rank',
      width: 50,
      ellipsis: true,
      render: (text) => (text ? text : 'N/A')
    }
  ]
}
