import { get, patch, post } from './utils/api-client'
import { generateFilterUrl, PaginatedResponse, SortDirection } from './utils/api-utils'
import { UserFilter } from './user-service'
import { SaivaOrg } from '../types/organization-types'

namespace OrgResponse {
  export interface Item {
    name: string,
    status: string,
    address: string,
    zipcode: string,
    city: string,
    state: string,
    id: string
  }
}

export namespace OrgRequest {
  export interface Create {
    id: string,
    name: string,
    is_active: boolean,
    status: string,
    address: string | null,
    zipcode: string | null,
    city: string | null,
    state: string | null,
  }
}

// TODO add parsing

const getOrganizations = async (filter: UserFilter) : Promise<PaginatedResponse<OrgResponse.Item> | undefined> => {
  const f: UserFilter = {...filter, sort: filter.sort ? filter.sort : {property: "name", direction: SortDirection.Ascending}}
  const url = generateFilterUrl("v2/orgs", [], f)
  return await get<OrgResponse.Item>(url, { paginated: true })
}

const getOrganization = async (orgId: string) : Promise<SaivaOrg.Item | undefined> => {
  return await get<OrgResponse.Item, SaivaOrg.Item>('v2/orgs/' + orgId)
}

const updateOrganization = async (orgId: string, payload: {name?: string, address?: string, zipcode?: string, city?: string, state?: string}) : Promise<OrgResponse.Item | undefined> => {
  return await patch<OrgResponse.Item, SaivaOrg.Item>('v2/orgs/' + orgId, {payload})
}

const superuserCreateOrganization = async (payload: OrgRequest.Create) : Promise<OrgResponse.Item | undefined> => {
  return await post<OrgResponse.Item, SaivaOrg.Item>('v2/orgs', {payload})
}

const OrganizationService = {
  getOrganizations,
  getOrganization,
  updateOrganization,
  superuserCreateOrganization
}

export default OrganizationService
