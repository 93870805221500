import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Outlet } from 'react-router-dom'
import { mixpanelInstance } from '../../utils/mixpanel'
import { useUserContext } from '../../context/UserContext'
import { UserPermission } from '../../types/user-types'
import AccessControl from 'components/AccessControl/AccessControl'

export default function UsersTabs() {
  const { t } = useTranslation()
  const userContext = useUserContext()

  const handleTabChange = (label) => {
    mixpanelInstance.usersTabChange(label)
  }

  const makeTabItem = (label, url) => {
    const isActive = matchPath(url, location.pathname)
    return <Link to={url} onClick={() => handleTabChange(label)} className={`navigationTab ms-4 ${isActive ? "active" : ""}`}>{label}</Link>
  }

  return (
    <>
      <div className='row d-flex mt-3'>
        <div className='col d-flex navigationTabs'>
          {makeTabItem(t("users.tab.activeUsers"), "/users")}
          {makeTabItem(t("users.tab.inactiveUsers"), "/users/inactive")}
          <AccessControl userPermissions={[UserPermission.INVITATIONS_READ]}>
            {makeTabItem(t("users.tab.invitedUsers"), "/users/invited")}
          </AccessControl>
        </div>
      </div>
      <Outlet />
    </>
  )
}
