import { useState, useEffect } from 'react'
import FormLogic from './FormLogic'
import FacilityService from 'services/facility-service'
import { mixpanelInstance } from 'utils/mixpanel'
import { useUserContext } from 'context/UserContext'
import { SaivaFacility } from 'types/facility-types'
import { useTranslation } from 'react-i18next'
import { showSuccessToast } from 'utils'
import RegionService from 'services/region-service'

const FormApi = ({ formState, closeModal, existingFacility, isClosed }) => {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const [apiError, setApiError] = useState<any>(undefined)
  const [regions, setRegions] = useState<any>([])

  useEffect(() => {
    fetchRegions()
  }, [existingFacility, userContext.currentOrg.id])

  useEffect(() => {
    setApiError(undefined)
  }, [isClosed])

  const handleCancel = async () => {
    closeModal()
  }

  const fetchRegions = async () => {
    try {
      const regions = await RegionService.getRegions(userContext.currentOrg.id)
      if (regions)
        setRegions(
          regions.map((item) => {
            return { value: item.id, label: item.name }
          })
        )
    } catch (e) {
      setRegions([])
    }
  }

  const handleSubmit = async (data: SaivaFacility.Item) => {
    let message = {
      facility_id: existingFacility.id,
      facility_name: data.name,
      facility_status: existingFacility.status,
      facility_address: data.address,
      facility_zipcode: data.zipcode,
      facility_city: data.city,
      facility_region: data.region_id,
      facility_region_name: regions.find(
        (item) => item.value === data.region_id
      )?.label,
      facility_state: data.state,
      facility_latitude: existingFacility.latitude,
      facility_longitude: existingFacility.longitude
    }
    const submitData = {
      id: existingFacility.id,
      name: data.name,
      status: existingFacility.status,
      address: data.address,
      zipcode: data.zipcode,
      city: data.city,
      state: data.state,
      region_id: data.region_id,
      latitude: existingFacility.latitude,
      longitude: existingFacility.longitude
    }

    await FacilityService.updateFacility(
      userContext.currentOrg.id,
      submitData.id,
      submitData
    )
      .then((resp) => {
        mixpanelInstance.facilityEdit(message)
        closeModal()
        showSuccessToast(
          t('facilityForm.updateUserSuccessText', { name: resp?.name })
        )
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError(err.errorCode)
        } else {
          setApiError('genericError')
        }
        throw err
      })

    closeModal()
  }

  const defaultValues = {
    id: existingFacility?.id ?? 0,
    name: existingFacility.name ?? '',
    status: existingFacility.status ?? '',
    address: existingFacility.address ?? '',
    zipcode: existingFacility.zipcode ?? '',
    city: existingFacility.city ?? '',
    state: existingFacility.state ?? '',
    latitude: existingFacility.latitude ?? '',
    longitude: existingFacility.longitude ?? '',
    region_id: existingFacility.region_id ?? ''
  }

  return (
    <FormLogic
      defaultValues={defaultValues}
      formState={formState}
      apiError={apiError}
      onSubmit={handleSubmit}
      handleCancel={handleCancel}
      regions={regions}
    />
  )
}

export default FormApi
