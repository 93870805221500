import LoadingPill from 'assets/icons/pill-loader.gif'
import styles from './LoadingAnimation.module.scss'


export default function LoadingAnimation() {
  return (
    <div className={styles.orgPopOverLoading}>
      <div style={{ width: '100%' }}>
        <img
          src={LoadingPill}
          width={'48px'}
          style={{ marginBottom: '24px' }}
        ></img>
        <div>Loading.... Please wait</div>
      </div>
    </div>
  )
}
