import { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TopNavigation from './components/UI/TopNavigation'
import Layout from './components/UI/Layout'
import SaivaTabs from './components/UI/Tabs'
import type { TabsProps } from 'antd'
import { Button } from 'antd'
import OverviewApiCards from './views/OverviewCards/ApiCards'
import VitalsApiCards from './views/VitalsCards/ApiCards'
import RiskApiCards from './views/RiskCards/ApiCards'
import ResidentService from '../../services/resident-service'
import { OrgProductFeature } from 'types/user-types'
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation'
import { ReactComponent as InfoIcon } from '../../assets/icons/empty-data.svg'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { showErrorToast } from 'utils'
import styles from './Resident.module.scss'
import { useUserContext } from 'context/UserContext'
import {getFeatureLabel} from 'utils/helper'
import { mixpanelInstance } from 'utils/mixpanel'

const Resident = () => {
  const [resident, setResident] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [activeQM, setActiveQM] = useState<OrgProductFeature>(
    OrgProductFeature.MODEL_UPT
  )
  const [apiError, setApiError] = useState<any>(undefined)
  const [orgId, setOrgId] = useState<any>(undefined)
  const { residentId } = useParams()
  const { t } = useTranslation()
  const userContext = useUserContext()
  const navigate = useNavigate()

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let query = useQuery()
  const report = query.get('report') ? query.get('report') : ''

  useEffect(() => {
    if (residentId) {
      const decodedId = JSON.parse(atob(residentId))
      fetchResident(decodedId)
      setOrgId(decodedId.org_id)
      setActiveQM(decodedId.quality_measure)
    }
    setApiError(undefined)
  }, [])

  useEffect(() => {
    if (orgId && orgId !== userContext.currentOrg.id) {
      navigate('/reports?error=usernotpartorganization', { replace: true })
    }
  }, [userContext.currentOrg.id])

  const handleChangeQM = (e) => {
    if (residentId) {
      const decodedId = JSON.parse(atob(residentId))
      fetchResident({ ...decodedId, quality_measure: e })
      setActiveQM(e)
    }
  }

  const fetchResident = async (data) => {
    setLoading(true)
    const filter = {
      quality_measure: [data.quality_measure],
      group_id: data.group_id
    }
    await ResidentService.getResident(
      data.org_id,
      data.facility_id,
      data.patient_id,
      filter
    )
      .then((res: any) => {
        setResident(res)
        setLoading(false)
        mixpanelInstance.openResident(res.overview.resident_info, data.quality_measure)
      })
      .catch((err) => {
        setLoading(false)
        setResident(undefined)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode, {model_name: getFeatureLabel(activeQM).label}))
        } else {
          showErrorToast(t(err.errorCode, {activeQM}))
        }
        throw err
      })
  }

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('residentPage.tabs.overview'),
      children: <OverviewApiCards type={'overview'} data={resident} />
    },
    {
      key: '2',
      label: t('residentPage.tabs.riskAnalysis'),
      children: (
        <RiskApiCards
          type={'overview'}
          data={{ ...resident, quality_measure: activeQM }}
        />
      )
    }
    // {
    //   key: '3',
    //   label: 'Vitals',
    //   children: <VitalsApiCards type={'overview'}/>,
    // }
  ]
  return (
    <Layout>
      <>
        {report && <Button
          className={styles.actionButton}
          onClick={() => navigate('' + report)}
        >
          <ArrowIcon /> {t('residentPage.tabs.backToReports')}
        </Button>}
        {!loading ? (
          <>
            {resident ? (
              <>
                <TopNavigation
                  data={{
                    ...resident.overview.resident_info,
                    current_rank_indicator:
                      resident.risk_analysis.overview.current_rank_indicator,
                    rank_yesterday:
                      resident.risk_analysis.overview.rank_yesterday
                  }}
                  onChangeQM={(e) => {
                    handleChangeQM(e)
                  }}
                  activeQM={activeQM}
                />
                <SaivaTabs items={items} />
              </>
            ) : (
              <h5 className={styles.noData}>
                <EmptyDataIcon style={{marginBottom: '6px'}}/><br />
                {t('dashboard.cards.no-card-value')}
              </h5>
            )}{' '}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </>
    </Layout>
  )
}

export default Resident
