import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, matchPath, Outlet } from 'react-router-dom'
import { UserPermission, OrgProductFeature } from '../../../types/user-types'
import { useUserContext } from '../../../context/UserContext'
import AccessControl from 'components/AccessControl/AccessControl'

export default function AdminOrgSettingsTabs() {

  const userContext = useUserContext()
  const { t } = useTranslation()

  const makeTabItem = (label, url) => {
    const isActive = matchPath(url, location.pathname)
    return <Link to={url} className={`navigationTab ${isActive ? "active" : ""}`}>{label}</Link>
  }

  return (
    <>
      <div className='row d-flex mt-3'>
        <div className='col d-flex navigationTabs'>
          {makeTabItem(t("settings.tab.generalInfo"), "/settings")}
          <AccessControl
            userPermissions={[UserPermission.ORGANIZATION_BRANDING_READ_WRITE]}
            productFeatures={[OrgProductFeature.ORGANIZATIN_BRANDING]}
          >
            {makeTabItem(t("settings.tab.branding"), "/settings/branding")}
          </AccessControl>
          <AccessControl userPermissions={[UserPermission.ORGANIZATIONS_EDIT]}>
            {makeTabItem(t("settings.tab.clinical"), "/settings/clinical")}
          </AccessControl>
        </div>
      </div>
      <Outlet />
    </>
  )
}
