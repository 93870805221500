import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from 'react'
import { validateValue, ValidatorTypes } from 'utils/validation'
import { RecursivePartial } from '../types/utlis/utils'
import { deepEqual } from '../utils'


interface IProps {
	children: ReactNode
	defaultValue?: any
}

interface IFormContext {
	errors: Map<string, string>
	setErrors: (value: (prev: Map<string, string>) => Map<string, string>) => void
	isDirty: boolean,
	isValid: () => boolean,
	values: {[key: string]: any} | undefined,
	setValue<T>(value: RecursivePartial<T> | undefined): void
	updateValue<T = any>(value: RecursivePartial<T>): void
	getValue(id: string | number | symbol) : any | undefined
	getValues<T>() : Partial<T> | undefined
	getBaseValues<T>() : Partial<T>
	clear(): void
	addValidationType: (id: string, validation: ValidatorTypes[] | undefined) => void,
	resetBase(): void
	discardChanges(): void
	clearErrors(): void
}

export function useFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useFormContext must be used within a FormProvider');
	}
	return context;
}

const FormContext = createContext<IFormContext>(null!)

const FormProvider: FC<IProps> = ({children, defaultValue}: IProps) => {

	const [values, setValues] = useState<{[key: string]: any} | undefined>(undefined)
	const [baseValues, setBaseValues] = useState<{[key: string]: any} | undefined>(undefined)
	const [validators, setValidators] = useState<Map<string, ValidatorTypes[]>>(new Map())
	const [errors, setErrors] = useState<Map<string, string>>(new Map())

	const isValid = () : boolean => {
		let response = true
		for (let [key, value] of validators) {
			const item = values ? values[key] : undefined
			if(value) {
				const {isValid, error} = validateValue(item, value)
				if(!isValid) {
					response = false
					setErrors(prev => {
						return new Map(prev.set(key, error))
					})
				}
			}
		}
		return response
	}

	const isDirty = useMemo(() => {
		const removeEmpty = (obj) => {
			if(!obj) return
			let copy = JSON.parse(JSON.stringify(obj));
			return copy;
		}
		return !deepEqual(removeEmpty(baseValues), removeEmpty(values))
	}, [baseValues, values])

	function setValue<T>(value: RecursivePartial<T>) : void {
		setValues(value)
		setBaseValues(value)
	}

	const updateValueErrors = (id, value) => {
		if(errors.has(id)) {
			const vals = validators.get(id)
			if(vals) {
				const {isValid, error} = validateValue(value[id], vals)
				if(!isValid) {
					setErrors(prev => {
						return new Map(prev.set(id, error))
					})
				} else {
					setErrors(new Map())
				}
			}
		}
	}

	const compareObjects = (prev, value) => {
		const updateObject = (old: any | undefined, obj: any | undefined) => {
			let copy = old ?  JSON.parse(JSON.stringify(old)) : {};
			for (let i in obj) {
				if (typeof obj[i] === "object" && !Array.isArray(obj[i]) && typeof copy[i] === "object") {
					const value = updateObject(copy[i], obj[i]);
					if(value) copy[i] = value
				}
				else {
					if(obj[i] !== undefined && obj[i] !== null && obj[i] !== "") copy[i] = obj[i];
					else if(copy[i]) delete copy[i]
				}
			}
			return copy && Object.keys(copy).length > 0 ? copy : undefined
		};
		const obj = updateObject(prev, value)
		return obj && Object.keys(obj).length > 0 ? obj : undefined
	}

	function updateValue<T>(value: RecursivePartial<T> | undefined) : void {
		if(!value) return undefined
		Object.keys(value).forEach(id => {
			setValues(prev => compareObjects(prev, value))
			updateValueErrors(id, value)
		})
	}

	function getValue(id: string | number | symbol) : any | undefined {
		if(typeof id === "symbol") return undefined
		if(values) return values[id]
	}

	function getValues<T>() : Partial<T> {
		return values as Partial<T>
	}

	function getBaseValues<T>() : Partial<T> {
		return baseValues as Partial<T>
	}

	const clear = () => {
		setErrors(new Map())
		setValues(undefined)
		setBaseValues(undefined)
	}

	const addValidationType = (id: string, validation: ValidatorTypes[] | undefined) => {
		if(!validation) return
		setValidators(prev => {
			return prev.set(id, validation)
		})
	}

	const resetBase = () => {
		setBaseValues(values)
	}

	const discardChanges = () => {
		setValues(baseValues)
	}

	const clearErrors = () => {
		setErrors(new Map())
	}

	return (
		<FormContext.Provider value={{
			errors,
			setErrors,
			isDirty,
			isValid,
			values,
			clear,
			getValue,
			getValues,
			getBaseValues,
			setValue,
			updateValue,
			addValidationType,
			resetBase,
			discardChanges,
			clearErrors
		}}>
			{children}
		</FormContext.Provider>
	)
}

export default FormProvider
