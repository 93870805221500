import { del, get, patch, post } from 'services/utils/api-client'
import { generateFilterUrl } from 'services/utils/api-utils'
import { AuthType, IntegrationType, SaivaIntegration, StatusType } from '../types/integration-types'

namespace IntegrationResponse {

  export interface CatalogItem {
    id: string,
    organization_id: string,
    status:  "connecting" | "connected" | "updating" | "disconnecting" | "disconnected" | "unavailable"
    label: string,
    description: string,
    short_description: string,
    user_guide_url?: string,
    is_enabled: boolean,
    icon?: string,
    values?: SFTP.Data | CustomerApi.Data | Webhook.Data
  }

  export interface AllIntegrations {
    active_integrations: CatalogItem[],
    inactive_integrations: CatalogItem[]
  }

  export interface Status {
    status: "in_progress" | "success" | "failed",
    action: "connect" | "update" | "disconnect" | "test"
    files_requested: 0,
    files_uploaded: 0,
    error_code: string
  }

  interface DataBase {
    is_enabled: boolean,
    status?: string,
  }

  export namespace SFTP {
    export interface Data extends DataBase{
      hostname: string,
      username: string,
      auth_option: string,
      public_key_hash: string,
      file_format: string,
      report_types: string[],
      facility_ids: number[],
      risk_list_length_ratio: number,
      region_ids: number[]
    }
  }

  export namespace CustomerApi {
    export interface Data extends DataBase{
      api_tokens: Token[]
    }

    export interface Token {
      id: 0,
      name: string,
      scopes: ["scope_download_daily_risk_report"],
      api_token: string
    }
  }

  export namespace Webhook {
    export interface Data extends DataBase{
      hostname?: string,
      username?: string,
      auth_option?: string,
      public_key_hash?: string,
      file_format?: string,
      report_types?: string[],
      facility_ids?: number[],
      region_ids: number[],
      risk_list_length_ratio?: number,
      api_tokens?: Token[]
    }

    export interface Token {
      id: 0,
      name: string,
      scopes: ["scope_download_daily_risk_report"],
      api_token: string
    }
  }
}

export namespace IntegrationRequest {

  export namespace SFTP {
    export interface Create {
      file_format: string,
      report_types: string[],
      risk_list_length_ratio: number,
      facility_ids: number[],
      report_type?: string,
      password?: string,
      public_key?: string,
    }

    export interface Update extends Partial<Create> {
      [x: string]: any
      is_enabled?: boolean,

      file_format?: string,
      report_types?: string[],
      report_type?: string, //
      facility_ids?: number[],
      region_ids?: number[],
      password?: string,
      public_key?: string,
      risk_list_length_ratio?: number
    }
  }

  export namespace CustomerApi {
    export interface Update {
      is_enabled?: boolean,
    }

    export namespace Token {
      export interface Create {
        name: string,
        scopes: string[],
      }
    }
  }

  export namespace Webhook {
    export interface Create {
      file_format?: string,
      report_types?: string[],
      facility_ids?: number[],
      region_ids: number[],
      password?: string,
      public_key?: string,
      risk_list_length_ratio?: number,
    }

    export namespace Token {
      export interface Create {
        name?: string,
        scope?: string[],
      }
    }

    export interface Update extends Partial<Create> {
      is_enabled?: boolean,
    }
  }

  export type Create<T> =
    T extends IntegrationType.SFTP_EXPORT ? IntegrationRequest.SFTP.Create :
    T extends IntegrationType.WEBHOOK ? IntegrationRequest.Webhook.Create : undefined

  export type Update<T> =
    T extends IntegrationType.SFTP_EXPORT ? IntegrationRequest.SFTP.Update :
    T extends IntegrationType.CUSTOMER_API ? IntegrationRequest.CustomerApi.Update : 
    T extends IntegrationType.WEBHOOK ? IntegrationRequest.Webhook.Update : undefined
}

const parseIntegration = {
  Values: (data: IntegrationResponse.SFTP.Data | IntegrationResponse.CustomerApi.Data | IntegrationResponse.Webhook.Data |undefined) : SaivaIntegration.SFTP.Data | SaivaIntegration.CustomerApi.Data | SaivaIntegration.Webhook.Data | undefined => {
    if(!data) return undefined
    {/*@ts-ignore*/}
    if("api_tokens" in data) {
       {/*@ts-ignore*/}
      return parseIntegration.CustomerApi.Data(data)
    } else {
       {/*@ts-ignore*/}
      return parseIntegration.SFTP.Data(data)
    }
  },
  CatalogItem: (response: IntegrationResponse.CatalogItem, isActive: boolean) : SaivaIntegration.CatalogItem => {
    const values = parseIntegration.Values(response.values)
    return {
      id: response.id as IntegrationType,
      label: response.label,
      description: response.description,
      shortDescription: response.short_description,
      userGuideUrl: response.user_guide_url,
      isEnabled: response.is_enabled,
      icon: response.icon,
      statusBase: response.status as StatusType,
      isActive,
      values: values ? {...values, isEnabled: response.is_enabled} : undefined
    }
  },
  AllIntegrations: (response: IntegrationResponse.AllIntegrations) : SaivaIntegration.AllIntegrations => {
    const integrations: SaivaIntegration.AllIntegrations = []
    integrations.push(...response.active_integrations.map((i) => parseIntegration.CatalogItem(i, true)))
    integrations.push(...response.inactive_integrations.map((i) => parseIntegration.CatalogItem(i, false)))
    return integrations
  },
  Status: (response: IntegrationResponse.Status) : SaivaIntegration.Status => {
    const value: SaivaIntegration.Status = {
      status: response.status as SaivaIntegration.StatusOptions,
      action: response.action as SaivaIntegration.ActionOptions
    }
    if(response.files_requested) value.filesRequested = response.files_requested
    if(response.files_uploaded !== null && response.files_uploaded !== undefined) value.filesUploaded = response.files_uploaded
    if(response.error_code) value.errorCode = response.error_code
    return value
  },
  SFTP: {
    Data: (response: IntegrationResponse.SFTP.Data) : SaivaIntegration.SFTP.Data => {
      const value: SaivaIntegration.SFTP.Data = {
        isEnabled: response.is_enabled
      }
      if(response.auth_option) value.authOption = response.auth_option as AuthType
      if(response.file_format) value.fileFormat = response.file_format
      if(response.username) value.username = response.username
      if(response.hostname) value.hostname = response.hostname
      if(response.facility_ids) value.facilityIds = response.facility_ids
      if(response.report_types) value.reportTypes = response.report_types
      if(response.public_key_hash) value.publicKeyHash = response.public_key_hash
      if(response.status) value.status = response.status as StatusType
      if(response.risk_list_length_ratio) value.riskListLengthRatio = response.risk_list_length_ratio
      return value
    },
  },
  CustomerApi: {
    Data: (response: IntegrationResponse.CustomerApi.Data) : SaivaIntegration.CustomerApi.Data => {
      return {
        isEnabled: response.is_enabled,
        apiTokens: response.api_tokens.map(parseIntegration.CustomerApi.Token)
      }
    },
    Token: (response: IntegrationResponse.CustomerApi.Token) : SaivaIntegration.CustomerApi.Token => {
      return {
        id: response.id,
        name: response.name,
        scopes: response.scopes.map(scope => scope as SaivaIntegration.CustomerApi.Scopes),
        apiToken: response.api_token
      }
    }
  },
  Webhook: {
    Data: (response: any) => {
      const value = {
        name: response.name,
        endpoints: response.endpoints.map(parseIntegration.Webhook.endpoint),

        authOption: response.auth_option,
        fileFormat: response.file_format,
        username: response.username,
        hostname: response.hostname,
        facility_ids: response.facility_ids,
        region_ids: response.region_ids,
        report_types: response.report_types,
        publicKeyHash: response.public_key_hash,
        status: response.status as StatusType,
        riskListLengthRatio: response.risk_list_length_ratio
      };

      return value
    },
    endpoint: (response: any) => {
      return {
        id: response.id,
        client_endpoint_url: response.client_endpoint_url,
        facility_ids: response.facility_ids,
        region_ids: response.region_ids,
        name: response.name,
        quality_measure: response.quality_measure,
        report_type: response.report_type,
        secret_key: response.secret_key,
        status: response.status
      }
    }
  }
}

// SHARED ENDPOINTS

const getAllIntegrations = async (orgId: string) : Promise<SaivaIntegration.AllIntegrations | undefined> => {
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations", [{id: "{org_id}", value: orgId}])
  const int = await get<IntegrationResponse.AllIntegrations, SaivaIntegration.AllIntegrations>(url, { parser: parseIntegration.AllIntegrations})
  return int
}


async function getIntegration(orgId: string, integrationId: IntegrationType) : Promise<SaivaIntegration.SFTP.Data | SaivaIntegration.CustomerApi.Data | undefined> {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}", params)
  if(integrationId === IntegrationType.SFTP_EXPORT) return await get<IntegrationResponse.SFTP.Data, SaivaIntegration.SFTP.Data>(url, { parser: parseIntegration.SFTP.Data})
  if(integrationId === IntegrationType.CUSTOMER_API) return await get<IntegrationResponse.CustomerApi.Data, SaivaIntegration.CustomerApi.Data>(url, { parser: parseIntegration.CustomerApi.Data})
  if(integrationId === IntegrationType.WEBHOOK) return await get(url)
}


async function connectIntegration(orgId: string, integrationId: IntegrationType, payload?: IntegrationRequest.Create<IntegrationType.SFTP_EXPORT>) : Promise<SaivaIntegration.SFTP.Data | SaivaIntegration.CustomerApi.Data | undefined> {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}", params)
  if(integrationId === IntegrationType.SFTP_EXPORT) return await post<IntegrationResponse.SFTP.Data, SaivaIntegration.SFTP.Data>(url, { parser: parseIntegration.SFTP.Data, payload: {...payload, is_enabled: true}})
  if(integrationId === IntegrationType.CUSTOMER_API) return await post<IntegrationResponse.CustomerApi.Data, SaivaIntegration.CustomerApi.Data>(url, { parser: parseIntegration.CustomerApi.Data, payload: {...payload, is_enabled: true}})
  //@ts-ignore
  if(integrationId === IntegrationType.WEBHOOK) return await post(url)
}


async function updateIntegration(orgId: string, integrationId: IntegrationType, payload: IntegrationRequest.Update<IntegrationType.SFTP_EXPORT | IntegrationType.CUSTOMER_API>) : Promise<SaivaIntegration.SFTP.Data | SaivaIntegration.CustomerApi.Data | undefined> {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}", params)
  if(integrationId === IntegrationType.SFTP_EXPORT) return await patch<IntegrationResponse.SFTP.Data, SaivaIntegration.SFTP.Data>(url, { parser: parseIntegration.SFTP.Data, payload})
  if(integrationId === IntegrationType.CUSTOMER_API) return await patch<IntegrationResponse.CustomerApi.Data, SaivaIntegration.CustomerApi.Data>(url, { parser: parseIntegration.CustomerApi.Data, payload})
  if(integrationId === IntegrationType.WEBHOOK) return await patch(url)
}

const deleteIntegration = async (orgId: string, integrationId: string) => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}", params)
  return await del(url)
}

// SFTP INTEGRATION ENDPOINTS

const getIntegrationStatus = async (orgId: string, integrationId: string) : Promise<SaivaIntegration.Status | undefined> => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/status", params)
  return await get<IntegrationResponse.Status, SaivaIntegration.Status>(url, { parser: parseIntegration.Status})
}

const testIntegration = async (orgId: string, integrationId: string) : Promise<SaivaIntegration.Status | undefined> => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/test", params)
  return await post<IntegrationResponse.Status, SaivaIntegration.Status>(url, { parser: parseIntegration.Status})
}

const getIntegrationTestStatus = async (orgId: string, integrationId: string) : Promise<SaivaIntegration.Status | undefined> => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: integrationId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/test/status", params)
  return await get<IntegrationResponse.Status, SaivaIntegration.Status>(url, { parser: parseIntegration.Status})
}

// CUSTOMER API ENDPOINTS

const CustomerApi = {
  generateAPIToken: async (orgId: string, payload: IntegrationRequest.CustomerApi.Token.Create) : Promise<SaivaIntegration.CustomerApi.Token | undefined> => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: IntegrationType.CUSTOMER_API}]
    const url = generateFilterUrl(`/v2/orgs/{org_id}/integrations/{integration_id}/api_token`, params)
    return await post<IntegrationResponse.CustomerApi.Token, SaivaIntegration.CustomerApi.Token>(url, { parser: parseIntegration.CustomerApi.Token, payload})
  },
  updateAPIToken: async (orgId: string, apiTokenId: number, payload: IntegrationRequest.CustomerApi.Token.Create) : Promise<SaivaIntegration.CustomerApi.Token | undefined> => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: IntegrationType.CUSTOMER_API}, {id: "{api_token_id}", value: apiTokenId.toString()}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/api_token/{api_token_id}", params)
    return await patch<IntegrationResponse.CustomerApi.Token, SaivaIntegration.CustomerApi.Token>(url, { parser: parseIntegration.CustomerApi.Token, payload})
  },
  revokeAPIToken: async (orgId: string, apiTokenId: number) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: IntegrationType.CUSTOMER_API}, {id: "{api_token_id}", value: apiTokenId.toString()}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/api_token/{api_token_id}", params)
    return await del(url)
  },
  regenerateAPIToken: async (orgId: string, apiTokenId: number) : Promise<SaivaIntegration.CustomerApi.Token | undefined> => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{integration_id}", value: IntegrationType.CUSTOMER_API}, {id: "{api_token_id}", value: apiTokenId.toString()}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/{integration_id}/api_token/{api_token_id}/regenerate", params)
    return await post<IntegrationResponse.CustomerApi.Token, SaivaIntegration.CustomerApi.Token>(url, { parser: parseIntegration.CustomerApi.Token})
  },
}

// WEBHOOK API ENDPOINTS

const WebhookAPI = {
  getWebhookEndpointInfo: async (orgId: string, webhookId) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}", params)
    return await get(url, { parser: parseIntegration.Webhook.endpoint})
  },

  getWebhookIntegrationInfo: async (orgId: string) => {
    const params = [{id: "{org_id}", value: orgId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook", params)
    return await get(url)
  },

  createWebhookEndpoint: async (orgId: string, payload) => {
    const params = [{id: "{org_id}", value: orgId}]
    const url = generateFilterUrl("v2/orgs/{org_id}/integrations/webhook/endpoints", params)
    return await post(url, {payload: {...payload}})
  },

  connectWebhookIntegration: async (orgId: string) => {
    const params = [{id: "{org_id}", value: orgId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook", params)
    return await post(url)
  },

  removeWebhookEndpoint: async (orgId: string, webhookId) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}", params)
    return await del(url)
  },

  regenerateWebhookKey: async (orgId: string, webhookId) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}/regenerate_secret_key", params)
    return await post(url)
  },

  testWebhookEndpoint: async (orgId: string, webhookId) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}/test", params)
    return await post(url)
  },

  updateWebhookEndpoint: async (orgId: string, webhookId, payload) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}", params)
    return await patch(url, {payload: {...payload}})
  },

  testWebhookEndpointStatus: async (orgId: string, webhookId) => {
    const params = [{id: "{org_id}", value: orgId}, {id: "{webhook_integration_endpoint_id}", value: webhookId}]
    const url = generateFilterUrl("/v2/orgs/{org_id}/integrations/webhook/endpoints/{webhook_integration_endpoint_id}/status", params)
    return await get(url)
  },
}

const IntegrationService = {
  getIntegration,
  getAllIntegrations,
  createIntegration: connectIntegration,
  updateIntegration,
  deleteIntegration,
  getIntegrationStatus,
  testIntegration,
  getIntegrationTestStatus,
  CustomerApi,
  WebhookAPI
}

export default IntegrationService
