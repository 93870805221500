import React from 'react';
import Gradient from 'assets/icons/Gradient.gif';

type Props = {
  showSpinner: boolean,
  onClick?: () => void,
  className?: string,
  size?: string,
  children?: React.ReactNode,
  disabled?: boolean
}
export default function ButtonWithSpinner(props: Props) {
  return (
    <button type="submit" className={props.className} onClick={props.onClick} disabled={props.disabled}>
      {props.showSpinner && <img className="me-2" src={Gradient} width={props.size} height={props.size} />}
      {props.children}
    </button>
  )
}
