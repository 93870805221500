import React from 'react'

import {ReactComponent as DownloadIcon} from '../assets/icons/download.svg'

const DesignTheme = () => {

  return (
    <div>
      <button className={"primary-button"}><DownloadIcon /> Download</button>
      <button className={"secondary-button"}><DownloadIcon /> Download</button>
      <button className={"text-button"}><DownloadIcon /> Download</button>
      <button className={"text-button-small"}><DownloadIcon /> Download</button>
      <button className={"text-button"}><DownloadIcon /> Download</button>
      <button className={"text-button"}><DownloadIcon /> Download</button>
    </div>
  )
}

export default DesignTheme
