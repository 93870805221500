import { RequestFilter } from 'services/utils/api-utils'
import { OrgProductFeature } from './user-types'

export interface ScheduleFilter extends RequestFilter {
  file_format?: string[]
  schedule_type?: string[]
  categories?: string[]
  report_type?: string[]
  quality_measure?: OrgProductFeature[]
  show_all?: boolean
  scheduled_by_id?: string[]
}

export namespace Schedules {
  export enum ReportTypes {
    CROSS_FACILITY_REPORT = 'cross_facility_risk_report',
    HIT_MISS_REPORT = 'prediction_hit_miss_report',
    //OPEN_RATE_REPORT = 'open_rates',
    PREDICTIONS_PERFORMANCE_REPORT = 'prediction_performance_report',
    UNPLANNED_TRANSFERS = 'unplanned_transfers',
    VISITS_REPORT = 'resident_followup',
    VITALS_REPORT = 'resident_abnormal_vitals',
    PATIENT_DAYS_REPORT = 'patient_days_report',
    USER_ENGAGEMENT_REPORT = 'user_engagement_report',
    MOST_VIEWED_REPORT = 'viewed_reports',
    DEFAULT = 'default'
  }

  export interface Config {
    day_of_week: string[]
    time_of_day: string
    time_zone: string
    schedule_type: string
    is_enabled: boolean
    file_format: string
    week_of_month: string
    name: string
    user_id: string
    assignee_name?: string
  }

  export interface ConfigEdit {
    day_of_week?: string[]
    time_of_day?: string
    time_zone?: string
    schedule_type?: string
    is_enabled?: boolean
    file_format?: string
    week_of_month?: string
    name?: string
    user_id?: string
    assignee_name?: string
  }

  export interface ReportConfig {
    report_type: ReportTypes
    params: any
  }

  export interface Item {
    schedule_config: Config
    report_config: ReportConfig
    id: string
  }

  export interface TableItem {
    schedule_config: Config
    report_config: ReportConfig
    id: string
  }

  export interface scheduledByUsers {
    scheduled_by_users: {
      label: string
      id: number
    }[]
  }

  export interface Create {
    schedule_config: Config
    report_config: ReportConfig
  }
}
