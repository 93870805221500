import { get, post } from './utils/api-client'
import { generateFilterUrl, RequestFilter } from './utils/api-utils'
import { OrgProductFeature } from '../types/user-types'

export interface ResidentFilter extends RequestFilter {
  group_id?: string
  quality_measure?: OrgProductFeature[]
  patient_id?: string
}

export interface RiskScoreChartFilter extends RequestFilter {
  group_id?: string
  quality_measure?: OrgProductFeature[]
  date_start?: string
  date_end?: string
}

const getResident = async (
  orgId: string,
  facility_id: string,
  patient_id: string,
  filter?: ResidentFilter
): Promise<any> => {
  const url = generateFilterUrl(
    'v2/orgs/{org_id}/facilities/{facility_id}/residents/{patient_id}',
    [
      { id: '{org_id}', value: orgId },
      {
        id: '{facility_id}',
        value: facility_id
      },
      {
        id: '{patient_id}',
        value: patient_id
      }
    ],
    filter
  )
  return await get<any>(url)
}

const getRiskScoreChart = async (
  orgId: string,
  facility_id: string,
  patient_id: string,
  filter?: RiskScoreChartFilter
): Promise<any> => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/facilities/{facility_id}/residents/{patient_id}/risk_score_chart',
    [
      { id: '{org_id}', value: orgId },
      {
        id: '{facility_id}',
        value: facility_id
      },
      {
        id: '{patient_id}',
        value: patient_id
      }
    ],
    filter
  )
  return await get<any>(url)
}

const postShareResident = async (
  orgId: string,
  customers_facility_identifier: string,
  filter?: ResidentFilter,
  payload?: any
): Promise<any> => {
  const url = generateFilterUrl(
    '/api/v1.0/sharing/org/{org_id}/facility/{customers_facility_identifier}/',
    [
      { id: '{org_id}', value: orgId },
      {
        id: '{customers_facility_identifier}',
        value: customers_facility_identifier
      }
    ],
    filter
  )
  return await post<any>(url, { payload })
}

const getResidentSharingUsers = async (
  orgId: string,
  customers_facility_identifier: string
): Promise<any> => {
  const url = generateFilterUrl(
    '/api/v1.0/sharing/org/{org_id}/facility/{customers_facility_identifier}/users/',
    [
      { id: '{org_id}', value: orgId },
      {
        id: '{customers_facility_identifier}',
        value: customers_facility_identifier
      }
    ]
  )
  return await get<any>(url)
}

const ResidentService = {
  getResident,
  getRiskScoreChart,
  postShareResident,
  getResidentSharingUsers
}

export default ResidentService
