import { get } from './utils/api-client'
import { generateFilterUrl } from './utils/api-utils'
import { Regions } from 'types/region-types'

const getRegions = async (
  orgId: string,
  controller?: AbortController
): Promise<Regions.Item[] | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/regions/', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<any>(url, { signal: controller?.signal })
}

const getRegion = async (
  orgId: string,
  region_id: string,
): Promise<Regions.Item | undefined> => {
  const url = generateFilterUrl('v2/orgs/{org_id}/regions/{region_id}', [
    { id: '{org_id}', value: orgId },
    { id: '{scheduled_report_id}', value: region_id }
  ])
  return await get<Regions.Item, Regions.Item>(url)
}

const RegionService = {
  getRegions,
  getRegion
}

export default RegionService
