import { useEffect, useState } from 'react'
import { useUserContext } from '../../../context/UserContext'
import { OrgProductFeature, UserPermission } from '../../../types/user-types'

import styles from './Settings.module.scss'
import UploadModal, { UploadType } from '../../../components/UploadModal/UploadModal'
import apiClient from 'services/utils/api-client'
import { ReactComponent as Checkbox } from 'assets/icons/check.svg'
import LoadingSpinner from 'components/LoadingSpinner'
import Input from '../../../components/Form/Input'
import FormProvider, { useFormContext } from '../../../context/FormProvider'
import SettingsService from '../../../services/settings-service'
import { validateValue } from '../../../utils/validation'
import { showErrorToast, showSuccessToast } from '../../../utils'
import { mixpanelInstance } from '../../../utils/mixpanel'
import { useTranslation } from "react-i18next";
import AccessControl from 'components/AccessControl/AccessControl'

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE);

const EmailBranding = () => {
  const {t} = useTranslation();

  const userContext = useUserContext()
  const formContext = useFormContext()

  const [isFocused, setFocused] = useState<boolean>(false)
  const [before, setBefore] = useState<string | undefined>(undefined)

  const fetchEmail = async () => {
    const res = await SettingsService.getSettings(userContext.currentOrg.id);
    if(res) {
      setBefore(res.email_from_address)
      formContext.updateValue({email: res.email_from_address})
    }
  }

  const handleCancel = () => {
    formContext.updateValue({email: before})
  }

  const handleUploadEmail = async () => {
    try {
      if (formContext.isValid()) {
        const email = formContext.getValue("email")
        setBefore(email)
        await SettingsService.updateSettings(userContext.currentOrg.id, { email_from_address: email ? email : "noreply@saiva.ai" })
        showSuccessToast(t(('settings.branding.email-successfully-updated'), 'Email successfully updated'))
      }
    } catch (e) {
      console.error(e)
      showErrorToast(t(('settings.branding.error'), 'An error occurred when updating email'))
    }
  }

  useEffect(() => {
    formContext.setValue({ email: '' })
    fetchEmail()
  }, [userContext.currentOrg]);

  return (
    <div style={{marginTop: 20}}>
      <h6 className={"subtitle description"}>{t('settings.branging.email-branding')}</h6>
      <div className={`${styles.brandingWrapper} ${styles.emailBranding}`}>
        <div className={"d-flex"}>
          <label style={{marginRight: 20, marginTop: 8, fontSize: 16, fontWeight: 500}}>Email: </label>
          <Input id={"email"} width={280} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} placeholder={"noreply@saiva.ai"} label={""} validators={[{email: true}]}/>
        </div>
        {isFocused || (before !== undefined && formContext.getValue("email") !== before) ?
          <>
            <button className={"text-button"} onClick={handleCancel} style={{margin: "0 20px"}}>{t('settings.branging.cancel')}</button>
            <button className={"primary-button-small"} onClick={handleUploadEmail}>{t('settings.branging.save-changes')}</button>
          </>
          : null }
      </div>
    </div>
  )
}

const AdminOrgBranding = () => {
  const {t} = useTranslation();
  const userContext = useUserContext()
  const [uploadModal, setUploadModal] = useState<boolean>(false)
  const [img, setImg] = useState("");
  const [spinnerIsLoading, setSpinnerIsLoading] = useState(false);

  const mixpanelEventData = {
    organisation: userContext.currentOrg.id,
    user: userContext.email
  }

  useEffect(() => {
    setImageUploadedStatus()
  }, []);

  const setImageUploadedStatus = async () => {
    try {
      setSpinnerIsLoading(true);
      const img = await SettingsService.getLogo(userContext.currentOrg.id)
      if (img?.report_logo_url) setImg(img?.report_logo_url);
      setSpinnerIsLoading(false);
    } catch (e) {
      setImg("");
    }
  }

  const handleOpenUpload = () => {
    mixpanelInstance.settingsEventHandler(mixpanelEventData, "SettingsManageLogo")
    setUploadModal(true)
  }

  const handleCloseUpload = () => {
    setImageUploadedStatus();
    setUploadModal(false)
  }

  return (
    <div id="adminOrgSettingsBranding" className='container-fluid'>
      <AccessControl 
        userPermissions={[
          UserPermission.ORGANIZATIONS_EDIT,
          UserPermission.USERS_EDIT
        ]} 
        productFeatures={[OrgProductFeature.ORGANIZATIN_BRANDING]}
      >
        <>
          <div>
            <h6 className={"subtitle description"}>{t('settings.branging.logo-branding')}</h6>
            <div className={styles.brandingWrapper}>
              <label className={"body-2-regular"}>
                {spinnerIsLoading && !img ? 
                  <LoadingSpinner width={"20px"} height={"20px"} />
                  : <Checkbox />
                } {t('settings.branging.custom-company-logo-image')}
              </label>
              <button className={"primary-button"} onClick={handleOpenUpload}>{t('settings.branging.manage-logo')}</button>
            </div>
          </div>
          <UploadModal
            title={t(('settings.branging.upload-your-company-logo'), "Upload your Company logo")}
            description={`Supported formats PNG, JPG with maximum file size ${MAX_FILE_SIZE ? MAX_FILE_SIZE / (1024 ** 2) : 0}MB`}
            type={UploadType.IMAGE}
            open={uploadModal}
            handleClose={handleCloseUpload}
          />
        </>
      </AccessControl>
      <FormProvider>
        <EmailBranding />
      </FormProvider>
    </div>
  )
}

export default AdminOrgBranding;