import { UseFormReturn } from 'react-hook-form'
import { Row, Col, Tooltip } from 'antd'
import { Controller } from 'react-hook-form'
import styles from './SRModal.module.scss'
import {
  fileTypeOpts,
  weekOptions,
  timeZoneOptions,
  repeatOptions,
  generateSendingTimeOptions,
  weekOfMonth
} from '../utils'
import { ReactComponent as TimeZone } from 'assets/icons/timezone.svg'
import { ReactComponent as Clock } from 'assets/icons/clock.svg'
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as SendIcon } from 'assets/icons/send-2.svg'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import SaivaRadioButton from 'components/SaivaForm/SaivaRadioButton/saivaRadioButton'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import { Schedules } from 'types/schedule-reports'
import SaivaToggle from 'components/SaivaForm/SaivaToggle/saivaToggle'
import { useTranslation } from 'react-i18next'
import { SaivaUser, UserPermission } from 'types/user-types'
import AccessControl from 'components/AccessControl/AccessControl'
import { useUserContext } from 'context/UserContext'

interface Props {
  form: UseFormReturn<Schedules.Config>
  onSubmit: (data: Schedules.Config) => any
  children: JSX.Element
  isEditMode: boolean
  apiError: any
  isDuplicate: boolean
  handleDelete: () => void
  dateType: string
  sendNow: () => void
  reportId: string
  loading: boolean
  users: SaivaUser.User[]
  defaultValues: Schedules.Config
}

const SRModalForm = ({
  form,
  onSubmit,
  children,
  isEditMode,
  apiError,
  isDuplicate,
  handleDelete,
  dateType,
  loading,
  users,
  defaultValues,
  sendNow
}: Props) => {
  const { t } = useTranslation()
  const { formState, handleSubmit, control, setValue, getValues } = form
  const { errors, isSubmitting, isValid, dirtyFields, isDirty } = formState
  const sendingTimeOptions = generateSendingTimeOptions()
  const UserContext = useUserContext()

  const assigneeOptionList = users.map(
    (user) =>
      ({
        label: user.name || user.email,
        value: user.id
      } as any)
  )

  const assigneeName = defaultValues.assignee_name
  const userId =  defaultValues.user_id

  const currentUser = {
    label: UserContext.name || UserContext.email,
    value: UserContext.id
  }

  const assignedUser =
    userId && userId !== UserContext.id.toString()
      ? {
          label: assigneeName,
          value: userId
        }
      : currentUser

  const assigneeOptions = [
    currentUser, assignedUser,
    ...assigneeOptionList
  ]

  const uniqueAssigneeOptions = assigneeOptions.filter(
    (item, index) =>
      assigneeOptions.findIndex((i) => i.value === item.value) === index
  )

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Row gutter={[16, 16]} align="top" style={{ marginBottom: '40px' }}>
        <Col>
          <Controller
            control={control}
            name="is_enabled"
            render={({ field }) => (
              <SaivaToggle
                value={field.value}
                name="is_enabled"
                onChange={(e) => {
                  setValue('is_enabled', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
      </Row>
      {children}
      <Row gutter={[16, 16]} align="top">
        <Col span={10}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <SaivaInput
                value={field.value}
                placeholder="Input name of the schedule"
                name="name"
                label="Schedule Name"
                errors={errors.name}
                onChange={(e) => {
                  setValue('name', e.target.value, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
        <AccessControl
          userPermissions={[
            UserPermission.SCHEDULE_EDIT,
            UserPermission.SCHEDULE_CREATE
          ]}
        >
          <Col span={7}>
            <Controller
              control={control}
              name="user_id"
              render={({ field }) => (
                (
                  <SaivaSelect
                    value={field.value}
                    disabled={loading}
                    icon={null}
                    label={t('scheduleReport.form.label.assignee')}
                    options={uniqueAssigneeOptions}
                    multiselect={false}
                    placeholder={t('scheduleReport.form.label.assignee')}
                    errors={errors.user_id}
                    defaultValue={currentUser.value || {}}
                    onChange={(e) => {
                      setValue('user_id', e, { shouldDirty: true })
                    }}
                  />
                )
              )}
            />
          </Col>
        </AccessControl>
        <Col span={7}>
          <Controller
            control={control}
            name="file_format"
            render={({ field }) => (
              <SaivaRadioButton
                value={field.value}
                name="file_format"
                width={'60px'}
                height={24}
                inputType="radio"
                returnType="string"
                label={t('scheduleReport.form.label.fileFormat')}
                values={fileTypeOpts}
                errors={errors.file_format}
                onChange={(e) => {
                  setValue('file_format', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
      </Row>
      <Row gutter={[16, 16]} align="top">
        <Col span={getValues('schedule_type') == 'monthly' ? 4 : 5}>
          <Controller
            control={control}
            name="schedule_type"
            render={({ field }) => (
              <SaivaSelect
                value={field.value}
                icon={null}
                label={t('scheduleReport.form.label.scheduleType')}
                options={repeatOptions}
                placeholder="Select Repeat"
                errors={errors.schedule_type}
                defaultValue={{}}
                onChange={(e) => {
                  form.trigger('schedule_type')
                  setValue('schedule_type', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
        {getValues('schedule_type') == 'monthly' && (
          <Col span={4}>
            <Controller
              control={control}
              name="week_of_month"
              render={({ field }) => (
                <SaivaSelect
                  value={field.value}
                  icon={null}
                  label={t('scheduleReport.form.label.weekOfMonth')}
                  options={weekOfMonth}
                  placeholder="Select Time"
                  errors={errors.week_of_month}
                  onChange={(e) => {
                    setValue('week_of_month', e, { shouldDirty: true })
                  }}
                />
              )}
            />
          </Col>
        )}
        <Col span={getValues('schedule_type') == 'monthly' ? 8 : 9}>
          <Controller
            control={control}
            name="day_of_week"
            render={({ field }) => (
              <SaivaRadioButton
                value={field.value}
                name="day_of_week"
                width={'calc(100%/7 - (2px * 4))'}
                height={32}
                returnType="array"
                inputType={
                  getValues('schedule_type') == 'daily' ? 'checkbox' : 'radio'
                }
                label={t('scheduleReport.form.label.dayOfWeek')}
                errors={errors.day_of_week}
                values={weekOptions}
                onChange={(e) => {
                  setValue('day_of_week', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
        <Col span={getValues('schedule_type') == 'monthly' ? 4 : 5}>
          <Controller
            control={control}
            name="time_of_day"
            render={({ field }) => (
              <SaivaSelect
                value={field.value}
                icon={<Clock />}
                label={t('scheduleReport.form.label.TimeOfDay')}
                options={sendingTimeOptions}
                placeholder="Select Time"
                errors={errors.time_of_day}
                onChange={(e) => {
                  setValue('time_of_day', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
        <Col span={getValues('schedule_type') == 'monthly' ? 4 : 5}>
          <Controller
            control={control}
            name="time_zone"
            render={({ field }) => (
              <SaivaSelect
                value={field.value}
                icon={<TimeZone />}
                label={t('scheduleReport.form.label.timeZone')}
                options={timeZoneOptions}
                placeholder="Select Time Zone"
                errors={errors.time_zone}
                onChange={(e) => {
                  setValue('time_zone', e, { shouldDirty: true })
                }}
              />
            )}
          />
        </Col>
      </Row>
      {apiError && (
        <>
          <div className={styles.error}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                gap: '8px'
              }}
            >
              <InfoIcon />
              <p>
                {t(apiError.code)}

                {apiError.details?.facility_names && (
                  <>
                    {' '}
                    {t('scheduleReport.form.error.facilityList')}
                    <Tooltip
                      title={
                        <div className={styles.tooltip}>
                          {apiError.details.facility_names.map(
                            (facility: string) => (
                              <div>{facility}</div>
                            )
                          )}
                        </div>
                      }
                      arrow={false}
                      overlayInnerStyle={{
                        background: '#112443',
                        textAlign: 'left'
                      }}
                    >
                      {apiError.details.facility_names.map(
                        (i: string, index: number) => (
                          <span key={index}>
                            {' '}
                            {i}
                            {index !==
                              apiError.details.facility_names.length - 1 && ','}
                          </span>
                        )
                      )}
                    </Tooltip>
                  </>
                )}
              </p>
            </div>
          </div>
        </>
      )}
      {isEditMode ? (
        <Row justify={'space-between'} style={{ marginTop: '32px' }}>
          <Col>
            <button className={styles.buttonClose} onClick={handleDelete}>
              <span>
                <TrashIcon />
              </span>
              {t('scheduleReport.form.button.delete')}
            </button>
          </Col>
          <Col>
            <button
              disabled={isDirty}
              className={styles.buttonText}
              onClick={sendNow}
            >
              <SendIcon style={{ padding: '2px' }} />
              {t('scheduleReport.form.action.sendNow')}
            </button>
            <button
              disabled={!isDirty}
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
            >
              {t('scheduleReport.form.action.saveChanges')}
            </button>
          </Col>
        </Row>
      ) : (
        <Row justify={'end'} style={{ marginTop: '32px' }}>
          <Col>
            <button
              disabled={isSubmitting}
              className={styles.button}
              onClick={handleSubmit(onSubmit)}
            >
              {isDuplicate
                ? t('scheduleReport.form.action.duplicate')
                : t('scheduleReport.form.action.schedule')}
            </button>
          </Col>
        </Row>
      )}
    </form>
  )
}

export default SRModalForm
