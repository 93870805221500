import { generateFilterUrl, PaginatedResponse, SortDirection } from './utils/api-utils'
import { get, patch, post } from './utils/api-client'
import { SaivaFacility } from '../types/facility-types'
import { UserFilter } from './user-service'
import { handleError } from '../utils/errorHandler'

namespace FacilityResponse {
  export interface Item {
    status: string,
    name: string,
    address: string,
    zipcode: string,
    city: string,
    state: string,
    latitude: number,
    longitude: number,
    id: number
    region_id: number
  }
}

namespace FacilityRequest {
  export interface Update {
    name: string,
    address: string,
    zipcode: string,
    city: string,
    state: string
  }
}

//TODO apply parsing
const parseFacilities = {
  Item: (response: FacilityResponse.Item) : SaivaFacility.Item => {
    return response
  }
}

const getFacilities = async (orgId: string, filter: UserFilter) : Promise<PaginatedResponse<SaivaFacility.Item> | undefined> => {
  const f: UserFilter = {...filter, sort: filter.sort ? filter.sort : {property: "name", direction: SortDirection.Ascending}}
  const url = generateFilterUrl("/v2/orgs/{org_id}/facilities", [{id: "{org_id}", value: orgId}], f)
  return await get<FacilityResponse.Item, SaivaFacility.Item>(url, {paginated: true, parser: parseFacilities.Item})
}

//TODO create new version
const getAllFacilities = async (orgId: string, controller?: AbortController): Promise<SaivaFacility.Item[] | undefined> => {
  try {
    let facilities: FacilityResponse.Item[] = []
    let count = 50000
    let params = {
      page: 1,
      page_size: 100,
    }
    while(facilities.length < count) {
      const f: UserFilter = {sort: {property: "name", direction: SortDirection.Ascending}}
      const url = generateFilterUrl("/v2/orgs/{org_id}/facilities", [{id: "{org_id}", value: orgId}], f)
      const res = await get<FacilityResponse.Item, SaivaFacility.Item>(url, {
        params: params,
        signal: controller?.signal,
        paginated: true,
        parser: parseFacilities.Item
      })
      if(!res) return
      facilities = [...facilities, ...res.items]
      count = res.total
      params = {...params, page: params.page + 1}
    }
    return facilities
  } catch (err: any) {
    handleError({id: "GetAllFacilities", error: err})
  }
}

const getFacility = async (orgId: string, facilityId: number) : Promise<SaivaFacility.Item | undefined> => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{facility_id}", value: facilityId.toString()}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/facilities/{facility_id}", params)
  return await get<FacilityResponse.Item, SaivaFacility.Item>(url, {parser: parseFacilities.Item})
}

const updateFacility = async (orgId: string, facilityId: number, payload: FacilityRequest.Update) : Promise<SaivaFacility.Item | undefined> => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{facility_id}", value: facilityId.toString()}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/facilities/{facility_id}", params)
  return await patch<FacilityResponse.Item, SaivaFacility.Item>(url, {parser: parseFacilities.Item, payload})
}

const superuserSyncFacilities = async (orgId: string) => {
  const params = [{id: "{org_id}", value: orgId}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/facilities/sync", params)
  return await post<null>(url)
}

const FacilityService = {
  getFacilities,
  getAllFacilities,
  getFacility,
  updateFacility,
  superuserSyncFacilities
}

export default FacilityService
