import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

export function notEmpty() {
    return (value: string) => {
        return value.length > 0 && value.trim().length > 0
    }
}

export function isEmail() {
    const { t } = useTranslation()
    const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/
    const regex = new RegExp(emailRegex, 'i')
    return (value: string) => {
        if(regex.test(value)) {
            return true
        } else {
            return t('validator.email')
        }
    }
}

export function minLength(min: number) {
    const { t } = useTranslation()
    return (value: string) => {
        if(value.length >= min) {
            return true
        } else {
            return t('validator.minLength', {value: min})
        }
    }
}

export function maxLength(max: number) {
    const { t } = useTranslation()
    return (value: string) => {
        if(value.length <= max) {
            return true
        } else {
            return t('validator.maxLength', {value: max})
        }
    }
}

export function alphanumeric() {
    const { t } = useTranslation()
    const pattern = /^[0-9a-zA-Z\s]{0,}$/
    return (value: string) => {
        if(value.match(pattern)) {
            return true
        } else {
            return t('validator.alphanumeric')
        }
    }
}

export function makeEmailValidator(required: boolean) {
    const { t } = useTranslation()
    const v = makeTextValidator(required, notEmpty(), minLength(6), maxLength(256), isEmail())
    return v
}

export function makeTextValidator(required: boolean, ...validators: any[]) {
    const v = validators.reduce((obj, item) => {
        const id = uuidv4()
        obj[id] = item
        return obj
    }, {})
    return {
        required: required ? t('validator.required') : false,
        validate: v
    }
}