import { useEffect, useState, useRef } from 'react'
import SaivaTable from 'components/Tables/AntdTable/AntdTable'
import { Button, Tooltip, Checkbox, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as ResizeIcon } from 'assets/icons/resize-small-vertical.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import styles from '../BasicTable.module.scss'
import { TableColumns } from './TableColumns'
import { FacilityViewColumns } from './FacilityViewColumns'
import { useTranslation } from 'react-i18next'

export interface ISaivaTableHeaderItem {
  label: string
  id: string
  type?: string
  attribute?: string
}

const editableColumns = [
  'facility_name',
  'region_name',
  'rank_movement',
  'last_transfer_date'
]

type PositionType = 'left' | 'right'

interface IBasicTableProps {
  data: any
  rowKey: string
  loading: boolean
  onSearch: (value: any) => void
  onFacilitySearch: (value: any) => void
  handleSortClick: (value: string) => void
  pagination: any
  filteredHeaders: any
  setFilteredHeaders: (value: any) => void
  onChangeTab: (key: string) => void
  activeView: string
}

export default function BasicTable({
  data,
  rowKey,
  loading,
  onSearch,
  onFacilitySearch,
  handleSortClick,
  pagination,
  filteredHeaders,
  setFilteredHeaders,
  onChangeTab,
  activeView
}: IBasicTableProps) {
  const { t } = useTranslation()
  const [filteredHeadersColumns, setFilteredHeadersColumns] = useState<any>([])
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [columns, setColumns] = useState<any>([])
  const [filteredHeadersState, setFilteredHeadersState] = useState<any>([])
  const tableRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (tableRef.current && !tableRef.current.contains(event.target)) {
        setFilteredHeadersState(filteredHeaders)
        setIsEdit(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isEdit])

  useEffect(() => {
    setFilteredHeadersColumns(
      TableColumns(sortingHeader).filter((column) => {
        return !filteredHeaders?.includes(column.key)
      })
    )
    setFilteredHeadersState(filteredHeaders)
  }, [filteredHeaders])

  useEffect(() => {
    const updateColumns = () => {
      return TableColumns(sortingHeader).map((column) => {
        const isHiddenColumn = filteredHeadersState?.includes(column.key)
        const isEditableColumn = editableColumns.includes(column.key)

        if (isEdit && isEditableColumn) {
          return {
            ...column,
            title: (
              <Checkbox
                onChange={() => handleCheck(column.key)}
                checked={isHiddenColumn}
              >
                {column.title}
              </Checkbox>
            ),
            className: isHiddenColumn && styles.hiddenColumn
          }
        }
        return column
      })
    }

    if (isEdit) {
      setColumns(updateColumns())
    } else {
      setColumns(filteredHeadersColumns)
    }
  }, [isEdit, filteredHeadersState, filteredHeadersColumns])

  const handleCheck = (checkedValue: string | number) => {
    setFilteredHeadersState((prevState) => {
      if (prevState.includes(checkedValue)) {
        return prevState.filter((item) => item !== checkedValue)
      } else {
        return [...prevState, checkedValue]
      }
    })
  }

  const sortingHeader = (title: string, sortingID: string) => (
    <>
      <Button
        type="link"
        onClick={() => handleSortClick(sortingID)}
        className={styles.sortButton}
      >
        {title}
        <ResizeIcon />
      </Button>
    </>
  )

  const Operations: Record<PositionType, React.ReactNode> = {
    left: null,
    right: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <div style={{ display: 'flex', gap: '8px' }}>
          <Tooltip
            arrow={false}
            placement="left"
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={
              activeView == 'patient_view'
                ? t('reports.crossFacilityRiskReport.table.searchMessage')
                : t('reports.crossFacilityRiskReport.table.facilitySearchMessage')
            }
          >
            <div>
              {activeView == 'patient_view' ? (
                <SaivaInput
                  key="search"
                  placeholder={t(
                    'reports.crossFacilityRiskReport.table.search.placeholder'
                  )}
                  name="search"
                  label=""
                  prefixIcon={<SearchIcon />}
                  allowClear={true}
                  onChange={(value) => onSearch(value)}
                />
              ) : (
                <SaivaInput
                  key="facilitySearch"
                  placeholder={t(
                    'reports.crossFacilityRiskReport.table.search.placeholder'
                  )}
                  name="facilitySearch"
                  label=""
                  prefixIcon={<SearchIcon />}
                  allowClear={true}
                  onChange={(value) => onFacilitySearch(value)}
                />
              )}
            </div>
          </Tooltip>
        </div>
        {activeView === 'patient_view' && (
          <div style={{ display: 'flex', gap: '8px' }}>
            {!isEdit ? (
              <Button
                onClick={() => {
                  setIsEdit(true)
                  setFilteredHeaders(filteredHeadersState)
                }}
                className={`${styles.actionButton} ${styles.actionButtonIsEdit}`}
              >
                <EditIcon />{' '}
                {t('reports.crossFacilityRiskReport.table.hide.editButton')}
              </Button>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setIsEdit(false)
                    setFilteredHeadersState(filteredHeaders)
                  }}
                  className={`${styles.actionButton} ${styles.cancelButton}`}
                >
                  {t('reports.crossFacilityRiskReport.table.hide.cancelButton')}
                </Button>
                <Button
                  onClick={() => {
                    setIsEdit(false)
                    setFilteredHeaders(filteredHeadersState)
                  }}
                  className={`${styles.actionButton} ${styles.confirmButton}`}
                >
                  {t(
                    'reports.crossFacilityRiskReport.table.hide.confirmButton'
                  )}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  function saivaTable(view: string) {
    const tablecColumns =
      view === 'patient_view' ? columns : FacilityViewColumns(sortingHeader)
    return (
      <SaivaTable
        rowSelection={undefined}
        columns={tablecColumns}
        rowClassName={undefined}
        rowKey={rowKey}
        expandable={undefined}
        data={data}
        loading={loading}
        pagination={pagination}
        onRow={undefined}
        scroll={{ x: 'max-content' }}
      />
    )
  }

  const items: TabsProps['items'] = [
    {
      key: 'patient_view',
      label: t('reports.crossFacilityRiskReport.table.patientView.label'),
      children: saivaTable('patient_view')
    },
    {
      key: 'facility_view',
      label: t('reports.crossFacilityRiskReport.table.facilityView.label'),
      children: saivaTable('facility_view')
    }
  ]

  return (
    <>
      {data && (
        <div ref={tableRef}>
          <Tabs
            activeKey={activeView}
            destroyInactiveTabPane={true}
            items={items}
            onChange={onChangeTab}
            tabBarExtraContent={Operations}
            className={styles.tabs}
          />
        </div>
      )}
    </>
  )
}
