import styles from './Labs.module.scss'
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg'
import { ReactComponent as NoDataIcon } from '../../../assets/icons/calendar-cross.svg'

interface LabsItem {
  date_info: string
  value: string
  is_critical: boolean
}

type Props = {
  children?: JSX.Element
  icon: JSX.Element
  title: string
  data: LabsItem[]
}

export default function TopNavigation({ children, icon, title, data }: Props) {
  const isCritical = data.filter((item) => item.is_critical).length > 0
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <h4>
          <span style={{ paddingRight: '10px' }}>{icon}</span>
          {title} ({data.length})
        </h4>
      </div>
      <div className={styles.content}>
        {data && data?.length > 0 ? (
          <table className={styles.table}>
            <tbody>
              {data
                .filter((item) => !item.is_critical)
                .map((item, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        className={`
                    ${styles.labItem}
                  `}
                      >
                        <td width={110} className={styles.date}>
                          {item.date_info}
                        </td>
                        <td className={styles.message}>{item.value}</td>
                      </tr>
                    </>
                  )
                })}
              {isCritical && (
                <div className={styles.errorWrapper}>
                  <span className={styles.error}>
                    <InfoIcon style={{ marginRight: '6px' }} /> Critical Lab
                    Alert:
                  </span>
                </div>
              )}
              {data
                .filter((item) => item.is_critical)
                .map((item, index) => {
                  return (
                    <>
                      <tr
                        key={index}
                        className={`
                    ${styles.labItem}
                    ${styles.critical}
                  `}
                      >
                        <td width={110} className={styles.date}>
                          {item.date_info}
                        </td>
                        <td className={styles.message}>{item.value}</td>
                      </tr>
                    </>
                  )
                })}
            </tbody>
          </table>
        ) : (
          <>
            <div className={styles.noData}>
              <NoDataIcon />
              No {title}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
