import React, { createRef, useEffect, useRef, useState } from 'react'
import SaivaModal from '../saivaModal'
import styles from './UploadModal.module.scss'
import Dropzone, { DropzoneRef, Accept, useDropzone } from 'react-dropzone'
import { useConfirmModal } from '../ConfirmModal/ConfirmModal'
import { useTranslation } from 'react-i18next'

import { ReactComponent as EyeIcon } from '../../assets/icons/eye.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-2.svg'
import { ReactComponent as TrashIcon } from '../../assets/icons/trash.svg'
import { ReactComponent as ImageIcon } from '../../assets/icons/image_placeholder.svg'
import { ReactComponent as ErrorIcon } from '../../assets/icons/exclamation.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/cross.svg'

import report_placeholder from '../../assets/report_placeholder.png'
import apiClient from 'services/utils/api-client'
import { useUserContext } from 'context/UserContext'
import { UploadModalErr } from 'types/admin-errors'
import { mixpanelInstance } from '../../utils/mixpanel'
import SettingsService from 'services/settings-service'

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE)

export enum UploadType {
  IMAGE = 'image',
  CSV = 'csv'
}

interface UploadModalProps {
  title: string
  description: string
  type: UploadType
  open: boolean
  handleClose: () => void
}

export default function UploadModal(props: UploadModalProps) {
  const confirmModal = useConfirmModal()
  const { t } = useTranslation()
  const userContext = useUserContext()

  //const {open} = props
  const [hoverOverEdit, setHoverOverEdit] = useState<boolean>(false)
  const [file, setFile] = useState<any>(undefined)
  const [error, setError] = useState<string>('')
  const [preview, setPreview] = useState<boolean>(false)
  const [path, setPath] = useState<string>('')

  let dropzoneRef = createRef()

  const mixpanelEventsData = {
    organisation: userContext.currentOrg?.name,
    user: userContext.email
  }

  const openDialog = () => {
    if (dropzoneRef.current) {
      mixpanelInstance.settingsEventHandler(
        mixpanelEventsData,
        'SettingsLogoEdit'
      )
      //@ts-ignore
      dropzoneRef.current.open()
    }
  }

  const handleClose = () => {
    setFile(undefined)
    props.handleClose()
  }

  const showUploadModalErr = (err) => {
    const code = err?.response?.data?.code as UploadModalErr
    code ? setError(t(`backend.${code}`)) : setError(t('unknown'))
  }

  const imgFileType = (type) =>
    ['image/png', 'image/jpg', 'image/jpeg'].includes(type)

  const handleSetFile = (file) => {
    if (imgFileType(file.type)) {
      if (file.size > MAX_FILE_SIZE) {
        setError(
          `File too big. Max file size is ${MAX_FILE_SIZE / 1024 ** 2}MB`
        )
        return
      }
      setFile(file)
      setError('')
    } else {
      setError(t('wrong_file_format'))
    }
  }

  const handleClearFile = async () => {
    const confirm = await confirmModal.open({
      title: 'Are you sure you want to delete logo?'
    })
    if (confirm) {
      try {
        await SettingsService.deleteLogo(userContext.currentOrg.id)
        mixpanelInstance.settingsEventHandler(
          mixpanelEventsData,
          'SettingsLogoDelete'
        )
        setFile(undefined)
        setPath('')
        setError('')
      } catch (e) {
        showUploadModalErr(e)
      }
    }
  }

  useEffect(() => {
    checkImageOnload()
  }, [userContext.currentOrg.id])

  const checkImageOnload = async () => {
    try {
      const img = await SettingsService.getLogo(userContext.currentOrg.id)
      if (img?.report_logo_url) {
        setPath(img?.report_logo_url)
        mixpanelInstance.settingsEventHandler(
          mixpanelEventsData,
          'SettingsFetchCurrentLogo'
        )
      }
    } catch (e) {
      showUploadModalErr(e)
    }
  }

  const handleUpload = async () => {
    try {
      const body = new FormData()
      body.append('logo', file)
      await SettingsService.postLogo(userContext.currentOrg.id, body)
      mixpanelInstance.settingsEventHandler(
        mixpanelEventsData,
        'SettingsLogoUpload'
      )
      props.handleClose()
      setFile('')
      await checkImageOnload()
    } catch (e) {
      showUploadModalErr(e)
    }
  }

  const filefromApiName =
    path.length > 0 && path?.substring(path.lastIndexOf('/') + 1).split('?')[0]
  const isPreviewFileFromAPI = Boolean(!file) && Boolean(path.length > 0)
  const isNoFilesDetected = Boolean(!file) && Boolean(path.length === 0)

  return (
    <SaivaModal
      show={props.open}
      onHide={handleClose}
      onCancel={handleClose}
      className={preview ? 'logo-preview' : styles.uploadModal}
    >
      {!preview ? (
        <div className={styles.content}>
          <h4>{props.title}</h4>
          <p>{props.description}</p>

          <Dropzone
            accept={{
              'image/png': ['.png'],
              'image/jpg': ['.jpg', '.jpeg', '.jpe']
            }}
            onDrop={(acceptedFiles) => handleSetFile(acceptedFiles[0])}
            //@ts-ignore
            ref={dropzoneRef}
            disabled={Boolean(file) && !hoverOverEdit}
          >
            {({ getRootProps, getInputProps }) => (
              <>
                <div
                  {...getRootProps({ className: 'dropzone' })}
                  className={`${styles.fileInput} ${
                    !file ? styles.fileInputHover : ''
                  }`}
                >
                  {isNoFilesDetected ? (
                    <>
                      <input {...getInputProps()} />
                      <ImageIcon />
                      <p className={'body-1-light'}>
                        {file ? (
                          file.name
                        ) : (
                          <>
                            Drag and drop your file or <label>browse</label>
                          </>
                        )}
                      </p>
                    </>
                  ) : (
                    <div>
                      {isPreviewFileFromAPI ? (
                        <img src={path} height={200} width={'100%'} />
                      ) : (
                        <img
                          src={URL.createObjectURL(file)}
                          height={200}
                          width={'100%'}
                        />
                      )}
                    </div>
                  )}
                </div>
                {(file || isPreviewFileFromAPI) && (
                  <div className={styles.status}>
                    {isPreviewFileFromAPI ? filefromApiName : file.name}
                    <div className={styles.icons}>
                      <div className={styles.iconWrapper}>
                        <EyeIcon
                          onClick={() => {
                            setPreview(true)
                            mixpanelInstance.settingsEventHandler(
                              mixpanelEventsData,
                              'SettingsLogoPreview'
                            )
                          }}
                        />
                      </div>
                      <div className={styles.iconWrapper}>
                        <input {...getInputProps()} />
                        <EditIcon
                          onMouseEnter={() => setHoverOverEdit(true)}
                          onMouseLeave={() => setHoverOverEdit(false)}
                          type="button"
                          onClick={openDialog}
                        />
                      </div>
                      <div className={styles.iconWrapper}>
                        <TrashIcon onClick={handleClearFile} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Dropzone>

          {error && (
            <div className={styles.error}>
              <ErrorIcon /> {error}
            </div>
          )}

          <div className={styles.buttons}>
            <button className={'primary-text-button'} onClick={handleClose}>
              CANCEL
            </button>
            <button
              className={'primary-button'}
              onClick={handleUpload}
              disabled={!file}
            >
              UPLOAD
            </button>
          </div>
        </div>
      ) : (
        <div className={styles.preview}>
          <div className={styles.header}>
            <label>Previewing report</label>
            <span className={styles.close} onClick={() => setPreview(false)}>
              <CloseIcon />
              Close
            </span>
          </div>
          <div className={styles.content}>
            <img
              src={isPreviewFileFromAPI ? path : URL.createObjectURL(file)}
              className={styles.logo}
            />
            <img src={report_placeholder} />
          </div>
        </div>
      )}
    </SaivaModal>
  )
}
