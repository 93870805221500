import { get } from './utils/api-client'
import { SaivaDashboard } from '../types/dashboard-types'
import { OrgProductFeature } from '../types/user-types'

export namespace DashboardResponse {
  export interface Card {
    id: string,
    icon: string,
    title: string,
    subtitle: string,
    details: string[],
    value_type: string,
    value: string,
    quality_measure: string
  }
}

const parseDashboard = {
  Card: (response: DashboardResponse.Card): SaivaDashboard.Card => {
    return {
      id: response.id,
      icon: response.icon,
      title: response.title,
      subtitle: response.subtitle,
      details: response.details,
      valueType: response.value_type,
      value: response.value,
      hierarchy: 1,
      quality_measure: response.quality_measure,
    }
  },
  Cards: (response: DashboardResponse.Card[]): SaivaDashboard.Card[] => {
    return response.map(card => parseDashboard.Card(card))
  }
}

const getDashboardMetadata = async (orgId: string) : Promise<SaivaDashboard.Card[] | undefined> => {
  return get<DashboardResponse.Card[], SaivaDashboard.Card[]>(`v2/orgs/${orgId}/dashboard/metadata`, {parser: parseDashboard.Cards})
}

const getDashboardCard = async (orgId: string, cardId: string) : Promise<SaivaDashboard.Card | undefined> => {
  return get<DashboardResponse.Card, SaivaDashboard.Card>(`v2/orgs/${orgId}/dashboard/card/${cardId}`)
}

const DashboardService = {
  getDashboardMetadata,
  getDashboardCard
}

export default DashboardService
