import {
  Link,
  Outlet,
  useLocation,
  useNavigate
} from 'react-router-dom'
import { userActions, useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import SaivaIcon from 'components/saivaIcon'
import { useEffect, useState } from 'react'
import SaivaModal from 'components/saivaModal'
import PrivacyPolicy from '../privacyPolicy'
import TermsOfService from '../termsOfService'
import Navbar from 'react-bootstrap/Navbar'
import { useWindowSize } from 'hooks/useWindowSize'
import { mixpanelInstance } from 'utils/mixpanel'
import { OrgProductFeature, UserPermission } from 'types/user-types'
import { isMobile } from 'utils/helper'
import AccessControl from 'components/AccessControl/AccessControl'
import { AuthService } from 'services/auth'
import { Button, Popover, Tooltip } from 'antd'
import styles from './Navigation.module.scss'
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron-small-down.svg'
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh-circle.svg'
import { ReactComponent as UserIcon } from 'assets/icons/user.svg'
import LoadingPill from 'assets/icons/pill-loader.gif'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as QuestionIcon } from 'assets/icons/question-circle.svg'
import Background from 'assets/background-resident-page.svg'
import { showErrorToast } from 'utils'
import WoundCareService from 'services/wound-care-service'

export default function Navigation({ setRoleCategory }) {
  const userContext = useUserContext()
  const location = useLocation()

  const { t } = useTranslation()
  const navigate = useNavigate()
  const windowSize = useWindowSize()
  const [navExpanded, setNavExpanded] = useState<boolean>(false)
  const [showTos, setShowTos] = useState<boolean>(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false)
  const [organisations, setOrganisations] = useState<any>([])
  const [ogranisationFilter, setOrganisationFilter] = useState<any>({
    search: '',
    status: ['active', 'in_progress']
  })
  const appVersion = '1.1'

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()
  const error = query.get('error')

  const currentWoundOrg = organisations.find(
    (org) => org.id === userContext.currentMedicalSupplyOrg
  )

  useEffect(() => {
    if (error) {
      navigate('/')
    }
  }, [error])

  useEffect(() => {
    fetchOrganisations()
  }, [ogranisationFilter, userContext.currentOrg])

  const fetchOrganisations = async () => {
    const orgs = await WoundCareService.getOrganisationsWoundCare(
      ogranisationFilter
    )
    if (orgs) {
      setOrganisations(orgs.items)
    }
  }

  const logout = () => {
    userContext.logout()
  }

  const handleOrgSwitch = (newOrg, parentOrg) => {
    userContext.dispatch(
      userActions.setCurrentMedicalSupplyOrg(newOrg, parentOrg)
    )
    setNavExpanded(false)
  }

  const handleLink = (url, text) => {
    mixpanelInstance.navigationChange(text)
    navigate(url)
    setNavExpanded(false)
  }

  const makeOrgSwitcher = () => {
    const [loading, setLoading] = useState(false)
    const [openPopover, setOpenPopover] = useState(false)
    const [isOpenMobileContent, setIsOpenMobileContent] = useState(false)

    const refresh = async () => {
      setLoading(true)
      await AuthService.refreshToken().then(() => {
        userContext.refreshOrgs()
        setLoading(false)
      })
      setOrganisationFilter({ ...ogranisationFilter, search: '' })
    }

    const availiableOrgs = organisations

    const content = (
      <>
        <div className={styles.searchWrapper}>
          <SaivaSearch
            placeholder={t('search.text')}
            filters={false}
            activeFilters={false}
            onChange={(e) => {
              setOrganisationFilter({
                ...ogranisationFilter,
                search: e.target.value
              })
            }}
            value={ogranisationFilter.search}
            content={<></>}
          />
          <Tooltip
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            placement="bottom"
            title={'Refresh'}
          >
            <Button className={styles.refreshButton} onClick={refresh}>
              <RefreshIcon />
            </Button>
          </Tooltip>
        </div>
        {loading ? (
          <div className={styles.orgPopOverLoading}>
            <div style={{ width: '100%' }}>
              <img
                src={LoadingPill}
                width={'48px'}
                style={{ marginBottom: '24px' }}
              ></img>
              <div>Loading.... Please wait</div>
            </div>
          </div>
        ) : (
          <div className={styles.orgPopOverContent}>
            {availiableOrgs && availiableOrgs.length > 0 ? (
              availiableOrgs.map((parentOrg) => {
                return (
                  <>
                    <div className={styles.parentOrgName}>{parentOrg.name}</div>
                    {parentOrg.child_orgs?.map((org) => (
                      <Button
                        className={`
                      ${styles.QMButton}
                      ${
                        (org?.name === userContext.currentOrg?.name ||
                          org?.id === userContext.currentMedicalSupplyOrg.id) &&
                        styles.QMButtonActive
                      }
                    `}
                        onClick={() => {
                          const organisation =
                            userContext &&
                            userContext.orgs?.find((o) => o.id == org.id)
                          const parentOrganisation =
                            userContext &&
                            userContext.orgs?.find((o) => o.id == parentOrg.id)
                          if (organisation && parentOrganisation) {
                            handleOrgSwitch(organisation, parentOrganisation)
                          }
                          setOpenPopover(false)
                          setOrganisationFilter({
                            ...ogranisationFilter,
                            search: ''
                          })
                        }}
                        key={org.id}
                      >
                        <div className="elipsis">
                          {org.status != 'active' ? '[' + org.status + ']' : ''}{' '}
                          {org?.name}
                        </div>
                      </Button>
                    ))}
                  </>
                )
              })
            ) : (
              <div className={styles.orgPopOverLoading}>
                <div style={{ width: '100%' }}>
                  <EmptyDataIcon style={{ marginBottom: '6px' }} />
                  <br />
                  {t('dashboard.cards.no-card-value')}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    )

    return (
      <div className={styles.orgSwitcher}>
        {isMobile() ? (
          <Button
            className={styles.actionButton}
            size="large"
            onClick={() => setIsOpenMobileContent(!isOpenMobileContent)}
          >
            {userContext.currentOrg?.name}
            <ChevronIcon
              className={`${isOpenMobileContent && styles.isOpenIcon} ${
                styles.chevronIcon
              }`}
            />
          </Button>
        ) : (
          <Popover
            placement="bottomRight"
            zIndex={1200}
            overlayInnerStyle={{ padding: '8px 0px 8px 0px' }}
            open={openPopover}
            overlayStyle={{ maxWidth: '300px' }}
            trigger={'click'}
            onOpenChange={(open) => setOpenPopover(open)}
            content={content}
          >
            <Button className={styles.actionButton} size="large">
              {userContext.currentOrg?.name}
              <ChevronIcon
                className={`${openPopover && styles.isOpenIcon} ${
                  styles.chevronIcon
                }`}
              />
            </Button>
          </Popover>
        )}
        {isMobile() && isOpenMobileContent && (
          <div className={styles.mobileContentOrgs}>{content}</div>
        )}
      </div>
    )
  }

  const makeProductSwitcher = () => {
    const [openPopover, setOpenPopover] = useState(false)
    const [isOpenMobileContent, setIsOpenMobileContent] = useState(false)

    const content = (
      <>
        <div className={styles.orgPopOverContent}>
          {userContext.product_name.map((role) => {
            return (
              <Button
                className={`
                    ${styles.QMButton}
                    ${role === userContext.currentRole && styles.QMButtonActive}
                  `}
                onClick={() => {
                  setRoleCategory(role)
                  navigate('/')
                }}
                key={role}
              >
                <div className="elipsis">
                  {role === 'medical_supply' ? 'Wounds' : 'UPT'}
                </div>
              </Button>
            )
          })}
        </div>
      </>
    )

    return (
      <div className={styles.orgSwitcher}>
        {isMobile() ? (
          <Button
            className={styles.actionButton}
            size="large"
            onClick={() => setIsOpenMobileContent(!isOpenMobileContent)}
          >
            {userContext.currentOrg?.permissions.includes(
              UserPermission.WOUNDS_EDIT
            )
              ? currentWoundOrg?.name
              : userContext.currentOrg?.name}
            <ChevronIcon
              className={`${isOpenMobileContent && styles.isOpenIcon} ${
                styles.chevronIcon
              }`}
            />
          </Button>
        ) : (
          <Popover
            placement="bottomRight"
            zIndex={1200}
            overlayInnerStyle={{ padding: '8px 0px 8px 0px' }}
            open={openPopover}
            overlayStyle={{ maxWidth: '300px' }}
            trigger={'click'}
            onOpenChange={(open) => setOpenPopover(open)}
            content={content}
          >
            <Button className={styles.actionButton} size="large">
              {userContext.currentRole === 'medical_supply' ? 'Wounds' : 'UPT'}
              <ChevronIcon
                className={`${openPopover && styles.isOpenIcon} ${
                  styles.chevronIcon
                }`}
              />
            </Button>
          </Popover>
        )}
        {isMobile() && isOpenMobileContent && (
          <div className={styles.mobileContentOrgs}>{content}</div>
        )}
      </div>
    )
  }

  const makeMenuItem = (url, iconName, text, inTopbar = false) => {
    const isActive =
      url === '/' && location.pathname !== '/'
        ? false
        : location.pathname.includes(url)
    return (
      <li
        className={`${inTopbar ? 'nav-item d-block d-lg-none ms-3' : ''} ${
          isActive ? 'active' : ''
        }`}
        onClick={() => handleLink(url, text)}
      >
        <a className="nav-link d-flex align-items-center">
          <SaivaIcon
            name={iconName}
            className={`${inTopbar ? 'me-1' : ''} ${isActive ? 'active' : ''}`}
            size={'24px'}
          />
          <label>{text}</label>
        </a>
      </li>
    )
  }

  const getMenu = (topbar: boolean = false) => {
    return (
      <>
        <AccessControl userPermissions={[UserPermission.USERS_EDIT]}>
          {makeMenuItem('/users', 'User1', t('sidebar.userManagement'), topbar)}
        </AccessControl>
        <AccessControl userPermissions={[UserPermission.FACILITIES_READ]}>
          {makeMenuItem(
            '/facilities',
            'Meds',
            t('sidebar.facilityManagement'),
            topbar
          )}
        </AccessControl>
        {userContext.isSuperuser ? (
          makeMenuItem(
            '/order-capture',
            'WoundCare',
            t('sidebar.wounds'),
            topbar
          )
        ) : (
          <AccessControl
            userPermissions={[
              UserPermission.WOUNDS_READ,
              UserPermission.WOUNDS_EDIT
            ]}
            productFeatures={[OrgProductFeature.WOUND_CARE]}
          >
            {makeMenuItem(
              '/order-capture',
              'WoundCare',
              t('sidebar.wounds'),
              topbar
            )}
          </AccessControl>
        )}
      </>
    )
  }

  const makeNavbar = () => {
    const [openPopover, setOpenPopover] = useState(false)
    const [isOpenMobileContent, setIsOpenMobileContent] = useState(false)

    return (
      // <nav className="navbar navbar-expand-lg shadow-sm">
      <Navbar
        expanded={navExpanded}
        expand="lg"
        style={{ padding: '12px 4px' }}
        className={styles.navigation}
      >
        <div className="container-fluid">
          <Link to="/" className="navbar-brand nav-link">
            <img
              className="image-fluid"
              style={{ height: '32px', marginLeft: '0.5rem' }}
              src="/saiva-logo-white.png"
            />
          </Link>
          <Navbar.Toggle
            className="navbar-toggler"
            onClick={() => setNavExpanded(!navExpanded)}
            type="button"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </Navbar.Toggle>
          <Navbar.Collapse
            className="justify-content-end"
            style={{ width: '100%' }}
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav d-flex align-items-start align-items-md-center">
              {getMenu(true)}
              <li className="nav-item dropdown">
                <a href={'https://support.saiva.ai'} target="_blank">
                  <Button className={styles.actionButton} size="large">
                    <QuestionIcon style={{ marginRight: '8px' }} />
                    {t('sidebar.help')}
                  </Button>
                </a>
              </li>
              <li
                className="nav-item dropdown org-switcher"
                style={{ width: '100%' }}
              >
                {userContext.orgs &&
                  userContext.orgs.length > 0 &&
                  makeOrgSwitcher()}
              </li>
              <li>{userContext.isSuperuser && makeProductSwitcher()}</li>
              <li
                className="nav-item dropdown user-dropdown"
                style={{ width: '100%' }}
              >
                {isMobile() ? (
                  <Button
                    className={styles.userActionButton}
                    size="large"
                    onClick={() => setIsOpenMobileContent(!isOpenMobileContent)}
                  >
                    <UserIcon className={styles.userIcon} />
                    {userContext.email}
                    <ChevronIcon
                      className={`${isOpenMobileContent && styles.isOpenIcon} ${
                        styles.chevronIcon
                      }`}
                    />
                  </Button>
                ) : (
                  <Popover
                    placement="bottomRight"
                    zIndex={1200}
                    overlayInnerStyle={{ padding: '8px 0px 8px 0px' }}
                    overlayStyle={{ maxWidth: '300px' }}
                    onOpenChange={(open) => setOpenPopover(open)}
                    trigger={'click'}
                    content={
                      <>
                        <div className={styles.loginPopover}>
                          <Button className={styles.QMButton} onClick={logout}>
                            <LogoutIcon style={{ marginRight: '8px' }} />
                            {t('sidebar.logout')}
                          </Button>
                        </div>
                      </>
                    }
                  >
                    <Button className={styles.userActionButton} size="large">
                      <UserIcon className={styles.userIcon} />
                      {userContext.email}
                      <ChevronIcon
                        className={`${openPopover && styles.isOpenIcon} ${
                          styles.chevronIcon
                        }`}
                      />
                    </Button>
                  </Popover>
                )}
                {isMobile() && isOpenMobileContent && (
                  <div className={styles.mobileContentOrgs}>
                    <div className={styles.loginPopover}>
                      <Button className={styles.QMButton} onClick={logout}>
                        <LogoutIcon style={{ marginRight: '8px' }} />
                        {t('sidebar.logout')}
                      </Button>
                    </div>
                  </div>
                )}
              </li>
            </ul>
          </Navbar.Collapse>
        </div>
      </Navbar>
    )
  }

  const makeComingSoonMenuItem = (iconName, text) => {
    return (
      <li className="comingSoon">
        <a className="nav-link d-flex align-items-center" href="#">
          <SaivaIcon name={iconName} />
          <label>
            {text}
            <span className="badge">{t('sidebar.comingSoon')}</span>
          </label>
        </a>
      </li>
    )
  }

  const makeSidebar = () => {
    return (
      <ul>
        {/* <li>
          <SaivaIcon name="SaivaClosedBurgerMenu" />
        </li> */}
        {getMenu()}
      </ul>
    )
  }

  const tosClicked = () => {
    setShowTos(true)
  }

  const privacyPolicyClicked = () => {
    setShowPrivacyPolicy(true)
  }

  const handleCloseTos = () => {
    setShowTos(false)
  }

  const handleClosePrivacyPolicy = () => {
    setShowPrivacyPolicy(false)
  }

  return (
    <>
      <section
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundPosition: 'top',
          height: '100%'
        }}
      >
        <SaivaModal
          show={showTos}
          onHide={() => handleCloseTos()}
          onCancel={() => handleCloseTos()}
          backdrop={true}
        >
          <TermsOfService />
        </SaivaModal>
        <SaivaModal
          show={showPrivacyPolicy}
          onHide={() => handleClosePrivacyPolicy()}
          onCancel={() => handleClosePrivacyPolicy()}
          backdrop={true}
        >
          <PrivacyPolicy />
        </SaivaModal>
        <div className="container-fluid">
          <div className="row navigationWrapper">{makeNavbar()}</div>
          <div className="row contentWrapper">
            {(userContext.isSuperuser ||
              !userContext.currentOrg?.permissions.includes(
                UserPermission.WOUNDS_EDIT
              )) && (
              <div className="sidebar d-none d-lg-inline-block">
                {makeSidebar()}
              </div>
            )}
            {userContext.isSuperuser ||
            !userContext.currentOrg?.permissions.includes(
              UserPermission.WOUNDS_EDIT
            ) ? (
              windowSize.width && windowSize.width < 992 ? (
                <div className="d-inline-block mobile-content">
                  <Outlet />
                </div>
              ) : (
                <div className="d-inline-block desktop-content">
                  <Outlet />
                </div>
              )
            ) : (
              <Outlet />
            )}
          </div>
          <div className="row">
            <div className="col d-flex justify-content-center my-4">
              <span>
                ©Saiva AI 2024 |{' '}
                <a
                  href="#"
                  onClick={() => privacyPolicyClicked()}
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  Privacy Policy
                </a>{' '}
                |
                <a
                  href="#"
                  onClick={() => tosClicked()}
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  Terms & Conditions
                </a>{' '}
                |
                <a
                  href="http://status.saiva.ai"
                  style={{ textDecoration: 'none' }}
                >
                  {' '}
                  Service Status
                </a>{' '}
                | Version {appVersion}
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
