import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import SaivaTable from 'components/Tables/AntdTable/AntdTable'
import SaivaRadioButton from 'components/SaivaForm/SaivaRadioButton/saivaRadioButton'
import SRModal from '../SRModal/SRModal'
import ConfirmationModal from '../ConfirmationModal/CModal'
import { ScheduleFilter } from 'types/schedule-reports'
import SchedulesService from 'services/schedules-service'
import { repeatOptions, fileTypeOpts } from '../utils'
import { showSuccessToast } from 'utils'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { SaivaSort, SortDirection } from 'services/utils/api-utils'
import { mixpanelInstance } from 'utils/mixpanel'
import ReportService from 'services/report-service'
import { smScreen } from 'utils/helper'
import { ColumnsAllSchedules, ColumnsMineSchedules } from './Columns'
import styles from './Schedules.module.scss'
import { UserPermission } from 'types/user-types'
import { getFeatureLabel } from 'utils/helper'

type PositionType = 'left' | 'right'

export function Schedules() {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const modelProductFeatures = userContext.currentOrg.productFeatures.filter(
    (item) => {
      return item.includes('model')
    }
  )
  const [schedules, setSchedules] = useState<any>([])
  const [selected, setSelected] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [apiError, setApiError] = useState<any>(undefined)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [total, setTotal] = useState(0)
  const [categories, setCategories] = useState<any>([])
  const [reports, setReports] = useState<any>([])
  const [tableSort, setTableSort] = useState<SaivaSort | undefined>(undefined)
  const [usersForFilter, setUsersForFilter] = useState<any>([])
  const navigate = useNavigate()

  const adminPermissions =
    userContext.hasPermission(UserPermission.SCHEDULE_CREATE) &&
    userContext.hasPermission(UserPermission.SCHEDULE_EDIT)

  const defaultTab = adminPermissions ? 'all_schedules' : 'my_schedules'
  const [activeTab, setActiveTab] = useState<string>(defaultTab)
  const [schedulesFilter, setSchedulesFilter] = useState<
    ScheduleFilter | undefined
  >(adminPermissions ? { show_all: true } : { show_all: false })

  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  useEffect(() => {
    const id = query.get('id')
    if (smScreen()) {
      navigate('/')
    } else if (id) {
      fetchSchedule(id)
    }
    mixpanelInstance.scheduleViewAll()
  }, [])

  useEffect(() => {
    fetchSchedules()
  }, [page, pageSize, schedulesFilter, tableSort])

  useEffect(() => {
    fetchCategories()
    if (adminPermissions) {
      getUsersForFilter()
      setSchedulesFilter({ show_all: true })
    } else {
      setSchedulesFilter(undefined)
    }
  }, [userContext.currentOrg.id])

  useEffect(() => {
    switch (activeTab) {
      case 'all_schedules':
        setSchedulesFilter({ ...schedulesFilter, show_all: true })
        break
      default:
        setSchedulesFilter({ ...schedulesFilter, show_all: false })
        break
    }
  }, [activeTab])

  function getUsersForFilter() {
    SchedulesService.getScheduledByUsers(userContext.currentOrg.id).then(
      (res) => {
        if (!res) return
        setUsersForFilter(res.scheduled_by_users)
      }
    )
  }

  const fetchCategories = async () => {
    await ReportService.getReportCategories(userContext.currentOrg.id).then(
      (res) => {
        if (res && res.categories) {
          const categories = res.categories.map((item) => {
            return {
              label: item.label,
              value: item.id,
              reports: item.report_types.map((report) => {
                return report.id
              })
            }
          })
          const reports = res.categories
            .map((item) => {
              return item.report_types
                .filter((item) => item.id != 'daily_risk_report')
                .map((report) => {
                  return {
                    label: report.label,
                    value: report.id,
                    category: item.id
                  }
                })
            })
            .flat(1)
          setCategories(categories)
          setReports(reports)
        }
      }
    )
  }

  const fetchSchedule = async (id) => {
    if (id) {
      await SchedulesService.getSchedule(userContext.currentOrg.id, id)
        .then((res) => {
          setSelected(res)
          setIsEditMode(true)
          setOpenSRModal(true)
        })
        .catch((err) => {
          if (err.name === 'ApiError') {
            setApiError(t(err.errorCode))
            setOpenSRModal(false)
          } else {
            setApiError('genericError')
          }
        })
    }
  }

  const fetchSchedules = async () => {
    setLoading(true)
    await SchedulesService.getSchedules(userContext.currentOrg.id, {
      ...schedulesFilter,
      page,
      size: pageSize,
      sort: tableSort
    })
      .then((res) => {
        if (!res) return
        const lastPage = Math.ceil(res.total ? res.total / res.size : 1)
        if (page > lastPage) {
          setPage(lastPage)
        }
        setPageSize(pageSize)
        setApiError(undefined)
        setSchedules(res.items)
        setTotal(res.total ? res.total : 0)
        setLoading(false)
        mixpanelInstance.scheduleFilter({
          ...schedulesFilter,
          page,
          size: pageSize,
          sort: tableSort
        })
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setSchedules([])
          setApiError(err.errorCode)
        } else {
          setApiError('genericError')
        }
        setLoading(false)
      })
  }

  const [openSRModal, setOpenSRModal] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false)

  const edit = (schedule) => {
    setSelected(schedule)
    setIsEditMode(true)
    setOpenSRModal(true)
  }

  const duplicate = (schedule) => {
    setSelected(schedule)
    setIsDuplicate(true)
    setOpenSRModal(true)
  }

  const deleteItem = (schedule) => {
    setSelected(schedule)
    setOpenConfirm(true)
  }

  const deleteFromEdit = async (selected) => {
    await SchedulesService.deleteSchedule(
      userContext.currentOrg.id,
      selected.id
    )
      .then(async () => {
        if (schedules.length === 1 && page > 1) {
          setPage(page - 1)
        } else {
          fetchSchedules()
        }
        setIsDuplicate(false)
        setOpenSRModal(false)
        showSuccessToast(
          t('scheduleReport.deleteSuccessText', { name: selected?.name })
        )
        mixpanelInstance.scheduleDelete({
          schedule: selected
        })
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError(t(err.errorCode))
        } else {
          setApiError('genericError')
        }
      })
  }

  const closeModal = () => {
    fetchSchedules()
    setIsEditMode(false)
    setIsDuplicate(false)
    setOpenSRModal(false)
  }

  const handleCancelConfirm = () => {
    setIsEditMode(false)
    setIsDuplicate(false)
    setOpenConfirm(false)
  }

  const handleOk = async () => {
    await SchedulesService.deleteSchedule(
      userContext.currentOrg.id,
      selected.id
    )
      .then(async () => {
        if (schedules.length === 1 && page > 1) {
          setPage(page - 1)
        } else {
          fetchSchedules()
        }
        setIsDuplicate(false)
        setOpenConfirm(false)
        showSuccessToast(
          t('scheduleReport.deleteSuccessText', { name: selected?.name })
        )
        mixpanelInstance.scheduleDelete({
          schedule: selected
        })
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError(t(err.errorCode))
        } else {
          setApiError('genericError')
        }
      })
  }

  const toggleEdit = async (checked: boolean, schedule) => {
    const requestData = {
      schedule_config: {
        is_enabled: checked,
        schedule_type: schedule.schedule_config.schedule_type,
        time_of_day: schedule.schedule_config.time_of_day,
        day_of_week: schedule.schedule_config.day_of_week,
        week_of_month: schedule.schedule_config.week_of_month
      }
    }
    await SchedulesService.updateSchedule(
      userContext.currentOrg.id,
      schedule.id,
      requestData
    )
      .then((resp) => {
        fetchSchedules()
        mixpanelInstance.scheduleEdit({
          id: schedule.id,
          schedule_config: requestData.schedule_config
        })
        showSuccessToast(
          t('scheduleReport.editSuccessText', {
            name: resp?.schedule_config.name
          })
        )
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError(err.errorCode)
        } else {
          setApiError('genericError')
        }
      })
  }

  function showTotalTable(total, range) {
    return `${range[0]} - ${range[1]} from ${total}`
  }

  const handleSortClick = (propertyName: string) => {
    const currentDirection = tableSort?.direction ?? SortDirection.Descending
    setTableSort({
      property: propertyName,
      direction:
        currentDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending
    })
  }

  const onChangeTab = (key: string) => {
    setActiveTab(key)
  }

  const pagination = {
    total: total,
    page: page,
    pageSize: pageSize,
    showTotal: showTotalTable,
    onChange(newCurrent: number) {
      setPage(newCurrent)
    },
    onShowSizeChange(pageSize: number) {
      const lastPage = Math.ceil(total / pageSize)
      if (page > lastPage) {
        setPage(lastPage)
      }
      setPageSize(pageSize)
    }
  }

  const filteredReports = reports.filter((item) => {
    if (schedulesFilter?.categories?.length) {
      return schedulesFilter?.categories?.includes(item.category)
    } else {
      return true
    }
  })

  const filters = (
    <div
      style={{
        minWidth: '300px',
        padding: '16px 16px 0px 16px',
        maxWidth: '300px'
      }}
    >
      <b style={{ fontSize: '14px' }}>
        {t('schedules.allSchedules.filters.title')}
      </b>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          value={schedulesFilter ? schedulesFilter.categories : []}
          icon={null}
          label={t('schedules.allSchedules.filters.label.reportCategory')}
          options={categories}
          placeholder={t(
            'schedules.allSchedules.filters.placeholder.reportCategory'
          )}
          multiselect={true}
          name="reportCategory"
          reset={() => {
            setSchedulesFilter({
              ...schedulesFilter,
              categories: [],
              report_type: []
            })
          }}
          onChange={(e) => {
            setSchedulesFilter({
              ...schedulesFilter,
              categories: e,
              report_type: schedulesFilter?.report_type?.filter((i) => {
                return reports
                  .filter((item) => {
                    return e.includes(item.category)
                  })
                  .map((item) => item.value)
                  .includes(i)
              })
            })
          }}
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          value={schedulesFilter ? schedulesFilter.report_type : []}
          icon={null}
          label={t('schedules.allSchedules.filters.label.report')}
          options={filteredReports}
          placeholder={t('schedules.allSchedules.filters.placeholder.report')}
          multiselect={true}
          name="report"
          reset={() => {
            setSchedulesFilter({ ...schedulesFilter, report_type: [] })
          }}
          onChange={(e) => {
            setSchedulesFilter({ ...schedulesFilter, report_type: e })
          }}
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          value={schedulesFilter?.scheduled_by_id}
          icon={null}
          label={t('schedules.allSchedules.filters.label.scheduled_by')}
          options={usersForFilter.map((item) => {
            return { label: item.label, value: item.id }
          })}
          placeholder={t(
            'schedules.allSchedules.filters.placeholder.scheduled_by'
          )}
          multiselect={true}
          name="scheduled_by_id"
          reset={() => {
            setSchedulesFilter({ ...schedulesFilter, scheduled_by_id: [] })
          }}
          onChange={(e) => {
            setSchedulesFilter({ ...schedulesFilter, scheduled_by_id: e })
          }}
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          value={schedulesFilter?.schedule_type}
          icon={null}
          label={t('schedules.allSchedules.filters.label.repeat')}
          options={repeatOptions}
          placeholder={t('schedules.allSchedules.filters.placeholder.repeat')}
          multiselect={true}
          name="repeat"
          reset={() => {
            setSchedulesFilter({ ...schedulesFilter, schedule_type: [] })
          }}
          onChange={(e) => {
            setSchedulesFilter({ ...schedulesFilter, schedule_type: e })
          }}
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaRadioButton
          value={
            schedulesFilter && schedulesFilter?.file_format
              ? schedulesFilter?.file_format
              : []
          }
          name="file_format"
          width={'60px'}
          height={24}
          inputType="checkbox"
          returnType="string"
          label={t('scheduleReport.form.label.fileFormat')}
          values={fileTypeOpts}
          onChange={(e) => {
            setSchedulesFilter({ ...schedulesFilter, file_format: e })
          }}
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaRadioButton
          value={
            schedulesFilter && schedulesFilter?.quality_measure
              ? schedulesFilter?.quality_measure
              : []
          }
          name="quality_measure"
          width={'60px'}
          height={24}
          inputType="checkbox"
          returnType="string"
          label={t('reports.filters.label.qualityMeasure')}
          values={modelProductFeatures.map((feature) => {
            return {
              label: getFeatureLabel(feature).label,
              value: getFeatureLabel(feature).id
            }
          })}
          onChange={(e) => {
            setSchedulesFilter({ ...schedulesFilter, quality_measure: e })
          }}
        />
      </div>
    </div>
  )

  const isScheduleFilterActive = !!(
    schedulesFilter &&
    (schedulesFilter.report_type?.length ||
      schedulesFilter.schedule_type?.length ||
      schedulesFilter.categories?.length ||
      schedulesFilter.file_format?.length)
  )

  const AllSchedulesTab = {
    key: 'all_schedules',
    label: t('schedules.allSchedules.tabs.allSchedules'),
    children: (
      <SaivaTable
        columns={ColumnsAllSchedules(
          handleSortClick,
          toggleEdit,
          edit,
          duplicate,
          deleteItem
        )}
        data={schedules}
        loading={loading}
        pagination={pagination}
        rowSelection={undefined}
        expandable={undefined}
        scroll={undefined}
        onRow={undefined}
        rowKey={undefined}
        rowClassName={undefined}
      />
    )
  }

  const MineSchedulesTab = {
    key: 'my_schedules',
    label: t('schedules.allSchedules.tabs.mySchedules'),
    children: (
      <SaivaTable
        columns={ColumnsMineSchedules(
          handleSortClick,
          toggleEdit,
          edit,
          duplicate,
          deleteItem
        )}
        data={schedules}
        loading={loading}
        pagination={pagination}
        rowSelection={undefined}
        expandable={undefined}
        scroll={undefined}
        onRow={undefined}
        rowKey={undefined}
        rowClassName={undefined}
      />
    )
  }

  function TabsItems() {
    if (adminPermissions) {
      return [AllSchedulesTab, MineSchedulesTab]
    } else {
      return [MineSchedulesTab]
    }
  }

  const items: TabsProps['items'] = TabsItems()

  const Operations: Record<PositionType, React.ReactNode> = {
    left: null,
    right: (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline'
        }}
      >
        <div
          style={{
            display: 'flex',
            marginRight: 'auto'
          }}
        >
          <SaivaSearch
            placeholder={t('schedules.allSchedules.filters.placeholder.search')}
            filters={true}
            activeFilters={isScheduleFilterActive}
            onChange={(e) => {
              setSchedulesFilter({ ...schedulesFilter, search: e.target.value })
            }}
            value={schedulesFilter?.search}
            content={filters}
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <ConfirmationModal
        openConfirm={openConfirm}
        handleCancelConfirm={handleCancelConfirm}
        handleOk={handleOk}
        title={t('schedules.allSchedules.confirmationModal.title')}
        message={t('schedules.allSchedules.confirmationModal.message')}
      />
      <SRModal
        key={selected?.id}
        filtersData={selected?.report_config.params.filters}
        reportId={selected?.report_config.report_type}
        isEditMode={isEditMode}
        isDuplicate={isDuplicate}
        item={selected}
        deleteItem={() => deleteFromEdit(selected)}
        open={openSRModal}
        close={closeModal}
      />
      <div className={styles.backButton}>
        <button
          className="primary-text-button"
          onClick={() => navigate('/reports')}
        >
          <ArrowIcon style={{ padding: '2px' }} />{' '}
          {t('schedules.allSchedules.header.backButton')}
        </button>
      </div>
      <div>
        <Tabs
          activeKey={activeTab}
          items={items}
          onChange={onChangeTab}
          tabBarExtraContent={Operations}
          className={styles.tabs}
        />
      </div>
      {apiError && (
        <>
          <div
            className={`banner bannerFailed`}
            style={{ marginBottom: '20px' }}
          >
            <InfoIcon />
            <p>{t(apiError)}</p>
          </div>
        </>
      )}
    </div>
  )
}
