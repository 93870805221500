import React, { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import { ReactComponent as LessIcon } from 'assets/icons/less.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as MoreInfo } from 'assets/icons/more-info.svg'
import { useUserContext } from 'context/UserContext'
import * as Sentry from '@sentry/react'
import { FileType } from 'services/api'
import { useOnClickOutside } from 'usehooks-ts'
import { loadPaginationFromLocalStorage, savePaginationToLocalStorage } from 'utils/localStorage'
import { handleError } from '../../../utils/errorHandler'
import SaivaPagination from '../SaivaPagination/SaivaPagination'
import { Flex, Tooltip } from 'antd'

const add = (accumulator, a) => {
  return accumulator + a;
}

const getAnimationStyle = (selected, length) => {
  return {maxHeight: selected ? 56 * length : 0 }
}

const OrganizationNameCollapse = ({organization, setData, rowsPerPage, page, allRows} : {setData: (value: any) => void, organization: any, rowsPerPage: number, page: number, allRows: number}) => {
  const [show, setShow] = useState<boolean>(organization.isSelected)
  const [regionsToggle, setRegionsToggle] = useState<any>(organization.regions.map(region => Number(region.id)))

  useEffect(() => {
    setShow(organization.isSelected)
  }, [organization.isSelected])

  useEffect(() => {
    setData( prev => {
      return {timePeriods: [...prev.timePeriods], organization: {...prev.organization, isSelected: show, regionsToggle: regionsToggle}}
    })
  }, [show, regionsToggle])

  const handleToggle = () => {
    setShow(prev => !prev)
  }

  const handleToggleRegion = (id: number) => {
    if(regionsToggle.includes(id)) {
      setRegionsToggle(prev => prev.filter(item => item !== id))
    } else {
      setRegionsToggle(prev => [...prev, id])
    }
  }

  return (
    <div className={"animation"} style={{maxHeight: rowsPerPage * page > allRows ? 56 * (rowsPerPage - ((rowsPerPage * page) - allRows)) : 56 * rowsPerPage, overflow: 'hidden'}}>
      <div className={"animation"} style={{transform: allRows > rowsPerPage ? `translateY(${-(page - 1) * rowsPerPage * 56}px)` : 'translateY(0)'}}>
        <div className={"name organization"} onClick={handleToggle}>
          {organization.isSelected ?
            <LessIcon />
            :
            <MoreIcon />
          }
          {organization.name}
        </div>
        {organization.isSelected && <div>
          {organization?.regions?.map((region, index) => {
            return (
              <>
                {region.facilities.length > 0 ?
                <>
                  <div className={"name region"} style={{paddingLeft: '40px'}} onClick={() => handleToggleRegion(Number(region.id))}>
                    {organization.regionsToggle.includes(Number(region.id)) ?
                      <LessIcon />
                      :
                      <MoreIcon />
                    }
                    {region?.name}
                  </div>
                  {organization.regionsToggle.includes(Number(region.id)) && <div>
                    {region.facilities?.map((facility, index) => {
                      return (
                        <>
                          <div className={"name"} style={{paddingLeft: '40px'}}>
                            {facility?.name}
                          </div>
                        </>
                      )
                    })}
                  </div>}
                </>
                  : (
                  <div className={"name organization"}>
                    {region?.name}
                  </div>)
                }
              </>
            )
          })}
        </div>}
      </div>
    </div>
  )
}

const CardSlider = ({timePeriods, headerItems, facility} : {timePeriods: string[], headerItems: {label: string, id: string, info?: string}[], facility: any}) => {

  const ref = useRef<HTMLDivElement>(null)

  const handleMouseDown = () => {
    ref.current?.addEventListener("mousemove", handleMouseMove)
  }

  const handleMouseUp = () => {
    ref.current?.removeEventListener("mousemove",  handleMouseMove)
  }

  const handleMouseMove = (e) => {
    if(ref.current) {
      ref.current.scrollLeft -= e.movementX
    }
  }

  const handleScroll = (e) => {
    if(!ref.current) return
    if((ref.current.scrollWidth > ref.current.clientWidth + ref.current.scrollLeft || e.deltaY < 0)
      && (ref.current.scrollLeft !== 0 || e.deltaY > 0)
    ) {
      const isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0
      if(!isTouchPad) {
        e.preventDefault()
        ref.current.scrollLeft += e.deltaY;
      }
    }
  }

  useEffect(() => {
    const item = ref.current
    item?.addEventListener("mousedown", handleMouseDown)
    item?.addEventListener("mousewheel", handleScroll)
    item?.addEventListener("DOMMouseScroll", handleScroll)
    window.addEventListener("mouseup", handleMouseUp)

    return () => {
      item?.removeEventListener("mousedown", handleMouseDown)
      item?.removeEventListener("mousewheel", handleScroll)
      item?.removeEventListener("DOMMouseScroll", handleScroll)
      window.removeEventListener("mouseup", handleMouseUp)
    }
  }, [ref.current])

  return <div ref={ref} className={"cardSlider"}>
    {timePeriods.map((period, index) => {
      return <Card key={index} period={period} index={index} headerItems={headerItems} facility={facility} />
    })}
  </div>
}

const Card = ({period, index, headerItems, facility} : {period: string, index: number, headerItems: {label: string, id: string}[], facility: any}) => {
  return <div className={"facilityCard"}>
    <label className={"period"}>{period}</label>
    <div className={"cardContent"}>
      {headerItems.map((item, i) => {
        return <Fragment key={i}><label>{item.label}</label> <div>{facility.data[item.id][index]}</div></Fragment>
      })}
    </div>
  </div>
}

const options = [{label: "5", value: 5}, {label: "10", value: 10}, {label: "20", value: 20}, {label: "50", value: 50}, {label: "100", value: 100}]

export interface IPeriodTableData {
  timePeriods: string[],
  organization: {
    name: string,
    data: {
      //TODO refactor detail url proprety
      [id: string]: Array<string | {value: string, detail_url: string}>
    },
    isSelected: boolean,
    regionsToggle: number[],
    regions: {
      id: string,
      name: string,
      data: {
        [id: string]: string[]
      },
      facilities: {
        id: string,
        name: string,
        data: {
          [id: string]: string[]
        }
      }[]
    }[]
  }
}

export interface HeaderItem {
  label: string,
  id: string,
  type?: "number" | "string" | "percentage" | "date" | "boolean" | "array" | "url",
  info?: string
  sortable?: boolean
}

interface ComponentProps {
  mainTitle: string,
  headerItems: HeaderItem[],
  downloadReport?: (fileType: FileType) => void,
  downloadDetail?: (detailUrl: string, facility: string | undefined, date: string) => void
  fileLoading?: boolean
  data: IPeriodTableData,
  noDownload?: boolean,
  headerElement: ReactElement
  quality_measure: string
}

const DataTable = (props: ComponentProps) => {

  const userContext = useUserContext()

  const downloadPopup = useRef(null)

  const [data, setData] = useState<IPeriodTableData | undefined>(undefined)
  const [page, setPage] = useState<number>(1)
  const [maxPages, setMaxPages] = useState<number>(4)
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [openFileType, setOpenFileType] = useState<boolean>(false)

  const ref = useRef<HTMLDivElement>(null)

  const handleMouseDown = () => {
    ref.current?.addEventListener("mousemove", handleMouseMove)
  }

  const handleMouseUp = () => {
    ref.current?.removeEventListener("mousemove",  handleMouseMove)
  }

  const handleMouseMove = (e) => {
    if(ref.current) {
      ref.current.scrollLeft -= e.movementX
    }
  }

  const handleScroll = (e) => {
    if(!ref.current) return
    if((ref.current.scrollWidth > ref.current.clientWidth + ref.current.scrollLeft || e.deltaY < 0)
      && (ref.current.scrollLeft !== 0 || e.deltaY > 0)
    ) {
      const isTouchPad = e.wheelDeltaY ? e.wheelDeltaY === -3 * e.deltaY : e.deltaMode === 0
      if(!isTouchPad) {
        e.preventDefault()
        ref.current.scrollLeft += e.deltaY;
      }
    }
  }

  useEffect(() => {
    if(ref.current) {
      const item = ref.current
      item.addEventListener("mousedown", handleMouseDown)
      item.addEventListener("mousewheel", handleScroll)
      window.addEventListener("mouseup", handleMouseUp)

      return () => {
        item.removeEventListener("mousedown", handleMouseDown)
        item.removeEventListener("mousewheel", handleScroll)
        window.removeEventListener("mouseup", handleMouseUp)
      }
    }
  }, [ref.current])

  const setAllSelected = (oldData) => {
    try {
      setData({ timePeriods: [...oldData?.timePeriods], organization: { ...oldData?.organization, isSelected: true, regionsToggle: oldData?.organization?.regions?.map(region => Number(region.id)) }})
    } catch (e) {
      Sentry.captureException("Could not set all selected in data table ! " + e)
      setData(undefined)
    }
  }

  useEffect(() => {
    const rows = loadPaginationFromLocalStorage(userContext.currentOrg.id)
    if(rows) setRowsPerPage(rows)
  }, [userContext.currentOrg])

  useEffect(() => {
    if(props.data) {
      setAllSelected(props.data)
      // setAllSelected(testData)
    } else {
      setData(undefined)
    }
  }, [props.data])

  const facilitiesLength = data?.organization.regions
    .filter((item) => data?.organization.regionsToggle
      .includes(Number(item.id)))
    .map(region => region.facilities.length)
    .reduce(add, 0)

  const regionsLength = data?.organization.regions.length

  const handleDataChange = () => {
    try {
      if(!data) return
      let length = 1
      if (data.organization.isSelected) {
        length += regionsLength + facilitiesLength
      }
      setMaxPages(Math.ceil(length / rowsPerPage))
    } catch (e) {
      Sentry.captureException("Could not set max pages in data table !" + e)
    }
  }

  useEffect(() => {
    handleDataChange()
  }, [data])

  const handleRowsPerPageChange = () => {
    try {
      if(!data) return
      let length = 1
      if (data.organization.isSelected) {
        length += regionsLength + facilitiesLength
      }
      const maxPage = Math.ceil(length / rowsPerPage)
      setMaxPages(Math.ceil(length / rowsPerPage))
      if(page > maxPage) setPage(maxPage)

      if(allRows < rowsPerPage) {
        setAllSelected(props.data)
      }
    } catch (e) {
      Sentry.captureException("Could not rows per page in data table !" + e)
    }
  }

  useEffect(() => {
    handleRowsPerPageChange()
  }, [rowsPerPage])


  const handleSelectChange = (newValue: any) => {
    savePaginationToLocalStorage(userContext.currentOrg.id, newValue)
    setRowsPerPage(newValue)
  }

  const getBasis = () => {
    return (100 / props.headerItems.length) + "%"
  }

  const allRows = useMemo(() => {
    try {
      if(data && data?.organization.isSelected) return regionsLength + facilitiesLength + 1
      else return 1
    } catch (e) {
      Sentry.captureException("Could not get number of all rows ! " + e)
      return 0
    }
  }, [data?.organization])

  const parseTimePeriodLabel = (label: string) : string => {
    const parts = label.split(" ")
    if(parts[1].includes("Q")) return parts[1] + " " + parts[0]
    return label
  }

  const getRowItem = (data: any, item: HeaderItem, periodIndex: number, name?: string) => {
    try {
      if (!data) return
      const rowItem = data[item.id][periodIndex]
      switch (item.type) {
        case 'url':
          const canDownload = Boolean(rowItem.detail_url) && rowItem.value !== "0"
          if (rowItem && typeof rowItem !== "string") return (
            <div
              onClick={() => {
                if(props.downloadDetail && canDownload) props.downloadDetail(rowItem.detail_url + '&quality_measure=' + props.quality_measure, name, props.data.timePeriods[periodIndex])
              }}
              style={{marginLeft: canDownload ? -23 : 0}}
              className={`${canDownload ? "urlItem" : ""}`}
            >
              {canDownload && <DownloadIcon />} {rowItem.value}
            </div>
          )
          return
        default: {
          if (rowItem && typeof rowItem === "string") return (
            <div>
              {rowItem}
            </div>
          )
        }
      }
    } catch (e) {
      handleError({id: "RenderPeriodTableRowItem", error: e, track: true, customHandle: true})
    }
  }


  useOnClickOutside(downloadPopup, () => setOpenFileType(false))

  if(!data || !data.organization || !data.organization.regions || data.organization.regions.length === 0) {
    return <div className={"dataTableWrapper"}>
      {props.headerElement}
      <div className={'center'}>
        No Data to Display
      </div>
    </div>
  }

  function renderLabel(item: HeaderItem, index: number) {
    if (item.info) {
      return (
        <Tooltip
          arrow={false}
          overlayInnerStyle={{
            background: '#112443',
            textAlign: 'left'
          }}
          title={item.info}
        >
          <div key={index} className={'header'}>
            <Flex>
              <MoreInfo style={{paddingTop: '4px', width: '20px', height: '20px', marginRight: '4px'}}/>
              {item.label}
            </Flex>
          </div>

        </Tooltip>
      )
    } else
      return (
        <div key={index} className={'header'}>
          {item.label}
        </div>
      )
  }

  return (
    <>
      {props.headerElement}
      <div className={"dataTableWrapper"}>
        <>
            <div className={"contentWrapper"}>
              <div className={"tableSidebar"}>
                <div className={"tableSidebarHeader"} />
                <div className={'header'}>
                  {props.mainTitle}
                </div>
                <div className={"data"}>
                  {data && <OrganizationNameCollapse organization={data?.organization} setData={setData} rowsPerPage={rowsPerPage} page={page} allRows={allRows}/> }
                </div>
              </div>
              <div ref={ref} className={"content"}>
                {data?.timePeriods?.map((period, periodIndex) => {
                  return (
                    <div key={periodIndex} className={"periodItem"}>
                      <div className={"periodWrapper"}>
                        <div className={"period"}>{parseTimePeriodLabel(period)}</div>
                      </div>
                      <div className={"headerItem"}>
                        <div className={"headerWrapper"} style={{gridTemplateColumns: `repeat(${props.headerItems?.length}, 1fr)`}} >
                          {props.headerItems?.map((item, index) => {
                            return (
                              renderLabel(item, index)
                            )
                          })}
                        </div>
                      </div>
                      <div className={"animation"} style={{maxHeight: rowsPerPage * page > allRows ? 56 * (rowsPerPage - ((rowsPerPage * page) - allRows)) : 56 * rowsPerPage, overflow: 'hidden'}}>
                        <div className={"animation"} style={{transform: allRows > rowsPerPage ? `translateY(${-(page - 1) * rowsPerPage * 56}px)` : 'translateY(0)'}}>
                          <div className={"dataItem"} >
                        <div className={"dataWrapper"}>
                          {props.headerItems?.map((item) => {
                            return (
                              <div key={item.id} className={"dataItem"} style={{flexBasis: getBasis()}}>
                                {data && getRowItem(data?.organization?.data, item, periodIndex)}
                              </div>
                            )
                          })}
                        </div>
                        <div className={"animation"} style={getAnimationStyle(data.organization?.isSelected, Math.min(allRows, rowsPerPage - 1))}>
                          {data?.organization?.regions?.map((region) => {
                            return (
                              <>
                                <div key={region.id} className={"dataItem"}>
                                  <div className={"dataWrapper"}>
                                    {props.headerItems?.map((item) => {
                                      return (
                                        <div key={item.id} className={"dataItem region"} style={{flexBasis: getBasis()}}>
                                          {getRowItem(region.data, item, periodIndex, region.name)}
                                        </div>
                                      )
                                    })}
                                  </div>
                                  {data.organization.regionsToggle.includes(Number(region.id)) && <div>
                                    {region.facilities.map((facility) => {
                                      return (
                                        <div key={facility.id} className={"dataItem"}>
                                          <div className={"dataWrapper"}>
                                            {props.headerItems?.map((item) => {
                                              return (
                                                <div key={item.id} className={"dataItem facility"} style={{flexBasis: getBasis()}}>
                                                  {getRowItem(facility.data, item, periodIndex, facility.name)}
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>}
                                </div>
                              </>
                            )
                          })}
                        </div>
                      </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
            <SaivaPagination
              total={allRows}
              page={page}
              pageSize={rowsPerPage}
              onChange={setPage}
              onShowSizeChange={handleSelectChange}
            />
          </>
      </div>
    </>
  )

}

export default DataTable

