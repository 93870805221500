import { compareArrays } from 'utils/helper'

type labelValue = {
  id: string,
  label: string
}

export function normalizeData(values: labelValue[] | string[]): string[] {
  const normalisedValues: string[] =  values?.map((value) => {
    if (value && value.id) {
      return value.id
    } else return value
  })
  return [...new Set(normalisedValues)]
}

export function changedFilters(filters, selectedFilters) {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      let newFilter = normalizeData(filters[key])
      let newSelectedFilter = normalizeData(selectedFilters[key])
      if (selectedFilters[key] && filters[key]) {
        const selectedValues = Object.values(newSelectedFilter)
        const filterValues = Object.values(newFilter)

        const hasChanged = selectedValues.some(
          (value) => !filterValues.includes(value)
        )
        if (hasChanged) {
          return key
        }
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

export function filtersSelectedAll(filters, selectedFilters) {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      if (selectedFilters[key] && filters[key]) {
        let newFilter = normalizeData(filters[key])
        let newSelectedFilter = normalizeData(
          selectedFilters[key]
        )
        const selectedValues = Object.values(newSelectedFilter)
        const filterValues = Object.values(newFilter)

        // Check if no values are selected but there are filter values available
        if (selectedValues.length === 0 && filterValues.length > 0) {
          return key
        }

        // Compare arrays using a custom comparison function
        if (compareArrays(filterValues, selectedValues)) {
          return key
        }
      } else if (
        selectedFilters[key] == undefined ||
        filters[key] == undefined
      ) {
        return key
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

export function saveFiltersToLocalStorage(
  orgId: string,
  filter: any,
  id: string
) {
  try {
    const res = window.localStorage.getItem(id)
    let value = res ? JSON.parse(res) : undefined
    if (value && filter)
      window.localStorage.setItem(
        id,
        JSON.stringify({
          ...value,
          [orgId]: filter
        })
      )
    else
      window.localStorage.setItem(
        id,
        JSON.stringify({
          [orgId]: filter
        })
      )
  } catch (e) {}
}

export function loadFiltersFromLocalStorage(
  orgId: string,
  id: string
): any | undefined {
  try {
    const res = window.localStorage.getItem(id)
    if (res && JSON.parse(res)[orgId]) {
      return JSON.parse(res)[orgId]
    } else return undefined
  } catch (e) {
    return undefined
  }
}
