import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useUserContext } from 'context/UserContext'
import { Divider, Button, Progress, Modal, Collapse, Tooltip, Space  } from 'antd'
import type { CollapseProps } from 'antd'
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ArrowIconDown } from 'assets/icons/arrowDown.svg'
import { ReactComponent as LessIcon } from 'assets/icons/less.svg'
import { ReactComponent as MoreIcon } from 'assets/icons/more.svg'
import styles from './FacilitiesSelectWounds.module.scss'
import SaivaCheckbox from 'components/SaivaForm/SaivaCheckbox/SaivaCheckbox'
import { compareArrays } from 'utils/helper'
import { showErrorToast } from '../../../utils'
import { useTranslation } from 'react-i18next'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import { mixpanelInstance } from 'utils/mixpanel'

const max_number_of_facilities = Number(process.env.REACT_APP_MAX_NUMBER_OF_FACILITIES)

interface ComponentProps {
  options?: any
  items: any | (() => Promise<any>) | undefined
  selectedItems: any
  onChange: (value: any) => void
  width?: number
}

const FacilitiesSelectCard = ({ onChange, items, selectedItems, options, width }: ComponentProps) => {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const [regions, setRegions] = useState<any | undefined>([])
  const [selectedRegions, setSelectedRegions] = useState<any | undefined>([])
  const [selectedFacilities, setSelectedFacilities] = useState<any>([])
  const [allFacilities, setAllFacilities] = useState<any>([])
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState<string>('')
  const [height, setHeight] = useState<number>(99)
  const [boxHeight, setBoxHeight] = useState<number>(0)
  const [boxFullHeight, setBoxFullHeight] = useState<boolean>(false)
  const ref = React.useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (ref.current) {
      if (ref.current.offsetHeight) {
        setBoxHeight(ref.current.offsetHeight)
      }
    }
  })

  const sendMixpanelEvent = (regions) => {
    const mixpanelRegions = regions
      .filter((region) => region.facilities.length > 0)
      .map((item) => {
        return item.name
      })
    const mixpanelFacilities = regions
      .map((item) => {
        {
          return item.facilities.map((i) => {
            return i.name
          })
        }
      })
      .flat(1)
    const mixpanelRegionsIds = regions
      .filter((region) => region.facilities.length > 0)
      .map((item) => {
        return item.id
      })
    const mixpanelFacilitiesIds = regions
      .map((item) => {
        {
          return item.facilities.map((i) => {
            return i.id
          })
        }
      })
      .flat(1)
    mixpanelInstance.facilitySelect(
      mixpanelFacilities,
      mixpanelFacilitiesIds,
      mixpanelRegions,
      mixpanelRegionsIds,
      userContext.currentRole,
      options
    )
  }

  const getItems = async () => {
    if (items) {
      const facilities = items
        ?.map((item) => {
          return item.facilities.map((i) => {
            return { id: i.id, name: i.name }
          })
        })
        .flat(1)
      setRegions(items)
      setAllFacilities(facilities)
    }
  }

  const getSelectedItems = async () => {
    if (selectedItems) {
      const facilities = selectedItems
        ?.map((item) => {
          return item.facilities.map((i) => {
            return { id: i.id, name: i.name }
          })
        })
        .flat(1)
      setSelectedRegions(selectedItems)
      setSelectedFacilities(facilities)
    }
  }

  useEffect(() => {
    getItems()
  }, [items])

  useEffect(() => {
    getSelectedItems()
  }, [selectedItems])

  const closeModal = () => {
    if (selectedFacilities.length === 0) {
      showErrorToast(t('optionSelect.error.oneOrMoreItem'))
    } else if (selectedFacilities.length > max_number_of_facilities) {
      showErrorToast(t('optionSelect.error.maxFacilities', {number: max_number_of_facilities}))
    }
    else {
      setSearch('')
      setIsOpen(false)
    }
  }

  const handleRemoveItem = (item) => {
    if (selectedFacilities.map((i) => i.id).includes(item.id)) {
      if (selectedFacilities.length === 1) {
        showErrorToast(t('optionSelect.error.oneOrMoreItem'))
      } else {
        const facilities = selectedFacilities.filter((i) => i.id !== item.id)
        const newSelectedRegions = selectedRegions.map((region) => {
          return {
            ...region,
            facilities: region.facilities.filter((i) => i.id !== item.id)
          }
        })
        setSelectedFacilities(facilities)
        onChange(newSelectedRegions)
        sendMixpanelEvent(newSelectedRegions)
      }
    } else {
      if ([...selectedFacilities, item].length > max_number_of_facilities) {
        showErrorToast(t('optionSelect.error.maxFacilities', {number: max_number_of_facilities}))
      } else {
        const facilities = [...selectedFacilities, item]
        const newSelectedRegions = regions.map((region) => {
          return {
            ...region,
            facilities: region.facilities.filter((i) =>
              facilities.map((j) => j.id).includes(i.id)
            )
          }
        })
        setSelectedFacilities(facilities)
        onChange(newSelectedRegions)
        sendMixpanelEvent(newSelectedRegions)
      } 
    }
  }

  const handleSelectAllFromRegion = (item) => {
    const valueSelectedAll = regions.map((region) => {
      if (region.id !== item.id) {
        return {
          ...region,
          facilities: region.facilities.filter((i) =>
            selectedFacilities.map((j) => j.id).includes(i.id)
          ),
          isSelected: false
        }
      } else {
        const selectedAllFromRegion = compareArrays(
          region.facilities.map((i) => i.id),
          region.facilities
            .filter((i) => selectedFacilities.map((j) => j.id).includes(i.id))
            .map((i) => i.id)
        )
        if (!selectedAllFromRegion) {
          if (selectedFacilities.length + region.facilities.filter(
            (i) => !selectedFacilities.map((j) => j.id).includes(i.id)
          ).length > max_number_of_facilities) {
            showErrorToast(t('optionSelect.error.maxFacilities', {number: max_number_of_facilities}))
            setSelectedFacilities(selectedFacilities)
            return {
              ...region,
              facilities: region.facilities.filter(
                (i) => selectedFacilities.map((j) => j.id).includes(i.id)
              ),
              isSelected: false
            }
          } else {
            setSelectedFacilities([
              ...selectedFacilities,
              ...region.facilities.filter(
                (i) => !selectedFacilities.map((j) => j.id).includes(i.id)
              )
            ])
            return {
              ...region,
              facilities: region.facilities,
              isSelected: false
            }
          }
        } else {
          setSelectedFacilities(
            selectedFacilities.filter(
              (i) => !region.facilities.map((j) => j.id).includes(i.id)
            )
          )
          return {
            ...region,
            facilities: [],
            isSelected: false
          }
        }
      }
    })
    onChange(valueSelectedAll)
    sendMixpanelEvent(valueSelectedAll)
  }

  const filteredRegions = regions.filter((item) => {
    return (
      item.facilities
        .map((child) => {
          return child.name.toLowerCase().includes(search.toLowerCase())
        })
        .includes(true) ||
      item.name.toLowerCase().includes(search.toLowerCase())
    )
  })

  const collapseParents: CollapseProps['items'] =
    filteredRegions &&
    filteredRegions.map((item) => {
      const selectedAllFromRegion = compareArrays(
        item.facilities.map((i) => i.id),
        item.facilities
          .filter((i) => selectedFacilities.map((j) => j.id).includes(i.id))
          .map((i) => i.id)
      )
      return {
        key: Number(item.id),
        label: (
          <div className={styles.selectAllbuttonWrapper}>
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={item.name}
            >
              <div className="elipsis">{item.name}</div>
            </Tooltip>
            <button
              className={styles.selectAllbutton}
              onClick={() => {
                handleSelectAllFromRegion(item)
              }}
            >
              {selectedAllFromRegion ? 'Deselect all' : 'Select all'}
            </button>
          </div>
        ),
        children: (
          <div key={item.value}>
            <ul style={{ padding: '0px' }}>
              {item.facilities.map((child) => {
                if (
                  child.name.toLowerCase().includes(search.toLowerCase()) ||
                  item.name.toLowerCase().includes(search.toLowerCase())
                ) {
                  return (
                    <li key={child.id} className={styles.checkbox}>
                      <SaivaCheckbox
                        checked={selectedFacilities
                          .map((i) => {
                            return i.id
                          })
                          .includes(child.id)}
                        onChange={() => handleRemoveItem(child)}
                      />
                      <div className={`${styles.facilityNameModal} elipsis`}>
                        {child.name}
                      </div>
                    </li>
                  )
                }
              })}
            </ul>
          </div>
        )
      }
    })

  return (
    <>
      {regions && (
        <div className={styles.box} style={{width: width ? width + 'px' : "100%"}}>
          <Modal
            closeIcon={<CloseIcon />}
            width={'384px'}
            footer={
              <>
                <Divider className={styles.fullWidthDivider} />
                <div className={styles.footerModal}>
                  <Button
                    onClick={() => closeModal()}
                    disabled={selectedFacilities.length === 0}
                    className={styles.applyButton}
                  >
                    Apply
                  </Button>
                </div>
              </>
            }
            wrapClassName={styles.modal}
            open={isOpen}
            onCancel={closeModal}
          >
            <div style={{ padding: '50px 20px 0px 20px' }}>
              <SaivaSearch
                placeholder={t('search.text')}
                filters={false}
                activeFilters={false}
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                value={search}
                content={<></>}
              />
              {filteredRegions.length > 0 && (
                <div className={styles.selectAll}>
                  <SaivaCheckbox
                    checked={selectedFacilities.length === allFacilities.length}
                    all={true}
                    onChange={() => {
                      if (selectedFacilities.length === allFacilities.length) {
                        setSelectedFacilities([])
                      } else {
                        if (allFacilities.length > max_number_of_facilities) {
                          showErrorToast(t('optionSelect.error.maxFacilities', {number: max_number_of_facilities}))
                        } else {
                          setSelectedFacilities(allFacilities)
                          onChange(regions)
                        }
                      }
                    }}
                  />
                  <div className={`${styles.facilityNameModal} elipsis`}>
                    Select All
                  </div>
                </div>
              )}
            </div>
            <Divider className={styles.fullWidthDivider} />
            <div className={styles.facilitiesList}>
              {filteredRegions.length > 0 ? (
                <Collapse
                  collapsible="icon"
                  className={styles.collapseContent}
                  defaultActiveKey={regions.map((item) => {
                    return item.id.toString()
                  })}
                  ghost
                  expandIcon={() => <ArrowIconDown />}
                  items={collapseParents}
                />
              ) : (
                <div className={styles.noResults}>No results found</div>
              )}
            </div>
          </Modal>
          <table className={styles.header}>
            <tbody>
              <tr>
                <td>
                  <h5>Facilities</h5>
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Button className={styles.actionButton} onClick={() => setIsOpen(true)} disabled={regions.length === 0}>
                    <EditIcon />
                    <Space style={{ paddingLeft: '10px' }}>Manage Facilities</Space>
                  </Button>
                </td>
              </tr>
              <tr>
                <td>
                  <small>
                    Showing {selectedFacilities.length}/{allFacilities.length}
                  </small>
                </td>
                <td>
                  <div style={{ width: 80, marginRight: '10px', marginLeft: 'auto' }}>
                    <Progress
                      strokeColor="#A03AFD"
                      trailColor="rgba(160, 58, 253, 0.2)"
                      percent={
                        (selectedFacilities.length / allFacilities.length) * 100
                      }
                      showInfo={false}
                      size="small"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <Divider style={{ margin: '8px 0px' }} />
          <div className={styles.facilities} style={{ maxHeight: height }}>
            <div ref={ref}>
              {selectedRegions &&
                selectedRegions.map((item) => {
                  return (
                    <>
                      {item.facilities && item.facilities.length > 0 && (
                        <div key={item.value}>
                          <div
                            style={{
                              color: '#11244399',
                              padding: '0px 8px',
                              fontSize: '12px'
                            }}
                            className={'elipsis'}
                          >
                            {item.name}
                          </div>
                          <Divider style={{ margin: '3px 0px 8px 0px' }} />
                          <ul>
                            {item.facilities.map((child) => {
                              return (
                                <li key={child.id} className={styles.listItem}>
                                  <div
                                    className={`${styles.facilityName} elipsis`}
                                  >
                                    {child.name}
                                  </div>
                                  <Button
                                    icon={<CrossIcon />}
                                    type="text"
                                    onClick={() => handleRemoveItem(child)}
                                  />
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      )}
                    </>
                  )
                })}
            </div>
          </div>
          {boxHeight > height && !boxFullHeight && (
            <>
              <Divider style={{ margin: '8px 0px' }} />
              <Button className={styles.actionButton} 
                onClick={() => {
                  setHeight(500)
                  setBoxFullHeight(true)
                }}
              >
                <Space style={{ paddingRight: '10px' }}>Show more</Space>
                <MoreIcon style={{ marginLeft: 8 }} />
              </Button>
            </>
          )}
          {boxFullHeight && (
            <>
              <Divider style={{ margin: '8px 0px' }} />
              <Button className={styles.actionButton} 
                onClick={() => {
                  setHeight(99)
                  setBoxFullHeight(false)
                }}
              >
                <Space style={{ paddingRight: '10px' }}>Show less</Space>
                <LessIcon style={{ marginLeft: 8 }} />
              </Button>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default FacilitiesSelectCard
