import React, { useEffect, useState } from 'react'
import styles from './SaivaRadioButton.module.scss'
import { error } from 'console'

interface Props {
  values: { value: string; label: string }[]
  value?: any
  label: string
  name: string
  inputType: string
  returnType: string
  width: number | string
  height: number
  fieldHeight?: number
  disabled?: boolean
  errors?:
    | { message?: string | undefined; type?: string | undefined }
    | undefined
  onChange?: any
}

export default function SaivaRadioButton(props: Props) {
  const [selected, setSelected] = useState<string[]>([])
  const [valuesIds, setValuesIds] = useState<string[]>([])

  useEffect(() => {
    setValuesIds(props.values.map((item) => item.value))
    setSelected(props.value)
  }, [props.values, props.inputType, props.value])

  useEffect(() => {
    if (
      props.inputType == 'radio' &&
      props.returnType == 'array' &&
      props.value.length > 0
    ) {
      setSelected([props.value[0]])
      props.onChange([props.value[0]])
    }
  }, [props.inputType])

  const handleChange = (e) => {
    if (props.returnType == 'string' && props.inputType == 'radio') {
      const values = valuesIds.filter(
        (item) => item == e.target.value && !selected?.includes(e.target.value)
      )
      setSelected(selected.concat(values))
      props.onChange(e.target.value)
    } else if (props.returnType == 'array' && props.inputType == 'radio') {
      setSelected([e.target.value])
      props.onChange([e.target.value])
    } else {
      if (!e.target.checked) {
        setSelected(selected.filter((item) => item != e.target.value))
        props.onChange(selected.filter((item) => item != e.target.value))
      } else {
        const values = valuesIds.filter(
          (item) => item == e.target.value && !selected?.includes(e.target.value)
        )
        setSelected(selected.concat(values))
        props.onChange(selected.concat(values))
      }
    }
  }
  return (
    <>
      <label
        className={`
          ${!!props.errors && styles.labelErrors}
          saivaLabel
        `}
      >
        {props.label}
      </label>
      <div
        style={{ height: props.fieldHeight ? props.fieldHeight + 'px' : '44px'}}
        className={`
          ${styles.radioButton}
          ${!!props.errors && styles.radioButtonsErrors}
        `}
      >
        {props.values.map((label, i) => (
          <div
            key={i}
            style={{ minWidth: props.width, height: props.height + 'px' }}
          >
            <input
              style={{ width: '100%', height: props.height + 'px' }}
              type={props.inputType}
              value={label.value}
              name={props.name}
              disabled={props.disabled}
              onChange={handleChange}
              checked={selected?.includes(label.value)}
            />
            <label style={{ lineHeight: props.height - 4 + 'px' }}>
              {label.label}
            </label>
          </div>
        ))}
      </div>
      {!!props.errors && (
        <small className={styles.errorMessage}>{props.errors?.message}</small>
      )}
    </>
  )
}
