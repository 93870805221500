import { useEffect, useState } from 'react'
import { useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { AuthService, saveUserToStorage } from 'services/auth'
import { useNavigate } from 'react-router-dom'
import LoadingIcon from 'assets/pill-loader.gif'
import { ReactComponent as DeclinedIcon } from 'assets/icons/cross-circle.svg'
import { Button } from 'antd'
import styles from './MobileOTPLogin.module.scss'

type MobileOTPLoginProps = {
  email: string
  user_id?: number
  handleSwitchToEmailOTP: () => void
  handleSwitchToEmail: (error: Error | null) => void
}

const mobileLoginExpiryTime: number =
  Number(process.env.REACT_APP_MOBILE_LOGIN_EXPIRY_TIME) || 60000 // delay 1 minute

const mobileLoginSequenceTime: number = 5000 // 5 sec interval

export default function MobileOTPLogin({
  email: email,
  user_id: user_id,
  handleSwitchToEmailOTP: handleSwitchToEmailOTP,
  handleSwitchToEmail: handleSwitchToEmail
}: MobileOTPLoginProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userContext = useUserContext()
  const [loading, setLoading] = useState(false)
  const [loginStatusRes, setLoginStatusRes] = useState<any>(undefined)
  const [isDeclined, setIsDeclined] = useState<boolean>(false)

  useEffect(() => {
    if (email && user_id) {
      setLoading(true)
      getStatus(email, user_id)
    }
  }, [])

  useEffect(() => {
    if (!email || !loginStatusRes || !user_id) return

    let timeout: NodeJS.Timeout

    switch (loginStatusRes.status) {
      case 'Accepted':
        loginFCMOTP(loginStatusRes?.access_token, loginStatusRes?.refresh_token)
        setLoading(false)
        break
      case 'Pending':
        if (loading) {
          getStatus(email, user_id)
        }
        break
      case 'Declined' || 'Expired':
        setLoading(false)
        setIsDeclined(true)
        timeout = setTimeout(() => {
          handleSwitchToEmail(new Error(t('login.mobileOTP.errorDeclined')))
        }, mobileLoginSequenceTime)
        break
      case 'Failed':
      default:
        handleSwitchToEmail(new Error(t('login.mobileOTP.error')))
        setLoading(false)
        break
    }
    return () => clearTimeout(timeout)
  }, [loginStatusRes, loading])

  useEffect(() => {
    let requestTimeout = setInterval(() => {
      setLoading(false)
      if (email && user_id) {
        AuthService.patchFCMStatusExpired(user_id).then(() => {
          handleSwitchToEmail(new Error(t('login.mobileOTP.error')))
        }).catch(() => {
          handleSwitchToEmail(new Error(t('login.mobileOTP.error')))
        })
      } else {
        handleSwitchToEmail(new Error(t('login.mobileOTP.error')))
      }
    }, mobileLoginExpiryTime)

    return () => {
      clearInterval(requestTimeout)
    }
  }, [])

  function redirectAfterLogin() {
    const existingRedirect = window.location.search.split('?redirect=')[1]
    if (!existingRedirect || existingRedirect.includes('login')) {
      navigate('/')
    } else {
      navigate(existingRedirect)
    }
  }

  async function loginFCMOTP(accessToken: string, refreshToken: string) {
    const tokens = {
      access_token: accessToken,
      refresh_token: refreshToken
    }
    if (email) {
      saveUserToStorage(email, tokens)
    }
    userContext.loginFCMOTP({
      accessToken,
      refreshToken
    })
    redirectAfterLogin()
  }

  async function getStatus(email: string, user_id: number) {
    setTimeout(async () => {
      await AuthService.getFCMStatus(email, user_id).then(async (resp) => {
        setLoginStatusRes(resp)
      })
    }, mobileLoginSequenceTime)
  }

  function handleSwitchToOTP() {
    handleSwitchToEmailOTP()
  }

  function otpMessage() {
    return (
      <div>
        <h6>{t('login.mobileOTP.title')}</h6>
        <p>{t('login.mobileOTP.message')}</p>
        <Button type="link" onClick={() => handleSwitchToEmail(null)}>
          {t('login.mobileOTP.switchEmailOtpbutton')}
        </Button>
        <Button type="link" onClick={handleSwitchToOTP}>
          {t('login.mobileOTP.switchOTPOtpbutton')}
        </Button>
      </div>
    )
  }

  function otpDeclined() {
    return (
      <div>
        <div className={styles.logo}>
          <DeclinedIcon className={styles.declineIcon} />
        </div>
        <h6>{t('login.mobileOTP.declinedTitle')}</h6>
        <p>{t('login.mobileOTP.declinedMessage')}</p>
        <Button type="link" onClick={() => handleSwitchToEmail(null)}>
          {t('login.mobileOTP.switchEmailOtpbutton')}
        </Button>
        <Button type="link" onClick={handleSwitchToOTP}>
          {t('login.mobileOTP.switchOTPOtpbutton')}
        </Button>
      </div>
    )
  }

  return (
    <div className={styles.pageWrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {loading && (
            <div className={styles.logo}>
              <img src={LoadingIcon} width={72} />
            </div>
          )}
          {isDeclined ? otpDeclined() : otpMessage()}
        </div>
      </div>
    </div>
  )
}
