import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import styles from './AntdTable.module.scss'
import SaivaPagination from '../SaivaPagination/SaivaPagination'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation'

type TableProps = {
  columns: any
  data: any
  scroll?: any
  loading?: boolean
  pagination?: any
  rowSelection?: any
  expandable?: any
  onRow?: any
  rowKey?: any
  rowClassName?: any
}

const SaivaTable = (props: TableProps) => {
  const {
    columns,
    data,
    scroll,
    loading,
    pagination,
    rowSelection,
    expandable,
    onRow,
    rowKey,
    rowClassName
  } = props
  const { t } = useTranslation()
  return (
    <div className={`${!scroll && styles.tableWrapper}`}>
      <Table
        rowSelection={rowSelection}
        rowClassName={rowClassName}
        className={styles.table}
        columns={columns}
        dataSource={data}
        expandable={expandable}
        onRow={onRow}
        rowKey={rowKey}
        locale={{
          emptyText: loading ? (
            <></>
          ) : (
            <h5 className={styles.noData}>
              <EmptyDataIcon style={{ marginBottom: '10px' }} />
              {t('dashboard.cards.no-card-value')}
            </h5>
          )
        }}
        loading={{
          spinning: loading,
          indicator: <LoadingAnimation />
        }}
        pagination={false}
        scroll={scroll}
      />
      <SaivaPagination
        total={pagination.total}
        page={pagination.page}
        pageSize={pagination.pageSize}
        onChange={pagination.onChange}
        onShowSizeChange={pagination.onShowSizeChange}
      />
    </div>
  )
}

export default SaivaTable
