export enum AuthType {
  PUBLIC_KEY = "public_key"
}

export enum StatusType {
  CONNECTING = "connecting",
  CONNECTED = "connected",
  UPDATING = "updating",
  TESTING = "testing",
  DISCONNECTING = "disconnecting",
  DISCONNECTED = "disconnected",
  UNAVAILABLE = "unavailable",
}

export enum IntegrationType {
  SFTP_EXPORT = "sftp_export",
  CUSTOMER_API = "sdk_api",
  WEBHOOK = "webhook",
  UNKNOWN = "unknown"
}

export namespace SaivaIntegration {

  export interface CatalogItem {
    id: IntegrationType
    statusBase: StatusType | undefined,
    statusReceived?: Status,
    isActive: boolean,
    isEnabled: boolean,
    label: string,
    description: string,
    shortDescription: string,
    icon?: string,
    userGuideUrl?: string,
    values?: SFTP.Data | CustomerApi.Data  | Webhook.Data
  }


  export interface DirectCatalogItem<T> extends CatalogItem {
    id: T extends IntegrationType.SFTP_EXPORT ? IntegrationType.SFTP_EXPORT :
    T extends IntegrationType.CUSTOMER_API ? IntegrationType.CUSTOMER_API :
    T extends IntegrationType.WEBHOOK ? IntegrationType.WEBHOOK : IntegrationType.UNKNOWN
    values: T extends IntegrationType.SFTP_EXPORT ? SFTP.Data :
    T extends IntegrationType.CUSTOMER_API ? CustomerApi.Data :
    T extends IntegrationType.WEBHOOK ? Webhook.Data : undefined
  }

  export interface SFTPItem extends CatalogItem{
    id: IntegrationType.SFTP_EXPORT,
    values: SFTP.Data
  }

  export interface CustomerApiItem extends CatalogItem{
    id: IntegrationType.CUSTOMER_API,
    values: CustomerApi.Data
  }

  export interface WebhookItem extends CatalogItem{
    id: IntegrationType.WEBHOOK,
    values: Webhook.Data
  }

  export type AllIntegrations = CatalogItem[]

  export enum StatusOptions {
    IN_PROGRESS = "in_progress",
    COMPLETE = "complete",
    FAILED = "failed",
  }

  export enum ActionOptions {
    CONNECT = "connect",
    UPDATE = "update",
    DISCONNECT = "disconnect",
    TEST = "test",
  }

  export type Status = {
    status: StatusOptions
    action: ActionOptions
    filesRequested?: 0,
    filesUploaded?: 0,
    errorCode?: string
  } | undefined

  interface DataBase {
    isEnabled: boolean,
    status?: StatusType,
  }

  export namespace SFTP {
    export interface Data extends DataBase {
      hostname?: string,
      username?: string,
      authOption?: AuthType,
      publicKeyHash?: string,
      fileFormat?: string,
      reportTypes?: string[],
      facilityIds?: number[],
      riskListLengthRatio?: number,
    }
  }

  export namespace CustomerApi {
    export enum Scopes {
      SCOPE_DOWNLOAD_DAILY_RISK_REPORT = "scope_download_daily_risk_report"
    }

    export interface Data extends DataBase {
      apiTokens: Token[]
    }

    export interface Token {
      id: number,
      name: string,
      scopes: Scopes[],
      apiToken?: string
    }
  }

  export namespace Webhook {
    export interface Data extends DataBase {
      created_at?: string,
      created_by?: number,
      id?: number,
      is_enabled?: boolean,
      organization_id?: string
      endpoints?: []
    }
  }

  export type DataType = SaivaIntegration.SFTP.Data | SaivaIntegration.CustomerApi.Data | SaivaIntegration.Webhook.Data

}