import { useEffect, useState, Fragment } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import { Row, Col, Button, Dropdown, Space, Modal } from 'antd'
import type { MenuProps } from 'antd'
import ReportService from 'services/report-service'
import CrossFacilityRiskTable from 'components/Tables/BasicTable/CrossFacilityRiskTable/CrossFacilityRiskTable'
import OptionSelect from 'components/OptionSelect/OptionSelectWounds/OptionSelect'
import FacilitiesSelectCard from 'components/OptionSelect/OptionSelectWounds/FacilitiesSelectWounds'
import RegionService from 'services/region-service'
import { SaivaSort, SortDirection } from 'services/utils/api-utils'
import { ReactComponent as FilterIcon } from 'assets/icons/filters.svg'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/cross.svg'
import styles from './CrossFacilityReport.module.scss'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import { showErrorToast } from 'utils'
import moment from 'moment'
import DatePickerCard, { Range } from 'components/DatePicker/SaivaNewDatePicker'
import { mixpanelInstance } from 'utils/mixpanel'
import { OrgProductFeature } from 'types/user-types'
import QualityMeasureSelector from 'components/QualityMeasureSelector/QualityMeasureSelector'
import { SaivaReport } from 'types/reports-types'
import SchedulesButton from '../../ScheduleReporting/ScheduleButton'
import { Schedules } from 'types/schedule-reports'
import { compareArrays } from 'utils/helper'
import { normalizeData } from '../utils'

interface Day {
  day: { start: Date; end: Date }
}

interface Filters {
  date_start: string
  date_end: string
  quality_measure: OrgProductFeature | string
  selectedRegions: any
  payers?: string[]
  physicians?: string[]
  hidden_columns?: string[]
  special_filter?: string
  search?: string
  facilitySearch?: string
  sort?: SaivaSort
  defaultDates?: Day
  facilityIds?: string[]
}

const parseDate = (date) => {
  let day = date.getDate().toString()
  let month = (date.getMonth() + 1).toString()
  let year = date.getFullYear()
  if (Number(day) < 10) {
    day = '0' + day
  }
  if (Number(month) < 10) {
    month = `0${month}`
  }
  return `${year}-${month}-${day}`
}

const saveFiltersToLocalStorage = (orgId: string, filter: any): void => {
  if (!orgId || !filter) {
    console.warn('Invalid orgId or filter provided.')
    return
  }

  try {
    const storedFilters = window.localStorage.getItem('crossFacilityFilters')
    const filters = storedFilters ? JSON.parse(storedFilters) : {}

    // Update the filters with the new filter for the given orgId
    const updatedFilters = {
      ...filters,
      [orgId]: filter
    }

    window.localStorage.setItem(
      'crossFacilityFilters',
      JSON.stringify(updatedFilters)
    )
  } catch (error) {
    console.error('Failed to save filters to localStorage:', error)
  }
}

const loadFiltersFromLocalStorage = (orgId: string): any | undefined => {
  try {
    const filters = window.localStorage.getItem('crossFacilityFilters')
    return filters ? JSON.parse(filters)[orgId] : undefined
  } catch (error) {
    console.error('Failed to load filters from localStorage:', error)
    return undefined
  }
}

const defaultDate = {
  day: {
    start: new Date(),
    end: new Date()
  }
}

let abortData = new AbortController()
let abortRegions = new AbortController()

const changedFilters = (filters: Filters, selectedFilters: Filters) => {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      if (selectedFilters[key] && filters[key]) {
        const selectedValues = Object.values(selectedFilters[key])
        const filterValues = Object.values(filters[key])

        return selectedValues.map((value) => {
          const valueIncluded = filterValues.includes(value)
          if (!valueIncluded) {
            return key
          }
        })
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

const filtersSelectedAll = (filters: Filters, selectedFilters: Filters) => {
  const keys = Object.keys(filters)
  const results = keys
    .map((key) => {
      if (selectedFilters[key] && filters[key]) {
        const selectedValues = Object.values(selectedFilters[key])
        const filterValues = Object.values(filters[key])
        if (selectedValues.length === 0 && filterValues.length > 0) {
          return key
        } else if (compareArrays(filterValues, selectedValues)) {
          return key
        }
      } else if (
        selectedFilters[key] == undefined ||
        filters[key] == undefined
      ) {
        return key
      }
    })
    .flat(1)
    .filter((item) => item)
  return [...new Set(results)]
}

const setUrlParam = (param: string, value: string) => {
  if ('URLSearchParams' in window) {
    var newurl = ''
    var searchParams = new URLSearchParams(window.location.search)
    searchParams.set(param, value)
    var newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams
    window.history.pushState({ path: newurl }, '', newurl)
  }
}

export function CrossFacilityReport() {
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }

  let query = useQuery()

  const quality_measure = query.get('qm')
    ? (query.get('qm') as OrgProductFeature)
    : undefined

  const defaultFilters = {
    date_start: moment().format('YYYY-MM-DD'),
    date_end: moment().format('YYYY-MM-DD'),
    quality_measure: quality_measure
      ? quality_measure
      : OrgProductFeature.MODEL_UPT,
    selectedRegions: []
  }

  function parseFilters(filters: Filters) {
    const facilities =
      filters.selectedRegions?.map((i) => i.facilities).flat() ?? []
    let newFilters = {
      facility_ids: [] as string[],
      facilities: [] as string[],
      payers: filters.payers,
      physicians: filters.physicians,
      hidden_columns: filters.hidden_columns,
      date_start: filters.date_start,
      date_end: filters.date_end,
      quality_measure: filters.quality_measure
    }

    facilities.forEach((i) => {
      newFilters.facility_ids.push(i.id)
      newFilters.facilities.push(i.name)
    })

    return newFilters
  }

  const { t } = useTranslation()
  const navigate = useNavigate()
  const today = new Date()
  const userContext = useUserContext()
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [filters, setFilters] = useState<Filters | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] =
    useState<Filters>(defaultFilters)
  const [stats, setStats] = useState<any>({})
  const [hideFilters, setHideFilters] = useState<boolean>(true)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(100)
  const [regions, setRegions] = useState<any>([])
  const [fileLoading, setFileLoading] = useState<boolean>(false)
  const [scheduleFilters, setScheduleFilters] = useState<any>(undefined)
  const [numberOfSelectedFilters, setNumberOfSelectedFilters] =
    useState<number>(0)
  const [modelReportOptions, setModelReportOptions] = useState<
    OrgProductFeature[] | undefined
  >([OrgProductFeature.MODEL_UPT])
  const [reportStructure, setReportStructure] = useState<
    SaivaReport.StructureItem | undefined
  >(undefined)
  const [activeQM, setActiveQM] = useState<OrgProductFeature>(
    quality_measure ? quality_measure : OrgProductFeature.MODEL_UPT
  )
  const [direction, setDirection] = useState<SortDirection | undefined>(
    undefined
  )
  const [defaultDates, setDefaultDates] = useState<Day>(defaultDate)
  const [pdfUrl, setPdfUrl] = useState<string | undefined>('')
  const [isOpenPdfModal, setIsOpenPdfModal] = useState<boolean | undefined>(
    false
  )
  const [facilityViewData, setFacilityViewData] = useState<any>([])
  const [patientViewData, setPatientViewData] = useState<any>([])
  const [activeView, setactiveView] = useState<string>('patient_view')

  useEffect(() => {
    abortData = new AbortController()
    abortRegions = new AbortController()
    return () => {
      abortData.abort()
      abortRegions.abort()
    }
  }, [])

  useEffect(() => {
    setSelectedFilters(defaultFilters)
    setRegions([])
    init()
  }, [userContext.currentOrg.id, activeQM])

  async function init() {
    const structure = fetchStructure()
    const regions = fetchRegions()

    await Promise.all([structure, regions]).then((res) => {
      const report = res[0]
      const localstorageFilters = loadFiltersFromLocalStorage(
        userContext.currentOrg.id
      )

      if (report && report.length > 0) {
        const avaliableQMs = report.map((item) => {
          return item.quality_measure
        })

        const activeQM =
          localstorageFilters &&
          localstorageFilters.quality_measure &&
          avaliableQMs.includes(localstorageFilters.quality_measure)
            ? localstorageFilters.quality_measure
            : report[0].quality_measure

        setModelReportOptions(avaliableQMs)
        if (
          localstorageFilters &&
          activeQM &&
          localstorageFilters.date_start &&
          localstorageFilters.date_end
        ) {
          const selectedReport = report.find(
            (item) => item.quality_measure === activeQM
          )
          localstorageFilters.defaultDates?.day
            ? setDefaultDates({
                day: {
                  start: new Date(localstorageFilters.defaultDates.day.start),
                  end: new Date(localstorageFilters.defaultDates.day.end)
                }
              })
            : setDefaultDates(defaultDate)
          setReportStructure(selectedReport)
          setActiveQM(activeQM)
          setDirection(localstorageFilters.sort?.direction)
          setUrlParam('qm', activeQM)
          setFilterAndRefetch(
            {
              ...localstorageFilters,
              quality_measure: activeQM,
              search: ''
            },
            false
          )
          mixpanelInstance.reportsNavigationChange(
            'CrossFacilityRisk',
            'cross_facility_risk_report',
            parseFilters({ ...localstorageFilters, quality_measure: activeQM })
          )
        } else {
          const filters = {
            ...defaultFilters,
            date_start: moment().format('YYYY-MM-DD'),
            date_end: moment().format('YYYY-MM-DD'),
            quality_measure: report[0].quality_measure,
            selectedRegions: res[1]
          }
          setReportStructure(report[0])
          setActiveQM(report[0].quality_measure)
          setUrlParam('qm', report[0].quality_measure)
          setDefaultDates(defaultDate)
          setFilterAndRefetch(filters, false)
          mixpanelInstance.reportsNavigationChange(
            'CrossFacilityRisk',
            'cross_facility_risk_report',
            parseFilters(filters)
          )
        }
      } else {
        navigate('/reports?error=noacces', { replace: true })
      }
      setRegions(res[1])
    })
  }

  useEffect(() => {
    if (regions.length > 0) {
      setFilterAndRefetch(selectedFilters, true)
    }
  }, [regions.length])

  useEffect(() => {
    const maxPage = Math.ceil(data.length / rowsPerPage)
    const currentPage = Math.min(page, maxPage)
    const currentPageData = data.slice(
      (currentPage - 1) * rowsPerPage,
      currentPage * rowsPerPage
    )

    setPageData(currentPageData)
  }, [page, data, rowsPerPage])

  useEffect(() => {
    const compareLength = (array1, array2) =>
      array1?.length !== array2?.length ? 1 : 0

    let number = 0

    number += compareLength(filters?.payers, selectedFilters?.payers)
    number += compareLength(filters?.physicians, selectedFilters?.physicians)

    const flattenFacilities = (regionsArray) =>
      regionsArray?.map((region) => region.facilities).flat(1) || []

    number += compareLength(
      flattenFacilities(regions),
      flattenFacilities(selectedFilters?.selectedRegions)
    )

    setNumberOfSelectedFilters(number)
  }, [selectedFilters, filters, regions])

  useEffect(() => {
    switch (activeView) {
      case 'facility_view':
        setData(facilityViewData)
        break
      case 'patient_view':
        setData(patientViewData)
        break
      default:
        setData(patientViewData)
        break
    }
  }, [facilityViewData, patientViewData, activeView])

  const setFilterAndRefetch = (filter: Filters, refetch: boolean) => {
    setSelectedFilters((prev) => {
      const newFilters = { ...prev, ...filter }

      if (regions.length > 0 && activeQM) {
        const parsedFilters = parseFilters(newFilters)
        saveFiltersToLocalStorage(userContext.currentOrg.id, newFilters)
        setScheduleFilters(parseFilters(newFilters))
        if (refetch) {
          fetchReport({
            ...filter,
            facilityIds: parsedFilters.facility_ids
          })
        }
      }
      return newFilters
    })
  }

  const handleSortClick = (propertyName: string) => {
    setDirection((prev) => {
      const newDirection =
        prev === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending
      setFilterAndRefetch(
        {
          ...selectedFilters,
          sort: {
            property: propertyName,
            direction: newDirection
          }
        },
        true
      )
      return newDirection
    })
  }

  function onTabChange(key: string) {
    switch (key) {
      case 'facility_view':
        setData(facilityViewData)
        break
      case 'patient_view':
        setData(patientViewData)
        break
      default:
        setData(patientViewData)
        break
    }
    setFilterAndRefetch(
      {
        ...selectedFilters,
        facilitySearch: '',
        search: ''
      },
      true
    )
    setactiveView(key)
    mixpanelInstance.reportsTabSelect(
      'cross_facility_risk_report',
      key,
      scheduleFilters
    )
  }

  const handleSetDate = (date) => {
    const formattedDay = {
      day: {
        start: new Date(date.start),
        end: new Date(date.end)
      }
    }
    setFilterAndRefetch(
      {
        ...selectedFilters,
        date_start: moment(date.start).format('YYYY-MM-DD'),
        date_end: moment(date.end).format('YYYY-MM-DD'),
        defaultDates: formattedDay
      },
      true
    )
    setDefaultDates(formattedDay)
    mixpanelInstance.reportsDateSelect(date, {
      reportType: 'cross_facility_risk_report'
    })
  }

  const handleChangeQM = (qm: OrgProductFeature) => {
    setActiveQM(qm)
    setFilterAndRefetch(
      {
        ...selectedFilters,
        quality_measure: qm
      },
      true
    )
    setUrlParam('qm', qm)
  }

  const fetchStructure = async () => {
    const structure = await ReportService.getReportStructure(
      'cross_facility_risk_report',
      userContext.currentOrg.id
    ).then((res) => {
      return res
    })
    return structure
  }

  const fetchReport = async (
    selectedFilters: Filters,
    dynamicFilters?: Filters
  ) => {
    abortData.abort()
    abortData = new AbortController()
    setLoading(true)
    let filtersForRequest = selectedFilters
    let filtersForCompare = dynamicFilters ? dynamicFilters : filters
    if (filtersForCompare && selectedFilters) {
      const newSelectedAllFilters = filtersSelectedAll(
        filtersForCompare,
        selectedFilters
      )
      if (newSelectedAllFilters && newSelectedAllFilters.length > 0) {
        let newFilters = {}
        for (const filterKey of newSelectedAllFilters) {
          if (filterKey) {
            newFilters = {
              ...newFilters,
              [filterKey]: []
            }
          }
        }
        filtersForRequest = { ...selectedFilters, ...newFilters }
      }
    }
    await ReportService.getReportDataByURL(
      reportStructure ? reportStructure.url : '',
      userContext.currentOrg.id,
      filtersForRequest,
      abortData
    )
      .then(async (res) => {
        if (!res) return
        const filterIds = res.filters
        const newChangedFilters = changedFilters(filterIds, filtersForRequest)
        if (newChangedFilters && newChangedFilters.length > 0) {
          let newFilters = {}
          for (const filterKey of newChangedFilters) {
            if (filterKey) {
              newFilters = {
                ...newFilters,
                [filterKey]: filterIds[filterKey]
              }
            }
          }
          fetchReport({ ...selectedFilters, ...newFilters }, filterIds)
        }
        setLoading(false)
        setFacilityViewData(res.facility_view)
        setPatientViewData(res.rows)
        setStats(res.stats)
        setFilters((prev: Filters | undefined) => {
          if (prev && selectedFilters) {
            const newSelectedAllFilters = filtersSelectedAll(
              prev,
              selectedFilters
            )
            if (newSelectedAllFilters && newSelectedAllFilters.length > 0) {
              let newFilters = {}
              for (const filterKey of newSelectedAllFilters) {
                if (filterKey) {
                  newFilters = {
                    ...newFilters,
                    [filterKey]: filterIds[filterKey]
                  }
                }
              }
              setFilterAndRefetch({ ...selectedFilters, ...newFilters }, false)
            }
          }
          return res.filters
        })
      })
      .catch((err) => {
        if (err.name === 'CanceledError') {
          return
        } else if (err.errorCode === 'backend.analytics_facility_id_invalid') {
          window.localStorage.setItem(
            'crossFacilityFilters',
            JSON.stringify({
              [userContext.currentOrg.id]: undefined
            })
          )
          init()
        } else if (err.name === 'ApiError') {
          const QM = activeQM.split('_')[1].toUpperCase()
          showErrorToast(t(err.errorCode, { model_name: QM }))
          setLoading(false)
          setData([])
        } else {
          showErrorToast(t(err.errorCode))
          setLoading(false)
          setData([])
        }
        throw err
      })
  }

  const exportPdf = async () => {
    setFileLoading(true)
    await ReportService.exportCrossFacilityReportPDF(
      userContext.currentOrg.id,
      {
        ...selectedFilters,
        quality_measure: activeQM
      },
      activeView
    )
      .then((res) => {
        var file = new Blob([res], { type: 'application/pdf;base64' })
        var fileURL = URL.createObjectURL(file)
        setPdfUrl(fileURL)
        setIsOpenPdfModal(true)
        setFileLoading(false)
        mixpanelInstance.reportsFileDownload(
          'cross_facility_risk_report',
          fileURL,
          'pdf',
          scheduleFilters,
          activeView
        )
      })
      .catch((err) => {
        setFileLoading(false)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
  }

  const exportCSV = async () => {
    setFileLoading(true)
    await ReportService.exportCrossFacilityReportCSV(
      userContext.currentOrg.id,
      {
        ...selectedFilters,
        quality_measure: activeQM
      },
      activeView
    )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${parseDate(today)}-${activeView}-cross-facility-risk-export.csv`
        )
        document.body.appendChild(link)
        link.click()
        link.remove()
        setFileLoading(false)
        mixpanelInstance.reportsFileDownload(
          'cross_facility_risk_report',
          url,
          'csv',
          scheduleFilters,
          activeView
        )
      })
      .catch((err) => {
        setFileLoading(false)
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
  }

  const fetchRegions = async () => {
    const regions = await RegionService.getRegions(userContext.currentOrg.id)
      .then((res) => {
        return res
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          showErrorToast(t(err.errorCode))
        } else {
          showErrorToast(t(err.errorCode))
        }
        throw err
      })
    return regions
  }

  function showTotalTable(total, range) {
    return `${range[0]} - ${range[1]} from ${total}`
  }

  const pagination = {
    total: data.length,
    page: page,
    pageSize: rowsPerPage,
    showTotal: showTotalTable,
    onChange(newCurrent: number) {
      if (newCurrent === 0) {
        setPage(1)
      } else setPage(newCurrent)
    },
    onShowSizeChange(pageSize: number) {
      const lastPage = Math.ceil(data.length / pageSize)
      if (page > lastPage) {
        if (lastPage === 0) {
          setPage(1)
        } else setPage(lastPage)
      }
      setRowsPerPage(pageSize)
    }
  }

  const menu = [
    {
      key: '1',
      label: (
        <button onClick={() => exportPdf()} className={styles.dropdownButton}>
          Export PDF
        </button>
      )
    },
    {
      key: '2',
      label: (
        <button onClick={() => exportCSV()} className={styles.dropdownButton}>
          Export CSV
        </button>
      )
    }
  ]
  const items: MenuProps['items'] = menu
  const navigateToReportNavigation = () => {
    navigate('/reports')
  }

  const navigateToReport = (reportId: string) => {
    navigate('/reports/' + reportId)
  }

  const accessibleReportsInCategory = () => {
    return reportStructure?.allReportsInCategory
  }

  const getButton = (title: string, onClick: () => void, active: boolean) => {
    return (
      <button className={`${active && styles.active}`} onClick={onClick}>
        {title}
      </button>
    )
  }

  return (
    <div style={{ margin: '24px 0px' }}>
      <Modal
        closeIcon={<CloseIcon />}
        width={'100%'}
        className={styles.modal}
        footer={null}
        open={isOpenPdfModal}
        onCancel={() => setIsOpenPdfModal(false)}
      >
        <>
          {pdfUrl ? (
            <>
              <iframe
                src={pdfUrl}
                width={'100%'}
                style={{
                  height: '80vh',
                  width: '100%'
                }}
              ></iframe>
            </>
          ) : (
            <h5 className={styles.noData}>
              <EmptyDataIcon style={{ marginBottom: '6px' }} />
              <br />
              {t('dashboard.cards.no-card-value')}
            </h5>
          )}{' '}
        </>
      </Modal>
      <div className={styles.topNavigationWrapper} style={{ width: '100%' }}>
        <div
          style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}
        >
          <Button
            className={`${styles.actionButton} ${styles.backButton}`}
            onClick={navigateToReportNavigation}
          >
            <ArrowIcon /> BACK
          </Button>
          {!loading ? (
            <>
              {reportStructure?.allReportsInCategory.length === 1 ||
              accessibleReportsInCategory()?.length === 1 ? (
                <h6
                  className={'mb-0'}
                  style={{ fontSize: 20, lineHeight: '2.2' }}
                >
                  {reportStructure?.allReportsInCategory[0].label}
                </h6>
              ) : (
                <div className={styles.reportSelect}>
                  {reportStructure?.allReportsInCategory.map((report) => {
                    const isActive = report.id === 'cross_facility_risk_report'
                    return (
                      <Fragment key={report.id}>
                        {getButton(
                          report.label,
                          () => navigateToReport(report.id),
                          isActive
                        )}
                      </Fragment>
                    )
                  })}
                </div>
              )}
            </>
          ) : (
            <LoadingIcon />
          )}
        </div>
        <div style={{ marginLeft: 'auto' }}>
          {!loading && modelReportOptions && modelReportOptions.length > 1 && (
            <QualityMeasureSelector
              options={modelReportOptions}
              defaultoption={activeQM}
              setQM={(qm: OrgProductFeature) => {
                handleChangeQM(qm)
              }}
            />
          )}
        </div>
      </div>
      <div className={styles.topBar}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DatePickerCard
            rangeOptions={[Range.Day]}
            defaultValue={defaultDates}
            onDateChange={(date) => {
              if (date.start && date.end) {
                handleSetDate(date)
              }
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <Button
            className={`${styles.actionButton} ${styles.filtersButton}`}
            size="large"
            onClick={() => setHideFilters(!hideFilters)}
          >
            <FilterIcon style={{ marginRight: '8px', stroke: '#4070DC' }} />
            {hideFilters ? 'Show Filters' : 'Hide Filters'}{' '}
            {!loading && numberOfSelectedFilters > 0
              ? '(' + numberOfSelectedFilters + ')'
              : ''}
          </Button>

          <>
            {scheduleFilters && (
              <SchedulesButton
                loading={loading}
                reportId={'cross_facility_risk_report' as Schedules.ReportTypes}
                filtersData={{
                  ...scheduleFilters,
                  quality_measure: quality_measure || undefined,
                  type: 'daily',
                  date_start:
                    selectedFilters?.date_start ||
                    moment().format('YYYY-MM-DD'),
                  date_end:
                    selectedFilters?.date_end || moment().format('YYYY-MM-DD')
                }}
              />
            )}
          </>

          <>
            <Dropdown menu={{ items }} overlayClassName={styles.dropdown}>
              <Button className={styles.actionButton} size="large">
                {fileLoading ? <LoadingIcon /> : <DownloadIcon />}
                <Space style={{ paddingLeft: '10px' }}>Export</Space>
              </Button>
            </Dropdown>
          </>
        </div>
      </div>
      {filters && (
        <Row className={`topCards ${hideFilters && styles.hidden}`}>
          <Col
            xs={24}
            md={24 / 2}
            xl={24 / 3}
            xxl={24 / 4}
            style={{ padding: '12px' }}
          >
            <FacilitiesSelectCard
              key={0}
              items={regions}
              selectedItems={selectedFilters.selectedRegions}
              onChange={(value) => {
                setFilterAndRefetch(
                  {
                    ...selectedFilters,
                    selectedRegions: value
                  },
                  true
                )
              }}
            />
          </Col>
          {reportStructure?.filters.map((filter) => {
            if (filter.type == 'option_select_items' && filters[filter.id]) {
              return (
                <Col
                  xs={24}
                  md={24 / 2}
                  xl={24 / 3}
                  xxl={24 / 4}
                  style={{ padding: '12px' }}
                >
                  <OptionSelect
                    loading={loading}
                    label={filter.label}
                    items={filters[filter.id].map((f) => ({
                      id: f.id ? f.id : f,
                      label: f.label ? f.label : f
                    }))}
                    selectedItems={normalizeData(selectedFilters[filter.id])}
                    onChange={(value) => {
                      setFilterAndRefetch(
                        {
                          ...selectedFilters,
                          [filter.id]: value
                        },
                        true
                      )
                    }}
                    color={filter.color}
                  />
                </Col>
              )
            }
          })}
        </Row>
      )}
      <div style={{ padding: '12px' }}>
        <CrossFacilityRiskTable
          data={pageData}
          loading={loading}
          rowKey="id"
          onSearch={(e) => {
            setFilterAndRefetch(
              {
                ...selectedFilters,
                search: e.target.value,
                facilitySearch: ''
              },
              true
            )
          }}
          onFacilitySearch={(e) => {
            setFilterAndRefetch(
              {
                ...selectedFilters,
                facilitySearch: e.target.value,
                search: ''
              },
              true
            )
          }}
          pagination={pagination}
          handleSortClick={handleSortClick}
          filteredHeaders={
            selectedFilters?.hidden_columns
              ? selectedFilters?.hidden_columns
              : []
          }
          setFilteredHeaders={(value) =>
            setFilterAndRefetch(
              {
                ...selectedFilters,
                hidden_columns: value
              },
              true
            )
          }
          onChangeTab={onTabChange}
          activeView={activeView}
        />
      </div>
    </div>
  )
}
