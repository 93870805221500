import React, { useCallback, useEffect, useState } from 'react'
import IntegrationModalWrapper, { useIntegrationStatus } from '../IntegrationModal'
import { IntegrationType, SaivaIntegration, StatusType } from 'types/integration-types'
import { useFormContext } from 'context/FormProvider'
import IntegrationService, { IntegrationRequest } from 'services/integration-service'

import { SWMIcon } from 'react-swm-icon-pack'
import { useUserContext } from '../../../../context/UserContext'

import { ReactComponent as RegenerateIcon } from 'assets/icons/refresh-circle.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle.svg'
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg'

import styles from './SecretModal.module.scss'
import colors from 'styles/variables.module.scss'
import { useConfirmModal } from '../../../../components/ConfirmModal/ConfirmModal'
import { validateValue } from '../../../../utils/validation'
import SaivaMultiModal from '../../../../components/SaivaMultiModal/SaivaMultiModal'
import { SaivaMultiModalItem } from '../../../../components/SaivaMultiModal/SaivaModalItem/SaivaMultiModalItem'
import Input from '../../../../components/Form/Input'
import { copyToClipboard } from '../../../../utils/helper'
import { handleError } from '../../../../utils/errorHandler'
import { showErrorToast, showSuccessToast } from '../../../../utils'

import { mixpanelInstance } from 'utils/mixpanel'
import { useTranslation } from 'react-i18next'

interface ApiTokensModalProps {
  item: SaivaIntegration.CustomerApiItem
  updateItem: (item: Partial<SaivaIntegration.CustomerApiItem>) => void
  open: boolean,
  handleClose: () => void,
}

const GENERATE_TOKEN_EVENT = "generate-token"
let event = new Event(GENERATE_TOKEN_EVENT);

export default function ApiTokensModal(props: ApiTokensModalProps) {

  const { t } = useTranslation()

  const userContext = useUserContext()
  const formContext = useFormContext()
  const confirmModal = useConfirmModal()

  const {item, open, handleClose} = props
  const data = formContext.getValues<SaivaIntegration.CustomerApi.Data>()

  const {currentOrg, email} = userContext;
  const mixpanelEventsData = {
    integration_type: item?.id,
    organisation: currentOrg?.name,
    user: email
  }

  const [token, setToken] = useState<SaivaIntegration.CustomerApi.Token | undefined>(undefined)
  const [name, setName] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [isEditing, setEditingToken] = useState<number | undefined>(undefined)
  const [generateModal, setGenerateModal] = useState<boolean>(false)

  const addTokenTable = (token: SaivaIntegration.CustomerApi.Token | undefined) => {
    if(token) {
      if(data?.apiTokens) formContext.setValue<SaivaIntegration.CustomerApi.Data>({apiTokens: [...data.apiTokens, token]})
      else formContext.setValue<SaivaIntegration.CustomerApi.Data>({apiTokens: [token]})
    }
  }

  const updateTokenTable = (token: SaivaIntegration.CustomerApi.Token | undefined) => {
    if(data?.apiTokens && token) formContext.setValue<SaivaIntegration.CustomerApi.Data>({apiTokens: data.apiTokens.map(i => {if(i.id === token.id) return token; return i})})
  }

  const removeTokenFromTable = (tokenId: number | undefined) => {
    if(data?.apiTokens && tokenId) formContext.setValue<SaivaIntegration.CustomerApi.Data>({apiTokens: data.apiTokens.filter(i => i.id !== tokenId)})
    mixpanelInstance.integrationEventHandler(mixpanelEventsData, "integrationApiRemoveToken");
  }

  function getUpdatePayload() : IntegrationRequest.Update<IntegrationType.CUSTOMER_API> {
    const value: IntegrationRequest.CustomerApi.Update = {}
    value.is_enabled = data?.isEnabled
    return value
  }

  const handleOpenGenerate = () => {
    setToken(undefined)
    setGenerateModal(true)
  }

  const handleCloseGenerate = () => {
    setEditingToken(undefined)
    setGenerateModal(false)
    setError("")
    setName("")
  }

  const handleCloseTokenModal = () => {
    setToken(undefined)
  }

  const handleGenerateToken = async (handleConnect: () => Promise<boolean>, name: string) => {
    const {isValid, error} = validateValue(name,  [{required: true}])

    if(isValid) {
      if (item.statusBase === StatusType.DISCONNECTED) await handleConnect()
      try {
        const res = await IntegrationService.CustomerApi.generateAPIToken(userContext.currentOrg.id, {
          name,
          scopes: ["scope_download_daily_risk_report"]
        })
        setName("")
        handleCloseGenerate()
        setToken(res)
        addTokenTable(res)
        mixpanelInstance.integrationEventHandler(mixpanelEventsData, "integrationApiGenerateToken");

      } catch (e: any) {
        if(e?.errorCode === "backend.sdk_api_integration_api_token_name_exists") showErrorToast("Token with this name already exists.")
        else handleError({id: "GeneratingToken", error: e})
      }
    } else {
      setError(error)
    }
  }

  const handleRevokeToken = async (tokenId: number, handleDisconnect?: (confirmCheck?: boolean) => Promise<boolean>) => {
    const response = await confirmModal.open({title: "Are you sure you want to delete this token ?"})
    if(response) {
      await IntegrationService.CustomerApi.revokeAPIToken(userContext.currentOrg.id, tokenId)
      if(data?.apiTokens?.length === 1 && handleDisconnect) await handleDisconnect(false)
      removeTokenFromTable(tokenId)
      showSuccessToast("Token was successfully removed!")
      handleEditClose()
    }
  }

  const handleUpdateToken = async (tokenId: number) => {
    const res = await IntegrationService.CustomerApi.updateAPIToken(userContext.currentOrg.id, tokenId, {name, scopes: ["scope_download_daily_risk_report"]})
    setToken(res)
    updateTokenTable(res)
    handleEditClose()
  }

  const handleRegenerateToken = async (tokenId: number) => {
    const response = await confirmModal.open({title: "Are you sure you want to regenerate this token ?"})
    if(response) {
      const res = await IntegrationService.CustomerApi.regenerateAPIToken(userContext.currentOrg.id, tokenId)
      setToken(res)
    }
  }

  const handleEditClose = () => {
    setName("")
    setError("")
    handleCloseTokenModal()
  }

  const handleDisconnect = () => {
    setName("")
    setToken(undefined)
    setGenerateModal(false)
    setError("")
    showSuccessToast("Integration was successfully disconnected!")
    mixpanelInstance.integrationEventHandler(mixpanelEventsData, "integrationApiDisconnectToken");
  }

  useEffect(() => {
    if(open) {
      setName("")
      setToken(undefined)
      setGenerateModal(false)
    }
  }, [open])

  const getAdditionalModals = (handleConnect, handleDisconnect) => {
    return (
      <>
        <SaivaMultiModalItem
          open={Boolean(generateModal || isEditing)}
          handleClose={handleCloseGenerate}
          overlay
        >
          <div>
            <h4>{ isEditing ? "Regenerate" : "Generate" } Token</h4>
            <p>SAIVA API Development Token</p>
          </div>
          <Input
            id={"name"}
            value={name}
            onChange={(value) => {
              setName(value)
              setError("")
              if(value.length > 100) {
                setError(t("integrations.errors.nameMaxLength"))
              }
            }}
            placeholder={"Name"}
            error={error}
          />
          <div className={styles.buttons}>
            <button className={"primary-button"} onClick={() => {
              if(name.length <= 100) {
                handleGenerateToken(handleConnect, name)
              }
            }}>
              { isEditing ? "REGENERATE" : "GENERATE" }
            </button>
          </div>
        </SaivaMultiModalItem>
        <SaivaMultiModalItem
          open={Boolean(token)}
          handleClose={handleCloseTokenModal}
          isClosing={false}
          overlay
        >
          <div className={styles.tokenWrapper}>
            <div className={styles.info}>
              <CheckCircleIcon /> New Token was {isEditing ? "regenerated" : "created" } successfully.
            </div>
            <div className={styles.key}>
              <h6>Token</h6>
              <label>{token?.apiToken}</label>
              <div className={styles.icon}>
                <CopyIcon onClick={() => {
                  copyToClipboard(token?.apiToken, "Token")
                  handleCloseTokenModal()
                }} />
              </div>
            </div>
            <div className={styles.infoBottom}>
              {t("integrations.secretModal.keyPopup.infoLabel")}
            </div>
          </div>
        </SaivaMultiModalItem>
      </>
    )
  }

  const apiTokensLng = data?.apiTokens?.length || 0;

  return (
    <IntegrationModalWrapper
      <IntegrationType.CUSTOMER_API>
      open={open}
      handleClose={handleClose}
      handleDisconnect={handleDisconnect}
      item={item}
      updateItem={props.updateItem}
      getUpdatePayload={getUpdatePayload}
      additionalModals={getAdditionalModals}
      disconnectMessage={"This action is irreversible. You will lost all data and set tokens."}
      doNotAwaitStatus
      doNotCheckDirty
      hasDisconnectBtn={false}
      mixpanelEventsData={mixpanelEventsData}
    >
      {(handleConnect, handleDisconnect) => {
        return (<div>
          <div className={styles.tokenHeader}>
            <div>
              <h5>Tokens</h5>
              {apiTokensLng > 0 ?
                <p>You have {apiTokensLng} active API token(s)</p>
              :
                <p>No active API tokens: Click + to create a new API Token</p>}
            </div>
            <button className={"primary-text-button-small"} onClick={handleOpenGenerate}>
              <SWMIcon name={"PlusSmall"} color={colors["primary-blue"]}/>Create Token
            </button>
          </div>
          {apiTokensLng > 0 ?
            <>
              <div className={styles.tokenTable}>
                {data?.apiTokens?.map((token, id) => {
                  return (
                    <div className={`${styles.row}`} key={token.id}>
                      <label className={styles.name}>Token {token.name.length > 50 ? token.name.slice(0,50) + "..." : token.name}</label>
                      <div className={styles.icons}>
                        <div className={styles.iconWrapper}><RegenerateIcon onClick={() => handleRegenerateToken(token.id)} /></div>
                        <div className={styles.iconWrapper}><DeleteIcon onClick={() => handleRevokeToken(token.id, handleDisconnect)} className={styles.delete} /></div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </>
            :
            <div className={styles.tablePlaceholder}>There are no active tokens</div>
          }
        </div>
      )}}
    </IntegrationModalWrapper>
  )
}

