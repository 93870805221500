import React, { useEffect, useState, useRef } from 'react'
import { Select, Tag, Tooltip } from 'antd'
import type { CustomTagProps } from 'rc-select/lib/BaseSelect'
import styles from './SaivaSelect.module.scss'
import { ReactComponent as CloseWhite } from 'assets/icons/closeWhite.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ArrowIconDown } from 'assets/icons/arrowDown.svg'
import RequiredAsterisk from 'components/requiredAsterisk'
import { compareArrays } from 'utils/helper'
import { useTranslation } from 'react-i18next'

interface Props {
  options: any
  size?: 'large' | 'middle' | 'small'
  bordered?: boolean
  icon?: JSX.Element | null
  placeholder?: string
  label?: string
  selectall?: boolean
  required?: boolean
  defaultValue?: any
  errors?:
    | { message?: string | undefined; type?: string | undefined }
    | undefined
  onChange?: any
  value?: any
  reset?: () => void
  name?: string
  multiselect?: boolean
  disabled?: boolean
}

export default function SaivaSelect(props: Props) {
  const { t } = useTranslation()
  const [isEmtyOption, setIsEmtyOption] = useState(true)
  const [options, setOptions] = useState<any>([])
  const selectElement = useRef<any>(null)

  useEffect(() => {
    if (Array.isArray(props.value)) {
      const values = props.options.filter(
        (item) => !props.value.includes(item.value)
      )
      if (values.length == 0) {
        selectElement.current.blur()
      }
      setIsEmtyOption(values.length == 0)
      setOptions(values)
    } else {
      const values = props.options.filter((item) => props.value != item.value)
      setIsEmtyOption(values.length === 0)
      setOptions(values)
    }
  }, [props.value, props.options])

  const tag = (color, option, onPreventMouseDown, closable, onClose) => (
    <Tag
      color={color ? color : '#799BE6'}
      onMouseDown={onPreventMouseDown}
      closable={false}
      onClose={onClose}
      style={{
        marginRight: 8,
        marginTop: '3px',
        marginBottom: '3px'
      }}
    >
      <div
        style={{
          position: 'relative',
          paddingRight: '10px'
        }}
      >
        <span
          style={{
            paddingRight: '6px',
            maxWidth: '150px',
            display: 'block'
          }}
          className="elipsis"
        >
          {option.label}
        </span>
        <CloseWhite onClick={onClose} className={styles.closeTag} />
      </div>
    </Tag>
  )

  const tagRender = (tagProps: CustomTagProps) => {
    const { value, label, closable, onClose } = tagProps
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault()
      event.stopPropagation()
    }
    const option = props.options.find((item) => item.value == value)
      ? props.options.find((item) => item.value == value)
      : {
          label: `${t('saivaSelect.option.all')} ${props.label}`,
          color: undefined,
          value: value
        }
    const color = option[0]?.color
    return (
      <>
        {option.label.length > 22 ? (
          <Tooltip
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={option.label}
          >
            {tag(color, option, onPreventMouseDown, closable, onClose)}
          </Tooltip>
        ) : (
          tag(color, option, onPreventMouseDown, closable, onClose)
        )}
      </>
    )
  }

  const noDataOption = (
    <div className={styles.emptnyDropdown}>{t('saivaSelect.noDataOption')}</div>
  )

  const template = (
    <Select
      ref={selectElement}
      value={
        props.selectall &&
        props.value &&
        props.multiselect &&
        props.value.length > 0
          ? compareArrays(
              props.value,
              props.options.map((item) => {
                return item.value
              })
            )
            ? 'all'
            : props.value
          : props.value
      }
      mode={props.multiselect ? 'multiple' : undefined}
      tagRender={tagRender}
      notFoundContent={props.multiselect ? noDataOption : null}
      defaultValue={props.defaultValue}
      size={props.size ? props.size : 'large'}
      popupClassName={styles.popup}
      variant="borderless"
      disabled={props.disabled}
      placeholder={props.placeholder}
      onChange={props.onChange}
      virtual={false}
      showSearch={false}
      suffixIcon={
        <ArrowIconDown className={`${!!props.errors && styles.iconErrors}`} />
      }
      className={`
          ${props.icon && styles.paddingIcon}
          ${!!props.errors && styles.selectErrors}
          ${props.multiselect && styles.multiselect}
          ${styles.select}
        `}
    >
      {!isEmtyOption && props.selectall && (
        <Select.Option className={styles.option} key={'all'} value={'all'}>
          {t('saivaSelect.option.all')}
        </Select.Option>
      )}
      {!props.multiselect
        ? props.options.map((data) => {
            return (
              <Select.Option
                className={`
                ${styles.option} 
                ${data.value == props.value && styles.selected}
              `}
                key={data.value}
                value={data.value}
              >
                {data.label}
              </Select.Option>
            )
          })
        : options.map((data) => {
            return (
              <Select.Option
                className={styles.option}
                key={data.value}
                value={data.value}
              >
                {data.label}
              </Select.Option>
            )
          })}
    </Select>
  )

  return (
    <>
      {props.label && (
        <label
          className={`
            ${!!props.errors && styles.labelErrors}
            saivaLabel
          `}
        >
          {props.label}
          {props.required && <RequiredAsterisk />}
        </label>
      )}
      <div
        className={`
        ${!!props.errors && styles.selectDivErrors}
        ${props.multiselect && styles.selectDivMultiselect}
        ${props.bordered && styles.border}
        ${styles.selectDiv}
      `}
      >
        {props.icon && (
          <span
            className={`
              ${styles.prefixIconWrapper}
              ${!!props.errors && styles.prefixIconWrapperErrors}
            `}
          >
            {props.icon}
          </span>
        )}
        {props.multiselect && props.value && props.value.length > 0 && (
          <button
            onClick={props.reset}
            className={`
            ${!!props.errors && styles.clearIconErrors}
            ${styles.clearIcon}
          `}
          >
            <CloseIcon />
          </button>
        )}
        {template}
      </div>
      {!!props.errors && (
        <small className={styles.errorMessage}>{props.errors?.message}</small>
      )}
    </>
  )
}
