import { useState } from 'react'
import { Button } from 'antd'
import styles from './QMS.module.scss'
import { ReactComponent as QMIcon } from 'assets/icons/medkit.svg'
import { OrgProductFeature } from 'types/user-types'
import { getFeatureLabel } from 'utils/helper'
import { mixpanelInstance } from 'utils/mixpanel'

const qualityMeasureSelector = ({ options, defaultoption, setQM }) => {
  const pageName = window.location.pathname.split('/')[1]
  const report = window.location.pathname.split('/')[2]
  const page = pageName.charAt(0).toUpperCase() + pageName.slice(1)
  const [qualityMeasure, setQualityMeasure] =
    useState<OrgProductFeature>(defaultoption)
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  return (
    <div
      className={`${styles.qualityMeasureSelector} ${
        openMenu && styles.qualityMeasureSelectorOpen
      }`}
    >
      <div
        className={`${styles.selectDiv} ${
          openMenu && styles.qualityMeasureSelectorOpen
        }`}
        onClick={() => setOpenMenu(!openMenu)}
      >
        <div className={styles.selectorTitle}>
          <Button className={styles.selectButton}>
            <QMIcon style={{ marginRight: '6px', marginBottom: '2px' }} />
            Quality Measures
          </Button>
        </div>
        <div className={styles.QMValue}>
          {getFeatureLabel(qualityMeasure)?.label}
        </div>
      </div>
      <div className={`${styles.dropDown} ${openMenu && styles.dropDownOpen}`}>
        {openMenu &&
          options.map((feature) => {
            return (
              <Button
                className={`
                    ${styles.QMButton}
                    ${qualityMeasure === feature && styles.QMButtonActive}
                  `}
                onClick={() => {
                  setQualityMeasure(feature)
                  setQM(feature)
                  setOpenMenu(false)
                  if (page === 'Reports') {
                    mixpanelInstance.switchQM(feature, page, report)
                  } else mixpanelInstance.switchQM(feature, page)
                }}
              >
                {getFeatureLabel(feature)?.label}
              </Button>
            )
          })}
      </div>
    </div>
  )
}

export default qualityMeasureSelector
