import { useState } from 'react'
import styles from './Settings.module.scss'
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg'
import CSVUploadModal from '../../../components/UploadModal/CSVUploadModal'
import apiClient from '../../../services/utils/api-client'
import { UploadModalErr } from '../../../types/admin-errors'
import { showErrorToast } from '../../../utils'
import { useTranslation } from 'react-i18next'
import { useUserContext } from '../../../context/UserContext'
import { FileType } from '../../../services/api'
import { mixpanelInstance } from '../../../utils/mixpanel'
import SettingsService from 'services/settings-service'

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE)
const AdminOrgClinical = () => {
  const userContext = useUserContext()
  const { t } = useTranslation()
  const [uploadModal, setUploadModal] = useState<boolean>(false)

  const mixpanelEventsData = {
    organisation: userContext.currentOrg?.name,
    user: userContext.email
  }

  const handleOpenUpload = () => {
    mixpanelInstance.settingsEventHandler(
      mixpanelEventsData,
      'SettingsHighlightedKeywords'
    )
    setUploadModal(true)
  }
  const handleCloseUpload = () => {
    setUploadModal(false)
  }

  const showUploadModalErr = (err) => {
    const code = err?.response?.data?.code as UploadModalErr
    code ? showErrorToast(t(`backend.${code}`)) : showErrorToast(t('unknown'))
  }

  const handleDownloadTemplate = async () => {
    try {
      const res = await SettingsService.getKeyWords(userContext.currentOrg.id);
      if(res) {
        window.open(res.file_url)
        mixpanelInstance.settingsEventHandler(mixpanelEventsData, "SettingsHighlightedKeywordsDownload")
      }
    } catch (e) {
      showUploadModalErr(e)
    }
  }

  return (
    <div>
      <div style={{ padding: '0 12px' }}>
        <h6 className={'subtitle description'}>Progress Notes</h6>
        <div className={styles.brandingWrapper}>
          <div className={'d-flex flex-column'}>
            <label className={'body-2-regular'}>
              Configure a dictionary of keywords
            </label>
            {/*NOTE: not implemented on BE*/}
            {/*<label className={styles.lastUpdate}>Last update</label>*/}
          </div>
          <div className={'d-flex'}>
            <button
              className={'primary-text-button'}
              onClick={handleDownloadTemplate}
              style={{ marginRight: 16 }}
            >
              <DownloadIcon />
              Highlighted Keywords
            </button>
            <button className={'primary-button'} onClick={handleOpenUpload}>
              Upload CSV File
            </button>
          </div>
        </div>
      </div>
      <CSVUploadModal
        title={'Upload CSV file'}
        open={uploadModal}
        handleClose={handleCloseUpload}
      />
    </div>
  )
}

export default AdminOrgClinical
