import React, { useEffect, useState } from 'react'
import { Divider } from 'antd'
import styles from './RadioSelect.module.scss'
import { useTranslation } from 'react-i18next'
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation'
import { SaivaReport } from 'types/reports-types'

interface ComponentProps {
  items: { id: string; label: string }[]
  onChange: (value: any) => void
  name: string
  selectedValue?: SaivaReport.Filter.OptionItem | undefined
  loading?: boolean
  label?: string
  color?: string
}

const RadioSelect = ({
  onChange,
  items,
  selectedValue,
  loading,
  label,
  name
}: ComponentProps) => {
  const { t } = useTranslation()
  const [selectedItem, setSelectedItem] = useState<any>([])

  useEffect(() => {
    if (selectedValue) {
      setSelectedItem(selectedValue)
    }
  }, [selectedValue])

  const handleChange = (e: any) => {
    const value = e.target.value
    const item = items.find((item) => item.id === value)
    setSelectedItem(item)
    onChange(item)
  }

  return (
    <>
      {items && (
        <div className={styles.box}>
          <table className={styles.header}>
            <tbody>
              <tr>
                <td>
                  <h5>{label}</h5>
                </td>
              </tr>
            </tbody>
          </table>
          <Divider style={{ margin: '8px 0px' }} />
          {loading ? (
            <LoadingAnimation />
          ) : (
            <div className={styles.facilities}>
              <div>
                <ul>
                  {items.map((item) => {
                    return (
                      <li key={item.id} className={styles.listItem}>
                        <div className={styles.dot}></div>
                        <label className={styles.container}>
                          {item.label}
                          <input
                            type="radio"
                            checked={selectedItem.id === item.id}
                            value={item.id}
                            name={name}
                            onClick={handleChange}
                          />
                          <span className={styles.checkmark}></span>
                        </label>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default RadioSelect
