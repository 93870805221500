import React, { createRef, useEffect, useRef, useState } from 'react'
import SaivaModal from '../saivaModal'
import styles from './UploadModal.module.scss'
import Dropzone, { DropzoneRef, Accept, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { ReactComponent as ErrorIcon } from '../../assets/icons/exclamation.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/edit-2.svg'
import { ReactComponent as CheckIcon } from '../../assets/icons/check.svg'
import { UploadModalErr } from 'types/admin-errors'
import apiClient from '../../services/utils/api-client'
import { useUserContext } from '../../context/UserContext'
import { showSuccessToast } from '../../utils'
import LoadingSpinner from '../LoadingSpinner'
import { useConfirmModal } from '../ConfirmModal/ConfirmModal'
import { mixpanelInstance } from '../../utils/mixpanel'
import SettingsService from 'services/settings-service'

interface UploadModalProps {
  title: string
  description?: string
  open: boolean
  handleClose: () => void
}

const MAX_FILE_SIZE = Number(process.env.REACT_APP_MAX_FILE_SIZE);

let abortData = new AbortController()
export default function CSVUploadModal(props: UploadModalProps) {

  const { t } = useTranslation()

  const userContext = useUserContext()
  const confirmModal = useConfirmModal()

  const [error, setError] = useState<string>("")

  const dropRef = useRef<DropzoneRef>(null!)

  const [file, setFile] = useState<any>(undefined)
  const [hoverOverEdit, setHoverOverEdit] = useState<boolean>(false)
  const [isUploading, setUploading] = useState<boolean>(false)

  const {open} = props

  const mixpanelEventsData = {
    organisation: userContext.currentOrg?.name,
    user: userContext.email
  }

  useEffect(() => {
    if(open) setError("")
  }, [open])

  const handleSetFile = (file) => {
    if (file.size > MAX_FILE_SIZE) {
      setError(`File is too big. Max file size is ${MAX_FILE_SIZE / (1024 ** 2)}MB`)
      return
    }
    setError("")
    setFile(file)
  }

  const handleUpload = async () => {
    try {
      setUploading(true)
      setError("")
      const body = new FormData()
      body.append('progress_notes_highlight_keywords', file)
      await SettingsService.postKeyWords(userContext.currentOrg.id, body);
      setFile("")
      showSuccessToast("File successfully uploaded!")
      mixpanelInstance.settingsEventHandler(mixpanelEventsData, "SettingsHighlightedKeywordsUpload")
      setUploading(false)
      props.handleClose()
    } catch (e: any) {
      if(e.name === "CanceledError") return
      showUploadModalErr(e);
    } finally {
      setUploading(false)
    }
  }

  const showUploadModalErr = (err) => {
    setUploading(false)
    const code = err?.response?.data?.code as UploadModalErr;
    code ? setError(t(`backend.${code}`)) : setError(t("unknown"));
  };

  const handleClose = async () => {
    if(!isUploading) setFile(undefined)
    setError("")
    props.handleClose()
  }

  const handleCancel = async () => {
    if(isUploading) {
      const confirm = await confirmModal.open({ title: "Are you sure you cancel upload?" })
      if (confirm) {
        abortData.abort()
        abortData = new AbortController()
        setError("")
        setFile(undefined)
        props.handleClose()
      }
    } else {
      setError("")
      setFile(undefined)
      props.handleClose()
    }
  }

  return (
    <SaivaModal show={open} onHide={handleClose} onCancel={handleClose} className={styles.uploadModal}>
      <div className={styles.content}>
        <h4>{props.title}</h4>
        <p>{props.description ? props.description : ""}</p>

        <Dropzone
          accept={{
            'image/csv': ['.csv']
          }}
          onDrop={(acceptedFiles) => {
            handleSetFile(acceptedFiles[0])
          }}
          ref={dropRef}
          disabled={Boolean(file) && !hoverOverEdit}
        >
          {({getRootProps, getInputProps}) => (
            <div {...getRootProps()} className={`${styles.fileInput} ${styles.fileInputCSV} ${file ? styles.fileInputFinish : ""}`}>
              <input {...getInputProps()} />
              <p className={"body-1-light"}>{file ? file.name : "Drag and drop your file or browse" }</p>
              {file && <div className={styles.icons}>
                {isUploading ?
                  <LoadingSpinner width={"25px"} height={"25px"} />
                  :
                  <EditIcon
                    onMouseEnter={() => setHoverOverEdit(true)}
                    onMouseLeave={() => setHoverOverEdit(false)}
                    style={{cursor: hoverOverEdit ? "pointer" : "auto"}}
                  />
                }
              </div>}
            </div>
          )}
        </Dropzone>

        {error && <div className={styles.error}>
          <ErrorIcon /> {error}
        </div>}

        <div className={styles.buttons}>
          <button className={"primary-text-button"} onClick={handleCancel}>CANCEL</button>
          <button className={"primary-button"} disabled={!file || isUploading} onClick={handleUpload}>{ isUploading ? "UPLOADING..." : "UPLOAD"}</button>
        </div>
      </div>
    </SaivaModal>
  )
}
