import { Spin, Skeleton } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const antIcon = (
  <LoadingOutlined
    style={{ marginRight: '4px', fontSize: 24, color: '#4070DC' }}
    spin
  />
)

interface Props {
  loading: boolean
  repeat: number
  rows: number
  width: number
  title: boolean
}

const SkeletonCard = (props: Props) => {
  return [...Array(props.repeat)].map((e, i) => {
    return (
      <Skeleton
        key={i}
        paragraph={{ rows: props.rows, width: props.width + 'px' }}
        title={props.title}
      />
    )
  })
}

export default SkeletonCard
