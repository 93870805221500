import styles from './SaivaTextArea.module.scss'
import { Input } from 'antd'
import RequiredAsterisk from 'components/requiredAsterisk'

const { TextArea } = Input

interface Props {
  placeholder: string
  name: string
  label: string
  required?: boolean
  errors?:
    | { message?: string | undefined; type?: string | undefined }
    | undefined
  value?: string
  onChange?: (e: any) => void
}

export default function SaivaTextArea(props: Props) {
  return (
    <div>
      <label
        className={`${styles.label} ${!!props.errors && styles.labelErrors}`}
      >
        {props.label}
        {props.required && <RequiredAsterisk />}
      </label>
      <TextArea
        rows={4}
        maxLength={100}
        value={props.value}
        className={`${styles.inputDiv} ${
          !!props.errors && styles.inputDivError
        }`}
        size="large"
        name={props.name}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
      {!!props.errors && (
        <small className={styles.errorMessage}>{props.errors?.message}</small>
      )}
    </div>
  )
}
