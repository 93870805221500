import React, { useEffect, useState } from 'react'
import { Button, Space, Dropdown, Badge } from 'antd'
import { ReactComponent as SubscriptionIcon } from 'assets/icons/subscription.svg'
import LoadingIcon from 'components/Skeleton/LoadinIcon/LoadingIcon'
import { MenuProps } from 'antd/lib/menu'
import { SortDirection } from 'services/utils/api-utils'
import SchedulesService from 'services/schedules-service'
import { useUserContext } from 'context/UserContext'
import { getReportId } from 'utils/helper'
import ManageReportsModal from './MRModal/MRModal'
import ScheduleReportingModal from './SRModal/SRModal'
import styles from './SchedulesButton.module.scss'
import { UserPermission } from 'types/user-types'

const SchedulesButton = ({ loading, filtersData, reportId }) => {
  const userContext = useUserContext()
  const [openSRModal, setOpenSRModal] = useState(false)
  const [openMRModal, setOpenMRModal] = useState(false)
  const [manageSchedulesList, setManageScheulesList] = useState<any>([])

  const adminPermissions =
    userContext.hasPermission(UserPermission.SCHEDULE_CREATE) &&
    userContext.hasPermission(UserPermission.SCHEDULE_EDIT)

  const id = getReportId(reportId)

  useEffect(() => {
    getManageSchedulesList(userContext.currentOrg.id)
  }, [userContext.currentOrg.id, reportId])

  function menuItem(key: string, label: string, action: () => void) {
    return {
      key: key,
      label: (
        <button onClick={action} className={styles.dropdownButton}>
          {label}
        </button>
      )
    }
  }

  const getManageSchedulesList = async (orgId: string) => {
    const filters = {
      sort: { property: 'name', direction: SortDirection.Ascending },
      quality_measure: filtersData?.quality_measure && [
        filtersData?.quality_measure
      ],
      report_type: [getReportId(reportId)],
      show_all: adminPermissions
    }
    await SchedulesService.getSchedules(orgId, filters)
      .then((res) => {
        setManageScheulesList(res?.items)
      })
      .catch((e) => {
        setManageScheulesList([])
      })
  }

  const menu = [
    menuItem('1', 'New Subscription', () => setOpenSRModal(true)),
    menuItem('2', 'Manage Subscription', () => setOpenMRModal(true))
  ]

  const menuNoReports = [
    menuItem('1', 'New Subscription', () => setOpenSRModal(true))
  ]

  const items: MenuProps['items'] =
    manageSchedulesList?.length > 0 ? menu : menuNoReports

  const handleCloseSRModal = () => {
    getManageSchedulesList(userContext.currentOrg.id)
    setOpenSRModal(false)
  }

  const handleCloseMRModal = () => {
    getManageSchedulesList(userContext.currentOrg.id)
    setOpenMRModal(false)
  }

  return (
    <div className={'downloadButtonWrapper'}>
      <Dropdown
        menu={{ items }}
        overlayClassName={styles.dropdown}
        disabled={loading}
      >
        <Button className={styles.actionButton} size="large" disabled={loading}>
          <Badge count={manageSchedulesList?.length} offset={[-20, 0]}>
            {!loading ? <SubscriptionIcon /> : <LoadingIcon />}
          </Badge>
          <Space style={{ paddingLeft: '10px' }}>Subscription</Space>
        </Button>
      </Dropdown>
      <ScheduleReportingModal
        key={id}
        reportId={id}
        open={openSRModal}
        close={handleCloseSRModal}
        filtersData={filtersData}
      />
      <ManageReportsModal
        open={openMRModal}
        close={handleCloseMRModal}
        reportId={id}
        qualityMeasure={filtersData?.quality_measure}
        items={manageSchedulesList}
      />
    </div>
  )
}

export default SchedulesButton
