export enum UserLoginState {
  Loading = 'LOADING',
  LoggedIn = 'LOGGED_IN',
  LoggedOut = 'LOGGED_OUT'
}

export enum UserCapability {
  EMAIL_NOTIFICATION = 'can_receive_email_notifications',
  PUSH_NOTIFICATION = 'can_receive_push_notifications',
  WEB_APP_LOGIN = 'can_login_to_webapp',
  MOBILE_APP_LOGIN = 'can_login_to_mobileapp'
}

export enum UserPermission {
  INTEGRATIONS_CONNECT = 'integrations_connect',
  INVITATIONS_DELETE = 'invitations_delete',
  FACILITIES_READ_ACCESSIBLE = 'facilities_read_accessible',
  ORGANIZATIONS_EDIT = 'organizations_edit',
  DASHBOARD_READ = 'dashboard_read',
  INTEGRATIONS_READ = 'integrations_read',
  INTEGRATIONS_UPDATE = 'integrations_update',
  USERS_EDIT = 'users_edit',
  FACILITIES_READ = 'facilities_read',
  ANALYTICS_READ = 'analytics_read',
  REGIONS_DELETE = 'regions_delete',
  VISITS_EDIT = 'visits_edit',
  INVITATIONS_CREATE = 'invitations_create',
  INVITATIONS_RESEND = 'invitations_resend',
  VISITS_READ = 'visits_read',
  REGION_CREATE = 'regions_create',
  ORGANIZATIONS_READ = 'organizations_read',
  INTEGRATIONS_DELETE = 'integrations_delete',
  REGION_EDIT = 'regions_edit',
  INVITATIONS_READ = 'invitations_read',
  ORGANIZATION_BRANDING_READ_WRITE = 'organization_branding_read_write',
  REPORTS_READ = 'reports_read',
  FACILITIES_EDIT = 'facilities_edit',
  WOUNDS_READ = 'wound_care_read',
  WOUNDS_EDIT = 'wound_care_edit',
  SCHEDULE_CREATE = 'schedule_for_user_create',
  SCHEDULE_EDIT = 'schedule_for_user_edit',
  SCHEDULE_READ = 'schedule_for_user_read'
}

export enum OrgProductFeature {
  SFTP_EXPORT_INTEGRATION = 'sftp_export_integration',
  MOBILE_APP = 'mobile_app',
  WEBHOOK_EXPORT_INTEGRATION = 'webhook_integration',
  CUSTOMER_API_INTEGRATION = 'sdk_api_integration',
  ANALYTICS_DAILY_RISK_REPORT = 'reporting_daily_risk_report',
  ORGANIZATIN_BRANDING = 'organization_branding',
  ANALYTICS_READ = 'reporting_analytics',
  MODEL_FALLS = 'model_fall',
  MODEL_WOUNDS = 'model_wound',
  MODEL_UPT = 'model_upt',
  MODEL_RTH = 'model_rth',
  GEN_AI = 'gen_ai',
  TEST = 'test',
  WOUND_CARE = 'wound_care'
}

export namespace SaivaUser {
  export interface OrgItem {
    id: string
    name: string
    role: string
    partner_id: string | null
    last_active_at: Date
    permissions: UserPermission[]
    productFeatures: OrgProductFeature[]
  }

  export interface Role {
    name: string
    description: string
    product_name: string
  }

  export interface Title {
    name: string
    label: string
  }

  export interface LoggedUser {
    email: string
    title: string
    name: string
    id: number
    currentOrg: OrgItem
    currentMedicalSupplyOrg: OrgItem
    isSuperuser: boolean
    loginState: UserLoginState
    product_name: string[]
    currentRole: string
    orgs?: OrgItem[]
  }

  export interface Region {
    all_facilities: boolean
    facility_ids: number[]
    region_id: number
  }

  export interface Regions {
    full_access: boolean
    items: Region[]
  }

  export interface User {
    id: number
    email: string
    title: string
    name: string
    is_superuser: boolean
    roles: { name: string; enabled?: boolean; description: string }[]
    regions: Regions
    regions_control?: number[]
    capabilities: UserCapability[]
    last_active_at?: Date
  }
}
