import { ENVIRONMENT, Environments } from './constants'
import { AxiosError, CanceledError } from 'axios'
import * as Sentry from '@sentry/react'
import { ApiError } from '../services/api'

enum Errors {
  ApiError
}

interface Options {
  id: string
  error: any,
  function?: string,
  component?: string,
  message?: string,
  customHandle?: boolean,
  type?: Errors
  track?: boolean
}

export const handleError = (options: Options) => {

  const {type, customHandle, track = false} = options

  const printError = () => {
    console.error("ERROR: - " + options.error?.name + " - " + options.id)
    if(options.message) console.error(options.message)
    console.groupCollapsed("--- ERROR DETAILS ---")
      console.info("DATETIME: " + new Date().toDateString())
      // console.error("Version:" + new Date().toDateString())
      if(options.function) console.info("FUNCTION: " + options.function)
      if(options.component) console.info("COMPONENT: " + options.component)
      console.info("ERROR BASE:", options.error)
      console.groupCollapsed("TRACE:")
      console.trace()
      console.groupEnd()
    console.groupEnd()
  }

  const sendToSentry = () => {
    if(track && options.error) Sentry.captureException(options.error)
  }

  const code = "unknown"

  switch (ENVIRONMENT) {
    case Environments.LOCAL: {
      printError()
      break
    }
    case Environments.DEVELOPMENT: {
      // printError()
      sendToSentry()
      break
    }
    case Environments.STAGING: {
      // printError()
      sendToSentry()
      break
    }
    case Environments.PRODUCTION: {
      sendToSentry()
      break
    }
  }

  let error = Error("Unhandled error", options.error)
  try {
    if (options.error instanceof CanceledError) {
      if (customHandle) return options.error
      error = options.error
    }
    if (options.error instanceof AxiosError) {
      error = new ApiError(
        options?.error?.response?.data?.code ? options.error.response.data.code : code,
        undefined,
        options?.error?.response?.status,
        options?.error?.config,
        options?.error?.response
      )
      if (customHandle) return error
    }
    if (options.error?.name === "ApiError") {
      if (customHandle) return options.error
      error = options.error
    }
    if (options.error?.name === "CanceledError") {
      if (customHandle) return options.error
      error = options.error
    }
  } catch (e) {
    new Error("ErrorParsingError" + e)
  }
  if (customHandle) return error
  throw error
}
