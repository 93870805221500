import { useState, useEffect, useRef } from 'react'
import { Tag, Popover, Divider, Button, Tooltip } from 'antd'
import styles from './SaivaMultiSelect.module.scss'
import { ReactComponent as CloseWhite } from 'assets/icons/closeWhite.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { ReactComponent as ArrowIconDown } from 'assets/icons/arrowDown.svg'
import { useTranslation } from 'react-i18next'
import { compareArrays } from 'utils/helper'
import RequiredAsterisk from 'components/requiredAsterisk'

interface Value {
  value: string | number
  label: string
  parent?: number
}

interface Parent {
  value: number
  label: string
  children: Value[]
}

interface Props {
  values: Value[]
  parents: Parent[]
  label: string
  name: string
  disabled?: boolean
  errors?:
    | { message?: string | undefined; type?: string | undefined }
    | undefined
  required?: boolean
  selected?: any
  value?: any
  reset?: any
  onChange: (value: number[]) => void
}

export default function SaivaCustomSelect(props: Props) {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [valuesIds, setValuesIds] = useState<number[]>([])
  const [UIvalues, setUIvalues] = useState<Value[]>([])
  const [isChecked, setIsChecked] = useState<number[]>([])
  const [isEmptyOption, setIsEmptyOption] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const ref = useRef<any>(null)

  useEffect(() => {
    const values = props.values.filter(
      (item) => !props.value.includes(item.value)
    )
    const parents =
      props.parents.filter(
        (item) =>
          item.children.filter((r) => !isChecked.includes(Number(r.value)))
            .length > 0
      )
    setIsEmptyOption(values.length === 0 || parents.length === 0)
    if (values.length === 0 || parents.length === 0) {
      setIsOpen(false)
    }
    setIsEmpty(props.value.length === 0)
    if (props.value) {
      setIsChecked(props.value)
      generateUI(props.value)
    }
  }, [props.value, props.values, props.parents])

  useEffect(() => {
    setValuesIds(
      props.values.map((item) => {
        return Number(item.value)
      })
    )
  }, [props.values])

  const openPopup = () => {
    if (isOpen) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  const generateUI = (values) => {
    setUIvalues(props.values.filter((item) => values.includes(item.value)))
  }

  const onChange = props.onChange

  const clear = () => {
    setIsChecked([])
    generateUI([])
    onChange([])
    props.reset()
  }

  const isOpened = () => {
    props.disabled ? setIsOpen(false) : openPopup()
  }

  const selectAll = () => {
    const filteredIds = valuesIds.filter((item) => !isChecked.includes(item))
    setIsChecked(isChecked.concat(filteredIds))
    generateUI(isChecked.concat(filteredIds))
    onChange(isChecked.concat(filteredIds))
    setIsOpen(false)
  }

  const setParentValues = (e, value) => {
    const ids = value.children.map((item) => {
      return Number(item.value)
    })
    const filteredIds = ids.filter((item) => !isChecked.includes(item))
    setIsChecked(isChecked.concat(filteredIds))
    generateUI(isChecked.concat(filteredIds))
    onChange(isChecked.concat(filteredIds))
    setIsOpen(false)
  }

  const setValue = (e) => {
    const { checked, value } = e.target
    const values = valuesIds.filter(
      (item) => item == value && !isChecked.includes(value)
    )
    setIsChecked(isChecked.concat(values))
    generateUI(isChecked.concat(values))
    onChange(isChecked.concat(values))
    if (!checked) {
      setIsChecked(isChecked.filter((item) => item != value))
      generateUI(isChecked.filter((item) => item != value))
      onChange(isChecked.filter((item) => item != value))
    }
  }

  const closeTag = (value) => {
    setIsChecked(isChecked.filter((item) => item !== value.value))
    generateUI(isChecked.filter((item) => item !== value.value))
    onChange(isChecked.filter((item) => item !== value.value))
  }

  const catalog = (width: number) => {
    return (
      <div
        style={{ width: width + 12, padding: '0px' }}
        className={styles.selectPopover}
      >
        {props.parents.filter((k) => k.children.length > 0).length >= 2 && (
          <div className={styles.radioButton}>
            <button className={styles.checkbox} onClick={() => selectAll()}>
              {t('select-all')}
            </button>
          </div>
        )}
        <div>
          {props.parents.map((parent, i) => {
            return (
              <>
                {parent.children.filter(
                  (item) => !isChecked.includes(Number(item.value))
                ).length > 0 ? (
                  <div key={i}>
                    <label className={styles.label}>{parent.label}</label>
                    <Divider style={{ margin: '0px' }} />
                    <div className={styles.radioButton}>
                      <button
                        className={styles.checkbox}
                        onClick={(e) => setParentValues(e, parent)}
                      >
                        {t('all')}
                      </button>
                    </div>
                    {parent.children
                      .filter((item) => !isChecked.includes(Number(item.value)))
                      .map((children, i) => {
                        return (
                          <div className={styles.radioButton} key={i}>
                            <div
                              className={styles.checkbox}
                              title={children.label}
                            >
                              <input
                                type="checkbox"
                                className='saivaCheckboxValue'
                                value={children.value}
                                name={props.name}
                                checked={isChecked.includes(
                                  Number(children.value)
                                )}
                                onChange={(e) => setValue(e)}
                              />
                              <div className="elipsis">{children.label}</div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                ) : (
                  <></>
                )}
              </>
            )
          })}
        </div>
      </div>
    )
  }

  const tag = (facility) => (
    <>
      <span
        style={{
          paddingRight: '6px',
          display: 'block'
        }}
        className="elipsis"
      >
        {facility.label}
      </span>
      <CloseWhite
        onClick={() => closeTag(facility)}
        className={styles.closeTag}
      />
    </>
  )

  const noDataOption = (width) => (
    <div
      className={styles.emptnyDropdown}
      style={{ width: width - 12, padding: '0px' }}
    >
      You selected all options
    </div>
  )

  return (
    <>
      <label className={`${!!props.errors && styles.labelErrors} saivaLabel`}>
        {props.label}
        {props.required && <RequiredAsterisk />}
      </label>
      <Popover
        placement="bottomRight"
        content={
          isEmptyOption
            ? noDataOption(ref.current?.offsetWidth)
            : catalog(ref.current?.offsetWidth)
        }
        trigger="click"
        open={isOpen}
        onOpenChange={isOpened}
        arrow={false}
      >
        <div
          className={`${styles.inputDiv} ${
            !!props.errors && styles.inputDivError
          }`}
        >
          <div className={styles.stickyButtons}>
            {!isEmpty && (
              <div className={styles.clearIcon}>
                <Button
                  icon={
                    <CloseIcon
                      className={`${!!props.errors && styles.iconErrors}`}
                    />
                  }
                  onClick={() => clear()}
                />
              </div>
            )}
            <div className={styles.dropIcon}>
              <button
                className={`
                  ${isOpen && styles.dropIconOpen}
                  ${(isEmptyOption || props.disabled) && styles.disabled}
                  ${styles.dropDownArrow}
                `}
                onClick={() => openPopup()}
                disabled={isEmptyOption || props.disabled}
              >
                <ArrowIconDown
                  className={`${!!props.errors && styles.iconErrors}`}
                />
              </button>
            </div>
          </div>
          {UIvalues.length > 0 ? (
            !compareArrays(UIvalues, props.values) ? (
              <>
                {props.parents.map((item, l) => {
                  const checkedValues = UIvalues.filter(
                    (i: Value) => i.parent == item.value
                  )
                  return (
                    <div key={l} ref={ref} className={styles.values}>
                      {checkedValues.length > 0 && (
                        <div>
                          <label
                            className={styles.labelSelect}
                            style={{ marginTop: '6px' }}
                          >
                            {item.label}
                          </label>
                          <div>
                            {checkedValues?.map((facility, k) => {
                              return (
                                <Tag
                                  key={facility.value}
                                  color="#799BE6"
                                  closable={false}
                                  style={{
                                    marginRight: 8,
                                    marginBottom: '6px'
                                  }}
                                >
                                  <div
                                    style={{
                                      position: 'relative',
                                      paddingRight: '10px'
                                    }}
                                  >
                                    {facility.label.length > 27 ? (
                                      <Tooltip
                                        arrow={false}
                                        overlayInnerStyle={{
                                          background: '#112443',
                                          textAlign: 'left'
                                        }}
                                        title={facility.label}
                                      >
                                        {tag(facility)}
                                      </Tooltip>
                                    ) : (
                                      tag(facility)
                                    )}
                                  </div>
                                </Tag>
                              )
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  )
                })}
              </>
            ) : (
              <div ref={ref} className={styles.values}>
                <Tag
                  color="#799BE6"
                  closable={true}
                  onClose={(e) => clear()}
                  closeIcon={<CloseWhite />}
                  style={{
                    marginRight: 8,
                    marginTop: '10px'
                  }}
                >
                  <span style={{ paddingRight: '6px' }}>All {props.label}</span>
                </Tag>
              </div>
            )
          ) : props.disabled ? (
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={t('selectFacilities.selectRegion')}
              trigger={'click'}
            >
              <div
                ref={ref}
                className={`${styles.emptyValue} ${
                  props.disabled && styles.disabled
                }`}
              >
                {t('select-facilities')}
              </div>
            </Tooltip>
          ) : (
            <div
              ref={ref}
              className={`${styles.emptyValue} ${
                props.disabled && styles.disabled
              }`}
            >
              {t('select-facilities')}
            </div>
          )}
        </div>
      </Popover>
      {!!props.errors && (
        <small className={styles.errorMessage}>{props.errors?.message}</small>
      )}
    </>
  )
}