import SRModalForm from './SRModalForm'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useEffect } from 'react'
import { Schedules } from 'types/schedule-reports'
import { handleError } from 'utils/errorHandler'
import { SaivaUser } from 'types/user-types'

const dirtyValues = (
  dirtyFields: any,
  allValues: Schedules.Config
): Schedules.ConfigEdit => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key])
    ])
  )
}

const name = yup.string().max(100).required('This field is required')
const schedule_type = yup.string().required('This field is required')
const time_zone = yup.string().required('This field is required')
const time_of_day = yup.string().required('This field is required')
const file_format = yup.string().required('This field is required')
const day_of_week = yup.array().min(1, 'This field is required')
const week_of_month = yup
  .string()
  .nullable()
  .when('schedule_type', {
    is: (val: string) => val == 'monthly',
    then: (schema) => schema.required('This field is required')
  })
const assagnee = yup.string().nullable()

const CreateUserFormSchema = yup.object().shape({
  name,
  schedule_type,
  time_zone,
  time_of_day,
  file_format,
  day_of_week,
  week_of_month,
  assagnee
})

interface Props {
  defaultValues: Schedules.Config
  onSubmit: (data: Schedules.Config, dirtyFields: Schedules.ConfigEdit) => any
  children: JSX.Element
  formState: (value: boolean) => void
  isEditMode: boolean
  isDuplicate: boolean
  apiError: any
  handleDelete: () => void
  isClosed: boolean
  dateType: string
  sendNow: () => void
  reportId: string
  users: SaivaUser.User[]
  loading: boolean
}

const SRModalLogic = ({
  defaultValues,
  onSubmit,
  children,
  formState,
  isEditMode,
  isDuplicate,
  apiError,
  handleDelete,
  isClosed,
  dateType,
  users,
  sendNow,
  loading,
  reportId
}: Props) => {
  useEffect(() => {
    form.reset(defaultValues)
  }, [isClosed])

  const form = useForm<Schedules.Config | any>({
    mode: 'onSubmit',
    defaultValues,
    resolver: yupResolver(CreateUserFormSchema)
  })

  useEffect(() => {
    formState(form.formState.isDirty)
  }, [form.formState.isDirty])

  useEffect(() => {
    if (form.formState.isDirty == false) {
      form.reset(defaultValues)
    }
  }, [defaultValues])

  const handleSubmit = async (data: Schedules.Config) => {
    await onSubmit(data, dirtyValues(form.formState.dirtyFields, data)).catch(
      (err) =>
        handleError({ id: 'scheduleReport', error: err, customHandle: true })
    )
  }

  return (
    <SRModalForm
      form={form}
      onSubmit={handleSubmit}
      children={children}
      isEditMode={isEditMode}
      apiError={apiError}
      isDuplicate={isDuplicate}
      handleDelete={handleDelete}
      dateType={dateType}
      sendNow={sendNow}
      reportId={reportId}
      users={users}
      loading={loading}
      defaultValues={defaultValues}
    />
  )
}

export default SRModalLogic
