import React, { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import { ReactComponent as MoreIcon } from '../../assets/icons/more.svg'

const SaivaDropdown = (
  {label, popupLabel, options, defaultOption, onChange}
    :
  {
    label: string,
    popupLabel?: string,
    options: {label: string, value: any}[],
    defaultOption?: {label: string, value: any} | undefined,
    onChange: (value: {label: string, value: any}) => void
  }) => {

  const [popup, setPopup] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<any>(defaultOption)
  const ref = useRef(null)

  useOnClickOutside(ref, () => setPopup(false))

  const handleSelectOption = (option) => {
    setSelectedOption(option)
    setPopup(false)
  }

  useEffect(() => {
    setSelectedOption(defaultOption)
  }, [defaultOption])

  useEffect(() => {
    onChange(selectedOption)
  }, [selectedOption])

  return (
    <div className={"optionCardWrapper"}>
      <div className={"pickerWrapper"} ref={ref}>
        <label>{label}</label>
        <div className={`field ${popup ? 'active-field' : ''}`} onClick={() => setPopup(prev => !prev)}>
          <label className={`${selectedOption ? 'active' : ''}`}>{selectedOption?.label}</label>
          <MoreIcon className={"moreIcon"} />
        </div>
        {popup &&
          <div className={`popup`}>
            <div className={`header`}>
              <label>{popupLabel}</label>
            </div>
            <div className={`content`}>
              {options.map((option) => {
                return (
                  <div
                    className={`option ${selectedOption?.value === option.value ? "option-active" : ""}`}
                    onClick={() => handleSelectOption(option)}
                  >
                    {option.label}
                  </div>
                )
              })}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default SaivaDropdown
