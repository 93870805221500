import { useEffect, useState } from 'react'
import { Button, Divider, Form, Radio, Modal } from 'antd'
import { useTranslation } from 'react-i18next'
import WoundCareService from 'services/wound-care-service'
import { useUserContext } from 'context/UserContext'
import styles from './MissingDocForm.module.scss'
import Collapse from './Collapse/Collapse'
import { showErrorToast, showSuccessToast } from 'utils'
import moment from 'moment'
import { ReactComponent as WarningIcon } from 'assets/icons/warning-circle.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check-circle-true.svg'
import { ReactComponent as CalendarIcon } from 'assets/icons/menu-calendar.svg'
import { ReactComponent as HomeIcon } from 'assets/icons/menu-facility.svg'
import { ReactComponent as MedsIcon } from 'assets/icons/menu-mri.svg'
import { ReactComponent as UserIcon } from 'assets/icons/menu-user.svg'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import LoadingPill from 'assets/icons/pill-loader.gif'

type MissingDocFormProps = {
  order: {
    id: string
    order_type: string
    facility_name: string
    patient_name: string
    patient_mrn: string
    order_date: string
    order: string
  }
  onClose: (id: string, isUpdated: boolean) => void
}

type PayloadType = {
  wound_care_doc_component_id: string
  doc_obtained: boolean | null
}[]

type MissingDocItem = {
  wound_care_doc_component_id: string
  order_type: string
  component_name: string
  is_required: boolean
  doc_obtained: boolean
}

type MissingDocCategory = {
  category_name: string
  items: MissingDocItem[]
  total: number
  obtain: number
}

type MissingDocResponse = {
  components: MissingDocCategory[]
  total: number
  obtain: number
  updated_at: string
  updated_by: string
}

export default function MissingDocForm(props: MissingDocFormProps) {
  const userContext = useUserContext()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [touched, setTouched] = useState(false)
  const [isInProgressOrFinished, setIsInProgressOrFinished] = useState(false)
  const [missingDocResponse, setMissingDocResponse] = useState<
    MissingDocResponse | undefined
  >(undefined)

  const { confirm } = Modal

  const {
    id,
    order_type,
    facility_name,
    patient_name,
    patient_mrn,
    order_date,
    order
  } = props.order

  useEffect(() => {
    const fetchMissingDoc = async () => {
      setLoading(true)
      await WoundCareService.getMissingDoc(
        userContext.currentMedicalSupplyOrg.id,
        userContext.currentOrg.id,
        id
      )
        .then((res: MissingDocResponse) => {
          if (!res) return
          setLoading(false)
          setMissingDocResponse(res)
          const initialValues = res.components.map((component) => {
            return component.items.map((item) => {
              return {
                [item.wound_care_doc_component_id]: item.doc_obtained
              }
            })
          })
          for (let i = 0; i < initialValues.length; i++) {
            for (let j = 0; j < initialValues[i].length; j++) {
              form.setFieldsValue(initialValues[i][j])
              if (
                initialValues[i][j][Object.keys(initialValues[i][j])[0]] !==
                null
              ) {
                setIsInProgressOrFinished(true)
              }
            }
          }
        })
        .catch((err) => {
          showErrorToast(t(err.errorCode))
          setLoading(false)
        })
    }
    fetchMissingDoc()
  }, [props])

  const onFinish = async (values: any) => {
    setLoading(true)
    let payload: PayloadType = []
    Object.keys(values).forEach((key) => {
      payload.push({
        wound_care_doc_component_id: key,
        doc_obtained: values[key]
      })
    })
    await WoundCareService.patchMissingDoc(
      userContext.currentMedicalSupplyOrg.id,
      userContext.currentOrg.id,
      props.order.id,
      { components: payload }
    )
      .then(() => {
        props.onClose(id, true)
        setLoading(false)
      })
      .catch((err) => {
        showErrorToast(t(err.errorCode))
        setLoading(false)
      })
  }

  const checkMissingDocs = (items: MissingDocItem[]) => {
    let missingDocs: boolean | null = null
    const obtainedItems = items.map((item) => item.doc_obtained)
    if (obtainedItems.every((el) => el === null)) {
      missingDocs = null
    } else if (obtainedItems.every((el) => el !== null)) {
      missingDocs = false
    } else {
      missingDocs = true
    }
    return missingDocs
  }

  const showConfirm = () => {
    confirm({
      title: 'You have unsaved changes that will be lost.',
      content: 'Would you like to save your changes?',
      icon: null,
      okText: 'Save',
      cancelText: "Don't Save",
      okButtonProps: {
        type: 'primary'
      },
      cancelButtonProps: {
        type: 'text',
        className: styles.cancelButton
      },
      onOk() {
        form.submit()
      },
      onCancel() {
        props.onClose(id, false)
      }
    })
  }

  function handleCancel() {
    if (touched) {
      showConfirm()
    } else {
      props.onClose(id, false)
    }
  }

  return (
    <>
      {loading ? (
        <div className={styles.loadingAnimation}>
          <div style={{ width: '100%' }}>
            <img
              src={LoadingPill}
              width={'48px'}
              style={{ marginBottom: '24px' }}
            ></img>
            <div>{t('orderCapture.missingDocs.loading')}</div>
          </div>
        </div>
      ) : (
        <Form
          form={form}
          onFieldsChange={(changedValue, allFields) => {
            changedValue.forEach(() => {
              setTouched(true)
            })
          }}
          style={{ marginBottom: '0px' }}
          onFinish={onFinish}
        >
          <div>
            <div className={styles.header}>
              <h5>{t('orderCapture.missingDocs.documentation')}</h5>
              <Button
                icon={<CloseIcon />}
                type={'text'}
                onClick={handleCancel}
              ></Button>
            </div>
            <div className={styles.headerWrapper}>
              <div>
                <b>{order_type}</b>
              </div>
              <div>
                <UserIcon /> {patient_name}
              </div>
              <div>
                <MedsIcon />
                {patient_mrn}
              </div>
              <div>
                <HomeIcon />
                {facility_name}
              </div>
              <div>
                <CalendarIcon />
                {order_date}
              </div>
            </div>
            <div className={styles.headerWrapper}>
              <div>
                <b>{t('orderCapture.missingDocs.order')}</b>
              </div>
              <div>{order}</div>
            </div>
            <Divider style={{ border: '0.5px solid #C6D4F5' }} />
            <div className={styles.contentWrapper}>
              <div className={styles.components}>
                {missingDocResponse?.components &&
                missingDocResponse?.components.length > 0 ? (
                  missingDocResponse?.components.map(
                    (component: MissingDocCategory) => (
                      <Collapse
                        header={
                          <div className={styles.collapseHeader}>
                            <div style={{ maxWidth: '75%' }}>
                              {component.category_name}
                            </div>
                            <div>
                              {checkMissingDocs(component.items) === null ? (
                                <label>unreviewed</label>
                              ) : checkMissingDocs(component.items) === true ? (
                                <div className={styles.warning}>
                                  <label>
                                    {t('orderCapture.missingDocs.pending')}
                                  </label>
                                  <WarningIcon className={styles.warningIcon} />
                                </div>
                              ) : (
                                <div>
                                  {component.obtain + '/' + component.total}
                                  <CheckIcon className={styles.checkIcon} />
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        key={component.category_name}
                      >
                        <table className={styles.table}>
                          <tr style={{ borderBottom: '1px solid #C6D4F5' }}>
                            <th>
                              {t('orderCapture.missingDocs.documentObtained')}
                            </th>
                            <th>{t('orderCapture.missingDocs.component')}</th>
                          </tr>
                          <tbody>
                            {component.items.map((item: MissingDocItem) => (
                              <tr>
                                <td style={{ width: '50%' }}>
                                  <Form.Item
                                    style={{
                                      marginBottom: '0px',
                                      minHeight: 'auto'
                                    }}
                                    name={item.wound_care_doc_component_id}
                                    id={item.wound_care_doc_component_id}
                                  >
                                    <Radio.Group>
                                      <Radio
                                        value={true}
                                        className={styles.radioButton}
                                      >
                                        {t('orderCapture.missingDocs.yes')}
                                      </Radio>
                                      <Radio
                                        value={false}
                                        className={styles.radioButton}
                                      >
                                        {t('orderCapture.missingDocs.no')}
                                      </Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </td>
                                <td>{item.component_name}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Collapse>
                    )
                  )
                ) : (
                  <div className={styles.noData}>
                    <div>
                      <EmptyDataIcon />
                      <br />
                      No documentation
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.infoWrapper}>
                <div className={styles.infoTitle}>
                  <h5>{t('orderCapture.missingDocs.summary')}</h5>
                  <Divider
                    style={{ margin: '8px 0px', border: '0.5px solid #C6D4F5' }}
                  />
                </div>
                <div className={styles.info}>
                  <div>
                    <label>{t('orderCapture.missingDocs.contains')}</label>
                    {missingDocResponse?.total || '-'}
                  </div>
                  <div>
                    <label>{t('orderCapture.missingDocs.obtain')}</label>
                    {missingDocResponse?.obtain || '-'}
                  </div>
                  <div>
                    <label>{t('orderCapture.missingDocs.lastUpdate')}</label>
                    {missingDocResponse?.updated_at
                      ? moment(missingDocResponse.updated_at).format(
                          'DD/MM/YYYY'
                        )
                      : '-'}
                  </div>
                  <div>
                    <label>{t('orderCapture.missingDocs.updatedBy')}</label>
                    {missingDocResponse?.updated_by || '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form.Item style={{ marginBottom: '0px' }}>
            <div className={styles.buttons}>
              <Button type="text" onClick={handleCancel}>
                {t('orderCapture.missingDocs.cancel')}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className={styles.submitButton}
                disabled={(!touched && !isInProgressOrFinished) || loading}
              >
                {t('orderCapture.missingDocs.save')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  )
}
