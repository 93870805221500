import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Legend,
  Tooltip,
  LayoutPosition,
  ScriptableContext,
  Filler,
  Align
} from 'chart.js'
import styles from './Charts.module.scss'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { SaivaReport } from 'types/reports-types'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

ChartJS.defaults.borderColor = '#D9E2F8'
ChartJS.defaults.color = 'rgba(17, 36, 67, 0.60)'

function backgroundColor(context: ScriptableContext<'line'>) {
  const ctx = context.chart.ctx
  const gradient = ctx.createLinearGradient(0, 0, 0, 200)
  gradient.addColorStop(0, '#4070DC90')
  gradient.addColorStop(1, '#4070DC05')
  return gradient
}

const labelColors: { [key: string]: string } = {
  'Transfers': '#4070DC',
  'Admitted, Observation': '#6952E6',
  'Other': '#459DAB',
  'ED Visit Only': '#5EC7FF',
  'Unspecified': '#FFCE00',
  'Admitted, Inpatient': '#A94DFF',
  'Admitted, Status Uncertain': '#FF8227',
  'Admissions': '#A94DFF'
}

function getLabelColor(label: string): string {
  return labelColors[label] || '#4070DC' // Default color for unspecified labels
}

interface ComponentProps {
  data: SaivaReport.Data.Charts | undefined
  timePeriods: string[]
  handleResize?: () => void
  headerElement: ReactElement
}

export function Charts(props: ComponentProps) {
  const { t } = useTranslation()
  const { headerElement, data, timePeriods } = props

  const options = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true
      },
      legend: {
        position: 'bottom' as LayoutPosition,
        align: 'start' as Align,
        labels: {
          boxWidth: 21,
          boxHeight: 6,
          useBorderRadius: true,
          borderRadius: 3,
          fontColor: 'rgba(17, 36, 67, 0.60)',
          fontSize: 12
        }
      }
    },
    scales: {
      y: {
        beginAtZero: true
      }
    }
  }

  if (data && timePeriods) {
    return (
      <>
        {headerElement}
        <Row gutter={24}>
          {data.map((item, index) => {
            return (
              <Col md={12} key={index}>
                <div className={styles.chartWrapper} key={index}>
                  <h6>{item.title}</h6>
                  <div>
                    <Line
                      data={{
                        labels: timePeriods,
                        datasets: item.datasets.map((dataset) => {
                          return {
                            label: dataset.label.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
                            data: dataset.data,
                            borderColor: getLabelColor(dataset.label),
                            fill: dataset.label === 'Transfers' ? true : false,
                            backgroundColor: (
                              context: ScriptableContext<'line'>
                            ) => {
                              if (dataset.label === 'Transfers') {
                                return backgroundColor(context)
                              } else {
                                return getLabelColor(dataset.label)
                              }
                            }
                          }
                        })
                      }}
                      options={options}
                    />
                  </div>
                </div>
              </Col>
            )
          })}
        </Row>
      </>
    )
  } else {
    return (
      <h5 className={styles.noData}>
        <EmptyDataIcon style={{ marginBottom: '6px' }} />
        <br />
        {t('dashboard.cards.no-card-value')}
      </h5>
    )
  }
}
