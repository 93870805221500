import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SaivaIcon from 'components/saivaIcon'
import { useUserContext } from 'context/UserContext'
import { SaivaFacility } from 'types/facility-types'
import FacilityService from 'services/facility-service'
import {
  RequestFilter,
  SaivaSort,
  SortDirection
} from 'services/utils/api-utils'
import { UserPermission } from 'types/user-types'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import Modal from './EditFacility/Modal'
import SaivaPagination from 'components/Tables/SaivaPagination/SaivaPagination'
import {Dropdown, Menu, Space} from 'antd'
import { ReactComponent as MenuVertical } from 'assets/icons/menu-vertical.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import styles from './Facilities.module.scss'
import RegionService from 'services/region-service'
import AccessControl from 'components/AccessControl/AccessControl'
import Gradient from 'assets/icons/Gradient.gif'

export default function AdminFacilities() {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [editingFacility, setEditingFacility] = useState<
    SaivaFacility.Item | undefined
  >(undefined)
  const [facilities, setFacilities] = useState<Array<SaivaFacility.Item>>([])
  const [searchText, setSearchText] = useState<string>('')
  const [tableSort, setTableSort] = useState<SaivaSort | undefined>(undefined)
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const editFacilityTitle = t('facilityForm.edit.title')
  const [lastSearch, setLastSearch] = useState<string>('')
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [itemCount, setItemCount] = useState(0)
  const [regions, setRegions] = useState<any>([])

  useEffect(() => {
    fetchFacilities()
    fetchRegions()
  }, [currentPage, pageSize, userContext.currentOrg.id, lastSearch, tableSort])

  const fetchFacilities = async () => {
    let items: number = 0
    setRequestInFlight(true)
    const filter: RequestFilter = {
      search: lastSearch
    }
    const res = await FacilityService.getFacilities(userContext.currentOrg.id, {
      ...filter,
      page: currentPage,
      size: pageSize,
      sort: tableSort
    })
    const lastPage = Math.ceil(res?.total ? res.total / res.size : 1)
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    setPageSize(pageSize)
    setItemCount(res?.total ? res?.total : 0)
    setFacilities(
      (res?.items ?? []).filter((f): f is SaivaFacility.Item => !!f)
    )
    setRequestInFlight(false)
    items = res?.total ?? 0
  }

  const fetchRegions = async () => {
    try {
      const regions = await RegionService.getRegions(userContext.currentOrg.id)
      if (regions) setRegions(regions.map((item) => {
        return {value: item.id, label: item.name }
      }))
    } catch (e) {
      setRegions([])
    }
  }

  const onChangePage = (newCurrent: number) => {
    setCurrentPage(newCurrent)
  }

  const onShowSizeChange = (pageSize: number) => {
    const lastPage = Math.ceil(itemCount / pageSize)
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    setPageSize(pageSize)
  }

  const makeFacilityRow = (facility: SaivaFacility.Item) => {
    return (
      <tr key={facility.id}>
        <td>{facility.name}</td>
        <td>{facility.status}</td>
        <td>{facility.address}</td>
        <td>{facility.zipcode}</td>
        <td>{facility.city}</td>
        <td>{facility.state}</td>
        <td>{regions.filter((i) => {return i.value == facility.region_id}).map((i) => {return i.label})}</td>
        <AccessControl userPermissions={[UserPermission.FACILITIES_EDIT]}>
          <td>{makeActionMenu(facility)}</td>
        </AccessControl>
      </tr>
    )
  }

  const editFacility = (facility: SaivaFacility.Item) => {
    setEditingFacility(facility)
    setShowModal(true)
  }

  const makeActionMenu = (facility: SaivaFacility.Item) => {
    return (
      <Dropdown
        trigger={['click']}
        overlayClassName={styles.dropdown}
        dropdownRender={(menu) => (
          <Menu>
            <Menu.Item
              onClick={() => editFacility(facility)}
              className={`${styles.menuItem}`}
            >
              <EditIcon style={{ marginRight: '12px' }} />
              {t('facilities.table.action.edit.text')}
            </Menu.Item>
          </Menu>
        )}
      >
        <Space>
          <MenuVertical className={styles.menuIcon}/>
        </Space>
      </Dropdown>
    )
  }

  const handleSortClick = (propertyName: string) => {
    const currentDirection = tableSort?.direction ?? SortDirection.Descending
    setTableSort({
      property: propertyName,
      direction:
        currentDirection === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending
    })
  }

  const makeSortableTableHeadCell = (label: string, propertyName?: string) => {
    return (
      <th onClick={() => propertyName && handleSortClick(propertyName)}>
        {tableSort && tableSort.property === propertyName && (
          <SaivaIcon
            name={
              tableSort.direction === SortDirection.Ascending
                ? 'ArrowUp'
                : 'ArrowDown'
            }
            className="me-1"
          />
        )}
        {label}
      </th>
    )
  }

  const handleClose = () => {
    fetchFacilities()
    setShowModal(false)
  }

  const columnCount =
    userContext.hasPermission(UserPermission.FACILITIES_READ) ||
    userContext.hasPermission(UserPermission.FACILITIES_READ_ACCESSIBLE)
      ? 8
      : 7

  const content = (
    <div
      style={{
        minWidth: '300px',
        padding: '16px 16px 0px 16px',
        maxWidth: '300px'
      }}
    >
      <b style={{ fontSize: '14px' }}>Filters</b>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          icon={null}
          label="Region"
          options={regions}
          placeholder="Select Region"
          multiselect={true}
          name="regions"
        />
      </div>
      <div style={{ margin: '12px 0px' }}>
        <SaivaSelect
          icon={null}
          label="Status"
          options={[{ value: 'active', label: 'Active' }]}
          placeholder="Select options"
          multiselect={true}
          name="regions"
        />
      </div>
    </div>
  )

  return (
    <div id="adminFacilities" className="container-fluid mt-5">
      <Modal
        isOpen={showModal}
        title={editFacilityTitle}
        close={handleClose}
        existingFacility={editingFacility}
      />
      <div className="row justify-content-between">
        <div className="col-1 col-md-3 d-flex justify-content-end"></div>
        <div className="col-12 col-md-4 d-flex justify-content-end">
          <SaivaSearch
            placeholder={t('search.text')}
            filters={false}
            activeFilters={searchText.length > 0}
            onChange={(e) => {
              setSearchText(e.target.value)
              setLastSearch(e.target.value.length > 2 ? e.target.value : '')
            }}
            value={searchText}
            content={content}
          />
        </div>
        <div className="col-1 col-md-3 d-flex justify-content-end"></div>
      </div>
      <div className="row">
        <div className="col table-responsive pb-4" style={{display: 'grid'}}>
          <table className="mt-3 table table-hover saiva">
            <thead>
              <tr className="">
                {makeSortableTableHeadCell(
                  t('facilities.table.header.name.label'),
                  'name'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.status.label'),
                  'status'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.address.label'),
                  'address'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.zipcode.label'),
                  'zipcode'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.city.label'),
                  'city'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.state.label'),
                  'state'
                )}
                {makeSortableTableHeadCell(
                  t('facilities.table.header.region.label'),
                  'region'
                )}
                <AccessControl userPermissions={[UserPermission.FACILITIES_EDIT]}>
                  <th>{t('facilities.table.header.action.label')}</th>
                </AccessControl>
              </tr>
            </thead>
            <tbody className="shadow-big">
              {requestInFlight ? (
                <tr>
                  <td colSpan={columnCount} className="text-center">
                    <img
                      className="me-2"
                      src={Gradient}
                      width="40px"
                      height="40px"
                    />
                    <p className="text-muted">{t('loading.text')}</p>
                  </td>
                </tr>
              ) : facilities.length === 0 && (
                <tr>
                  <td colSpan={columnCount} className="text-center">
                    <p className="text-muted">
                      {t('facilities.table.empty.text')}
                    </p>
                  </td>
                </tr>
              )}
              <AccessControl userPermissions={[UserPermission.FACILITIES_READ]}>
                <>{facilities.map((f) => makeFacilityRow(f))}</>
              </AccessControl>
            </tbody>
          </table>
          <SaivaPagination
            total={itemCount}
            page={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      </div>
    </div>
  )
}
