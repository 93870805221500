import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ReportService from 'services/report-service'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import LoadingAnimation from 'components/LoadingAnimation/LoadingAnimation'
import { showErrorToast } from 'utils'
import styles from './Pdf.module.scss'
import { Button } from 'antd'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { mixpanelInstance } from 'utils/mixpanel'
import FacilityService from 'services/facility-service'

export default function PDF() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [pdfUrl, setPdfUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const reportPdfDownloadObject = {
    report_date: searchParams.get('report_date'),
    report_id: searchParams.get('report_id'),
    org_id: searchParams.get('org_id'),
    facility_id: searchParams.get('facility_id')
  }

  useEffect(() => {
    if (reportPdfDownloadObject) {
      openPdfFromUrl(reportPdfDownloadObject)
    }
  }, [])

  const openPdfFromUrl = async (data) => {
    const url = '/v2/orgs/{org_id}/analytics/daily_risk_report'
    if (data.org_id && data.report_id && data.report_date) {
      setLoading(true)
      await ReportService.downloadReportFile({
        requestUrl: url,
        organizationId: data.org_id,
        reportId: data.report_id.replace(' ', '+')
      })
        .then(async (response) => {
          if (!response) return
          setPdfUrl(response.url)
          setLoading(false)
          await FacilityService.getFacility(data.org_id, data.facility_id)
            .then((res) => {
              if (!res) {
                mixpanelInstance.reportOpenFromWebPortal(
                  'daily_risk_report',
                  {
                    ...data,
                    facilities: [],
                    facility_ids: [data.facility_id]
                  }
                )
              } else {
                mixpanelInstance.reportOpenFromWebPortal(
                  'daily_risk_report',
                  {
                    ...data,
                    facilities: [res.name],
                    facility_ids: [res.id]
                  }
                )
              }
            })
            .catch((err) => {
              if (err.name === 'ApiError') {
                showErrorToast(t(err.errorCode))
              } else {
                showErrorToast(t(err.errorCode))
              }
              mixpanelInstance.reportOpenFromWebPortal(
                'daily_risk_report',
                {
                  ...data,
                  facilities: [],
                  facility_ids: [data.facility_id]
                }
              )
            })
        })
        .catch((err) => {
          setLoading(false)
          if (err.name === 'ApiError') {
            showErrorToast(t(err.errorCode))
          } else {
            showErrorToast(t(err.errorCode))
          }
        })
    }
  }

  return (
    <div>
      <Button
        className={styles.actionButton}
        style={{ marginBottom: '10px', marginTop: '10px' }}
        onClick={() => navigate('/reports/daily_risk_report')}
      >
        <ArrowIcon /> {t('residentPage.tabs.backToReports')}
      </Button>
      {!loading ? (
        <>
          {pdfUrl ? (
            <>
              <iframe
                src={pdfUrl}
                width={'100%'}
                style={{
                  height: '80vh',
                  width: '100%'
                }}
              ></iframe>
            </>
          ) : (
            <h5 className={styles.noData}>
              <EmptyDataIcon style={{ marginBottom: '6px' }} />
              <br />
              {t('dashboard.cards.no-card-value')}
            </h5>
          )}{' '}
        </>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  )
}
