export function TableColumns(sortingHeader, columns) {
  function getColumnWidth(id) {
    switch (id) {
      case 'facility_name':
        return 250
      case 'date':
        return 120
      default:
        return null
    }
  }

  return columns.map((column) => {
    return {
      title: column.sortable
        ? sortingHeader(column.label, column.id)
        : column.label,
      dataIndex: column.id,
      key: column.id,
      width: getColumnWidth(column.id),
      ellipsis: true
    }
  })
}
