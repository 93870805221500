import React from 'react'
export default function PrivacyPolicy() {
  const email = "contactus@saiva.ai"
  return (
    <>
      <div className="dtext" ><h2>SAIVA AI,Inc.​ Privacy Policy</h2><p>Privacy Policy (the “<strong>Policy</strong>&#8220;)(updated: September 20, 2022)</p><p>Your privacy is important to us. We, at SAIVA AI, Inc. (“<strong>Saiva AI</strong>&#8220;,&#8221;<strong>&#8220;we</strong>,&#8221;&#8221;<strong>us</strong>,&#8221;and&#8221;<strong>our</strong>&#8220;) provide this Privacy Policy to describe our information collection, use, and sharing practices with respect to your use of our online services, including our website (the “<strong>Site</strong>&#8220;), mobile application (the “<strong>App</strong>&#8220;), and any other online service we offer (collectively, the “<strong>Services</strong>&#8220;).</p>
        <p>SAIVA AI’s Services have been developed for skilled nursing facilities (“<strong>Emplyoers</strong>&#8220;) and the healthcare providers, personnel, and administrators employed by the facilities (“<strong>Staff</strong>&#8220;) that are registered with a SAIVA AI account and thus authorized to access SAIVA AI Services (each the Employer and Staff are &#8220;<strong>Authorized User(s),</strong>&#8221; also referred to in this Policy as &#8220;<strong>You</strong>&#8221; or correlates of “<strong>You</strong>&#8221; as the case may<br /> be, whether or not capitalized), for use on behalf of the constituencies you serve (“<strong>Residents,</strong>&#8221; or &#8220;<strong>Patients</strong>&#8221; as the case may be) in order to deliver predictive analytics to those Authorized User(s) (including healthcare providers and administrators) about likelihood of a Resident’s imminent hospitalization (e.g., within a window of approximately seventy-two (72) hours).</p>
        <p>This Policy does not apply to information we process for or on behalf of Employers who have registered for the Services. SAIVA AI does not control use of information we process or render in the form of data analytics, and you should consult with the applicable Employer or Authorized User to understand how information receives from SAIVA AI is used.</p>

        <p>By using or accessing the Services, you agree to the terms and conditions of this Policy. Check periodically for updates. We may make changes to this Policy. If we make material changes to this Policy, we will provide you with notice as required by law. Your continued use of the Services will signify acceptance of the terms of any updated Policy.</p>

        <p><strong><em>Information We Collect and Methods of Collection:</em></strong></p>

        <p>We may collect information, including personal information, when you access or use our Services. We may collect information directly from you, such as at registration and as authorized for ongoing access to the Services once you are an Authorized User.  We may also collect information about you from third parties. For example, your employer may provide us with information about you when they subscribe to our Services. The information we collect may include your name, email address, phone number, business postal address, and billing information. We may also collect certain information automatically through your access and interaction with the Services, as described below in the section on “How We May Track.”</p>

        <p><strong>Purposes for Which Information We Collect May Be Used:</strong></p>

        <p>We may use the information we collect to:</p>
        <ul><li>register an account</li>
          <li>provide and support the Services, or to communicate updates to the Services</li>
          <li>contact you based on your interaction with the Services</li>
          <li>operate, maintain, manage and administer the Services, including processing<br /> registrations, payments, or for diagnosing and addressing technical problems</li>
          <li>respond to questions and communications</li>
          <li>make service or administrative announcements about unscheduled downtime</li>
          <li>perform audits, do research, or conduct analytics to help maintain, administer,<br /> support, enhance, and protect the integrity of the Service</li>
          <li>determine usage trends and patterns, or for measuring the effectiveness of the<br /> Services</li>
          <li>create or update features, or Services</li>
          <li>contribute to health and medical research</li>
          <li>as we believe to be necessary or appropriate to comply with: (a) applicable law,<br /> including laws outside your state or country of residence; (b) legal process; (c)<br /> requests from public and government authorities including public and government<br /> authorities outside your state or country of residence; (d) enforce our terms and<br /> conditions; (e) to protect our operations or those of any of our affiliates; (f)<br /> protect our rights, privacy, safety or property, and/or (g) allow us to pursue<br /> available remedies or limit the damages that we may sustain</li>
          <li>provide benchmarking and performance tracking solutions</li>
        </ul>
        <p>We may aggregate and/or de-identify information collected through the Services. We may use de-identified and/or aggregated data for any purpose, including without limitation for research and marketing purposes, and may also share such data with any third parties.</p>
        <p><strong>How We May Track</strong></p>
        <p>We may directly or through third-party analytics providers use cookies or other tracking technologies to collect and analyze information about your use of our Services, as further described below in “Use of Cookies.”  We may use web beacons to help determine which email messages sent by us were opened and whether a message was acted upon. This may help SAIVA AI analyze the effectiveness of our Services by measuring the number of visitors or how many visitors clicked on key elements of our Services. To do this, we use Google Analytics to track and analyze page usage behavior to improve performance in the use of the Services. To prevent Google Analytics from using your information for analytics, you may install the Google Analytics Opt-out Browser Add-on by visiting this Site: <a href="https://tools.google.com/dlpage/gaoptout?hl=en/" target="" rel="noopener">Disable Google Analytics</a>.</p>
        <p>Do Not Track: There are different ways you can prevent tracking of your online activity. One of them is setting a preference in your browser that alerts websites you visit that you do not want them to collect certain information about you. This is referred to as a Do Not Track (“DNT”) signal. Please note that<br /> currently our websites and web-based resources do not respond to these signals from web browsers.</p>
        <p><strong>Use of Cookies:</strong></p>
        <p>Cookies are small data files that a website places on your computer where your internet browser files are kept, and are designed to save you the trouble of reentering certain information in some registration areas because cookies can be used to enable a site to “remember” information a visitor has previously inputted. A cookie also helps deliver content and features likely to be of interest to you, based on your previous activities on our site, and to track how sections of the website are used. Cookies can be placed on your computer both by us and by third parties with whom we have a relationship, such as web analytic services, and those cookies may provide us with the following information, including the:</p>
        <ul><li>time zone</li><li>geographic location</li><li>internet protocol (IP) address</li><li>internet service provider</li><li>web browser used on the device and making the reques</li><li>type and version of the operating software running on the device</li><li>model of the device making the request</li><li>search terms usedprevious activity on the Services</li><li>information about some of the cookies that are installed on your computer, mobile device or tablet</li><li>URLs visited</li></ul><p>We may also use the information we collect for the additional following reasons:</p>
        <p><strong>Managing Cookies: </strong></p>
        <p>You have an opportunity to opt out of, or manage, cookies using your browser settings and visiting the privacy section. With most internet browsers or other software, you can change your browser settings to erase cookies from your computer hard drive, block all cookies or receive a warning before a cookie is stored. Please check your browser instructions to learn more about these functions. If you reject cookies, functionality of the site may be limited, and you may not be able to take advantage of many of the site&#8217;s features. For more about cookies, go to: <a href="https://allaboutcookies.org/" target="" rel="noopener">All About Cookies</a></p><p><strong>Consent and Authorization</strong></p><p>By accessing or otherwise using the Services, you are consenting to the use of your information for the aforementioned purposes.</p>
        <p><strong>Third-Party Websites, Software and Services</strong></p>
        <p>The Services may contain links to third-party sites or services, and may be used with third-party software and services. SAIVA AI is not responsible for, nor can we control, the privacy practices of third parties, and we are not responsible for third-party sites, software, or services.</p>
        <p><strong>Level of Security:</strong></p>
        <p>We employ security measures designed to protect your information from unauthorized access. Regardless of efforts, no data transmission over the Internet or other network can be guaranteed to be 100% secure. Accordingly, we cannot and do not guarantee the security of any information you transmit on or through the Services.</p>
        <p><strong>When We Disclose Information We Collect</strong></p>
        <p>Consent: We may disclose our information to any third parties based on your consent to do so.</p>
        <p><strong>Service Providers:</strong>We may provide access to or share your information with select third parties who perform services on our behalf, including without limitation customer support, data storage, analysis and processing, and legal services.</p>
        <p><strong>Employers:</strong> Where you use the Services as an Authorized User within an Employer’s account with us, we may share information about your use of the Services with the Employer.</p>
        <p><strong>Business Reorganizations:</strong> As we continue to develop our business, we may buy, merge or partner with other companies. In such transactions, (including in contemplation of such transactions, e.g., due diligence) user information may be among the transferred assets. If a portion or all of SAIVA AI’s assets are sold or transferred to a third-party, the information we collect would likely be one of the transferred business assets.</p>
        <p><strong>Legal Procedures:</strong> We may need to preserve, use or disclose the information we collect in response to a court order, subpoena, search warrant, judicial proceeding or other legal process, if we have a good faith belief that the law requires us to do so, or to otherwise protect our rights, or the rights of third parties. Some legal procedures may prohibit or prevent us from notifying users, other individuals or entities identified in such procedures or may compel us to take measures otherwise in violation of this Policy or a written agreement you have with us.</p>
        <p><strong>Security, Threats and Breach Notification</strong></p>
        <p>Our Services have physical, administrative and technical security measures in place to protect against the loss, misuse, unauthorized access and alteration of data and PI under our direct control. Unique user names and passwords also are required and must be entered each time an Authorized User logs into the Services. You and your Employer are solely responsible for maintaining, safeguarding, securing, and not sharing any unique user name, and/or password(s).</p>
        <p>We are committed to educating SAIVA AI personnel about the importance of protecting and safeguarding PI, as well as the importance of compliance with relevant privacy legislation and company policies. Our personnel and contractors are required to sign confidentiality agreements.</p>
        <p>These safeguards help prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of PI; however, it is important to remember that no system can guarantee 100% security at all times. In the event that we detect a threat to security or a security vulnerability, we may attempt to contact you to recommend protective measures. Additionally, incidents of suspected or actual unauthorized handling of PI are always directed to <a href="mailto:contactus@saiva.ai" target="" rel="noopener">{email},</a> which is responsible for determining escalation and response procedures, depending on the severity and nature of the incident.</p>
        <p><strong>Openness, Transparency and Access to PI</strong></p>
        <p>If any Authorized User needs to update or change their PI stored by us, they may do so by editing the organization or user record via the Services.</p>
        <p><strong>Consent to Transfer</strong></p>
        <p>We currently maintain computer systems in the United States and European Union, so your personal information may be processed by us in the United States, where data protection and privacy regulations may not offer the same level of protection as in other parts of the world. By using the Services, you agree to this Policy and you consent to the transfer of all such information to the United States, which may not offer a level of protection equivalent to that required in the European Union, United Kingdom, or certain other countries, and to the processing of that information as described in this Policy.</p>
        <p><strong>Retention and Deletion</strong></p>
        <p>SAIVA AI will retain PI as necessary for the purposes outlined in this Policy and for as long as an Authorized User’s account remains active, as required to manage and administer the Services, as required to carry out legal responsibilities (e.g., legal holds and other legal procedures), to resolve a dispute (including enforcement of a contract), or, as communicated to you at the time of collection. After all applicable retention periods have expired, we may delete or destroy your PI.</p>
        <p><strong>Regarding Use of the Services Via the App:</strong></p>
        <p>While the SAIVA AI App may be available via Apple’s App Store or Google Play, you understand and agree that SAIVA AI in no way controls or is responsible for any third-party product or service made available to you. SAIVA AI will not be liable for your interactions with any organizations or individuals found on these sites. You will need to contract separately for the integrated products and services offered by those third parties, and those dealings are solely between you and such organizations or individuals. Your use of any third-party product or service will not affect your relationship with SAIVA AI.</p>
        <p>To Contact Us</p>
        <p>If you believe your PI has been used in a way that is inconsistent with this Policy or your specified preferences, or if you have further questions related to our privacy practices, please contact us by any of the following methods:</p><p>Email: <a href="mailto:contactus@saiva.ai" target="" rel="noopener">{email}</a></p>
        <p>Phone: (888) 521-4011</p>
        <p>Address: 196 Castro St., Suite A,<br /> Mountain View CA 94041</p></div>
    </>
  )
}
