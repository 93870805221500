import UserCard from '../../components/Custom/UserCard'
import TimeSeriesCard from '../../components/UI/TimeSeriesCard'
import LayoutCards from './LayoutCards'
import Labs from '../../components/Custom/Labs'
import Risks from '../../components/Custom/Risks'
import { ReactComponent as CapsuleIcon } from '../../assets/icons/capsule-pill.svg'
import { ReactComponent as StethoscopeIcon } from '../../assets/icons/stethoscope.svg'
import { ReactComponent as SerumIcon } from '../../assets/icons/serum.svg'
import { ReactComponent as ErlenmeyerIcon } from '../../assets/icons/erlenmeyer-flask.svg'

const LogicCards = ({ data, type }) => {
  const layouts = {
    xxl: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 3,
        h: 4,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 3,
        y: 0,
        w: 4,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 7,
        y: 0,
        w: 2,
        h: 2,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '4',
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 3,
        y: 2,
        w: 6,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      },
      {
        i: '6',
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      }
    ],
    xl: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 4,
        h: 3,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 4,
        y: 0,
        w: 5,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 9,
        y: 0,
        w: 3,
        h: 2,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '4',
        x: 4,
        y: 2,
        w: 4,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 8,
        y: 2,
        w: 4,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      },
      {
        i: '6',
        x: 0,
        y: 3,
        w: 4,
        h: 2,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      }
    ],
    lg: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 1.5,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 0,
        y: 1.5,
        w: 8,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 8,
        y: 1.5,
        w: 4,
        h: 2,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '6',
        x: 0,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      },
      {
        i: '4',
        x: 6,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 5.5,
        w: 12,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      }
    ],
    md: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 1.5,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 0,
        y: 1.5,
        w: 8,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 8,
        y: 1.5,
        w: 4,
        h: 2,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '6',
        x: 0,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      },
      {
        i: '4',
        x: 6,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 5.5,
        w: 12,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      }
    ],
    sm: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 1.5,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 0,
        y: 1.5,
        w: 8,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 8,
        y: 1.5,
        w: 4,
        h: 2,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '6',
        x: 0,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      },
      {
        i: '4',
        x: 6,
        y: 3.5,
        w: 6,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 5.5,
        w: 12,
        h: 2,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      }
    ],
    xs: [
      {
        i: '1',
        x: 0,
        y: 0,
        w: 12,
        h: 3,
        component: <UserCard user={data.overview.resident} />
      },
      {
        i: '2',
        x: 0,
        y: 3,
        w: 12,
        h: 3,
        component: (
          <TimeSeriesCard
            icon={<StethoscopeIcon />}
            title={'Recent Diagnoses'}
            data={data.overview.recent_diagnoses}
            color={'#4070DC'}
          />
        )
      },
      {
        i: '3',
        x: 0,
        y: 6,
        w: 12,
        h: 3,
        component: <Risks data={data.overview.risk_conditions} />
      },
      {
        i: '6',
        x: 0,
        y: 9,
        w: 12,
        h: 3,
        component: (
          <Labs
            icon={<ErlenmeyerIcon />}
            title={'Labs'}
            data={data.overview.recent_labs}
          />
        )
      },
      {
        i: '4',
        x: 0,
        y: 12,
        w: 12,
        h: 3,
        component: (
          <TimeSeriesCard
            icon={<SerumIcon />}
            title={'Recent Orders'}
            data={data.overview.recent_orders}
            color={'#A03AFD'}
          />
        )
      },
      {
        i: '5',
        x: 0,
        y: 15,
        w: 12,
        h: 3,
        component: (
          <TimeSeriesCard
            icon={<CapsuleIcon />}
            title={'New Medication'}
            data={data.overview.recent_medications}
            color={'#5EC7FF'}
          />
        )
      }
    ]
  }
  return <LayoutCards layouts={layouts} />
}

export default LogicCards
