import { getBrowserInfo } from 'utils/helper'
import { FileType } from 'services/api'

const getDateFormat = (date: Date): string => {
  return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
}
  
interface DownloadFileProps {
  title: { start: Date | undefined; end: Date | undefined } | string | undefined
  fileName?: string
  fromUrl?: boolean
  type?: FileType
  window?: Window | null
}

const downloadFile = (data, options: DownloadFileProps) => {
    const {
      title,
      fileName = 'report',
      fromUrl = false,
      type = undefined,
      window
    } = options
    const aElement = document.createElement('a')
    let name = 'report'
    if (typeof title === 'string') {
      name = fileName + '-' + title
    } else {
      if (title && title.start && title.end)
        name =
          fileName +
          '-' +
          getDateFormat(title.start) +
          '-' +
          getDateFormat(title.end)
    }
    if (fromUrl) {
      if (window) window.location.href = data
      if (getBrowserInfo?.name === 'ios') aElement.setAttribute('download', name)
      if (getBrowserInfo?.name === 'safari' || getBrowserInfo?.name === 'ios') {
        aElement.setAttribute('target', '_blank')
        aElement.click()
      }
      URL.revokeObjectURL(data)
    } else {
      const len = data.length
      const buffer = new ArrayBuffer(len)
      const view = new Uint8Array(buffer)
      for (let i = 0; i < len; i++) {
        view[i] = data.charCodeAt(i)
      }
      const file = new File([view], name, {
        type: type
          ? type === FileType.PDF
            ? 'application/pdf'
            : 'text/csv'
          : 'application/pdf'
      })
      const href = URL.createObjectURL(file)
  
      if (type === FileType.CSV || getBrowserInfo?.name === 'ios') {
        aElement.setAttribute('download', name)
      }
      if (
        type === FileType.CSV ||
        getBrowserInfo?.name === 'ios' ||
        getBrowserInfo?.name === 'safari'
      ) {
        window?.close()
        aElement.href = href
        aElement.setAttribute('target', '_blank')
        aElement.click()
      } else {
        if (window) {
          window.location.href = href
        }
      }
      URL.revokeObjectURL(href)
    }
  }

  export default downloadFile