import { del, get, post, patch } from 'services/utils/api-client'
import { UserCapability } from '../types/user-types'
import { generateFilterUrl, PaginatedResponse } from 'services/utils/api-utils'
import { SaivaInvitation } from '../types/invitation-types'
import UserService, { UserResponse } from './user-service'
import { UserFilter } from './user-service'

export namespace InvitationResponse {
  export interface Item {
    email: string,
    title: string,
    name: string,
    id: number,
    status: string,
    invited_by: string
    created_at: string,
    expires_at: string,
    roles: {role_name: string, enabled: boolean}[],
    regions: UserResponse.Regions,
    capabilities: UserCapability[],

  }
}

const parseInvitation = {
  Item: (response: InvitationResponse.Item, roles) : SaivaInvitation.Item => {
    return {
      id: response.id,
      email: response.email,
      title: response.title,
      name: response.name,
      status: response.status,
      createdAt: response.created_at,
      expiresAt: response.expires_at,
      invitedBy: response.invited_by,
      roles: response.roles.map(role => {
        const found = roles?.find(r => r.name === role.role_name)
        if (found) return {...found , enabled: role.enabled}
        return { name: role.role_name, enabled: true, description: "Unknown" }
      }),
      regions: response.regions,
      capabilities: response.capabilities.map(capability => capability as UserCapability)
    }
  }
}

const getInvitations = async (orgId: string, filter: UserFilter) : Promise<PaginatedResponse<SaivaInvitation.Item> | undefined> => {
  const url = generateFilterUrl("/v2/orgs/{org_id}/invitations", [{id: "{org_id}", value: orgId}], filter)
  const roles = await UserService.getRoles(orgId)
  return await get<InvitationResponse.Item, SaivaInvitation.Item>(url, { parser: (r) =>  parseInvitation.Item(r, roles), paginated: true })
}

const createInvitation = async (orgId: string, payload: UserResponse.Update.Invitation) => {
  const url = generateFilterUrl("/v2/orgs/{org_id}/invitations", [{id: "{org_id}", value: orgId}])
  return await post<InvitationResponse.Item, SaivaInvitation.Item>(url, {payload})
}

const updateInvitation = async (orgId: string, invitationId: string, payload: UserResponse.Update.Invitation) => {
  const url = generateFilterUrl("/v2/orgs/{org_id}/invitations/{invitation_id}", [{id: "{org_id}", value: orgId}, {id: "{invitation_id}", value: invitationId}])
  return await patch<InvitationResponse.Item, SaivaInvitation.Item>(url, {payload})
}

const resendInvitation = async (orgId: string, invitationId: number) => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{invitation_id}", value: invitationId.toString()}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/invitations/{invitation_id}/resend", params)
  return await post(url)
}

const deleteInvitation = async (orgId: string, invitationId: number) => {
  const params = [{id: "{org_id}", value: orgId}, {id: "{invitation_id}", value: invitationId.toString()}]
  const url = generateFilterUrl("/v2/orgs/{org_id}/invitations/{invitation_id}", params)
  return await del(url)
}

const acceptInvitation = async (payload: {token: string}) => {
  return await post("/v2/invitations/accept", {payload})
}


const InvitationService = {
  getInvitations,
  createInvitation,
  updateInvitation,
  resendInvitation,
  deleteInvitation,
  acceptInvitation
}

export default InvitationService
