import styles from './Collapse.module.scss'
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-small-down.svg'

type CollapseProps = {
  children: React.ReactNode
  header: React.ReactNode
}

export default function Collapse(props: CollapseProps) {
  const { children, header } = props
  return (
    <details className={styles.collapse} open>
      <summary>
        <ChevronRight className={styles.dropdownIcon}/>
        {header}
      </summary>
      <div className={styles.content}>{children}</div>
    </details>
  )
}
