import CardLayout from '../../components/UI/CardLayout'
import Card from '../../components/UI/Card'
import { Grid } from 'antd'

const { useBreakpoint } = Grid

const LayoutCards = ({ layouts }) => {
  const screens = useBreakpoint()
  const breakpoint = Object.entries(screens)
    .filter((screen) => !!screen[1])
    .map((screen) => {
      return screen[0]
    })

  return (
    <>
      {Object.entries(screens).length > 0 && (
        <CardLayout
          // [breakpoint[breakpoint.length - 1]]
          children={layouts[breakpoint[breakpoint.length - 1]]?.map(
            (layout: any) => {
              return (
                <div
                  style={{ padding: '10px' }}
                  key={layout.i}
                  data-grid={{
                    x: layout.x,
                    y: layout.y,
                    w: layout.w,
                    h: layout.h
                  }}
                >
                  <Card>{layout.component}</Card>
                </div>
              )
            }
          )}
        />
      )}
    </>
  )
}

export default LayoutCards
