import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import SaivaIcon from '../components/saivaIcon'
import jwt_decode, { JwtPayload } from "jwt-decode"
import { ApiError } from '../services/api'
import ButtonWithSpinner from '../components/buttonWithSpinner'
import SaivaModal from '../components/saivaModal'
import TermsOfService from './termsOfService'
import PrivacyPolicy from './privacyPolicy'
import InvitationService from '../services/invitation-service'
import { handleError } from '../utils/errorHandler'
import { Tooltip, Button } from 'antd'
import styles from './Invitation.module.scss'
import animation from 'assets/invitation_animation.gif'
import { UserCapability } from 'types/user-types'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

interface JwtTokenPayload {
  email: string,
  capabilities: UserCapability[],
}

enum InviteStep {
  AcceptInvite,
  Success,
  Error
}

export default function Invitation() {
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [submitError, setSubmitError] = useState<string | undefined>(undefined)
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false)
  const [currentStep, setCurrentStep] = useState<InviteStep>(InviteStep.AcceptInvite)
  const [decodedEmail, setDecodedEmail] = useState<string>("")
  const [decodedToken, setDecodedToken] = useState<JwtTokenPayload | undefined>(undefined)
  const { t } = useTranslation()
  const { token: inviteToken } = useParams()
  const navigate = useNavigate()
  const [isCheckboxInvalid, setIsCheckboxInvalid] = useState<boolean>(false)
  const [showTos, setShowTos] = useState<boolean>(false)
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState<boolean>(false)
  const [apiError, setApiError] = useState<any>(undefined)

  useEffect(() => {
    try {
      if(inviteToken) {
        const decodedToken = jwt_decode<JwtTokenPayload>(inviteToken)
        setDecodedToken(decodedToken)
        setDecodedEmail(decodedToken.email)
      }
    } catch {
      setSubmitError("backend.invitation_token_invalid")
      setApiError("backend.invitation_token_invalid")
    }
  }, [])

  const joinTeam = async () => {
    if(acceptedTerms === false) {
      setIsCheckboxInvalid(true)
      return
    }
    if(inviteToken === undefined) {
      setSubmitError("backend.invitation_token_required")
      setApiError("backend.invitation_token_invalid")
      return
    }
    try {
      setRequestInFlight(true)
      await InvitationService.acceptInvitation({token: inviteToken})
      setRequestInFlight(false)
      setCurrentStep(InviteStep.Success)

    } catch (err: any) {
      setRequestInFlight(false)
      if(err.name === "ApiError") {
        if (err.errorCode === "backend.feature_limit_is_reached") {
          setSubmitError("backend.feature_limit_is_reached_user")
          setApiError("backend.feature_limit_is_reached_user")
        } else {
          setSubmitError(err.errorCode)
          setApiError(err.errorCode)
        }
      } else {
        setSubmitError("genericError")
      }
    }
  }

  const loginClicked = () => {
    navigate(`/login/${decodedEmail}`)
  }

  const tosClicked = () => {
    setShowTos(true)
  }

  const privacyPolicyClicked = () => {
    setShowPrivacyPolicy(true)
  }

  const handleCloseTos = () => {
    setShowTos(false)
  }

  const handleClosePrivacyPolicy = () => {
    setShowPrivacyPolicy(false)
  }

  const makeStepJoin = () => {
    return (
    <div className="d-flex flex-column stepJoin">
      <div className={styles.joinStepContent}>
        <p className='header'>
          {decodedToken?.capabilities && 
            !decodedToken?.capabilities
              .includes(UserCapability.WEB_APP_LOGIN) ? 
                t('invite.header.subscriptionText') : 
                t('invite.header.text')}
        </p>
        <p className='subheader'>{t('invite.subheader.text')}</p>
        <div className={`${styles.inviteEmail} form-group inviteEmail`}>
          <label>{t('invite.email.label')}</label>
          <Tooltip 
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={decodedEmail}
          >
            <p className='email elipsis'>
                {decodedEmail}
            </p>
          </Tooltip>
        </div>
      </div>
      {apiError && (
        <div className={`${styles.error} banner bannerFailed`}>
          <InfoIcon width={'46px'} style={{padding: '2px'}}/>
          {t(apiError)}
        </div>
      )}
       <div className={styles.joinStepContent}>
        <div className='form-check terms d-flex flex-row align-items-center'>
          <input className={`form-check-input ${isCheckboxInvalid ? 'is-invalid' : ''}`} type="checkbox" checked={acceptedTerms} onChange={(e) => setAcceptedTerms(e.target.checked)} />
          <label className={styles.formCheckLabel}>
            {t('invite.terms.agreedTo')} <a href="#" onClick={() => tosClicked()}>{t('invite.terms.termsOfService')}</a> {t('invite.terms.and')} <a href="#" onClick={() => privacyPolicyClicked()}>{t('invite.terms.privacyPolicy')}</a>
          </label>
        </div>
            <>
              <ButtonWithSpinner
                showSpinner={requestInFlight}
                size="24px"
                disabled={apiError}
                className={`${styles.loginButton} btn btn-primary text-uppercase`}
                onClick={() => joinTeam()}
              >
                {t('invite.joinButton.text')}
              </ButtonWithSpinner>
              <div className={`d-flex mt-4 justify-content-center align-items-center`}>
                <span>{t('invite.alreadyUser')}</span>
                <a
                  className={`btn btn-text-light ms-3 text-uppercase`}
                  onClick={() => loginClicked()}
                >
                  {t('invite.loginButton.text')}
                </a>
              </div>
            </>
      </div>
    </div>)
  }

  const makeStepSuccess = () => {
    return (<div className={styles.succesLogin}>
      <h6>{t('invite.sucessfullyJoined')}</h6>
      <p>{t('invite.loginPromptText')}</p>
      <Button
        size="large"
        className={styles.subscribeButton}
        onClick={() => loginClicked()}
      >
        {t('invite.loginButton.text')}
      </Button>
    </div>)
  }

  const makeSuccesfullSubscription = () => {
    return (
      <div className={styles.succesSubscription}>
        <h6>{t('invite.sucessfullySubscribedTitle')}</h6>
        <p>{t('invite.sucessfullyJoined')}</p>
        <img src={animation} alt="Invitation animation" />
      </div>
    )
  }

  return (
    <>
      <SaivaModal show={showTos} onHide={() => handleCloseTos()} onCancel={() => handleCloseTos()} backdrop={true}>
        <TermsOfService />
      </SaivaModal>
      <SaivaModal show={showPrivacyPolicy} onHide={() => handleClosePrivacyPolicy()} onCancel={() => handleClosePrivacyPolicy()} backdrop={true}>
        <PrivacyPolicy />
      </SaivaModal>
      <div className="floatingDialog container-fluid align-items-center">
        <img src="/img/saiva-logo-combined.png" className="logo"/>
        <div className="row d-flex justify-content-center">
          <div className={styles.invitationWrapper}>
            <div className="contentWrapper">
              <div className={`d-flex content justify-content-center`}>
                <div className={`${styles.invitation} invitation`}>
                  {currentStep === InviteStep.AcceptInvite && makeStepJoin()}
                  {currentStep === InviteStep.Success && makeStepSuccess()}
                </div>
                <span className="footer my-4">
                  ©Saiva AI 2024 | <a href="#" onClick={() => privacyPolicyClicked()} style={{ textDecoration: 'none' }}> Privacy Policy</a> |
                  <a href="#" onClick={() => tosClicked()} style={{ textDecoration: 'none' }}> Terms & Conditions</a> |
                  <a href="http://status.saiva.ai" target="_blank" style={{ textDecoration: 'none' }}> Service Status</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    )
}
