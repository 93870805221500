import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import styles from './Tabs.module.scss'
import { mixpanelInstance } from 'utils/mixpanel'

export default function SaivaTabs({ items }: TabsProps) {
  const onChange = (key: string) => {
    if (items && items.length > 0) {
      const tab = items.find((item) => item.key === key)
      if (tab && tab.label) {
        mixpanelInstance.switchResidentTab(tab.label.toString())
      }
    }
  }

  return (
    <div style={{ padding: '0px' }}>
      <Tabs
        defaultActiveKey="1"
        items={items}
        className={styles.tabs}
        onChange={onChange}
      />
    </div>
  )
}
