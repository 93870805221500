import styles from './Schedules.module.scss'
import { Space, Dropdown, Menu, Button, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import type { ColumnsType } from 'antd/es/table'
import { ReactComponent as MenuVertical } from 'assets/icons/menu-vertical.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { ReactComponent as ResizeIcon } from 'assets/icons/resize-small-vertical.svg'
import { Schedules } from 'types/schedule-reports'
import {
  getReportLabel,
  getReportId,
  getCategoryLabel,
  getFeatureLabel
} from 'utils/helper'
import { getDaysGroup, repeatOptions } from '../utils'
import SaivaToggle from 'components/SaivaForm/SaivaToggle/saivaToggle'

export function ColumnsAllSchedules(
  handleSortClick,
  toggleEdit,
  edit,
  duplicate,
  deleteItem
): ColumnsType<Schedules.TableItem> {
  const { t } = useTranslation()
  return [
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('assignee_name')}
            className={styles.sortButton}
          >
            {t('schedules.allSchedules.table.title.assignee_name')}
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['schedule_config', 'assignee_name'],
      key: 'assignee_name',
      width: 200
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('name')}
            className={styles.sortButton}
          >
            {t('schedules.allSchedules.table.title.scheduleName')}
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['schedule_config', 'name'],
      key: 'name',
      render: (text) => (
        <Tooltip
          arrow={false}
          overlayInnerStyle={{
            background: '#112443',
            textAlign: 'left'
          }}
          title={text}
        >
          {text}
        </Tooltip>
      ),
      width: 160,
      ellipsis: true
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('scheduled_by_name')}
            className={styles.sortButton}
          >
            {t('schedules.allSchedules.table.title.scheduled_by_name')}
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['schedule_config', 'scheduled_by_name'],
      key: 'scheduled_by_name',
      width: 230
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('report_type')}
            className={styles.sortButton}
          >
            {t('schedules.allSchedules.table.title.report')}
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['report_config', 'report_type'],
      key: 'report_type',
      render: (text) => (
        <>
          <label
            className="category-label"
            style={{
              color: getReportLabel(getReportId(text))?.color,
              background: getReportLabel(getReportId(text))?.bg,
              fontSize: '14px'
            }}
          >
            {getReportLabel(getReportId(text))?.label}
          </label>
        </>
      ),
      width: 230
    },
    {
      title: t('schedules.allSchedules.table.title.reportCategory'),
      dataIndex: ['report_config', 'report_category'],
      key: 'report_category',
      render: (text) => <>{getCategoryLabel(text)}</>,
      width: 190,
      ellipsis: true
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('quality_measure')}
            className={styles.sortButton}
          >
            QM
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['report_config', 'params', 'filters', 'quality_measure'],
      key: 'quality_measure',
      render: (text) => <>{getFeatureLabel(text).label}</>,
      width: 70
    },
    {
      title: t('schedules.allSchedules.table.title.state'),
      dataIndex: ['schedule_config', 'is_enabled'],
      key: 'is_enabled',
      render: (is_enabled, schedule) => (
        <SaivaToggle
          value={is_enabled}
          hideLabel={true}
          name="name"
          onChange={(value) => {
            toggleEdit(value, schedule)
          }}
        />
      ),
      width: 80,
      ellipsis: true
    },
    {
      title: t('schedules.allSchedules.table.title.scheduleTime'),
      dataIndex: ['schedule_config', 'time_of_day'],
      key: 'schedule_time',
      render: (text, schedule) => (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div className="elipsis">
            {text}{' '}
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={schedule.schedule_config.time_zone}
            >
              {schedule.schedule_config.time_zone}
            </Tooltip>
          </div>
        </div>
      ),
      width: 180,
      ellipsis: true
    },
    {
      title: t('schedules.allSchedules.table.title.repeat'),
      dataIndex: ['schedule_config', 'schedule_type'],
      key: 'repeat',
      render: (text) => (
        <div className={styles.repeat}>
          {repeatOptions.find((item) => item.value == text)?.label}
        </div>
      ),
      width: 80,
      ellipsis: true
    },
    {
      title: t('schedules.allSchedules.table.title.scheduledDays'),
      dataIndex: ['schedule_config', 'day_of_week'],
      key: 'day_of_week',
      render: (_, { schedule_config }) => (
        <div style={{ display: 'flex' }}>
          <div className={styles.weekOfMonth}>
            {schedule_config.week_of_month
              ? schedule_config.week_of_month
              : '-'}
          </div>
          {getDaysGroup(schedule_config.day_of_week).map((group, i) => (
            <span key={i} className={styles.selectedDaysWrapper}>
              {group}
            </span>
          ))}
        </div>
      ),
      width: 210
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('file_format')}
            className={styles.sortButton}
          >
            {t('schedules.allSchedules.table.title.file')}
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: ['schedule_config', 'file_format'],
      key: 'file_format',
      render: (text) => <>{text.toUpperCase()}</>,
      width: 60
    },
    {
      title: t('schedules.allSchedules.table.title.action'),
      key: 'action',
      align: 'center',
      render: (index, schedule) => (
        <Dropdown
          trigger={['click']}
          overlayClassName={styles.dropdown}
          dropdownRender={(menu) => (
            <Menu>
              <Menu.Item
                onClick={() => {
                  edit(schedule)
                }}
                className={styles.menuItem}
              >
                <EditIcon style={{ marginRight: '12px' }} />
                {t('schedules.allSchedules.table.action.edit')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  duplicate(schedule)
                }}
                className={`${styles.duplicateMenuItem} ${styles.menuItem}`}
              >
                <DuplicateIcon style={{ marginRight: '12px' }} />
                {t('schedules.allSchedules.table.action.duplicate')}
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  deleteItem(schedule)
                }}
                className={`${styles.deleteMenuItem} ${styles.menuItem}`}
              >
                <DeleteIcon style={{ marginRight: '12px' }} />
                {t('schedules.allSchedules.table.action.delete')}
              </Menu.Item>
            </Menu>
          )}
        >
          <Space>
            <MenuVertical className={styles.menuIcon} />
          </Space>
        </Dropdown>
      ),
      width: 70
    }
  ]
}

export function ColumnsMineSchedules(
    handleSortClick,
    toggleEdit,
    edit,
    duplicate,
    deleteItem
  ): ColumnsType<Schedules.TableItem> {
    const { t } = useTranslation()
    return [
      {
        title: (
          <>
            <Button
              type="link"
              onClick={() => handleSortClick('name')}
              className={styles.sortButton}
            >
              {t('schedules.allSchedules.table.title.scheduleName')}
              <ResizeIcon />
            </Button>
          </>
        ),
        dataIndex: ['schedule_config', 'name'],
        key: 'name',
        render: (text) => (
          <Tooltip
            arrow={false}
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={text}
          >
            {text}
          </Tooltip>
        ),
        width: 160,
        ellipsis: true
      },
      {
        title: (
          <>
            <Button
              type="link"
              onClick={() => handleSortClick('scheduled_by_name')}
              className={styles.sortButton}
            >
              {t('schedules.allSchedules.table.title.scheduled_by_name')}
              <ResizeIcon />
            </Button>
          </>
        ),
        dataIndex: ['schedule_config', 'scheduled_by_name'],
        key: 'scheduled_by_name',
        width: 230
      },
      {
        title: (
          <>
            <Button
              type="link"
              onClick={() => handleSortClick('report_type')}
              className={styles.sortButton}
            >
              {t('schedules.allSchedules.table.title.report')}
              <ResizeIcon />
            </Button>
          </>
        ),
        dataIndex: ['report_config', 'report_type'],
        key: 'report_type',
        render: (text) => (
          <>
            <label
              className="category-label"
              style={{
                color: getReportLabel(getReportId(text))?.color,
                background: getReportLabel(getReportId(text))?.bg,
                fontSize: '14px'
              }}
            >
              {getReportLabel(getReportId(text))?.label}
            </label>
          </>
        ),
        width: 230
      },
      {
        title: t('schedules.allSchedules.table.title.reportCategory'),
        dataIndex: ['report_config', 'report_category'],
        key: 'report_category',
        render: (text) => <>{getCategoryLabel(text)}</>,
        width: 190,
        ellipsis: true
      },
      {
        title: (
          <>
            <Button
              type="link"
              onClick={() => handleSortClick('quality_measure')}
              className={styles.sortButton}
            >
              QM
              <ResizeIcon />
            </Button>
          </>
        ),
        dataIndex: ['report_config', 'params', 'filters', 'quality_measure'],
        key: 'quality_measure',
        render: (text) => <>{getFeatureLabel(text).label}</>,
        width: 70
      },
      {
        title: t('schedules.allSchedules.table.title.state'),
        dataIndex: ['schedule_config', 'is_enabled'],
        key: 'is_enabled',
        render: (is_enabled, schedule) => (
          <SaivaToggle
            value={is_enabled}
            hideLabel={true}
            name="name"
            onChange={(value) => {
              toggleEdit(value, schedule)
            }}
          />
        ),
        width: 80,
        ellipsis: true
      },
      {
        title: t('schedules.allSchedules.table.title.scheduleTime'),
        dataIndex: ['schedule_config', 'time_of_day'],
        key: 'schedule_time',
        render: (text, schedule) => (
          <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            <div className="elipsis">
              {text}{' '}
              <Tooltip
                arrow={false}
                overlayInnerStyle={{
                  background: '#112443',
                  textAlign: 'left'
                }}
                title={schedule.schedule_config.time_zone}
              >
                {schedule.schedule_config.time_zone}
              </Tooltip>
            </div>
          </div>
        ),
        width: 180,
        ellipsis: true
      },
      {
        title: t('schedules.allSchedules.table.title.repeat'),
        dataIndex: ['schedule_config', 'schedule_type'],
        key: 'repeat',
        render: (text) => (
          <div className={styles.repeat}>
            {repeatOptions.find((item) => item.value == text)?.label}
          </div>
        ),
        width: 80,
        ellipsis: true
      },
      {
        title: t('schedules.allSchedules.table.title.scheduledDays'),
        dataIndex: ['schedule_config', 'day_of_week'],
        key: 'day_of_week',
        render: (_, { schedule_config }) => (
          <div style={{ display: 'flex' }}>
            <div className={styles.weekOfMonth}>
              {schedule_config.week_of_month
                ? schedule_config.week_of_month
                : '-'}
            </div>
            {getDaysGroup(schedule_config.day_of_week).map((group, i) => (
              <span key={i} className={styles.selectedDaysWrapper}>
                {group}
              </span>
            ))}
          </div>
        ),
        width: 210
      },
      {
        title: (
          <>
            <Button
              type="link"
              onClick={() => handleSortClick('file_format')}
              className={styles.sortButton}
            >
              {t('schedules.allSchedules.table.title.file')}
              <ResizeIcon />
            </Button>
          </>
        ),
        dataIndex: ['schedule_config', 'file_format'],
        key: 'file_format',
        render: (text) => <>{text.toUpperCase()}</>,
        width: 60
      },
      {
        title: t('schedules.allSchedules.table.title.action'),
        key: 'action',
        align: 'center',
        render: (index, schedule) => (
          <Dropdown
            trigger={['click']}
            overlayClassName={styles.dropdown}
            dropdownRender={(menu) => (
              <Menu>
                <Menu.Item
                  onClick={() => {
                    edit(schedule)
                  }}
                  className={styles.menuItem}
                >
                  <EditIcon style={{ marginRight: '12px' }} />
                  {t('schedules.allSchedules.table.action.edit')}
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    duplicate(schedule)
                  }}
                  className={`${styles.duplicateMenuItem} ${styles.menuItem}`}
                >
                  <DuplicateIcon style={{ marginRight: '12px' }} />
                  {t('schedules.allSchedules.table.action.duplicate')}
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    deleteItem(schedule)
                  }}
                  className={`${styles.deleteMenuItem} ${styles.menuItem}`}
                >
                  <DeleteIcon style={{ marginRight: '12px' }} />
                  {t('schedules.allSchedules.table.action.delete')}
                </Menu.Item>
              </Menu>
            )}
          >
            <Space>
              <MenuVertical className={styles.menuIcon} />
            </Space>
          </Dropdown>
        ),
        width: 70
      }
    ]
  }
