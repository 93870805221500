import React, { ChangeEvent, ReactNode, useEffect, useMemo } from 'react'
import styles from './Form.module.scss'
import { useFormContext } from '../../context/FormProvider'
import { ValidatorTypes } from '../../utils/validation'

interface InputProps {
  id: string,
  value?: string,
  onChange?: (value: string) => void
  placeholder?: string,
  validators?: ValidatorTypes[],
  label?: string
  required?: boolean
  disabled?: boolean
  endIcons?: ReactNode[] | ReactNode
  error?: string,
  readOnly?: boolean,
  skipBoxShadow?: boolean,
  disabledText?: boolean,
  onFocus?: () => void,
  onBlur?: () => void,
  width?: number
}

const Input = (props: InputProps) => {

  const {errors, updateValue, getValue, addValidationType} = useFormContext()
  const {id, value, onChange, error = errors.get(id), placeholder = "", validators, label, required = false, disabled = false, endIcons, readOnly=false, skipBoxShadow, disabledText=false} = props

  useEffect(() => {
    addValidationType(id, validators)
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if(onChange) onChange(e.target.value)
    else updateValue({[id]: e.target.value})
  }

  return (
    <div className={styles.inputWrapper}>
      <div className={`${styles.inputContainer} ${disabled ? styles.inputContainerDisabled : ""} ${error ? styles.inputContainerError : ""}`}>
        {label && <label>{label}{required && <span className={styles.requiredLabel}>*</span>}</label>}
        <input
          value={value ? value : onChange ? "" : getValue(id)}
          onChange={handleChange}
          placeholder={placeholder}
          readOnly={readOnly}
          style={{
            boxShadow: skipBoxShadow ? 'none' : '',
            color: disabledText ? 'rgba(17, 36, 67, 0.6)' : '',
            width: props.width ? props.width : "auto"
          }}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
        <div className={styles.icons}>
          {endIcons}
        </div>
      </div>
      {error && <label className={styles.errorLabel}>{error}</label>}
    </div>
  )
}

export default Input
