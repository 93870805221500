import { useTranslation } from 'react-i18next'
import styles from './Risks.module.scss'
import { ReactComponent as HartRate } from '../../../assets/icons/heart-rate.svg'
import { ReactComponent as Diabeties } from '../../../assets/icons/diabetes.svg'
import { ReactComponent as Disase } from '../../../assets/icons/disease.svg'
import { ReactComponent as NoDataIcon } from '../../../assets/icons/calendar-cross.svg'
import { ReactComponent as Chf } from '../../../assets/icons/chf.svg'
import { ReactComponent as Uti } from '../../../assets/icons/uti.svg'
import { ReactComponent as Copd } from '../../../assets/icons/copd.svg'
import { ReactComponent as Covid19 } from '../../../assets/icons/covid-19.svg'
import { ReactComponent as Cellulitis } from '../../../assets/icons/cellulitis.svg'

const riskItem = (risk) => {
  if (risk == 'pressure_wounds') {
    return {
      icon: <HartRate />
    }
  }
  if (risk == 'diabetes') {
    return {
      icon: <Diabeties />
    }
  }
  if (risk == 'chf') {
    return {
      icon: <Chf />
    }
  }
  if (risk == 'copd') {
    return {
      icon: <Copd />
    }
  }
  if (risk == 'pneumonia') {
    return {
      icon: <Disase />
    }
  }
  if (risk == 'cellulitis') {
    return {
      icon: <Cellulitis />
    }
  }
  if (risk == 'sepsis') {
    return {
      icon: <Disase />
    }
  }
  if (risk == 'pressure_wounds') {
    return {
      icon: <Disase />
    }
  }
  if (risk == 'renal_diseases') {
    return {
      icon: <Disase />
    }
  }
  if (risk == 'uti') {
    return {
      icon: <Uti />
    }
  }
  if (risk == 'respiratory_failure') {
    return {
      icon: <Disase />
    }
  }
  if (risk == 'covid_19') {
    return {
      icon: <Covid19 />
    }
  } else
    return {
      icon: <Disase />
    }
}

interface RiskItem {
  id: string
  value: string
}

type Props = {
  data?: RiskItem[]
}

export default function TopNavigation({ data }: Props) {
  const { t } = useTranslation()
  return (
    <div className={styles.layout}>
      <h4>{t('residentPage.riskConditions.title')} ({data?.length})</h4>
      <div className={styles.content}>
        {data && data?.length > 0 ? (
          <table className={styles.table}>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <div key={index} className={styles.riskItem}>
                    <div style={{ paddingRight: '16px' }}>
                      {riskItem(item.id)?.icon}
                    </div>
                    <h5 style={{ marginBottom: 0, whiteSpace: 'normal', fontSize: '16px' }}>
                      {item.value}
                    </h5>
                  </div>
                )
              })}
            </tbody>
          </table>
        ) : (
          <>
            <div className={styles.noData}>
              <NoDataIcon />
              {t('residentPage.riskConditions.noData')}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
