import { Spin, Skeleton } from 'antd'
import styles from './SkeletonCard.module.scss'
import Gradient from 'assets/icons/Gradient.gif'

const antIcon = (
  <img src={Gradient} style={{marginRight: '4px'}} />
)

interface Props {
  repeat: number
}

const SkeletonCard = (props: Props) => {
  return [...Array(props.repeat)].map((e, i) => {
    return (
      <div key={i} className={styles.card}>
        <p>
          <Spin indicator={antIcon} />
          Please Wait
        </p>
        <Skeleton active />
      </div>
    )
  })
}

export default SkeletonCard
