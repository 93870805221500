import { Modal, Row } from 'antd';
import styles from './CModal.module.scss'

const ConfirmationModal = ({openConfirm, handleCancelConfirm, handleOk, message, title}) => {

  return (
    <Modal
      open={openConfirm}
      footer={null}
      maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
      }}
      width={'400px'}
      closable={false}
      centered={true}
    >
        {/* TODO Fonts are not right but this needs to be fixed in global styles */}
      <h4>{title}</h4>
      <p>{message}</p>
      <Row justify="end" style={{marginTop: '32px'}}>
        <button
          className={styles.cancelButton}
          onClick={handleCancelConfirm}
        >
          Cancel
        </button>
        <button
          className={styles.button}
          onClick={handleOk}
        >
          Delete
        </button>
      </Row>
    </Modal>
  )
}

export default ConfirmationModal;