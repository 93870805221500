const saivaTheme = {
  token: {
    colorPrimary: '#4070DC',
    colorInfo: '#4070DC',
    colorInfoBorder: '#4070DC',
  },
  components: {
    Tag: {
      colorSuccess: '#4070DC',
      colorSuccessBg: '#D9E2F8',
      colorSuccessBorder: '#D9E2F8',
      fontFamily: 'Heebo',
      fontSize: 14,
      lineHeight: 1.2,
      lineHeightSM: 1.2,
      fontSizeSM: 14,
      lineWidth: 2,
      fontSizeIcon: 22,
      borderRadiusSM: 6
    },
    Select: {
      fontSizeLG: 14,
    },
    Dropdown: {
      paddingXS: 0,
      paddingXXS: 0,
      controlPaddingHorizontal: 0,
      padding: 0
    },
    Collapse: {
      padding: 0
    },
    Radio: {
      radioSize: 20,
      dotSize: 11
    }
  },
}

export default saivaTheme