import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showErrorToast } from 'utils'
import { ReactComponent as CrossIcon } from 'assets/icons/cross-big.svg'
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg'
import { ReactComponent as CheckboxIcon } from 'assets/icons/checkbox.svg'
import { ReactComponent as CheckboxMinus } from 'assets/icons/checkbox-minus.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import styles from './OptionSelectCard.module.scss'
import { Button, Row, Col } from 'antd'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import SaivaModal from '../saivaModal'

export interface IOptionSelectItem {
  id: any
  label: string
  isSelected: boolean
}

interface ManageModalProps {
  open: boolean
  handleCancel: (e?: any) => void
  handleSubmit: (item: IOptionSelectItem[]) => void
  items: IOptionSelectItem[] | undefined
  required: boolean | undefined
}

const ManageModal = (props: ManageModalProps) => {
  const { open: showModal, handleCancel, required } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState<string>('')
  const [items, setItems] = useState<IOptionSelectItem[] | undefined>(
    props.items ? [...props.items] : []
  )

  useEffect(() => {
    setSearch('')
    if (props.open) {
      setItems(props.items ? [...props.items] : [])
    }
  }, [props.open, props.items])

  const allSelected = useMemo(() => {
    return !items?.find((item) => !item.isSelected)
  }, [items])

  const toggleAll = () => {
    if (allSelected) {
      setItems((prev) =>
        prev?.map((item) => {
          return { ...item, isSelected: false }
        })
      )
    } else {
      setItems((prev) =>
        prev?.map((item) => {
          return { ...item, isSelected: true }
        })
      )
    }
  }

  const handleToggleItem = (item: IOptionSelectItem) => {
    setItems((prev) => {
      if (!prev) return undefined
      const fac = prev.map((i) => {
        if (i.id === item.id) return { ...item, isSelected: !item.isSelected }
        return i
      })
      let isAll = true
      fac.forEach((item) => {
        if (!item.isSelected) isAll = false
      })
      return fac
    })
  }

  const handleSubmit = () => {
    if (!items) {
      showErrorToast(t('optionSelect.modal.error.general'))
      return
    }
    const selected = [...items].filter((i) => i.isSelected)
    if (selected.length === 0 && required) {
      showErrorToast(t('optionSelect.modal.error.oneOrMoreOption'))
      return
    }
    props.handleSubmit(items)
  }

  return (
    <SaivaModal
      show={showModal}
      onHide={handleCancel}
      onCancel={handleCancel}
      className={'reportModal'}
      header={false}
    >
      <div style={{ padding: '24px 24px 10px 24px' }}>
        <Row justify={'end'} style={{ marginBottom: '5px' }}>
          <Col>
            <CrossIcon onClick={handleCancel} style={{ padding: '4px' }} />
          </Col>
        </Row>
        <div>
          <SaivaInput
            placeholder="Search"
            prefixIcon={<SearchIcon />}
            name="name"
            label=""
            onChange={(e) => {
              setSearch(e.target.value)
            }}
          />
        </div>
        <div
          className={styles.headerModal}
          style={{ padding: '24px 0px 10px 0px' }}
        >
          <div className={'all'}>
            {allSelected ? (
              <div style={{ marginRight: '8px' }}>
                <CheckboxMinus onClick={toggleAll} />
              </div>
            ) : (
              <div className={'icon'}>
                <CheckboxIcon className={'box'} onClick={toggleAll} />
              </div>
            )}
            {t('optionSelect.modal.all')}
          </div>
        </div>
      </div>
      <div className={'modalContent'}>
        <>
          <div className={'items'}>
            {items?.map((item, index) => {
              if (item.label.toLowerCase().includes(search.toLowerCase())) {
                return (
                  <div key={index} className={'item'}>
                    {item.isSelected ? (
                      <div className={'icon'}>
                        <CheckIcon onClick={() => handleToggleItem(item)} />
                      </div>
                    ) : (
                      <div className={'icon'}>
                        <CheckboxIcon
                          className={'box'}
                          onClick={() => handleToggleItem(item)}
                        />
                      </div>
                    )}
                    {item.label}
                  </div>
                )
              } else {
                return null
              }
            })}
          </div>
        </>
      </div>
      <div className={styles.buttonsModal}>
        <Button
          type="primary"
          onClick={handleSubmit}
          className={styles.buttonApply}
        >
          {t('optionSelect.modal.apply')}
        </Button>
      </div>
    </SaivaModal>
  )
}

export default ManageModal