import { ReactComponent as CrossCircleIcon } from 'assets/icons/cross-circle-false.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/icons/check-circle-true.svg'
import { ReactComponent as RankIcon } from 'assets/icons/rank-indicator.svg'
import { ReactComponent as CalendarCrossIcon } from 'assets/icons/calendar-cross.svg'
import { ReactComponent as StarIcon } from 'assets/icons/status-star.svg'
import { Button } from 'antd'
import styles from '../BasicTable.module.scss'

export function TableColumns(sortingHeader) {
  return [
    {
      title: 'MRN',
      dataIndex: 'mrn',
      key: 'mrn',
      width: 50,
      fixed: 'left',
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: sortingHeader('Patient Name', 'patient_name'),
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 80,
      fixed: 'left',
      render: (_, record) =>
        record.patient_name.detail_url ? (
          <a
            href={
              record.patient_name.detail_url +
              '?report=' +
              window.location.pathname +
              window.location.search
            }
            style={{ textDecoration: 'none' }}
          >
            <Button type="link" className={styles.patientNameButton}>
              {record.rank_yesterday === 'N/A' && (
                <StarIcon style={{ width: '16px', height: '16px' }} />
              )}
              <span style={{ height: '20px', marginLeft: '5px' }}>
                {record.patient_name.value}
              </span>
            </Button>
          </a>
        ) : (
          <div className={styles.patientNameButton}>
            {record.rank_yesterday === 'N/A' && (
              <StarIcon style={{ width: '16px', height: '16px' }} />
            )}
            <span style={{ height: '20px', marginLeft: '5px' }}>
              {record.patient_name.value}
            </span>
          </div>
        )
    },
    {
      title: 'Facility',
      dataIndex: 'facility_name',
      key: 'facility_name',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Region',
      dataIndex: 'region_name',
      key: 'region_name',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      key: 'unit',
      width: 50,
      ellipsis: true,
      render: (text) =>
        text ? (
          <div className="elipsis" style={{ maxWidth: '50px' }}>
            {text}
          </div>
        ) : (
          'N/A'
        )
    },
    {
      title: 'Room',
      dataIndex: 'room',
      key: 'room',
      width: 50,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Visited',
      dataIndex: 'visited',
      key: 'visited',
      width: 50,
      render: (text) =>
        text && text == 'Yes' ? <CheckCircleIcon /> : <CrossCircleIcon />
    },
    {
      title: sortingHeader('Today’s Rank', 'rank_today'),
      dataIndex: 'rank_today',
      key: 'rank_today',
      width: 50,
      render: (text, record) => {
        if (text) {
          if (record.rank_today > record.rank_yesterday) {
            return (
              <div className={styles.rankDown}>
                {text} <RankIcon />
              </div>
            )
          } else if (record.rank_today < record.rank_yesterday) {
            return (
              <div className={styles.rankUp}>
                {text} <RankIcon />
              </div>
            )
          } else {
            return <div className={styles.rankSame}>{text}</div>
          }
        } else {
          return '-'
        }
      }
    },
    {
      title: 'Yesterday’s Rank',
      dataIndex: 'rank_yesterday',
      key: 'rank_yesterday',
      width: 50,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: sortingHeader('Rank Movement', 'rank_movement'),
      dataIndex: 'rank_movement',
      key: 'rank_movement',
      width: 50
    },
    {
      title: 'Payer',
      dataIndex: 'payer',
      key: 'payer',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: sortingHeader('Primary Physician', 'physician'),
      dataIndex: 'physician',
      key: 'physician',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Initial Admission Date',
      dataIndex: 'initial_admission_date',
      key: 'initial_admission_date',
      width: 140,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Last Transfer Date',
      dataIndex: 'last_transfer_date',
      key: 'last_transfer_date',
      width: 110,
      render: (text) =>
        text ? (
          text == 'N/A' ? (
            <CalendarCrossIcon />
          ) : (
            text
          )
        ) : (
          <CalendarCrossIcon />
        )
    }
  ]
}
