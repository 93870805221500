import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../context/UserContext'
import { ApiError } from '../../services/api'
import SaivaIcon from '../../components/saivaIcon'
import { useTranslation } from 'react-i18next'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import moment from 'moment'
import { showErrorToast, showSuccessToast } from '../../utils'
import FacilityService from '../../services/facility-service'
import { SaivaFacility } from '../../types/facility-types'
import InvitationService from '../../services/invitation-service'
import { SaivaInvitation } from '../../types/invitation-types'
import { UserPermission } from '../../types/user-types'
import { ReactComponent as PlusIcon } from 'assets/icons/plus-circle.svg'
import FormModal from './Users/CreateUser/Modal'
import SaivaPagination from 'components/Tables/SaivaPagination/SaivaPagination'
import {Dropdown, Menu, Space, Modal} from 'antd'
import { ReactComponent as MenuVertical } from 'assets/icons/menu-vertical.svg'
import { ReactComponent as RefreshIcon } from 'assets/icons/refresh.svg'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import styles from './UsersInvited.module.scss'
import SaivaSearch from 'components/SaivaForm/Search/SaivaSearch'
import AccessControl from 'components/AccessControl/AccessControl'
import Gradient from 'assets/icons/Gradient.gif'
import { ReactComponent as CrossIcon } from 'assets/icons/cross-big.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'

export default function AdminUsersInvited() {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const navigate = useNavigate()
  const [users, setUsers] = useState<SaivaInvitation.Item[]>([])
  const [showCreateUserModal, setShowCreateUserModal] = useState<boolean>(false)
  const [availableFacilities, setAvailableFacilities] = useState<SaivaFacility.Item[]>([])
  const inviteUserTitle = t('users.userForm.invite.title')
  const [requestInFlight, setRequestInFlight] = useState<boolean>(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [itemCount, setItemCount] = useState<number>(0)
  const [search, setSearch] = useState<string>('')
  const [openError, setOpenError] = useState<boolean>(false)
  const [editingUser, setEditingUser] = useState<any | undefined>(undefined)
  const [userFormModalTitle, setUserFormModalTitle] = useState<string>(inviteUserTitle)
  const editUserTitle = t('users.userForm.edit.title')

  useEffect(() => {
    fetchUsers()
  }, [currentPage, pageSize, userContext.currentOrg.id, search])

  const fetchUsers = async () => {
    setRequestInFlight(true)
    await InvitationService.getInvitations(userContext.currentOrg.id, {page: currentPage, size: pageSize, search: search.length > 2 ? search : '', product_name: userContext.currentRole}).then((res) => {
      setUsers((res?.items ?? []).filter((u): u is SaivaInvitation.Item => !!u))
      const lastPage = Math.ceil(res?.total ? res.total / res.size : 1)
      if (currentPage > lastPage) {
        setCurrentPage(lastPage)
      }
      setPageSize(pageSize)
      setItemCount(res?.total ? res?.total : 0)
      setRequestInFlight(false)
    }).catch((err) => {
      setRequestInFlight(false)
      if (err instanceof ApiError) {
        showErrorToast(t(err.errorCode))
      } else {
        showErrorToast(t("genericError"))
      }
    })
  }

  const onChangePage = (newCurrent: number) => {
    setCurrentPage(newCurrent)
  }

  const onShowSizeChange = (pageSize: number) => {
    const lastPage = Math.ceil(itemCount / pageSize)
    if (currentPage > lastPage) {
      setCurrentPage(lastPage)
    }
    setPageSize(pageSize)
  }

  const fetchFacilities = async () => {
    const res = await FacilityService.getAllFacilities(userContext.currentOrg.id)
    setAvailableFacilities(res ? res : [])
  }

  useEffect(() => {
    fetchFacilities()
  }, [userContext.currentOrg])

  const createUser = () => {
    setShowCreateUserModal(true)
  }

  const refreshInvite = async (user: SaivaInvitation.Item) => {
    await InvitationService.resendInvitation(userContext.currentOrg.id, user.id)
      .then(async () => {
        fetchUsers()
        showSuccessToast(t('invitedUsers.refreshInvite.successText'))
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          if (err.errorCode === 'backend.feature_limit_is_reached') {
            setOpenError(true)
          } else {
            showErrorToast(t(err.errorCode))
          }
        } else {
          showErrorToast(t('genericError'))
        }
      })
  }

  const removeInvite = async (user: SaivaInvitation.Item) => {
    const result = confirm(t('invitedUsers.removeUser.confirmationText', { email: user.email }))
    if (result === false) {
      return
    }
    try {
      const res = await InvitationService.deleteInvitation(userContext.currentOrg.id, user.id)
      showSuccessToast(t('invitedUsers.removeUser.successText'))
    } catch (err) {
      if (err instanceof ApiError) {
        showErrorToast(t(err.errorCode))
      } else {
        showErrorToast(t("genericError"))
      }
    }
    fetchUsers()
  }

  const editUser = (user: any | undefined) => {
    setUserFormModalTitle(editUserTitle)
    setEditingUser(user)
    setShowCreateUserModal(true)
  }

  const makeActionMenu = (user: SaivaInvitation.Item) => {
    return (
      <Dropdown
        trigger={['click']}
        overlayClassName={styles.dropdown}
        dropdownRender={(menu) => (
          <Menu>
            <AccessControl userPermissions={[UserPermission.USERS_EDIT]}>
              <Menu.Item
                onClick={() => editUser(users?.filter(item => item.id == user.id)[0])}
                className={`${styles.menuItem}`}
              >
                <EditIcon style={{ marginRight: '12px' }} />
                {t('users.table.action.edit.text')}
              </Menu.Item>
            </AccessControl>
            <AccessControl userPermissions={[UserPermission.INVITATIONS_RESEND]}>
              <Menu.Item
                onClick={() => refreshInvite(user)}
                className={`${styles.menuItem}`}
              >
                <RefreshIcon style={{ marginRight: '12px' }} />
                {t('users.table.action.refreshInvitation.text')}
              </Menu.Item>
            </AccessControl>
            <AccessControl userPermissions={[UserPermission.INVITATIONS_DELETE]}>
            <Menu.Item
                onClick={() => removeInvite(user)}
                className={`${styles.deleteMenuItem} ${styles.menuItem}`}
              >
                <DeleteIcon style={{ marginRight: '12px' }} />
                {t('users.table.action.deleteInvitation.text')}
              </Menu.Item>
            </AccessControl>
          </Menu>
        )}
      >
        <Space>
          <MenuVertical className={styles.menuIcon}/>
        </Space>
      </Dropdown>
    )
  }

  const renderTooltip = (user: SaivaInvitation.Item, props) => {
    return (
      <Tooltip id="button-tooltip" {...props}>
        {`${user.createdAt}`}
      </Tooltip>
    )
  }

  const makeUserRow = (user: SaivaInvitation.Item) => {
    return (
      <tr key={user.email}>
        <td>{user.email}</td>
        <td>{user.status}</td>
        <td>{user.invitedBy}</td>
        <td>
          <OverlayTrigger
            placement="right"
            delay={{ show: 100, hide: 100 }}
            overlay={(props) => renderTooltip(user, props)}
          >
            <span>{moment(user.createdAt).fromNow()}</span>
          </OverlayTrigger>
        </td>
        <AccessControl userPermissions={[UserPermission.INVITATIONS_RESEND, UserPermission.INVITATIONS_DELETE]}>
          <td>{makeActionMenu(user)}</td>
        </AccessControl>
      </tr>
    )
  }

  const handleClose = () => {
    fetchUsers()
    setShowCreateUserModal(false)
  }

  const columnCount = (userContext.hasPermission(UserPermission.INVITATIONS_RESEND) || userContext.hasPermission(UserPermission.INVITATIONS_DELETE)) ? 5 : 4
  
  const mailLink = `mailto:support@saivahc.com?subject=Request more users for ${userContext.currentOrg.id}`

  return (
    <div id="adminUsers" className='container-fluid'>
      <Modal
        closeIcon={
          <CrossIcon
            style={{ padding: '4px' }}
            onClick={() => setOpenError(false)}
          />
        }
        open={openError}
        footer={null}
        maskStyle={{
          background: 'rgba(0, 33, 50, 0.3)',
          backdropFilter: 'blur(10px)'
        }}
        closable={true}
        width={'342px'}
      >
        <div className={styles.errorModalTitle}>
          <h3><InfoIcon />{t('users.modal.userCapacity.title')}</h3>
          {t('users.modal.userCapacity.text')}
          <a href={mailLink}> support@saivahc.com </a>
          {t('users.modal.userCapacity.text2')}
        </div>
      </Modal>
      <FormModal isOpen={showCreateUserModal} close={handleClose} title={inviteUserTitle} editingUser={editingUser} editInvitation={true}/>
      <div className='row justify-content-between d-flex flex-md-row flex-column-reverse'>
        <div className='col-1 col-md-3 d-flex justify-content-end'>
        </div>
        <div className='col-12 col-md-4 d-flex justify-content-end'>
          <SaivaSearch
            placeholder={t('search.text')}
            filters={false}
            activeFilters={false}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            value={search}
            content={<></>}
          />
        </div>
        <div className='col-12 col-md-3 d-flex justify-content-start justify-content-md-end mb-3 mb-md-0'>
        <AccessControl userPermissions={[UserPermission.INVITATIONS_CREATE]}>
          <button className="primary-text-button" onClick={() => createUser()}>
            <PlusIcon style={{stroke: '#4070DC'}}/>
            {inviteUserTitle}
          </button>
        </AccessControl>
        </div>
      </div>
      <div className='row'>
        <div className='col table-responsive pb-4' style={{display: 'grid'}}>
          <table className='mt-3 table table-hover saiva'>
            <thead>
              <tr className=''>
                <th>{t('invitedUsers.table.header.email.label')}</th>
                <th>{t('invitedUsers.table.header.status.label')}</th>
                <th>{t('invitedUsers.table.header.invitedBy.label')}</th>
                <th>{t('invitedUsers.table.header.createdAt.label')}</th>
                <AccessControl userPermissions={[UserPermission.INVITATIONS_RESEND, UserPermission.INVITATIONS_DELETE]}>
                  <th>{t('invitedUsers.table.header.action.label')}</th>
                </AccessControl>
              </tr>
            </thead>
            <tbody className=''>
              {requestInFlight ? <tr>
                <td colSpan={columnCount} className="text-center">
                  <img className="me-2" src={Gradient} width="40px" height="40px" />
                  <p className='text-muted'>{t('loading.text')}</p>
                </td>
              </tr> : users.length === 0 && <tr>
                <td colSpan={columnCount} className="text-center">
                  <p className='text-muted'>{t('invitedUsers.table.empty.text')}</p>
                </td>
              </tr>}
              <AccessControl userPermissions={[UserPermission.INVITATIONS_READ]}>
                <>{users.map((u) => makeUserRow(u))}</>
              </AccessControl>
            </tbody>
          </table>
          <SaivaPagination
            total={itemCount}
            page={currentPage}
            pageSize={pageSize}
            onChange={onChangePage}
            onShowSizeChange={onShowSizeChange}
          />
        </div>
      </div>
    </div>
  )
}
