import { useTranslation } from 'react-i18next'

import styles from './Integrations.module.scss'
import IntegrationCard from './IntegrationCard/IntegrationCard'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { SaivaIntegration, StatusType } from 'types/integration-types'
import IntegrationService from '../../services/integration-service'
import { useUserContext } from '../../context/UserContext'

import SaivaMultiModal from '../../components/SaivaMultiModal/SaivaMultiModal'
import { SaivaMultiModalItem } from '../../components/SaivaMultiModal/SaivaModalItem/SaivaMultiModalItem'

export default function Integrations() {
  const { t } = useTranslation()
  const userContext = useUserContext()

  const [integrations, setIntegrations] = useState<SaivaIntegration.AllIntegrations | undefined>([])

  const fetchIntegrations = async () => {
    try {
      setIntegrations(undefined)
      const res = await IntegrationService.getAllIntegrations(userContext.currentOrg.id)

      if (res) setIntegrations(res)
      return res
      return []
    } catch (e) {
      setIntegrations([])
    }
  }

  const updateIntegration = (id: string, integration: Partial<SaivaIntegration.CatalogItem>) => {
    setIntegrations(prev => {
      return prev?.map((i) => {
        if (i.id === id) {
          return { ...i, ...integration }
        }
        return i
      })
    })
  }

  //useAddBackground()

  useEffect(() => {
    fetchIntegrations()
  }, [userContext.currentOrg.id])


  return (
    <>
      <div className={styles.integrationsWrapper}>
        <div className={styles.connected}>
          {integrations?.filter(i => i.isActive).map(integration => {
            return <Fragment key={integration.id}>
              <IntegrationCard
                integration={integration}
                updateIntegration={(value) => updateIntegration(integration.id, value)}
              />
            </Fragment>
          })}
        </div>
        <div className={styles.disconnected}>
          {integrations?.filter(i => !i.isActive).map(integration => {
            return <Fragment key={integration.id}>
              <IntegrationCard
                integration={integration}
                updateIntegration={(value) => updateIntegration(integration.id, value)}
              />
            </Fragment>
          })}
        </div>
      </div>
    </>
  )
}
