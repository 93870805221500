import { Pagination } from 'antd'
import SaivaSelect from 'components/SaivaForm/SaivaSelect/saivaSelect'
import styles from './SaivaPagination.module.scss'

function showTotalTable(total, range) {
  return `${range[0]} - ${range[1]} from ${total}`
}

const SaivaPagination = ({
  total,
  page,
  pageSize,
  onChange,
  onShowSizeChange
}) => {
  return (
    <div style={{ display: 'flex' }} className={styles.pagination}>
      <Pagination
        current={page}
        total={total}
        showSizeChanger={false}
        showTotal={showTotalTable}
        pageSize={pageSize}
        onChange={onChange}
      />
      <div className={styles.select}>
        <SaivaSelect
          size="middle"
          bordered={true}
          defaultValue={pageSize}
          options={[
            { label: '5/page', value: 5 },
            { label: '10/page', value: 10 },
            { label: '20/page', value: 20 },
            { label: '50/page', value: 50 },
            { label: '100/page', value: 100 }
          ]}
          icon={null}
          onChange={onShowSizeChange}
        />
      </div>
    </div>
  )
}

export default SaivaPagination
