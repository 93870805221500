import React, { useEffect } from 'react'
import { useFormContext } from '../../context/FormProvider'
import styles from './Switch.module.scss';


interface SwitchProps<T> {
  id: keyof T,
  value?: boolean,
  onChange?: (value: boolean) => void
  label?: boolean
  disabled?: boolean
  title?: string
}

function Switch<T>(props: SwitchProps<T>) {

  const formContext = useFormContext()
  const {id, value = formContext.getValue(id), onChange, label = true, disabled, title} = props

  const handleToggle = () => {
    if(onChange) onChange(!value)
    formContext.updateValue({[id]: !value})
  }

  return (
    <div title={title} className={`${styles.switchContainer} ${disabled && styles.switchContainerDisabled}`}>
      <label className={styles.switch}>
        <input type="checkbox" checked={value} onChange={handleToggle} />
        <span className={`${styles.slider} ${styles.round}`} />
      </label>
      {label && <label>{value ? "Enabled" : "Disabled"}</label>}
    </div>
  )
}

export default Switch
