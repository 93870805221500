import { useUserContext } from 'context/UserContext'
import { UserPermission, OrgProductFeature } from 'types/user-types'

const checkUserPermissions = (userPermissions: UserPermission[]) => {
  const userContext = useUserContext()
  const allowedPermissions: UserPermission[] =
    userContext.currentOrg?.permissions
  if (!userPermissions || !allowedPermissions) {
    return false
  }

  const hasPermission = userPermissions.every((permission: UserPermission) =>
    allowedPermissions.includes(permission)
  )
  return hasPermission
}

const checkProductFeatures = (userPermissions: OrgProductFeature[] | undefined) => {
  const userContext = useUserContext()
  const allowedPermissions: OrgProductFeature[] =
    userContext.currentOrg?.productFeatures
  if (!userPermissions || !allowedPermissions) {
    return true
  }

  const hasPermission = userPermissions.some((permission: OrgProductFeature) =>
    allowedPermissions.includes(permission)
  )
  return hasPermission
}

interface Props {
  userPermissions: UserPermission[]
  children: JSX.Element
  productFeatures?: OrgProductFeature[]
  hideMobile?: boolean
  returnValue?: JSX.Element
}

const AccessControl = ({
  userPermissions,
  productFeatures,
  children,
  hideMobile,
  returnValue
}: Props) => {
  const permitted = checkUserPermissions(userPermissions)
  const featureIncluded = checkProductFeatures(productFeatures)

  if (permitted && featureIncluded) {
    if (hideMobile) {
      return <></>
    } else return children
  } else if (returnValue) {
    return returnValue
  } else return <></>
}

export default AccessControl
