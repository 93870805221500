import React from 'react'
import styles from './ErrorPage.module.scss'

import {ReactComponent as SaivaLogo} from "assets/saiva-logo-white.svg"
import {ReactComponent as Person} from "assets/person.svg"
import {ReactComponent as ReloadIcon} from "assets/icons/reload-circle.svg"
import { useNavigate } from 'react-router-dom'
import { CONTACT_US_URL } from '../../utils/constants'

interface ErrorPageProps {
  emptyState?: boolean
}

const ErrorPage = (props: ErrorPageProps) => {

  const navigate = useNavigate()

  const contactUsRedirect = () => {
    if(CONTACT_US_URL) window.location.href = CONTACT_US_URL
  }

  const handleReloadPageClick = () => {
    if(props.emptyState) navigate("/")
    else navigate(-1)
  }

  return (
    <div className={styles.errorPageWrapper}>
      <div className={styles.header}>
        <SaivaLogo style={{cursor: "pointer"}} onClick={() => navigate("/")}/>
        <button className={"text-button"} onClick={contactUsRedirect}>CONTACT US</button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.contentContainer}>
          <div className={styles.person}><Person/></div>
          <div className={styles.content}>
            <h1>Oops!</h1>
            <label>We are sorry.<br />Something went wrong.</label>
            <button className={"primary-button"} onClick={handleReloadPageClick}>
              <ReloadIcon className={"icon-first"} />
              RELOAD PAGE
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
