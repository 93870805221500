import i18next from "i18next"
import { initReactI18next } from "react-i18next"
import ns1 from './en_translation.json';

export const defaultNS = 'ns1';

i18next.use(initReactI18next).init({
  interpolation: {
    escapeValue: false
  },
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources: {
    en: {
      ns1
    },
  },
  defaultNS
});
