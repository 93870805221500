import styles from './TimeSeriesCard.module.scss'
import { ReactComponent as RiskIllustration } from '../../../assets/illustrations/risk.svg'
import { ReactComponent as CalendarIllustration } from '../../../assets/illustrations/calendar.svg'
import { ReactComponent as RankIcon } from '../../../assets/icons/rank-indicator.svg'
import { ReactComponent as RiskScoreIcon } from '../../../assets//illustrations/risk-score.svg'
import { ReactComponent as RiskIllustrationDown } from '../../../assets//illustrations/current_rank_indicator_red.svg'
import { ReactComponent as RiskIllustrationStable } from '../../../assets//illustrations/risk-stable.svg'

type Props = {
  title: string
  type: string
  value: string
  risk_values?: {
    current_rank_indicator: string | undefined
    current_rank: number
    rank_yesterday: number
  }
}

export default function RiskCard({ title, type, value, risk_values }: Props) {
  return (
    <div className={styles.layout}>
      {type == 'risk' ? (
        <div
          className={`${styles.headerRisk} ${
            risk_values?.current_rank_indicator == 'down'
              ? styles.headerRiskGreen
              : risk_values?.current_rank === risk_values?.rank_yesterday
              ? styles.headerRiskOrange
              : styles.headerRiskRed
          }`}
        >
          <h4>{title}</h4>
          <h1 className={styles.number}>
            {Number(value)}
            <RankIcon style={{ marginLeft: '4px' }} />
          </h1>
        </div>
      ) : type == 'calendar' ? (
        <div className={styles.headerCalendar}>
          <h4>{title}</h4>
          <h1 className={styles.number}>{Number(value)}</h1>
        </div>
      ) : (
        <div className={styles.headerScore}>
          <h4>{title}</h4>
          <h1
            className={styles.number}
            style={{
              color:
                Number(value) > 0.3 && Number(value) < 0.6
                  ? '#F87803'
                  : Number(value) > 0.6
                  ? '#DC362E'
                  : '#00BB13'
            }}
          >
            {Number(value).toFixed(2)}
          </h1>
        </div>
      )}
      {type == 'risk' ? (
        <div className={styles.risk}>
          {risk_values?.current_rank_indicator == 'down' ? (
            <RiskIllustration />
          ) : risk_values?.current_rank === risk_values?.rank_yesterday ? (
            <RiskIllustrationStable />
          ) : (
            <RiskIllustrationDown />
          )}
        </div>
      ) : type == 'calendar' ? (
        <div className={styles.calendar}>
          <CalendarIllustration />
        </div>
      ) : (
        <div className={styles.score}>
          <div
            className={styles.line}
            style={{ transform: `rotate(${180 * Number(value)}deg)` }}
          ></div>
          <div className={styles.number}>0</div>
          <RiskScoreIcon />
          <div className={styles.number}>1</div>
        </div>
      )}
    </div>
  )
}
