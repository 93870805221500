import React, { useEffect, useState } from 'react'
import SRModalLogic from './SRModalLogic'
import { showSuccessToast } from 'utils'
import { useTranslation } from 'react-i18next'
import SchedulesService from 'services/schedules-service'
import { useUserContext } from 'context/UserContext'
import { Schedules } from 'types/schedule-reports'
import { mixpanelInstance } from 'utils/mixpanel'
import UserService from 'services/user-service'
import { SaivaUser } from 'types/user-types'
import { UserPermission } from 'types/user-types'

const SRModalApi = ({
  isDirty,
  children,
  closeModal,
  isEditMode,
  isDuplicate,
  isClosed,
  schedule,
  filtersData,
  deleteItem,
  reportId,
  dateType
}) => {
  const { t } = useTranslation()
  const userContext = useUserContext()
  const [apiError, setApiError] = useState<any>(undefined)
  const [users, setUsers] = useState<SaivaUser.User[]>([])
  const [loading, setLoading] = useState(false)

  const adminPermissions =
    userContext.hasPermission(UserPermission.SCHEDULE_CREATE) &&
    userContext.hasPermission(UserPermission.SCHEDULE_EDIT)

  useEffect(() => {
    setApiError(undefined)
    if (adminPermissions) {
      getUsers()
    }
  }, [isClosed])

  const formState = (value: boolean) => {
    isDirty(value)
  }

  const handleDelete = async () => {
    deleteItem()
  }

  const getUsers = async () => {
    try {
      setLoading(true)
      let totalUsers = [] as SaivaUser.User[]
      let page = 1

      async function getFacilities(page: number) {
        return await UserService.getUsers(userContext.currentOrg.id, {
          enabled: true,
          size: 100,
          page: page
        })
      }

      while (true) {
        const res = await getFacilities(page)
        if (!res || res.items.length === 0) {
          break
        }
        totalUsers = totalUsers.concat(res.items)
        if (res.items.length < 100) {
          break
        }
        page++
      }

      setUsers(totalUsers)
    } catch (err: Error | any) {
      if (err.name === 'ApiError') {
        setApiError({ code: err.errorCode, details: err.response.data.details })
      } else {
        setApiError('genericError')
      }
    } finally {
      setLoading(false)
    }
  }

  const sendNow = async () => {
    await SchedulesService.sendNow(userContext.currentOrg.id, schedule.id)
      .then(() => {
        showSuccessToast(
          t('scheduleReport.sendNowSuccessText', {
            name: schedule.schedule_config.name
          })
        )
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError({
            code: err.errorCode,
            details: err.response.data.details
          })
        } else {
          setApiError('genericError')
        }
        throw err
      })
  }

  const handleSubmit = async (
    data: Schedules.Config,
    dirtyData: Schedules.ConfigEdit
  ) => {
    const submitData = {
      day_of_week: !Array.isArray(data.day_of_week)
        ? [data.day_of_week]
        : data.day_of_week,
      file_format: data.file_format,
      time_of_day: data.time_of_day,
      time_zone: data.time_zone,
      schedule_type: data.schedule_type,
      is_enabled: data.is_enabled,
      name: data.name,
      week_of_month: data.week_of_month,
      user_id: data.user_id
    }
    if (isEditMode) {
      const requestData = {
        schedule_config: {
          ...dirtyData,
          schedule_type: dirtyData?.schedule_type
            ? dirtyData?.schedule_type
            : data.schedule_type,
          time_of_day: dirtyData?.time_of_day
            ? dirtyData?.time_of_day
            : data.time_of_day,
          day_of_week: dirtyData?.day_of_week
            ? dirtyData?.day_of_week
            : data.day_of_week,
          week_of_month: dirtyData?.week_of_month
            ? dirtyData?.week_of_month
            : data.week_of_month
        }
      }
      await SchedulesService.updateSchedule(
        userContext.currentOrg.id,
        schedule.id,
        requestData
      )
        .then((resp) => {
          closeModal()
          mixpanelInstance.scheduleEdit({ id: schedule.id, data: requestData })
          showSuccessToast(
            t('scheduleReport.editSuccessText', {
              name: resp?.schedule_config?.name
            })
          )
        })
        .catch((err) => {
          if (err.name === 'ApiError') {
            setApiError({
              code: err.errorCode,
              details: err.response.data.details
            })
          } else {
            setApiError('genericError')
          }
          throw err
        })
    } else if (isDuplicate) {
      const requestData = {
        schedule_config: submitData,
        report_config: {
          report_type: reportId,
          params: {
            filters: filtersData
          }
        }
      }
      await SchedulesService.createSchedule(
        userContext.currentOrg.id,
        requestData
      )
        .then((resp) => {
          closeModal()
          mixpanelInstance.scheduleCreate(requestData)
          showSuccessToast(
            t('scheduleReport.createSuccessText', {
              name: resp?.schedule_config?.name
            })
          )
        })
        .catch((err) => {
          if (err.name === 'ApiError') {
            setApiError({
              code: err.errorCode,
              details: err.response.data.details
            })
          } else {
            setApiError('genericError')
          }
          throw err
        })
    } else {
      const requestData = {
        schedule_config: submitData,
        report_config: {
          report_type: reportId,
          params: {
            filters: filtersData
          }
        }
      }
      await SchedulesService.createSchedule(
        userContext.currentOrg.id,
        requestData
      )
        .then((resp) => {
          closeModal()
          mixpanelInstance.scheduleCreate(requestData)
          showSuccessToast(
            t('scheduleReport.createSuccessText', {
              name: resp?.schedule_config?.name
            })
          )
        })
        .catch((err) => {
          if (err.name === 'ApiError') {
            setApiError({
              code: err.errorCode,
              details: err.response.data.details
            })
          } else {
            setApiError('genericError')
          }
          throw err
        })
    }
  }

  const defaultValues = {
    name: schedule?.schedule_config.name ?? null,
    day_of_week: schedule?.schedule_config.day_of_week ?? [],
    file_format: schedule?.schedule_config.file_format ?? 'pdf',
    time_of_day: schedule?.schedule_config.time_of_day ?? null,
    time_zone: schedule?.schedule_config.time_zone ?? null,
    schedule_type: schedule?.schedule_config.schedule_type ?? dateType,
    is_enabled: schedule?.schedule_config.is_enabled ?? true,
    week_of_month: schedule?.schedule_config.week_of_month ?? null,
    user_id: schedule?.schedule_config.user_id ?? null,
    scheduled_by_id: schedule?.schedule_config.scheduled_by_id ?? null,
    assignee_name: schedule?.schedule_config.assignee_name ?? null
  }

  return (
    <SRModalLogic
      isClosed={isClosed}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      children={children}
      formState={formState}
      apiError={apiError}
      isEditMode={isEditMode}
      isDuplicate={isDuplicate}
      handleDelete={handleDelete}
      dateType={dateType}
      sendNow={sendNow}
      reportId={reportId}
      users={users}
      loading={loading}
    />
  )
}

export default SRModalApi
