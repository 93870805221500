import React, { ReactComponentElement, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useUserContext } from 'context/UserContext'
import styles from './Dashboard.module.scss'

import { ReactComponent as HospitalIcon } from 'assets/icons/hospital.svg'
import { ReactComponent as HearthIcon } from 'assets/icons/cardiology.svg'
import { ReactComponent as StaffIcon } from 'assets/icons/users.svg'
import { ReactComponent as ReloadIcon } from 'assets/icons/reload-circle.svg'
import { ReactComponent as EmptyDataIcon } from 'assets/icons/no-data.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import DashboardService from '../../services/dashboard-service'
import { SaivaDashboard } from '../../types/dashboard-types'
import { handleError } from '../../utils/errorHandler'
import { Button, Progress } from 'antd'
import { getFeatureLabel } from 'utils/helper'
import { OrgProductFeature } from 'types/user-types'
import SkeletonCard from 'components/Skeleton/Card/SkeletonCard'
import { showErrorToast } from 'utils'
import { mixpanelInstance } from 'utils/mixpanel'

interface DashboardCard {
  id: string,
  invert?: Boolean,
  icon?: ReactComponentElement<any>,
  value: String,
  title: String,
  subtitle: String,
  details?: String[],
  error: boolean,
  color?: string,
  hierarchy: number,
  quality_measure: string
}

export default function Dashboard() {

  const userContext = useUserContext()
  const modelProductFeatures = userContext.currentOrg.productFeatures.filter((item) => {return item.includes('model')})

  useEffect(() => {
    const localActiveQM = localStorage.getItem('activeQM')
    if (localActiveQM && JSON.parse(localActiveQM).every((i:OrgProductFeature) => modelProductFeatures.includes(i))) {
      setActiveQM(JSON.parse(localActiveQM))
    } else {
      setActiveQM(modelProductFeatures)
    }
  }, [userContext.currentOrg])

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [cards, setCards] = useState<DashboardCard[]>([])
  const [activeQM, setActiveQM] = useState<OrgProductFeature[]>(modelProductFeatures)
  const [loading, setLoading] = useState<boolean>(true)
  
  const fetchDashboardData = async () => {
    setCards([])
    setLoading(true)
    await DashboardService.getDashboardMetadata(userContext.currentOrg.id).then((res) => {
      if (!res) return
      let itemsProcessed = 0
      res.forEach(async (card, index, array) => {
        await DashboardService.getDashboardCard(userContext.currentOrg.id, card.id).then((cardRes) => {
          if (!cardRes) return
          if (Array.isArray(cardRes)) {
            for (const element of cardRes) {
              setCards(cards => [...cards, {...parseCardFromResponse(element), hierarchy: index}])
            }
          } else {
            setCards(cards => [...cards, {...parseCardFromResponse(cardRes), hierarchy: index}])
          }
        }).catch((err) => {
          handleError({id: "FetchDashboardCard", error: err, customHandle: true})
          setLoading(false)
        })
        itemsProcessed++
        if(itemsProcessed === array.length) {
          setLoading(false)
        }
      })
    }).catch((err) => {
      handleError({id: "FetchDashboardMetadata", error: err, customHandle: true})
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchDashboardData()
  }, [userContext.currentOrg])

  useEffect(() => {
    localStorage.setItem('activeQM', JSON.stringify(activeQM))
    const selectedQMs =
      activeQM.length > 0
        ? activeQM
        : modelProductFeatures.map((item) => {
            return item.toString()
          })
    mixpanelInstance.switchQM(selectedQMs.join('&'), 'Dashboard')
  }, [activeQM])

  const reloadPage = async () => {
    setCards(prev => prev.map((card) => {
      return {...card, value: "", error: false}
    }))
    await fetchDashboardData()
  }

  const hasError = useMemo(() => {
    return cards.find(card => card.error)
  }, [cards])

  const getIcon = (iconId: string) => {
    switch (iconId) {
      case "hearth": return <div className={styles.icon} style={{background:'#ececfb'}}><HearthIcon /></div>
      case "hospital": return <div className={styles.icon} style={{background:'#ececfb'}}><HospitalIcon /></div>
      case "staff": return <div className={styles.icon} style={{background:'#ececfb'}}><StaffIcon /></div>
    }
  }

  const getColor = (id: string) => {
    switch (id) {
      case "total_staff": return styles.purpleCard
      case "total_facilities": return styles.redCard
      case "total_residents": return styles.blueCard
      case "email_open_rate" : return styles.redCard
      case "recall_rate" : return styles.blueCard
      case "patient_days" : return styles.purpleCard
    }
  }

  const parseCardFromResponse = (item: SaivaDashboard.Card, error: boolean = false) : DashboardCard => {
    return {
      id: item.id,
      icon: getIcon(item.icon),
      value: item.value,
      title: item.title,
      subtitle: item.subtitle,
      details: item.details,
      error: error,
      hierarchy: item.hierarchy,
      quality_measure: item.quality_measure,
    }
  }

  const slugify = (str) => {
    return String(str)
      .normalize('NFKD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim()
      .toLowerCase()
      .replace(/[^a-z0-9 -]/g, '')
      .replace(/\s+/g, '-')
      .replace(/-+/g, '-')
  }

  const makeCard = ({id, invert, icon, value, subtitle, title, details, color, error} : DashboardCard) => {
    return (
      <div id={slugify(title).toString()} className={`${styles.card} ${getColor(id)}`}>
        {value.includes("%") &&
          <Progress
            className={`${styles.progress} ${getColor(id)}`}
            percent={
              Number(value.replace('%', ''))
            }
            showInfo={false}
          />
        }
        <h1>{value}</h1>
        <h6>{title}</h6>
        {icon && icon}
        <ul className={styles.list}>
          <li>{subtitle}</li>
          {details?.map((detail) => {
            return <li>{detail}</li>
          })}
        </ul>
      </div>
    )
  }

  const selectedQMCards = 
    activeQM.length > 0 ? 
      modelProductFeatures.filter(item => activeQM.includes(item)) : 
      modelProductFeatures
  
  return (
    <div className={`${styles.dashboardWrapper} dashboardWrapper`}>
      {hasError && <div className={styles.reloadPage}>
        {t('dashboard.issue-with-data-display')}
        <button className={"text-button"} onClick={reloadPage}><ReloadIcon />{t('dashboard.reload-page')}</button>
      </div>}
      <div className={`${styles.dashboard} ${styles.topCards}`}>
        {loading ? <>
          {SkeletonCard({ repeat: 3})}
        </> : <>
        {cards && 
          cards
            .filter((item) => item.quality_measure == '')
            .sort((a, b) => a.hierarchy - b.hierarchy)
            .map((card) => makeCard(card))}
        </>}
      </div>
    {modelProductFeatures.length > 1 &&
    <div className={styles.qualityMeasureSelector}>
      <Button 
        className={`
          ${styles.allButton}
          ${activeQM.length < 1  && styles.allButtonActive}
        `}
        disabled={activeQM.length == modelProductFeatures.length}
        onClick={() => setActiveQM([])}
      >
        {t('dashboard.selector.all')}
      </Button>
      {modelProductFeatures.map((feature) => {
        return (
          <Button
            className={`${styles.QMButton} ${activeQM.includes(feature) && styles.QMButtonActive}`}
            onClick={() => setActiveQM(prev => {
              if (prev.includes(feature)) {
                return prev.filter(item => item != feature)
              } else {
                return [...prev, feature]
              }
            }
            )}
          >
            {getFeatureLabel(feature)?.label}
            {activeQM.includes(feature) && <CloseIcon className={styles.closeIcon}/>}
          </Button>
        )
      })}
    </div>}
    {selectedQMCards.map((feature) => {
      const filteredCards = cards
        .filter(item => item.quality_measure == feature)
        .sort((a, b) => a.hierarchy - b.hierarchy)
      return (
        <div id={feature} style={{marginBottom: '40px'}}>
          <h6 style={{margin: '0px'}}>{t('dashboard.cards.last-month')} {getFeatureLabel(feature)?.label}</h6>
            <>{filteredCards && filteredCards.length > 0 ?
              <div className={styles.dashboard}>
                {filteredCards.map(card => makeCard(card))}
              </div> : <>{loading ? 
                <div className={styles.dashboard}>
                  {SkeletonCard({ repeat: 3})}
                </div> : <div>
                  <div className={styles.card}>
                    <h5 className={styles.noCards}>
                      <EmptyDataIcon style={{marginBottom: '6px'}}/><br />
                      {t('dashboard.cards.no-card-value')}
                    </h5>
                  </div>
                </div>}
              </>
            }</>
        </div>)}
      )}
    </div>
  )
}
