import styles from './SaivaCheckbox.module.scss'

interface Props {
  checked: boolean
  all?: boolean
  onChange: (e: any) => void
}

export default function SaivaInput(props: Props) {
  return (
    <label
      className={`${styles.container} ${props.all && styles.containerAll}`}
    >
      <input
        type="checkbox"
        checked={props.checked}
        onChange={(e) => props.onChange(e)}
      />
      <span className={styles.checkmark}></span>
    </label>
  )
}
