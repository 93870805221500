import type { ViewedReportsStats } from './Stats.types'
import { Flex, Typography } from 'antd'
import clsx from 'clsx'
import styles from './Stats.module.scss'
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-small-down.svg'
import type { ScoreProps } from './Stats.types'

export function RollupStats(props: ViewedReportsStats) {
  const { label, data, Count, Illustration, itemLabel } = props
  return (
    <Flex className={styles.stats}>
      <details className={styles.collapse}>
        <summary data-testid="widget-most-viewed-report" className={styles.summary}>
          <div className={styles.rollupContent}>
            <Flex gap={23} align='center'>
              <div style={{minWidth: '30px'}}>
              <ChevronDown style={{ stroke: '#000', width: '40px' }} />
              </div>
              <Typography.Title level={5} className={styles.title}>
                {label}
              </Typography.Title>
              {Count}
            </Flex>
          </div>
          <div className={styles.rollUpIllustration}>{Illustration}</div>
        </summary>
        <div className={styles.content}>
          <table className={styles.table}>
            {data.map((stat, index) => {
              return (
                <tr key={index}>
                  <td>{stat.name}</td>
                  <td style={{ textAlign: 'right' }}>
                    <label>{itemLabel}</label>
                    <b>{stat.last_active_at}</b>
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </details>
    </Flex>
  )
}

RollupStats.Count = Count

function Count(props: ScoreProps) {
  const { value, trend = 'none', variant = 'normal', color } = props

  return (
    <Typography.Text
      className={clsx(styles.trend, styles[trend], styles[variant])}
      style={{ color }}
    >
      {value}
      {trend == 'none' ? null : <ChevronDown />}
    </Typography.Text>
  )
}
