import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'

type Props = {
  show: boolean
  onHide?: () => void
  onCancel?: () => void
  onSubmit?: () => void
  title?: string
  className?: string
  children?: React.ReactNode
  backdrop?: boolean
  header?: boolean
}

export default function SaivaModal(props: Props) {

  const {header = true} = props

  const cancelHandler = () => {
    if(props.onCancel) {
      props.onCancel()
    }
  }

  const submitHandler = () => {
    if(props.onSubmit) {
      props.onSubmit()
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      centered
      scrollable={true}
      backdrop={props.backdrop ?? 'static'}
      className={props.className}>
      {header && <Modal.Header closeButton>
        {props.title !== undefined && <Modal.Title>{props.title}</Modal.Title>}
      </Modal.Header>}
      <Modal.Body>
        {props.children}
      </Modal.Body>
    </Modal>
  )
}
