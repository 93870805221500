import React, { CSSProperties } from 'react'
import { Spin } from 'antd'
import Gradient from 'assets/icons/Gradient.gif'

const antIcon = <img src={Gradient} />
interface Props {
  style?: CSSProperties
}

const LoadingIcon = ({style}: Props) => (
  <Spin indicator={antIcon} style={style} />
)

export default LoadingIcon
