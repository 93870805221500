
export default function EmptyNavigation() {
    const makeNavbar = () => (
      <nav className="navbar navbar-expand-lg shadow-sm" style={{padding: '12px 4px'}}>
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img className="image-fluid" style={{ height: '32px', marginLeft: '0.5rem' }} src="/saiva-logo-white.png" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-end" id="navbarTogglerDemo02">
          </div>
        </div>
      </nav>
    )
  
    const makeMenuItem = () => {
      return (
        <li className="placeholder-glow">
            <span className="placeholder col-12"></span>
        </li>
      )
    }
  
    const makeSidebar = () => {
      return (
        <div className="row mt-5">
          <div className="col placeholder-glow">
            <span className="placeholder col-9"></span>
            <span className="placeholder col-10"></span>
            <span className="placeholder col-8"></span>
          </div>
        </div>
      )
    }

    const makeContent = () => {
      return (
        <>
          <div className="row mt-2 mb-5">
            <div className="col placeholder-glow">
              <div className={`placeholder col-10`}></div>
              <div className={`placeholder col-5`}></div>
              <div className={`placeholder col-9`}></div>
              <div className={`placeholder col-4`}></div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col placeholder-glow">
              <div className={`placeholder col-7`}></div>
              <div className={`placeholder col-11`}></div>
              <div className={`placeholder col-9`}></div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col placeholder-glow">
              <div className={`placeholder col-10`}></div>
              <div className={`placeholder col-5`}></div>
              <div className={`placeholder col-9`}></div>
              <div className={`placeholder col-4`}></div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col placeholder-glow">
              <div className={`placeholder col-7`}></div>
              <div className={`placeholder col-11`}></div>
              <div className={`placeholder col-9`}></div>
            </div>
          </div>
        </>
      )
    }
  
    return (
      <div className="container-fluid">
        <div className="row navigationWrapper">
            {makeNavbar()}
        </div>
        <div className="row contentWrapper">
          <div className="sidebar d-none d-lg-inline-block">
            {makeContent()}
          </div>
          <div className="d-none d-lg-inline-block desktop-content">
            {makeContent()}
          </div>
          <div className="d-inline-block d-lg-none mobile-content">
            {makeContent()}
          </div>
        </div>
      </div>
    )
  }