import { useState, useEffect } from 'react'
import { FileType } from 'services/api'
import SaivaTable from 'components/Tables/AntdTable/AntdTable'
import { Button, Table, Row, Col, Tooltip, Modal } from 'antd'
import type { TableColumnsType } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { ReactComponent as OrderIcon } from 'assets/icons/order-ineligible.svg'
import { ReactComponent as DocumentCrossIcon } from 'assets/icons/document-cross.svg'
import { ReactComponent as DocumentIcon } from 'assets/icons/document-filled.svg'
import { ReactComponent as PackageIcon } from 'assets/icons/package.svg'
import { ReactComponent as PlusCircle } from 'assets/icons/plus-circle-wounds.svg'
import { ReactComponent as MinusCircle } from 'assets/icons/minus-circle.svg'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as ResizeIcon } from 'assets/icons/resize-small-vertical.svg'
import { ReactComponent as TrueIcon } from 'assets/icons/check-circle-true.svg'
import { ReactComponent as FalseIcon } from 'assets/icons/cross-circle-false.svg'
import { ReactComponent as StarIcon } from 'assets/icons/status-star.svg'
import { ReactComponent as ClockIcon } from 'assets/icons/stopwatch.svg'
import SaivaInput from 'components/SaivaForm/SaivaInput/saivaInput'
import styles from './BasicTable.module.scss'
import MissingDocForm from 'views/wounds/components/MissingDocForm'

export interface ISaivaTableHeaderItem {
  label: string
  id: string
  type?: string
  attribute?: string
}

interface IBasicTableProps {
  data: any
  rowsPerPageOptions: { label: string; value: number }[]
  headers?: ISaivaTableHeaderItem[]
  fileLoading: boolean
  downloadReport: ((fileType: FileType) => void) | undefined
  sortBy: string | undefined
  sortChange: (value: string | undefined) => void
  rowKey: string
  loading: boolean
  onAction: (
    action: any,
    orgIds: { order_ids: string[] },
    bulk: boolean
  ) => void
  onSearch: (value: any) => void
  searchValue: string
  handleSortClick: (value: string) => void
  total: number
  widgetFilter: string
  resetFilters: () => void
  pagination: any
}

export default function BasicTable({
  data,
  rowKey,
  loading,
  onAction,
  onSearch,
  searchValue,
  pagination,
  handleSortClick,
  widgetFilter,
  resetFilters
}: IBasicTableProps) {
  const [selected, setSelected] = useState<any>({ selectedRowKeys: [] })
  const [actionDisplayed, setActionDisplayed] = useState<boolean>(false)
  const { selectedRowKeys } = selected
  const [openMissingDocModal, setOpenMissingDocModal] = useState<boolean>(false)
  const [missingDocOrder, setMissingDocOrder] = useState<any>({})

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      setSelected({
        ...selected,
        selectedRowKeys: selectedRowKeys
      })
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.order_status === 'Inactive'
      }
    }
  }

  useEffect(() => {
    if (selected.selectedRowKeys?.length > 0) {
      setActionDisplayed(true)
    } else {
      setActionDisplayed(false)
    }
  }, [selected])

  const handleCloseMissingDocModal = (id, isUpdated) => {
    setOpenMissingDocModal(false)
    if (isUpdated) {
      onAction({ action: 'Missing Doc.' }, { order_ids: [id] }, false)
    }
  }

  const headersComlmns: TableColumnsType<any> = [
    Table.SELECTION_COLUMN,
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('order_status')}
            className={styles.sortButton}
          >
            Status
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: 'order_status',
      key: 'order_status',
      width: 100,
      fixed: 'left',
      render: (text, record, index) => (
        <>
          {record.order_status === 'New' ? (
            <div className={styles.statusWrapper}>
              <StarIcon style={{ width: '16px', height: '16px' }} /> New
            </div>
          ) : record.action === 'Missing Doc.' &&
            record.order_status === 'Reviewed' ? (
            <div className={styles.statusWrapper}>
              {record.missing_doc_status === 'pending' ? (
                <>
                  <ClockIcon /> Reviewed
                </>
              ) : (
                <>
                  <div className={styles.status}>
                    <div className={styles.orangeDot}></div>
                  </div>
                  Reviewed
                </>
              )}
            </div>
          ) : record.order_status === 'Reviewed' ? (
            <div className={styles.statusWrapper}>
              <div className={styles.status}>
                <div className={styles.greenDot}></div>
              </div>
              Reviewed
            </div>
          ) : (
            <div className={styles.statusWrapper}>
              <div className={styles.status}>
                <div className={styles.redDot}></div>
              </div>
              Inactive
            </div>
          )}
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      fixed: 'left',
      render: (text, record, index) => (
        <div>
          {record.order_status === 'Inactive' ? (
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={"Can't rewrite this action"}
            >
              <div className={styles.actionValue}>
                {record.action ? record.action : 'N/A'}
              </div>
            </Tooltip>
          ) : (
            <div className={styles.actionValue}>
              {record.action ? record.action : 'N/A'}
            </div>
          )}
          <div className={styles.actionButtons}>
            <div style={{ display: 'flex', gap: '5px' }}>
              <Button
                type="link"
                className={`${styles.actionButtonIconRow} ${
                  record.action === 'On Caseload' &&
                  styles.actionButtonIconRowActive
                }`}
                onClick={() =>
                  onAction(
                    { action: 'On Caseload' },
                    { order_ids: [record.id] },
                    false
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'On Caseload'}
                >
                  <PackageIcon />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className={`${styles.actionButtonIconRow} ${
                  record.action === 'Missing Doc.' &&
                  styles.actionButtonIconRowActive
                }`}
                onClick={() => {
                  setMissingDocOrder(record)
                  setOpenMissingDocModal(true)
                }}
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'Missing Documentation'}
                >
                  <DocumentCrossIcon />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className={`${styles.actionButtonIconRow} ${
                  record.action === 'Order Ineligible' &&
                  styles.actionButtonIconRowActive
                }`}
                onClick={() =>
                  onAction(
                    { action: 'Order Ineligible' },
                    { order_ids: [record.id] },
                    false
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'Order Ineligible'}
                >
                  <DocumentIcon />
                </Tooltip>
              </Button>
              <Button
                type="link"
                className={`${styles.actionButtonIconRow} ${
                  record.action === 'Payer Ineligible' &&
                  styles.actionButtonIconRowActive
                }`}
                onClick={() =>
                  onAction(
                    { action: 'Payer Ineligible' },
                    { order_ids: [record.id] },
                    false
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'Payer Ineligible'}
                >
                  <OrderIcon />
                </Tooltip>
              </Button>
            </div>
          </div>
        </div>
      )
    },
    {
      title: 'Facility Name',
      dataIndex: 'facility_name',
      key: 'facility_name',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'State',
      dataIndex: 'facility_state',
      key: 'facility_state',
      width: 60,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('patient_name')}
            className={styles.sortButton}
          >
            Patient Name
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: 'patient_name',
      key: 'patient_name',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'MRN',
      dataIndex: 'patient_mrn',
      key: 'patient_mrn',
      width: 50,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Order Type',
      dataIndex: 'order_type',
      key: 'order_type',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: (
        <>
          <Button
            type="link"
            onClick={() => handleSortClick('order_date')}
            className={styles.sortButton}
          >
            Order Date
            <ResizeIcon />
          </Button>
        </>
      ),
      dataIndex: 'order_date',
      key: 'order_date',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    },
    Table.EXPAND_COLUMN,
    {
      title: 'Order Directions',
      dataIndex: 'order_directions',
      key: 'order_directions',
      ellipsis: true,
      width: 120,
      render: (text) =>
        text ? <div className={styles.orderDirections}>{text}</div> : 'N/A'
    },
    {
      title: 'Primary Payer',
      dataIndex: 'primary_payer',
      key: 'primary_payer',
      width: 120,
      render: (text) => (text ? text : 'N/A')
    },
    {
      title: 'Medicare MBI',
      dataIndex: 'has_medicare_hic',
      key: 'has_medicare_hic',
      width: 110,
      render: (text) => (
        <div className={styles.hasMedicareHic}>
          {text ? <TrueIcon /> : <FalseIcon />}
        </div>
      )
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
      key: 'updated_by',
      width: 110,
      render: (text) => (text ? text : 'N/A')
    }
  ]

  const columns: ColumnsType<TableColumnsType> = headersComlmns

  return (
    <>
      {openMissingDocModal ? (
        <Modal
          open={openMissingDocModal}
          footer={null}
          closable={false}
          width={660}
        >
          <MissingDocForm
            order={missingDocOrder}
            onClose={(id, isUpdated) =>
              handleCloseMissingDocModal(id, isUpdated)
            }
          />
        </Modal>
      ) : null}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          marginBottom: '16px'
        }}
      >
        <div className={styles.actionMenu}>
          {actionDisplayed && (
            <div className={styles.bulkActionButtons}>
              <Button
                type="link"
                style={{ padding: '6px' }}
                className={styles.actionButtonIcon}
                onClick={() =>
                  onAction(
                    { action: 'On Caseload' },
                    { order_ids: selected.selectedRowKeys },
                    true
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'On Caseload'}
                >
                  <PackageIcon />
                </Tooltip>
              </Button>
              <Button
                type="link"
                style={{ padding: '6px' }}
                className={styles.actionButtonIcon}
                onClick={() =>
                  onAction(
                    { action: 'Order Ineligible' },
                    { order_ids: selected.selectedRowKeys },
                    true
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'Order Ineligible'}
                >
                  <DocumentIcon />
                </Tooltip>
              </Button>
              <Button
                type="link"
                style={{ padding: '6px' }}
                className={styles.actionButtonIcon}
                onClick={() =>
                  onAction(
                    { action: 'Payer Ineligible' },
                    { order_ids: selected.selectedRowKeys },
                    true
                  )
                }
              >
                <Tooltip
                  arrow={false}
                  overlayInnerStyle={{
                    background: '#112443',
                    textAlign: 'left'
                  }}
                  title={'Payer Ineligible'}
                >
                  <OrderIcon />
                </Tooltip>
              </Button>
            </div>
          )}
          {widgetFilter && (
            <div className={styles.actionFilters}>
              Filter by {widgetFilter.replaceAll('_', ' ')} Orders
              <Button
                className={styles.actionFiltersButton}
                type="text"
                onClick={resetFilters}
              >
                Reset
              </Button>
            </div>
          )}
        </div>
        <div style={{ minWidth: '300px' }}>
          <Tooltip
            arrow={false}
            placement="left"
            overlayInnerStyle={{
              background: '#112443',
              textAlign: 'left'
            }}
            title={'Search by Patient Name, MRN, and Updated By'}
          >
            <div>
              <SaivaInput
                value={searchValue}
                placeholder="Search"
                name="search"
                label=""
                prefixIcon={<SearchIcon />}
                allowClear={true}
                onChange={(value) => onSearch(value)}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      <SaivaTable
        rowSelection={{ ...rowSelection, selected }}
        columns={columns}
        rowClassName={(record) =>
          `${record.order_status != 'Inactive' && styles.action}`
        }
        rowKey={rowKey}
        expandable={{
          expandedRowRender: (record) => (
            <Row>
              <Col span={12}>
                <div className={styles.expandedRowColumn}>
                  <h6>Order: </h6>
                  <p>{record.order}</p>
                </div>
              </Col>
              <Col span={12}>
                <div className={styles.expandedRowColumn}>
                  <h6>Order Directions: </h6>
                  <p>
                    {record.order_directions ? record.order_directions : 'N/A'}
                  </p>
                </div>
              </Col>
            </Row>
          ),
          columnWidth: 120,
          columnTitle: 'Order',
          showExpandColumn: false,
          expandIcon: ({ expanded, onExpand, record }) => (
            <div style={{ textAlign: 'left' }}>
              {expanded ? (
                <Button
                  onClick={(e) => onExpand(record, e)}
                  type={'text'}
                  style={{ padding: '0px', marginRight: '10px' }}
                >
                  <MinusCircle />
                </Button>
              ) : (
                <Button
                  onClick={(e) => onExpand(record, e)}
                  type={'text'}
                  style={{ padding: '0px', marginRight: '10px' }}
                >
                  <PlusCircle />
                </Button>
              )}
              {record.order.slice(0, 10) + '...'}
            </div>
          )
        }}
        data={data}
        loading={loading}
        pagination={pagination}
        onRow={undefined}
        scroll={{ x: 'max-content' }}
      />
    </>
  )
}
