import React, { useEffect, useState, useRef } from 'react'
import { useUserContext } from 'context/UserContext'
import { useTranslation } from 'react-i18next'
import {
  Modal,
  Space,
  Tag,
  Row,
  Col,
  Grid,
  Dropdown,
  Menu,
  Tooltip
} from 'antd'
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg'
import { ReactComponent as EditIcon } from 'assets/icons/edit-2.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg'
import { ReactComponent as MenuVertical } from 'assets/icons/menu-vertical.svg'
import { ReactComponent as DuplicateIcon } from 'assets/icons/duplicate.svg'
import { ReactComponent as CrossIcon } from 'assets/icons/cross-big.svg'
import ScheduleReportingModal from '../SRModal/SRModal'
import SaivaToggle from 'components/SaivaForm/SaivaToggle/saivaToggle'
import { getDaysGroup } from '../utils'
import ConfirmationModal from '../ConfirmationModal/CModal'
import styles from './MRModal.module.scss'
import SchedulesService from 'services/schedules-service'
import { Schedules } from 'types/schedule-reports'
import { getReportLabel, getReportId, getFeatureLabel } from 'utils/helper'
import { showSuccessToast } from 'utils'
import { SortDirection } from 'services/utils/api-utils'
import { mixpanelInstance } from 'utils/mixpanel'
import { OrgProductFeature } from 'types/user-types'
import { UserPermission } from 'types/user-types'

const { useBreakpoint } = Grid

const ManageReportItem = ({
  schedule,
  reportId,
  onDelete,
  onUpdate,
  adminPermissions
}) => {
  const userContext = useUserContext()
  const { t } = useTranslation()
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openSRModal, setOpenSRModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isDuplicateMode, setIsDuplicateMode] = useState(false)
  const screens = useBreakpoint()
  const ref = useRef<any>()
  const [isOverflow, setIsOverflow] = useState(false)

  useEffect(() => {
    setIsOverflow(ref.current?.offsetWidth < ref.current?.scrollWidth)
  }, [])

  const { id } = schedule
  const {
    file_format,
    time_zone,
    schedule_type,
    time_of_day,
    day_of_week,
    name,
    week_of_month,
    is_enabled,
    assignee_name
  } = schedule.schedule_config

  const toggleEdit = async (checked: boolean) => {
    const requestData = {
      schedule_config: {
        is_enabled: checked,
        schedule_type: schedule_type,
        time_of_day: time_of_day,
        day_of_week: day_of_week,
        week_of_month: week_of_month
      }
    }
    await SchedulesService.updateSchedule(
      userContext.currentOrg.id,
      id,
      requestData
    )
      .then((resp) => {
        onUpdate()
        mixpanelInstance.scheduleEdit({
          id: id,
          schedule_config: requestData.schedule_config
        })
        showSuccessToast(t('scheduleReport.editSuccessText', { name: name }))
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          onUpdate(err.errorCode)
        } else {
          onUpdate('genericError')
        }
      })
  }

  const handleCancelConfirm = async () => {
    setOpenConfirm(false)
  }

  const handleClick = () => {
    setOpenConfirm(true)
  }

  const handleOk = () => {
    setOpenConfirm(false)
    handleDelete()
  }

  const handleDelete = async () => {
    await SchedulesService.deleteSchedule(userContext.currentOrg.id, id)
      .then((resp) => {
        onDelete()
        mixpanelInstance.scheduleDelete({
          schedule
        })
        showSuccessToast(t('scheduleReport.deleteSuccessText', { name: name }))
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          onUpdate(err.errorCode)
        } else {
          onUpdate('genericError')
        }
      })
  }

  const handleDeleteModal = async () => {
    await SchedulesService.deleteSchedule(userContext.currentOrg.id, id)
      .then((resp) => {
        onDelete()
        mixpanelInstance.scheduleDelete({
          id: id
        })
        setOpenSRModal(false)
        showSuccessToast(t('scheduleReport.deleteSuccessText', { name: name }))
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          onUpdate(err.errorCode)
        } else {
          onUpdate('genericError')
        }
      })
  }

  const handleCloseModal = async () => {
    onUpdate()
    setOpenSRModal(false)
    setIsEditMode(false)
    setIsDuplicateMode(false)
  }

  return (
    <>
      <ConfirmationModal
        openConfirm={openConfirm}
        handleCancelConfirm={handleCancelConfirm}
        handleOk={handleOk}
        title={'Delete a schedule'}
        message={
          'Do you really want to delete the schedule? This operation is irreversible and you will lost all schedule settings.'
        }
      />
      <Row
        style={{
          padding: '12px 0px',
          marginBottom: '12px',
          lineHeight: '2.5'
        }}
        align={'stretch'}
      >
        <Col
          lg={adminPermissions ? 3 : 5}
          md={adminPermissions ? 10 : 20}
          sm={adminPermissions ? 10 : 20}
          className={`${styles.nameCol} elipsis`}
          ref={ref}
        >
          <label className={styles.label}>Name</label>
          {isOverflow ? (
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={name}
            >
              <div className={styles.name}>{name}</div>
            </Tooltip>
          ) : (
            <div className={styles.name}>{name}</div>
          )}
        </Col>
        {adminPermissions && (
          <Col lg={5} md={10} sm={10} className={`${styles.nameCol} elipsis`}>
            <label className={styles.label}>Assignee</label>
            <Tooltip
              arrow={false}
              overlayInnerStyle={{
                background: '#112443',
                textAlign: 'left'
              }}
              title={assignee_name}
            >
              <div className={styles.name}>{assignee_name}</div>
            </Tooltip>
          </Col>
        )}
        <Col lg={2} md={4} sm={4} style={{ margin: 'auto' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              paddingRight: screens.lg ? '12px' : '0px'
            }}
          >
            <SaivaToggle
              value={is_enabled}
              name="name"
              onChange={toggleEdit}
              hideLabel
            />
          </div>
        </Col>
        <Col lg={3} md={4} sm={4}>
          <label className={styles.label}>Format</label>
          {file_format.toUpperCase()}
        </Col>
        <Col
          lg={5}
          md={9}
          sm={9}
          className={styles.time}
          title={time_of_day + ' ' + time_zone}
        >
          <label className={styles.label}>Time</label>
          {time_of_day} {time_zone}
        </Col>
        <Col lg={2} md={3} sm={3} className={styles.scheduleType}>
          {schedule_type}
        </Col>
        <Col
          lg={adminPermissions ? 2 : 4}
          md={4}
          sm={4}
          className={styles.spacing}
        >
          <div style={{ display: 'flex' }}>
            <div className={styles.weekOfMonth}>
              {week_of_month ? week_of_month : '-'}
            </div>
            {getDaysGroup(day_of_week).map((group, i) => (
              <span key={i} className={styles.selectedDaysWrapper}>
                {group}
              </span>
            ))}
          </div>
        </Col>
        <Col
          lg={2}
          md={week_of_month ? 2 : 4}
          sm={week_of_month ? 2 : 4}
          style={{ margin: 'auto' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'flex-end'
            }}
          >
            <Dropdown
              overlayClassName={styles.dropdown}
              dropdownRender={(menu) => (
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setIsEditMode(true)
                      setOpenSRModal(true)
                    }}
                    className={styles.menuItem}
                  >
                    <EditIcon style={{ marginRight: '12px' }} />
                    {t('schedules.allSchedules.table.action.edit')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      setIsDuplicateMode(true)
                      setOpenSRModal(true)
                    }}
                    className={`${styles.duplicateMenuItem} ${styles.menuItem}`}
                  >
                    <DuplicateIcon style={{ marginRight: '12px' }} />
                    {t('schedules.allSchedules.table.action.duplicate')}
                  </Menu.Item>
                  <Menu.Item
                    onClick={handleClick}
                    className={`${styles.deleteMenuItem} ${styles.menuItem}`}
                  >
                    <DeleteIcon style={{ marginRight: '12px' }} />
                    {t('schedules.allSchedules.table.action.delete')}
                  </Menu.Item>
                </Menu>
              )}
            >
              <Space>
                <MenuVertical className={styles.menuIcon} />
              </Space>
            </Dropdown>
            <ScheduleReportingModal
              isEditMode={isEditMode}
              isDuplicate={isDuplicateMode}
              open={openSRModal}
              reportId={reportId}
              item={schedule}
              filtersData={schedule.report_config.params.filters}
              close={handleCloseModal}
              deleteItem={handleDeleteModal}
            />
          </div>
        </Col>
      </Row>
    </>
  )
}

export interface Props {
  reportId: Schedules.ReportTypes
  open?: boolean
  close: () => void
  items: Schedules.Item[]
  qualityMeasure: OrgProductFeature
}

const ManageReportsModal = ({
  reportId,
  open,
  close,
  items,
  qualityMeasure
}: Props) => {
  const userContext = useUserContext()
  const { t } = useTranslation()
  const [schedules, setSchedules] = useState<any>([])
  const [apiError, setApiError] = useState<any>(undefined)
  const screens = useBreakpoint()

  useEffect(() => {
    setSchedules(items)
  }, [items])

  const closeModal = () => {
    setApiError(undefined)
    close()
  }

  const reportName = getReportLabel(reportId)

  const adminPermissions =
    userContext.hasPermission(UserPermission.SCHEDULE_CREATE) &&
    userContext.hasPermission(UserPermission.SCHEDULE_EDIT)

  const updateItems = async (apiError) => {
    setApiError(apiError)
    await SchedulesService.getSchedules(userContext.currentOrg.id, {
      sort: { property: 'name', direction: SortDirection.Ascending },
      report_type: [getReportId(reportId)],
      quality_measure: qualityMeasure && [qualityMeasure],
      show_all: adminPermissions
    })
      .then((resp) => {
        setSchedules(resp?.items)
        mixpanelInstance.scheduleFilter({
          sort: { property: 'name', direction: SortDirection.Ascending },
          report_type: [getReportId(reportId)]
        })
      })
      .catch((err) => {
        if (err.name === 'ApiError') {
          setApiError(err.errorCode)
        } else {
          setApiError('genericError')
        }
      })
  }

  return (
    <>
      <Modal
        closeIcon={<CrossIcon style={{ padding: '2px' }} />}
        wrapClassName="custom-modal"
        width={screens.lg ? '1042px' : '700px'}
        open={open}
        onCancel={closeModal}
        centered
        footer={null}
      >
        <Row gutter={[16, 16]} align="top">
          <Col span={24}>
            <Space direction="horizontal" wrap align="start">
              <div
                style={{
                  flex: 'none',
                  order: 0,
                  flexGrow: 0
                }}
              >
                <h4>Manage Schedule</h4>
              </div>
              <label
                className="category-label"
                style={{
                  color: reportName?.color,
                  background: reportName?.bg,
                  fontSize: '14px',
                  marginLeft: '60px'
                }}
              >
                {reportName?.label}
              </label>
              {qualityMeasure && (
                <>
                  <label className={styles.qualityMeasure}>
                    Quality Measure
                  </label>
                  <label className={styles.qualityMeasureValue}>
                    {getFeatureLabel(qualityMeasure).label}
                  </label>
                </>
              )}
            </Space>
          </Col>
        </Row>
        <div
          style={{
            maxHeight: '370px',
            overflowY: 'scroll',
            paddingRight: '12px'
          }}
        >
          <table className={styles.table}>
            <tbody>
              {schedules?.length > 0 ? (
                schedules.map((item, i) => {
                  return (
                    <ManageReportItem
                      key={i}
                      schedule={item}
                      reportId={reportId}
                      onDelete={updateItems}
                      onUpdate={updateItems}
                      adminPermissions={adminPermissions}
                    />
                  )
                })
              ) : (
                <div className={styles.emptnyTable}>There are no schedules</div>
              )}
            </tbody>
          </table>
        </div>
        {apiError && (
          <>
            <div className={`banner bannerFailed`}>
              <InfoIcon />
              <p>{t(apiError)}</p>
            </div>
          </>
        )}
      </Modal>
    </>
  )
}

export default ManageReportsModal
