import React from 'react'
import styles from './LoadingPreview.module.scss'
import LoadingSpinner from '../../components/LoadingSpinner'

const LoadingPreview = () => {

  return (
    <div className={styles.loadingPreviewWrapper}>
      <div className={styles.container}>
        Your report export is loading… <br/> this will take a moment
      </div>
      <LoadingSpinner className={styles.spinner}/>
    </div>
  )
}

export default LoadingPreview
