import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './styles/saiva-bootstrap.scss'
import '@fontsource/heebo/400.css'
import '@fontsource/heebo/500.css'
import '@fontsource/heebo/700.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-toastify/dist/ReactToastify.css'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { UserProvider } from './context/UserContext';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ConfirmModal from './components/ConfirmModal/ConfirmModal'
import ConfirmModalProvider from './components/ConfirmModal/ConfirmModal'

import type { ThemeConfig } from 'antd';
import { ConfigProvider } from 'antd';
import saivaTheme from './themeConfig'
import * as DOMPurify from 'dompurify';
import Zendesk from "react-zendesk";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from 'views/ErrorPage/ErrorPage';

const ZENDESK_KEY = "c9b8bd01-447f-4155-9aba-fb602fea3536";

const config: ThemeConfig = saivaTheme

const setting = {
  color: {
    theme: "#000"
  },
  launcher: {
    chatLabel: {
      "en-US": "Need Help"
    }
  },
  contactForm: {
    fields: [
      { id: "description", prefill: { "*": "My pre-filled description" } }
    ]
  }
};

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://7a7d294602834eeeb824d5e5c01a194e@o1248300.ingest.sentry.io/6671023",
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'CanceledError: canceled'
    ]
  });
}

if (window.trustedTypes && window.trustedTypes.createPolicy) { // Feature testing
  window.trustedTypes.createPolicy('default', {
      createHTML: (string) => DOMPurify.sanitize(string, {RETURN_TRUSTED_TYPE: true}),
      createScriptURL: s => s,
      createScript: s => s
  });
}

const root = document.getElementById("root")
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider theme={config} wave={{ disabled: true }}>
      <BrowserRouter>
        <UserProvider>
          <ConfirmModalProvider>
            <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} onLoaded={() => console.log('is loaded')} />
            <ErrorBoundary fallback={<ErrorPage />}>
              <App />
            </ErrorBoundary>
          </ConfirmModalProvider>
        </UserProvider>
      </BrowserRouter>
    </ConfigProvider>
  </React.StrictMode>
, root);
