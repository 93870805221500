import styles from './UserCard.module.scss'
import { ReactComponent as ShieldIcon } from '../../../assets/icons/medical-shield.svg'
import { useTranslation } from 'react-i18next'

interface User {
  patient_id: string
  age: string
  sex: string
  race: string
  primary_dx: string
  payer: string
  initial_admission_date: string
  last_transfer_date: string
  patient_name: string
}

type Props = {
  children?: JSX.Element
  user: User
}

export default function TopNavigation({ children, user }: Props) {
  const { t } = useTranslation()

  return (
    <div className={styles.layout}>
      <div className={styles.topInfo}>
        <h1 style={{ textAlign: 'center' }}>
          {user.patient_name ? user.patient_name : '-'}
        </h1>
        <div className={styles.info}>
          <div className={styles.age}>
            {user.age} {t('residentPage.userOverview.yearsOld')}
          </div>
          <div className={styles.sex}>{user.sex ? user.sex : '-'}</div>
        </div>
        <div style={{ textAlign: 'center' }}>{user.race ? user.race : '-'}</div>
      </div>
      <div className={styles.overview}>
        <div className={styles.header}>
          <h4>
            <span style={{ paddingRight: '10px' }}>
              <ShieldIcon />
            </span>
            {t('residentPage.userOverview.overview')}
          </h4>
        </div>
        <div className={styles.content}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={styles.label}>
                  {t('residentPage.userOverview.primaryDx')}
                </td>
                <td style={{ fontWeight: 'bold' }}>
                  {user.primary_dx ? user.primary_dx : '-'}
                </td>
              </tr>
              <tr>
                <td className={styles.label}>
                  {t('residentPage.userOverview.payer')}{' '}
                </td>
                <td>{user.payer ? user.payer : '-'}</td>
              </tr>
              <tr>
                <td className={styles.label}>
                  {t('residentPage.userOverview.initialAdmission')}
                </td>
                <td>
                  {user.initial_admission_date
                    ? user.initial_admission_date
                    : '-'}
                </td>
              </tr>
              <tr>
                <td className={styles.label}>
                  {t('residentPage.userOverview.lastTransfer')}
                </td>
                <td>
                  {user.last_transfer_date ? user.last_transfer_date : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
