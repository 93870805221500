export enum Environments {
  LOCAL="local", DEVELOPMENT="development", STAGING="staging", PRODUCTION="production"
}

export const getEnv = () : Environments => {
  const env = process.env.REACT_APP_ENVIRONMENT
  switch (env) {
    case "local": return Environments.LOCAL
    case "development": return Environments.DEVELOPMENT
    case "staging": return Environments.STAGING
    case "production": return Environments.PRODUCTION
    default: return Environments.PRODUCTION
  }
}

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN
export const MIXPANEL_ACTIVE = process.env.REACT_APP_MIXPANEL_ACTIVE === "true"

export const LOGIN_HELP_URL = process.env.REACT_APP_LOGIN_HELP_URL
export const CONTACT_US_URL = process.env.REACT_APP_CONTACT_US_URL
export const INTEGRATION_GUIDE_URL = process.env.REACT_APP_INTETGRATION_GUIDE_URL

export const ENVIRONMENT: Environments = getEnv()

const time = process.env.REACT_APP_IDLE_TIME_LOGOUT
// default value 15 min
export const IDLE_TIME_LOGOUT: number = time ? Number(time) : 900000
