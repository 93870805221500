import { get, patch, post, del } from 'services/utils/api-client'
import { generateFilterUrl } from 'services/utils/api-utils'
import { SaivaSettings } from '../types/settings-types'

namespace SettingsResponse {
  export interface Logo {
    report_logo_url: string
  }
}

namespace SettingsRequest {
  export interface Patch {
    email_from_address?: string,
    sending_report_type?: string
  }
}

namespace ClinicalResponse {
  export interface Item {
    file_url: string
  }
}

namespace ClinicalRequest {
  export interface Post {
    file_url: string
  }
}

const parseSettings = {
  Item: (response: SaivaSettings.Item): SaivaSettings.Item => {
    return {
      email_from_address: response.email_from_address,
      enabled_users: response.enabled_users,
      feature_values: response.feature_values,
      packages: response.packages,
      sending_report_type: response.sending_report_type
    }
  }
}

const getSettings = async (
  orgId: string
): Promise<SaivaSettings.Item | undefined> => {
  const url = generateFilterUrl('/v2/orgs/{org_id}/settings', [
    { id: '{org_id}', value: orgId }
  ])
  return await get<SaivaSettings.Item, SaivaSettings.Item>(url, {
    parser: parseSettings.Item
  })
}

const updateSettings = async (
  orgId: string,
  payload: SettingsRequest.Patch
) => {
  const url = generateFilterUrl('/v2/orgs/{org_id}/settings', [
    { id: '{org_id}', value: orgId }
  ])
  return await patch<SaivaSettings.Item, SaivaSettings.Item>(url, {
    payload
  })
}

const getKeyWords = async (orgId: string) => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/settings/progress_notes_highlight_keywords',
    [{ id: '{org_id}', value: orgId }]
  )
  return await get<ClinicalResponse.Item>(url)
}

const postKeyWords = async (
  orgId: string,
  payload: any
 ) => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/settings/progress_notes_highlight_keywords',
    [{ id: '{org_id}', value: orgId }]
  )
  return await post<ClinicalRequest.Post, any, any>(url, {
    payload
  })
}

const deleteLogo = async (orgId: string) => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/settings/logo',
    [{ id: '{org_id}', value: orgId }]
  )
  return await del<SettingsResponse.Logo>(url)
}

const getLogo = async (orgId: string) => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/settings/logo',
    [{ id: '{org_id}', value: orgId }]
  )
  return await get<SettingsResponse.Logo>(url)
}

const postLogo = async (
  orgId: string,
  payload: any
 ) => {
  const url = generateFilterUrl(
    '/v2/orgs/{org_id}/settings/logo',
    [{ id: '{org_id}', value: orgId }]
  )
  return await post<ClinicalRequest.Post, any, any>(url, {
    payload
  })
}


const SettingsService = {
  getSettings,
  updateSettings,
  getKeyWords,
  postKeyWords,
  getLogo,
  postLogo,
  deleteLogo
}

export default SettingsService
