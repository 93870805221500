import React from 'react'
import { SWMIcon } from 'react-swm-icon-pack'

type Props = {
  name: string
  className?: string
  size?: string
}

export default function SaivaIcon(props: Props) {
  const searchIcon = (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4697 18.5303C17.7626 18.8232 18.2374 18.8232 18.5303 18.5303C18.8232 18.2374 18.8232 17.7626 18.5303 17.4697L17.4697 18.5303ZM14.3336 13.273C14.0407 12.9801 13.5659 12.9801 13.273 13.273C12.9801 13.5659 12.9801 14.0408 13.273 14.3336L14.3336 13.273ZM8.5 15.25C4.77208 15.25 1.75 12.2279 1.75 8.5H0.25C0.25 13.0563 3.94365 16.75 8.5 16.75V15.25ZM1.75 8.5C1.75 4.77208 4.77208 1.75 8.5 1.75V0.25C3.94365 0.25 0.25 3.94365 0.25 8.5H1.75ZM8.5 1.75C12.2279 1.75 15.25 4.77208 15.25 8.5H16.75C16.75 3.94365 13.0563 0.25 8.5 0.25V1.75ZM15.25 8.5C15.25 12.2279 12.2279 15.25 8.5 15.25V16.75C13.0563 16.75 16.75 13.0563 16.75 8.5H15.25ZM18.5303 17.4697L14.3336 13.273L13.273 14.3336L17.4697 18.5303L18.5303 17.4697Z"
        fill="#112443"
      />
    </svg>
  )

  const closedBurgerMenuIcon = (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10.25C0.585786 10.25 0.25 10.5858 0.25 11C0.25 11.4142 0.585786 11.75 1 11.75V10.25ZM17 11.75C17.4142 11.75 17.75 11.4142 17.75 11C17.75 10.5858 17.4142 10.25 17 10.25V11.75ZM7 5.25C6.58579 5.25 6.25 5.58579 6.25 6C6.25 6.41421 6.58579 6.75 7 6.75V5.25ZM17 6.75C17.4142 6.75 17.75 6.41421 17.75 6C17.75 5.58579 17.4142 5.25 17 5.25V6.75ZM10 0.25C9.58579 0.25 9.25 0.585786 9.25 1C9.25 1.41421 9.58579 1.75 10 1.75V0.25ZM17 1.75C17.4142 1.75 17.75 1.41421 17.75 1C17.75 0.585786 17.4142 0.25 17 0.25V1.75ZM1 11.75H17V10.25H1V11.75ZM7 6.75H17V5.25H7V6.75ZM10 1.75H17V0.25H10V1.75Z"
        fill="#112443"
      />
    </svg>
  )

  const dashboardIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0052 2.76923H3.76416C3.21471 2.76923 2.76929 3.21465 2.76929 3.7641V9.97948C2.76929 10.5289 3.21471 10.9744 3.76416 10.9744H11.0052C11.5546 10.9744 12.0001 10.5289 12.0001 9.97948V3.7641C12.0001 3.21465 11.5546 2.76923 11.0052 2.76923Z"
        stroke="#112443"
      />
      <path
        d="M20.2359 13.0256H12.9949C12.4454 13.0256 12 13.4711 12 14.0205V20.2359C12 20.7853 12.4454 21.2308 12.9949 21.2308H20.2359C20.7853 21.2308 21.2308 20.7853 21.2308 20.2359V14.0205C21.2308 13.4711 20.7853 13.0256 20.2359 13.0256Z"
        stroke="#112443"
        strokeWidth="1.5"
      />
      <path
        d="M20.2359 2.76923H15.0461C14.4967 2.76923 14.0513 3.21465 14.0513 3.7641V9.97948C14.0513 10.5289 14.4967 10.9744 15.0461 10.9744H20.2359C20.7853 10.9744 21.2308 10.5289 21.2308 9.97948V3.7641C21.2308 3.21465 20.7853 2.76923 20.2359 2.76923Z"
        stroke="#112443"
      />
      <path
        d="M8.9539 13.0256H3.76416C3.21471 13.0256 2.76929 13.4711 2.76929 14.0205V20.2359C2.76929 20.7853 3.21471 21.2308 3.76416 21.2308H8.9539C9.50336 21.2308 9.94877 20.7853 9.94877 20.2359V14.0205C9.94877 13.4711 9.50336 13.0256 8.9539 13.0256Z"
        stroke="#112443"
      />
    </svg>
  )
  const residentMenu = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon / chart-vertical">
        <path
          id="shape"
          d="M16 11V17M12 7L12 17M8 14L8 17M4 4H20V20H4V4Z"
          stroke="#112443"
        />
      </g>
    </svg>
  )
  const WoundCare = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.7204 2.42556C14.0606 1.08542 16.2334 1.08542 17.5735 2.42556V2.42556C18.9137 3.7657 18.9136 5.9385 17.5735 7.27863L14.4482 10.404L10.4039 14.4482L7.2786 17.5735C5.93846 18.9137 3.76567 18.9137 2.42553 17.5735V17.5735C1.08539 16.2334 1.08539 14.0606 2.42553 12.7205L5.55088 9.59513L9.5951 5.55089L12.7204 2.42556Z"
        stroke="white"
        strokeWidth="1.3"
      />
      <path
        d="M5.14697 10L8.38236 13.2354"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M11.6182 6.76367L14.8535 9.99905"
        stroke="white"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M9.19823 10.8008L9.20632 10.8089L9.19823 10.817L9.19015 10.8089L9.19823 10.8008Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M10.4116 12.0137L10.4197 12.0218L10.4116 12.0299L10.4035 12.0218L10.4116 12.0137Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M7.98534 9.58692L7.99343 9.59501L7.98534 9.6031L7.97726 9.59501L7.98534 9.58692Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M10.8159 9.18263L10.824 9.19071L10.8159 9.1988L10.8078 9.19071L10.8159 9.18263Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M12.0303 10.3965L12.0384 10.4046L12.0303 10.4127L12.0222 10.4046L12.0303 10.3965Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
      <path
        d="M9.60253 7.96973L9.61062 7.97782L9.60253 7.98591L9.59444 7.97782L9.60253 7.96973Z"
        stroke="white"
        strokeWidth="1.3"
        strokeLinejoin="round"
      />
    </svg>
  )

  const getIconElement = () => {
    if (props.name === 'SaivaSearch') {
      return searchIcon
    } else if (props.name === 'SaivaClosedBurgerMenu') {
      return closedBurgerMenuIcon
    } else if (props.name === 'SaivaDashboard') {
      return dashboardIcon
    } else if (props.name === 'ResidentMenu') {
      return residentMenu
    } else if (props.name === 'WoundCare') {
      return WoundCare
    } else {
      return <SWMIcon name={props.name} size={props.size} set="outline" />
    }
  }

  return (
    <i className={`saiva-icon ${props.className ?? ''}`}>{getIconElement()}</i>
  )
}
