import { useCallback, useEffect, useState } from 'react'
import { ENVIRONMENT, Environments, IDLE_TIME_LOGOUT } from 'utils/constants'
import { useLocation } from 'react-router-dom'

export function useCheckUserInactivity(logoutFunction: () => void) {

  const timeout = useState<NodeJS.Timeout>();
  const location = useLocation()


  const resetTimer = useCallback(() => {
    if(!location.pathname.includes("login")) {
      clearTimeout(timeout[0])
      timeout[0] = setTimeout(() => {
        logoutFunction()
      }, IDLE_TIME_LOGOUT)
    }
  }, [location.pathname])

  const events = ['load', 'mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart', 'touchmove', 'touchend', 'click'];


  useEffect(() => {
    if(ENVIRONMENT === Environments.LOCAL) return
    resetTimer()
    events.forEach(function(name) {
      window.addEventListener(name, resetTimer, true);
    });
    return () => {
      events.forEach(function(name) {
        window.removeEventListener(name, resetTimer, true);
      });
    }
  }, [location.pathname]);
}
