import { useState, useEffect, useRef } from 'react'
import { Input, Popover, Row, Button } from 'antd'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as FilterIcon } from 'assets/icons/filters.svg'
import styles from './SaivaSearch.module.scss'

interface Props {
  content: JSX.Element
  placeholder: string
  value: string | undefined
  activeFilters: boolean | undefined
  filters: boolean
  onChange: (e) => void
  reset?: () => void
}

const SaivaSearch = ({
  content,
  placeholder,
  value,
  activeFilters,
  filters,
  onChange,
  reset
}: Props) => {
  const [active, setActive] = useState(false)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        event.target.className !== 'ant-select-item-option-content' &&
        event.target.className !== 'SaivaSearch_filterButton__nDU4L' &&
        event.target.className !== 'SaivaMultiSelect_checkbox__S1UrQ' &&
        event.target.className !== 'saivaCheckboxValue' &&
        event.target.className !== 'SaivaMultiSelect_label__XiYvs'
      ) {
        setActive(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const filterContent = (
    <div className={styles.filtersDiv} ref={wrapperRef}>
      {content}
      <Row
        justify="end"
        style={{ marginTop: '32px', padding: '0px 10px 10px 0px' }}
      >
        <button
          onClick={() => setActive(!active)}
          className={'button-base-small primary-button'}
        >
          Apply filters
        </button>
      </Row>
    </div>
  )

  return (
    <Input
      className={`${styles.searchInput} ${
        !filters && styles.searchInputNoFilters
      }`}
      value={value}
      placeholder={placeholder}
      allowClear={{
        clearIcon: (
          <Button className={styles.clearButton} type="text">
            Clear
          </Button>
        )
      }}
      onChange={(e) => {
        onChange(e)
      }}
      prefix={<SearchIcon style={{ marginRight: 8 }} />}
      suffix={
        filters && (
          <Popover
            placement="bottomRight"
            open={active}
            content={filterContent}
            className={`${styles.suffix} ${active && styles.suffixActive} ${
              activeFilters && styles.activeFilters
            }`}
            zIndex={900}
          >
            <div className={styles.filterButtonDiv}>
              <button
                onClick={() => setActive(!active)}
                className={styles.filterButton}
              >
                <FilterIcon
                  className={styles.filterIcon}
                  style={{ marginRight: 8 }}
                />
                Filters
              </button>
            </div>
          </Popover>
        )
      }
    />
  )
}

export default SaivaSearch
