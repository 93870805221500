import { SaivaAuth } from '../types/auth-types'
import { getTimestampInSeconds } from '../utils'
import { handleError } from '../utils/errorHandler'
import jwt_decode from 'jwt-decode'
import { apiClient } from './utils/api-client'

namespace AuthResponse {
  export interface Login {
    access_token: string,
    refresh_token: string
  }
}

export namespace Auth {
  export interface StoredUser {
    id: number
    email: string
    accessToken: string
    refreshToken: string
    lastRefresh: number
    expiresAt: number
  }
  export interface JwtTokenPayload {
    token_type: string
    exp: number
    iat: number
    user_id: number
  }
}

const parseAuth = {
  Login: (response: AuthResponse.Login) : SaivaAuth.Login => {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token
    }
  },
  Refresh: (response: AuthResponse.Login) : SaivaAuth.Refresh => {
    return {
      ...parseAuth.Login(response),
      lastRefresh: getTimestampInSeconds()
    }
  }
}

const USER_STORAGE_KEY = "user"

export const saveUserToStorage = (email: string, tokens: AuthResponse.Login) => {
  try {
    const decodedToken = jwt_decode<Auth.JwtTokenPayload>(tokens.access_token)
    const user: Auth.StoredUser = {
      email: email,
      id: decodedToken.user_id,
      accessToken: tokens.access_token,
      refreshToken: tokens.refresh_token,
      lastRefresh: getTimestampInSeconds(),
      expiresAt: decodedToken.exp
    }
    window.localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user))
  } catch (err) {
    handleError({id: "SaveUserToStorage", error: err, track: true})
  }
}

export const getUserFromStorage = () : Auth.StoredUser | undefined => {
  try {
    const storage = window.localStorage.getItem(USER_STORAGE_KEY)
    if(storage) {
      const user = JSON.parse(storage)
      return user
    }
  } catch (e) {
    handleError({id: "GetUserFromStorage", error: e, track: true})
  }
}

const updateTokensInStorage = (tokens: AuthResponse.Login) => {
  const user = getUserFromStorage()
  if(user) saveUserToStorage(user.email, tokens)
}

const clearUserStorage = () => {
  try {
    window.localStorage.removeItem(USER_STORAGE_KEY)
  } catch (err) {
    handleError({id: "ClearUserStorage", error: err, track: true})
  }
}

const refreshToken = async (): Promise<SaivaAuth.Refresh | undefined> => {
  try {
    const user = getUserFromStorage()
    if (user && user.refreshToken) {
      const {data} = await apiClient.post<AuthResponse.Login>("v2/auth/jwt/refresh", {refresh_token: user.refreshToken})
      updateTokensInStorage(data)
      return parseAuth.Refresh(data)
    } else await logout()
  } catch (err: any) {
    throw handleError({id: "GetRefreshToken", error: err, customHandle: true})
  }
}

const requestOtp = async (payload: {email: string, phrase?: string, email_login?: boolean}) : Promise<{ phrase: string, active_devices: boolean, user_id: number } | null> => {
  try {
    const result = await apiClient.post(`v2/auth/otp`, {...payload, platform: "web"})
    return result.data
  } catch (err: any) {
    throw handleError({id: "RequestOtp", error: err, customHandle: true})
  }
}

const loginOtp = async (payload: {email: string, password: string, phrase?: string}): Promise<SaivaAuth.Login | undefined> => {
  try {
    const body = {
      ...payload,
      platform: "web"
    }
    const res = await apiClient.post<AuthResponse.Login>(`v2/auth/otp/token`, body)
    saveUserToStorage(payload.email, res.data)
    return parseAuth.Login(res.data)
  } catch (err: any) {
    throw handleError({id: "FailedLogin", error: err, customHandle: true})
  }
}

const loginPassword = async (payload: {username: string, password: string}): Promise<SaivaAuth.Login | undefined> => {
  try {
    const data = new FormData()
    data.set("username", payload.username)
    data.set("password", payload.password)
    const res = await apiClient.post<AuthResponse.Login>(`v2/auth/password`, data)
    saveUserToStorage(payload.username, res.data)
    return parseAuth.Login(res.data)
  } catch (err: any) {
    throw handleError({id: "FailedLogin", error: err, customHandle: true})
  }
}

const logout = async () => {
  const url = window.location.pathname + window.location.search
  try {
    const user = getUserFromStorage()
    if(user && user.refreshToken) await apiClient.post("v2/auth/jwt/logout", { refresh_token: user.refreshToken })
    clearUserStorage()
  } catch (e) {
    const err = handleError({id: "FailedLogout", error: e, customHandle: true})
    if(err.name === "ApiError" && err.errorCode.includes("auth_token_invalid")) {
      clearUserStorage()
    }
    else throw err
  }
  window.location.replace(`/login?redirect=${url}`)
};

const sendFCMOTP = async (email: string) => {
  try {
    const res = await apiClient.post(`v2/auth/fcm_otp`, {email, platform: 'web'})
    return res.data
  } catch (err: any) {
    throw handleError({id: "SendFCMRequest", error: err, customHandle: true})
  }
}

const getFCMStatus = async (email: string, user_id: number) => {
  try {
    const res = await apiClient.get(`v2/auth/fcm_status?email=${email}&user_id=${user_id}&platform=web`)
    return res.data
  } catch (err: any) {
    throw handleError({id: "SendFCStatus", error: err, customHandle: true})
  }
}

const patchFCMStatusExpired = async (user_id: number) => {
  try {
    const res = await apiClient.patch('v2/auth/fcm_status', {user_id, status: 'Expired'})
    return res.data
  } catch (err: any) {
    throw handleError({id: "PatchFCStatus", error: err, customHandle: true})
  }
}

export const AuthService = {
  requestOtp,
  loginOtp,
  logout,
  refreshToken,
  loginPassword,
  sendFCMOTP,
  getFCMStatus,
  patchFCMStatusExpired,
  saveUserToStorage
}

export default AuthService
