import { AxiosError, AxiosRequestConfig, CanceledError } from 'axios'

const requestTimeout = 60000

//TODO refactor
export class ApiError extends Error {
  errorCode: string = ""
  statusCode: number | undefined = undefined
  detail: string | undefined = undefined
  config: AxiosRequestConfig | undefined
  response: any | undefined

  constructor(errorCode: string, detail: string | undefined = undefined, statusCode: number | undefined = undefined, config: AxiosRequestConfig | undefined = undefined, response: any | undefined = undefined) {
    super(`${errorCode} ${detail ? " - " + detail : ""}`)
    Object.setPrototypeOf(this, Error.prototype);
    this.name = "ApiError"
    this.errorCode = `backend.${errorCode}`
    this.detail = detail
    this.statusCode = statusCode
    this.config = config
    this.response = response
  }
}

export function parseErrorResponse(axiosErrorResponse: any): ApiError | Error | undefined {
  try {
    if(axiosErrorResponse instanceof CanceledError) return axiosErrorResponse
    if(axiosErrorResponse?.response?.data === undefined) {
      return new ApiError("Network Error")
    }
    const code = axiosErrorResponse?.response?.data?.code
    if(axiosErrorResponse?.response?.status === 401 || code === 401){
      if(!axiosErrorResponse?.config?.url?.includes("accept")) window.location.replace("/login")
    }
    return new ApiError(code)
  } catch (err) {
    if(err instanceof CanceledError) return new ApiError(err.name)
    return new ApiError(typeof(axiosErrorResponse) === 'string' ? axiosErrorResponse : "unknown")
  }
}

export interface ISaivaUserCapabilities {
  emailNotifications: boolean,
  pushNotifications: boolean,
  webAppLogin: boolean,
  mobileAppLogin: boolean
}

export const emptyCapabilities: ISaivaUserCapabilities = {
  emailNotifications: false,
  pushNotifications: false,
  webAppLogin: false,
  mobileAppLogin: false,
}

export interface ISaivaDetailedUser {
  id: number
  email: string
  name: string
  title: string
  capabilities: ISaivaUserCapabilities
  last_active_at: Date
  permissions: {
    facilities: number[],
    regions: {full_access: boolean, items: any},
    role: {name: string, enabled: boolean, description: string}
  }
}

export interface ISaivaInvitedUser {
  id: number
  email: string
  status: string
  createdAt: Date
  invitedBy: string
  name: string
  title: string
}

export interface IUserMeOrgResponse {
  id: number
  name: string
  role: string
}

export type GetFacilitiesFilter = {
  searchText?: string
}

export enum FileType {
  PDF = "pdf",
  CSV = "csv"
}
