import styles from './TimeSeriesCard.module.scss'
import { ReactComponent as NoDataIcon } from '../../../assets/icons/calendar-cross.svg'

interface timeSeriesItem {
  date_info: string
  value: string
}

type Props = {
  color?: string
  icon: JSX.Element
  title: string
  data?: timeSeriesItem[]
}

export default function TimeSeriesCard({ color, icon, title, data }: Props) {
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <h4>
          <span style={{ paddingRight: '10px' }}>{icon}</span>
          {title} ({data?.length})
        </h4>
      </div>
      <div className={styles.content}>
        {data && data?.length > 0 ? <table className={styles.table}>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td width={8}>
                    <span className={styles.dot} style={{background: `${color}`}}></span>
                  </td>
                  <td width={110} className={styles.date}>
                    {item.date_info}
                  </td>
                  <td className={styles.message}>{item.value}</td>
                </tr>
              )
            })}
          </tbody>
        </table> : <>
          <div className={styles.noData}>
            <NoDataIcon />
            No {title}
          </div>
        </>}
      </div>
    </div>
  )
}
