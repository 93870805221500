import React, { useCallback, useContext, useEffect, useReducer } from 'react'
import AuthService, { Auth, getUserFromStorage } from 'services/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { mixpanelInstance as mixpanel } from 'utils/mixpanel'
import { getBrowserInfo } from 'utils/helper'
import { useCheckUserInactivity } from 'hooks/useCheckUserInactivity'
import { SaivaUser, UserLoginState, UserPermission, OrgProductFeature } from 'types/user-types'
import { Action, ActionWithPayload, createAction } from 'utils/reducer'
import UserService from 'services/user-service'
import { handleError } from 'utils/errorHandler'
import { SaivaAuth } from '../types/auth-types'

// TODO add to proper place
// const decodedToken = jwt_decode<JwtTokenPayload>(tokens.accessToken)

export interface IOrg {
  id: number
  name: string
  role: string
}

// UTILS FUNCTIONS

const loadCurrentOrgId = (): string | undefined => {
  const query = new URLSearchParams(window.location.search);

  const orgIdFromUrl = query.get("org_id")

  if (orgIdFromUrl) {
    saveCurrentOrgId(orgIdFromUrl)
    return orgIdFromUrl
  } else {
    try {
      const value = localStorage.getItem("currentOrgId")
      if(value !== null) {
        return value
      } else {
        return undefined
      }
    } catch (err) {
      return undefined
    }
  }
}

const saveCurrentOrgId = (id: string) => {
  localStorage.setItem('currentOrgId', `${id}`)
}

const loadCurrentRole = (): string | undefined => {
  try {
    const value = localStorage.getItem("currentRole")
    if(value !== null) {
      return value
    } else {
      return undefined
    }
  } catch (err) {
    return undefined
  }
}

const saveCurrentMedicalSupplyOrgId = (id: string) => {
  localStorage.setItem('currentMedicalSupplyOrgId', `${id}`)
}

const loadCurrentMedicalSupplyOrgId = (): string | undefined => {
  try {
    const value = localStorage.getItem("currentMedicalSupplyOrgId")
    if(value !== null) {
      return value
    } else {
      return undefined
    }
  } catch (err) {
    return undefined
  }
}

const saveCurrentRole = (role: string) => {
  localStorage.setItem('currentRole', `${role}`)
}

export interface IUserContext extends SaivaUser.LoggedUser {
  dispatch: any,
  loginOtp: (email: string, password: string, phrase: string) => void
  loginFCMOTP: (tokens: SaivaAuth.Login) => void
  loginPassword: (username: string, password: string) => void
  logout: () => void
  refreshOrgs: () => void
  hasFeature: (feature: OrgProductFeature | OrgProductFeature[] | undefined) => boolean
  hasPermission: (permission: UserPermission) => boolean
  accessibleReports: () => OrgProductFeature[]
}

// USER CONTEXT

const UserContext = React.createContext<IUserContext>(null!)

const useUserContext = (): IUserContext => {
  const context = useContext(UserContext)
  if (context == null) {
    throw new Error("ERROR: Using UserContext while it's null")
  }
  return context
}

export enum UserActionTypes {
  SET_USER = "SET_USER",
  SET_ORGANIZATION = "SWITCH_ORG",
  SET_MEDICAL_SUPPLY_ORG = "SET_MEDICAL_SUPPLY_ORG",
  SET_MEDICAL_SUPPLY_ORG_ROLE = "SET_MEDICAL_SUPPLY_ORG_ROLE",
  REFRESH_ORG = "REFRESH_ORG",
  CLEAR_USER = "CLAER_USER",
}

const INITIAL_STATE: SaivaUser.LoggedUser = {
  name: '',
  title: '',
  email: '',
  id: -1,
  currentOrg: {id: "-1", role: "", partner_id: null, name: "", last_active_at: new Date(), permissions: [], productFeatures: []},
  currentMedicalSupplyOrg: {id: "-1", role: "", partner_id: null, name: "", last_active_at: new Date(), permissions: [], productFeatures: []},
  isSuperuser: false,
  loginState: UserLoginState.Loading,
  orgs: [],
  product_name: [],
  currentRole: '',
}

const userReducer = (state: SaivaUser.LoggedUser, action: ActionWithPayload<UserActionTypes, any>) => {
  switch (action.type) {
    case UserActionTypes.SET_USER:
      const user = action.payload
      const role = user.product_name.includes(loadCurrentRole()) ? loadCurrentRole() : user.product_name[0]
      const regularOrgs = user.orgs.filter((org) => !org.productFeatures.includes(OrgProductFeature.WOUND_CARE))
      const woundCareOrgs = user.orgs.filter((org) => org.productFeatures.includes(OrgProductFeature.WOUND_CARE))
      if(regularOrgs && regularOrgs[0]) {
        const org = loadCurrentOrgId()
        const medicalSupplyOrg = loadCurrentMedicalSupplyOrgId()

        let currentOrg = regularOrgs[0]
        let currentMedicalSupplyOrg = woundCareOrgs[0]

        const foundOrg = regularOrgs.find(item => item.id === org)
        const foundMedicalSupplyOrg = woundCareOrgs.find(item => item.id === medicalSupplyOrg)

        if (foundMedicalSupplyOrg) currentMedicalSupplyOrg = foundMedicalSupplyOrg
        if(foundOrg) currentOrg = foundOrg
        return {
          ...user,
          loginState: UserLoginState.LoggedIn,
          currentOrg: currentOrg,
          currentMedicalSupplyOrg: currentMedicalSupplyOrg,
          currentRole: role
        }
      }
      return {
        ...user,
        loginState: UserLoginState.LoggedIn,
      }
    case UserActionTypes.SET_ORGANIZATION:
      const newOrg: SaivaUser.OrgItem = action.payload
      mixpanel.organizationChange(state.currentOrg, newOrg)
      saveCurrentOrgId(newOrg.id)
      return {
        ...state,
        currentOrg: newOrg
      }
    case UserActionTypes.SET_MEDICAL_SUPPLY_ORG:
      const {newRegularOrg, newParentOrg} = action.payload
      saveCurrentOrgId(newRegularOrg.id)
      saveCurrentMedicalSupplyOrgId(newParentOrg.id)
      return {
        ...state,
        currentOrg: newRegularOrg,
        currentMedicalSupplyOrg: newParentOrg
      }
    case UserActionTypes.SET_MEDICAL_SUPPLY_ORG_ROLE:
      const newMedicalSupplyOrgRole: string = action.payload
      saveCurrentRole(newMedicalSupplyOrgRole)
      return {
        ...state,
        currentRole: newMedicalSupplyOrgRole
      }
    case UserActionTypes.REFRESH_ORG:
      const {refreshedOrgs, newCurrentOrg} = action.payload
      return {
        ...state,
        orgs: refreshedOrgs,
        currentOrg: newCurrentOrg
      }
    case UserActionTypes.CLEAR_USER:
      return {
        ...INITIAL_STATE,
        loginSate: UserLoginState.LoggedOut
      }
    default:
      handleError({id: "UnknownUserReducerActionType", message: action.type, error: new Error().stack, track: true})
  }
}

const findSelectedOrg = (allOrgs: SaivaUser.OrgItem[]) : SaivaUser.OrgItem => {
  const persistedCurrentOrgId = loadCurrentOrgId()
  if(persistedCurrentOrgId) {
    const found = allOrgs.find((item) => item.id === persistedCurrentOrgId)
    if(found) return found
  }
  return allOrgs[0]
}


export const userActions = {
  setUser: (user: SaivaUser.LoggedUser) => createAction(UserActionTypes.SET_USER, user),
  setCurrentOrg: (newCurrentOrg: number) => createAction(UserActionTypes.SET_ORGANIZATION, newCurrentOrg),
  setCurrentMedicalSupplyOrg: (newRegularOrg: number, newParentOrg: number) => createAction(UserActionTypes.SET_MEDICAL_SUPPLY_ORG, {newRegularOrg, newParentOrg}),
  setCurrentMedicalSupplyOrgRole: (newMedicalSupplyOrgRole: string) => createAction(UserActionTypes.SET_MEDICAL_SUPPLY_ORG_ROLE, newMedicalSupplyOrgRole),
  refreshOrg: (refreshedOrgs) => createAction(UserActionTypes.REFRESH_ORG, {refreshedOrgs, newCurrentOrg: findSelectedOrg(refreshedOrgs)}),
  clearUser: () => createAction(UserActionTypes.CLEAR_USER, {}),
}

const UserProvider = ({ children }) => {

  const navigate = useNavigate()
  const location = useLocation()
  const [user, dispatch] = useReducer(userReducer, INITIAL_STATE)

  const handleLogin = async (tokens: SaivaAuth.Login | undefined, type) => {
    if(!tokens) return
    const user = await UserService.getLoggedUser()
    if(!user) return
    if(user.orgs && user.email) {
      const org = findSelectedOrg(user.orgs)
      mixpanel.setDefault({org, browser: getBrowserInfo, email: user.email, title: user.title})
      mixpanel.login(user.email, type)
    }
    dispatch(userActions.setUser(user))
  }

  const loginOtp = async (email: string, otp: string, phrase?: string) => {
    try {
      const tokens = await AuthService.loginOtp({ email, password: otp, phrase })
      await handleLogin(tokens, 'OTP')
    } catch (err: any) {
      handleError({id: "UserContextOTPLogin", error: err})
    }
  }

  const loginFCMOTP = async (tokens) => {
    await handleLogin(tokens, 'Mobile')
  }

  const loginPassword = async (username: string, password: string) => {
    try {
      const tokens = await AuthService.loginPassword({ username, password })
      await handleLogin(tokens, 'Password')
    } catch (err) {
      handleError({id: "UserContextPasswordLogin", error: err})
    }
  }

  const logout = async () => {
    try {
      await AuthService.logout()
      mixpanel.logout()
      dispatch(userActions.clearUser())
      window.localStorage.removeItem('filters')
    } catch (e: any) {
      handleError({id: "UserContextLogout", error: e})
    }
  }

  const refreshOrgs = async () => {
    try {
      const res = await UserService.getLoggedUser()
      if(res && res.orgs) {
        dispatch(userActions.refreshOrg(res.orgs))
      }
    } catch (err) {
      console.error(err)
    }
  }

  const hasPermission = (permission: UserPermission) : boolean => {
    try {
      return Boolean(user?.currentOrg?.permissions?.find(p => p === permission))
    } catch (e) {
      return false
    }
  }

  const hasFeature = (feature: OrgProductFeature | OrgProductFeature[] | undefined) : boolean => {
    try {
      if(Array.isArray(feature)) return Boolean(user?.currentOrg?.productFeatures.find(f => f === feature.find(i => i === f)))
      else return Boolean(user?.currentOrg?.productFeatures.find(f => f === feature))
    } catch (e) {
      return false
    }
  }

  const accessibleReports = () : OrgProductFeature[] => {
    try {
      // return user.currentOrg.productFeatures.filter(feat => feat.includes("analytics"))
      return user.currentOrg.productFeatures.filter(feat => feat.includes("reporting"))
    } catch (e) {
      return []
    }
  }

  const loadUserData = async () => {
    try {
      const user = getUserFromStorage()
      if(location.pathname.includes("join")) return
      if (!user) {
        if (location.pathname.includes("login")) return
        await logout()
      }
      const loggedUser = await UserService.getLoggedUser()
      if (loggedUser) {
        dispatch(userActions.setUser(loggedUser))
        if (loggedUser.orgs && loggedUser.email) {
          const org = findSelectedOrg(loggedUser.orgs)
          mixpanel.setDefault({ org, browser: getBrowserInfo, email: loggedUser.email, title: loggedUser.title })
        }
      } else {
        logout()
      }
    } catch (e) {
      handleError({id: "UserContextLoadData", error: e, customHandle: true})
    }
  }

  useCheckUserInactivity(logout)

  useEffect(() => {
    loadUserData()
  }, [])

  const value: IUserContext = {
    ...user,
    dispatch,
    loginOtp,
    loginFCMOTP,
    logout,
    refreshOrgs,
    loginPassword,
    hasFeature,
    hasPermission,
    accessibleReports
  }

  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export { UserProvider, UserContext, useUserContext }
