import { get, patch, post } from './utils/api-client'
import { generateFilterUrl, RequestFilter } from './utils/api-utils'
import { UserResponse } from './user-service'
import { InvitationResponse } from './invitation-service'
import { SaivaInvitation } from 'types/invitation-types'

export interface WoundsFilter extends RequestFilter {
  facility_states?: string[]
  payers?: string[]
  order_statuses?: string[]
  order_types?: string[]
}

export interface WoundCareAction extends RequestFilter {
  order_ids: string[]
}

const getWoundCareItems = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  filter: WoundsFilter,
  controller?: AbortController
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}',
    [
      { id: '{org_id}', value: orgId },
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg }
    ],
    filter
  )
  return await get<any>(url, { timeout: 300000, signal: controller?.signal })
}

const exportPDFWoundCareItems = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  filter: WoundsFilter
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}/pdf',
    [
      { id: '{org_id}', value: orgId },
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg }
    ],
    filter
  )
  return await get<any>(url)
}

const exportCSVWoundCareItems = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  filter: WoundsFilter
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}/csv',
    [
      { id: '{org_id}', value: orgId },
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg }
    ],
    filter
  )
  return await get<any>(url)
}

const setAction = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  orgIds: WoundCareAction,
  payload: any
) => {
  const url = generateFilterUrl(
    '/v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}',
    [
      { id: '{org_id}', value: orgId },
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg }
    ],
    orgIds
  )
  return await patch<any>(url, {
    payload
  })
}

const sendInvitationWoundCare = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  payload: UserResponse.Update.Invitation
) => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/invitations/wound_care/{org_id}',
    [
      { id: '{org_id}', value: orgId },
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg }
    ]
  )
  return await post<InvitationResponse.Item, SaivaInvitation.Item>(url, {
    payload
  })
}

const getOrganisationsWoundCare = async (
  filter: WoundsFilter
): Promise<any | undefined> => {
  const url = generateFilterUrl('v2/orgs/wound_care', [], filter)
  return await get<any>(url)
}

const getOrganisationWoundCare = async (
  orgId: string,
  filter: WoundsFilter
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/wound_care/{org_id}',
    [{ id: '{org_id}', value: orgId }],
    filter
  )
  return await get<any>(url)
}

const getMissingDoc = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  orderId: string
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}/{order_id}/missing_doc',
    [
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg },
      { id: '{org_id}', value: orgId },
      { id: '{order_id}', value: orderId }
    ]
  )
  return await get<any>(url)
}

const patchMissingDoc = async (
  currentMedicalSupplyOrg: string,
  orgId: string,
  orderId: string,
  payload: any
): Promise<any | undefined> => {
  const url = generateFilterUrl(
    'v2/orgs/{currentMedicalSupplyOrg}/wound_care/{org_id}/{order_id}/missing_doc',
    [
      { id: '{currentMedicalSupplyOrg}', value: currentMedicalSupplyOrg },
      { id: '{org_id}', value: orgId },
      { id: '{order_id}', value: orderId }
    ]
  )
  return await patch<any>(url, {payload})
}

const WoundCareService = {
  getWoundCareItems,
  exportPDFWoundCareItems,
  exportCSVWoundCareItems,
  setAction,
  sendInvitationWoundCare,
  getOrganisationsWoundCare,
  getOrganisationWoundCare,
  getMissingDoc,
  patchMissingDoc
}

export default WoundCareService
